/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader";
import { Route } from "react-router-dom";
import { Admin, Resource, translate } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import Dashboard from "./Dashboard";
import { AccessDenied } from "./components/layout/AccessDenied";
import { UserList, UserCreate, UserShow, UserEdit } from "./entities/users";
import { TicketList, TicketShow } from "./entities/tickets";
import restProvider from "./restProvider";
import {
  CompanyList,
  CompanyCreate,
  CompanyShow,
  CompanyEdit,
} from "./entities/companies";
import {
  FeesList,
  FeesCreate,
  FeesShow,
  FeesEdit,
} from "./entities/company_fees";
import { UserCardCreate, UserCardShow } from "./entities/user_cards";
import { UserPassportCreate } from "./entities/user_passports";
import { UserDriverLicenseCreate } from "./entities/user_driver_licenses";
import { CardTypeList, CardTypeShow } from "./entities/card_types";
import {
  AnalyticsList,
  AnalyticsCreate,
  AnalyticsShow,
  AnalyticsEdit,
} from "./entities/company_analytics";
import authProvider from "./authProvider";
import OAuthClient from "./utils/auth";
import Login from "./components/Login";
import {
  OperationList,
  OperationShow,
  OperationForm,
} from "./entities/operations";
import {
  SupplierList,
  SupplierCreate,
  SupplierShow,
  SupplierEdit,
} from "./entities/suppliers";
import { SupplyList, SupplyShow } from "./entities/supplies";
import { InvoiceList, InvoiceShow } from "./entities/invoices";
import { StatementList, StatementShow } from "./entities/statements";
import Layout from "./components/layout/Layout";
import frenchMessages from "./i18n/fr";
import "./App.css";
import {
  UserAnalyticsEdit,
  UserAnalyticsCreate,
  UserAnalyticsList,
  UserAnalyticsShow,
} from "./entities/user_analytics";
import {
  SubsidyList,
  SubsidyShow,
  SubsidyCreate,
  SubsidyEdit,
  DapUpload,
} from "./entities/subsidy";
import {
  ServiceList,
  ServiceShow,
  ServiceEdit,
  ServiceCreate,
} from "./entities/services";
import {
  EntityCreate,
  EntityEdit,
  EntityList,
  EntityShow,
} from "./entities/entities";
import { TicketLogList } from "./entities/ticket_log";
import {
  CustomerCreate,
  CustomerEdit,
  CustomerShow,
  CustomerList,
} from "./entities/customers";
import {
  AgencyCreate,
  AgencyEdit,
  AgencyList,
  AgencyShow,
} from "./entities/agencies";
import {
  SpecialFareCreate,
  SpecialFareList,
  SpecialFareShow,
  SpecialFareEdit,
} from "./entities/special_fares";
import {
  CarRentalConfigCompaniesList,
  CarRentalConfigCompaniesShow,
  CarRentalConfigCompaniesEdit,
  CarRentalConfigAgenciesList,
  CarRentalConfigAgenciesShow,
  CarRentalConfigAgenciesEdit,
} from "./entities/carrental_config";

const messages = {
  fr: frenchMessages,
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "fr");

const customRoutes = [
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/subsidy/upload" component={DapUpload} />,
];

class App extends Component {
  constructor(props) {
    super(props);
    try {
      OAuthClient.callback();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("exception while calling oauth client callback", e);
    }
  }

  render() {
    return (
      <Admin
        customRoutes={customRoutes}
        i18nProvider={i18nProvider}
        layout={Layout}
        dashboard={Dashboard}
        dataProvider={restProvider}
        authProvider={authProvider}
        loginPage={Login}
      >
        {(permissions) =>
          permissions != null &&
          (permissions.includes("resources_list") ||
            permissions.includes("company_dashboard"))
            ? [
              <Resource
                name="users"
                list={UserList}
                create={
                  permissions.includes("admin") ||
                    permissions.includes("company_dashboard")
                    ? translate(UserCreate)
                    : null
                }
                show={translate(UserShow)}
                edit={translate(UserEdit)}
              />,
              <Resource
                name="usercards"
                create={
                  permissions.includes("card_edit") ? UserCardCreate : null
                }
                show={
                  permissions.includes("card_list") ||
                    permissions.includes("company_dashboard")
                    ? UserCardShow
                    : null
                }
              />,
              <Resource
                name="userpassports"
                create={
                  permissions.includes("company_dashboard")
                    ? UserPassportCreate
                    : null
                }
              />,
              <Resource
                name="userdriverlicenses"
                create={
                  permissions.includes("company_dashboard")
                    ? UserDriverLicenseCreate
                    : null
                }
              />,
              <Resource
                name="cardtypes"
                list={CardTypeList}
                show={CardTypeShow}
              />,
              <Resource
                name="companies"
                list={
                  permissions.includes("resources_list") ? CompanyList : null
                }
                create={permissions.includes("admin") ? CompanyCreate : null}
                show={CompanyShow}
                edit={permissions.includes("admin") ? CompanyEdit : null}
              />,
              permissions.includes("resources_list") ? (
                <Resource
                  name="services"
                  list={ServiceList}
                  create={ServiceCreate}
                  show={ServiceShow}
                  edit={ServiceEdit}
                />
              ) : null,
              permissions.includes("admin") ? (
                <Resource
                  name="carrentalconfig_companies"
                  list={CarRentalConfigCompaniesList}
                  show={CarRentalConfigCompaniesShow}
                  edit={CarRentalConfigCompaniesEdit}
                />
              ) : null,
              permissions.includes("admin") ? (
                <Resource
                  name="carrentalconfig_agencies"
                  list={CarRentalConfigAgenciesList}
                  show={CarRentalConfigAgenciesShow}
                  edit={CarRentalConfigAgenciesEdit}
                />
              ) : null,
       
              permissions.includes("admin") ? (
                <Resource
                  name="special_fares"
                  list={SpecialFareList}
                  create={SpecialFareCreate}
                  show={SpecialFareShow}
                  edit={SpecialFareEdit}
                />
              ) : null,
              permissions.includes("admin") ? (
                <Resource
                  name="entities"
                  list={EntityList}
                  create={EntityCreate}
                  show={EntityShow}
                  edit={EntityEdit}
                />
              ) : null,
              <Resource
                name="fees"
                list={FeesList}
                create={permissions.includes("admin") ? FeesCreate : null}
                show={FeesShow}
                edit={permissions.includes("admin") ? FeesEdit : null}
              />,
              /**
               <Resource
               name="mission_orders"
               list={MissionOrdersList}
               />,
               */
              <Resource
                name="analytics"
                list={AnalyticsList}
                create={
                  permissions.includes("admin") ||
                    permissions.includes("company_dashboard")
                    ? AnalyticsCreate
                    : null
                }
                show={AnalyticsShow}
                edit={
                  permissions.includes("admin") ||
                    permissions.includes("company_dashboard")
                    ? AnalyticsEdit
                    : null
                }
              />,
              <Resource
                name="user_analytics"
                list={UserAnalyticsList}
                create={
                  permissions.includes("admin") ||
                    permissions.includes("company_dashboard")
                    ? UserAnalyticsCreate
                    : null
                }
                show={UserAnalyticsShow}
                edit={
                  permissions.includes("admin") ||
                    permissions.includes("company_dashboard")
                    ? UserAnalyticsEdit
                    : null
                }
              />,
              permissions.includes("dev") ? (
                <Resource
                  name="agencies"
                  list={AgencyList}
                  create={AgencyCreate}
                  show={AgencyShow}
                  edit={AgencyEdit}
                />
              ) : (
                <Resource
                  name="agencies"
                  list={AgencyList}
                  show={AgencyShow}
                />
              ), // if user is not a dev he can only list agencies (e.g. to select one config when editing a company) but not edit them
              <Resource name="tickets" list={TicketList} show={TicketShow} />,
              permissions.includes("resources_list") ? (
                <Resource
                  name="subsidy"
                  list={SubsidyList}
                  show={SubsidyShow}
                  create={SubsidyCreate}
                  edit={SubsidyEdit}
                />
              ) : null,
              permissions.includes("billing_backoffice") ? (
                <Resource
                  name="operations"
                  list={OperationList}
                  create={OperationForm}
                  show={OperationShow}
                  edit={OperationForm}
                />
              ) : null,
              permissions.includes("billing_backoffice") ? (
                <Resource
                  name="customers"
                  list={CustomerList}
                  create={CustomerCreate}
                  show={CustomerShow}
                  edit={CustomerEdit}
                />
              ) : null,
              permissions.includes("billing_backoffice") ? (
                <Resource
                  name="suppliers"
                  list={SupplierList}
                  create={SupplierCreate}
                  show={SupplierShow}
                  edit={SupplierEdit}
                />
              ) : null,
              permissions.includes("billing_backoffice") ? (
                <Resource
                  name="supplies"
                  list={SupplyList}
                  show={SupplyShow}
                />
              ) : null,
              permissions.includes("billing_backoffice") ? (
                <Resource
                  name="invoices"
                  list={InvoiceList}
                  show={InvoiceShow}
                />
              ) : null,
              permissions.includes("billing_backoffice") ? (
                <Resource
                  name="statements"
                  list={StatementList}
                  show={StatementShow}
                />
              ) : null,
              permissions.includes("admin") ? (
                <Resource name="ticket_log" list={TicketLogList} />
              ) : null,
            ]
            : [<Resource name="Accessdenied" list={AccessDenied} />]
        }
      </Admin>
    );
  }
}

export default hot(module)(App);
