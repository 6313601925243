/* eslint-disable react/no-unused-state */
import React from "react";
import {
  BooleanField,
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  SelectInput,
  NumberInput,
  TextInput,
  BooleanInput,
  Create,
  SimpleForm,
  required,
  ReferenceField,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  Edit,
  Filter,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  AutocompleteArrayInput,
  ArrayField,
  SingleFieldList,
  Link,
  FunctionField,
  Button,
  TopToolbar,
  CreateButton,
  ExportButton,
  Pagination,
  AutocompleteInput,
  NumberField,
  SelectField,
  downloadCSV,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { stringify } from "query-string";
import AddIcon from "@material-ui/icons/AddCircle";
import DuplicateIcon from "@material-ui/icons/FileCopy";
import PaidIcon from "@material-ui/icons/EuroSymbol";
import ClearIcon from "@material-ui/icons/Clear";
import LinkIcon from "@material-ui/icons/Link";
import PendingBillingIcon from "@material-ui/icons/Receipt";
import { Field } from "react-final-form";
import {
  TheTreepDateField,
  GolangDateParser,
  GolangDateFormatter,
  ValidateGolangDate,
} from "../components/Fields/TheTreepDateField";
import CurrencyField, {
  CurrencyParser,
  CurrencyFormatter,
} from "../components/Fields/CurrencyField";
import ColoredField from "../components/Fields/ColoredField";
import { apiHeaders, apiUrl, billingURL } from "../constants";
import ChipField from "../components/Fields/ChipField";
import { AnalyticsInput } from "../components/Input/AnalyticsInput";
import UpdateOperationStatusButton from "../components/Buttons/UpdateOperationStatusButton";
import ReconcileOperationsSuppliesButton from "../components/Buttons/ReconcileOperationsSuppliesButton";
import AutoReconciliationOperationSupplies from "../components/Buttons/AutoReconciliationOperationsSupplies";
import { renderCustomerNameWithType, renderCustomerName } from "./customers";

const OperationActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button
        disabled={props.filters == null || props.filters.length === 0}
        label="Supprimer les filtres"
        onClick={() => (props != null ? props.setFilters({}) : null)}
      >
        <ClearIcon />
      </Button>
      <CreateButton basePath={basePath} />
      <AutoReconciliationOperationSupplies
        label="Réconciliation automatique"
        icon={<LinkIcon />}
        resource="operations"
      />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

export const operationTypes = [
  { id: "purchase", name: "Achat" },
  { id: "cancellation", name: "Annulation" },
  { id: "modification", name: "Modification" },
  { id: "regularization", name: "Régularisation" },
];

export const operationCategories = [
  { id: "flight", name: "Vol" },
  { id: "rail", name: "Rail" },
  { id: "driver", name: "Taxi/VTC" },
  { id: "hotel", name: "Hotel" },
  { id: "car_rental", name: "Location de voitures" },
  { id: "subscription", name: "Abonnement" },
  { id: "discount_card", name: "Carte de réduction" },
];

export const operationStatuses = [
  { id: "draft", name: "Brouillon", backgroundColor: "lightgrey" },
  { id: "pending_billing", name: "À facturer", backgroundColor: "#ffcc80" },
  { id: "billed", name: "Facturé", backgroundColor: "#E1F5FE" },
  {
    id: "sent",
    name: "Facture envoyée",
    backgroundColor: "#E1F5FE",
    backgroundColorInvoice: "#ffcc80",
  },
  {
    id: "paid",
    name: "Payé",
    backgroundColor: "#C8E6C9",
    backgroundColorInvoice: "#C8E6C9",
  },
];

export const reconciliationStatuses = [
  { id: "pending", name: "En attente" },
  { id: "ok", name: "OK" },
];

const OperationPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const OperationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" />
    <ReferenceInput
      source="customer"
      alwaysOn
      reference="customers"
      filter={{ to_bill: true }}
      filterToQuery={(q) => ({ name: q })}
    >
      <AutocompleteInput optionText={renderCustomerNameWithType} />
    </ReferenceInput>
    <TextInput source="creator_lastname" alwaysOn />
    <SelectInput source="type" alwaysOn choices={operationTypes} />
    <SelectInput source="category" alwaysOn choices={operationCategories} />
    <SelectInput source="status" alwaysOn choices={operationStatuses} />
    <TextInput source="ticket_number" alwaysOn />
    <TextInput source="invoice_number" alwaysOn />
    <TextInput source="origin" alwaysOn />
    <TextInput source="destination" />
    <TextInput label="PAX" source="pax_contains" alwaysOn />
    <TextInput label="PNR" source="pnr" alwaysOn />
    <TextInput label="Ref" source="ref" alwaysOn />
    <NumberInput
      source="price"
      parse={CurrencyParser}
      format={CurrencyFormatter}
    />
    <BooleanInput source="online" />
    <BooleanInput
      label="Opérations de test (staging)"
      source="is_test"
      defaultValue={false}
    />
    <SelectInput
      label="Réconciliation"
      source="reconciliation_status"
      alwaysOn
      choices={reconciliationStatuses}
    />
    <TextInput
      label="À partir de (JJ/MM/AAAA)"
      source="start_operation_date"
      parse={GolangDateParser}
      format={GolangDateFormatter}
      validate={[ValidateGolangDate]}
      alwaysOn
    />
    <TextInput
      label="Jusqu'à (JJ/MM/AAAA)"
      source="end_operation_date"
      parse={GolangDateParser}
      format={GolangDateFormatter}
      validate={[ValidateGolangDate]}
      alwaysOn
    />
  </Filter>
);

const CreateOperationReferencingButton = ({ record }) => (
  <Button
    label="Opération liée"
    component={Link}
    to={{
      pathname: "/operations/create",
      state: {
        record: {
          creator_firstname: localStorage.getItem("user_firstname"),
          creator_lastname: localStorage.getItem("user_lastname"),
          creator_email: localStorage.getItem("user_email"),
          reference_operation: record.id,
          pnr: record.pnr,
          ref: record.ref,
          customer_id: record.customer_id,
          category: record.category,
          ticket_number: record.ticket_number,
          pax: record.pax,
          analytics: record.analytics,
          booking_id: record.booking_id,
          supplier: record.supplier,
          detail: record.detail,
          departure_date: record.departure_date,
          origin: record.origin,
          destination: record.destination,
          place_detail: record.place_detail,
          session_id: record.session_id,
          journey_id: record.journey_id,
          trip_id: record.trip_id,
          is_test: record.is_test,
          description: record.description,
        },
      },
    }}
  >
    <AddIcon />
  </Button>
);

const DuplicateOperationButton = ({ record }) => (
  <Button
    label="Dupliquer"
    component={Link}
    to={{
      pathname: "/operations/create",
      state: {
        record: {
          creator_firstname: localStorage.getItem("user_firstname"),
          creator_lastname: localStorage.getItem("user_lastname"),
          creator_email: localStorage.getItem("user_email"),
          reference_operation: record.reference_operation,
          pnr: record.pnr,
          ref: record.ref,
          customer: record.customer,
          category: record.category,
          type: record.type,
          ticket_number: record.ticket_number,
          pax: record.pax,
          analytics: record.analytics,
          booking_id: record.booking_id,
          supplier: record.supplier,
          detail: record.detail,
          departure_date: record.departure_date,
          origin: record.origin,
          destination: record.destination,
          place_detail: record.place_detail,
          session_id: record.session_id,
          journey_id: record.journey_id,
          trip_id: record.trip_id,
          is_test: record.is_test,
          description: record.description,
          online: record.online,
        },
      },
    }}
  >
    <DuplicateIcon />
  </Button>
);

const OperationBulkActionButtons = (props) => (
  <>
    <UpdateOperationStatusButton
      label="À facturer"
      status="pending_billing"
      {...props}
      icon={<PendingBillingIcon />}
    />
    <UpdateOperationStatusButton
      label="Payées"
      status="paid"
      {...props}
      icon={<PaidIcon />}
    />
    <ReconcileOperationsSuppliesButton {...props} />
  </>
);
function displayStatus(status) {
  for (let i = 0; i < operationStatuses.length; i++) {
    if (status === operationStatuses[i].id) {
      return operationStatuses[i].name;
    }
  }
}
function displayCategory(c) {
  for (let i = 0; i < operationCategories.length; i++) {
    if (c === operationCategories[i].id) {
      return operationCategories[i].name;
    }
  }
}

function displayTypes(type) {
  for (let i = 0; i < operationTypes.length; i++) {
    if (type === operationTypes[i].id) {
      return operationTypes[i].name;
    }
  }
}

function displayReconciation(c) {
  if (c.reconciliation_status !== null) {
    for (let i = 0; i < reconciliationStatuses.length; i++) {
      if (c === reconciliationStatuses[i].id) {
        return reconciliationStatuses[i].name;
      }
    }
  }
  return "";
}
function displayBoolean(b) {
  if (b) {
    return "oui";
  }

  return "non";
}
const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "tickets_id", "tickets").then((tickets) => {
    const data = records.map((record) => ({
      ...record,
      type: displayTypes(record.type),
      status: displayStatus(record.status),
      category: displayCategory(record.category),
      online: displayBoolean(record.online),
      lien: record.invoice_url,
      reconciliation_status: displayReconciation(record.reconciliation_status),
    }));
    const csv = convertToCSV({
      data,
      fields: [
        "id",
        "creator_firstname",
        "creator_lastname",
        "creator_email",
        "company_name",
        "customer",
        "type",
        "category",
        "supplier",
        "online",
        "operation_date",
        "update_date",
        "price",
        "excl_taxes",
        "status",
        "pnr",
        "origin",
        "destination",
        "pax",
        "vosfactures_id",
        "lien",
        "reconciliation_status",
      ],
    });
    downloadCSV(csv, "Operations");
  });
};

export const OperationList = (props) => (
  <List
    {...props}
    pagination={<OperationPagination />}
    title="Liste des opérations"
    rowclick="show"
    filters={<OperationFilter />}
    bulkActionButtons={<OperationBulkActionButtons />}
    actions={<OperationActions />}
    sort={{ field: "operation_date", order: "DESC" }}
  >
    <Datagrid
      rowclick="show"
      rowStyle={(operation) => {
        for (let i = 0; i < operationStatuses.length; i++) {
          const opStat = operationStatuses[i];
          if (operation.status === opStat.id) {
            return { backgroundColor: opStat.backgroundColor };
          }
        }
      }}
    >
      <TheTreepDateField source="creation_date" showTime />
      <ReferenceField
        source="customer"
        reference="customers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <FunctionField label="Nom" render={renderCustomerName} />
      </ReferenceField>
      <FunctionField
        label="Créateur"
        render={(record) =>
          `${record.creator_firstname} ${record.creator_lastname}`
        }
      />
      <TextField source="company_name" />
      <SelectField source="type" choices={operationTypes} />
      <SelectField source="category" choices={operationCategories} />
      <BooleanField source="online" />
      <ArrayField source="pax">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <TextField source="pnr" />
      <TextField source="ref" />
      <TheTreepDateField source="departure_date" />
      <TextField source="origin" />
      <TextField source="destination" />
      <CurrencyField source="price" />
      <SelectField source="status" choices={operationStatuses} />
      <TextField source="ticket_number" />
      <TextField label="Facture n°" source="invoice_number" />
      <FunctionField
        label="Facture"
        render={(record) =>
          record.invoice_url && (
            <a
              aria-label="Facture"
              href={record.invoice_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkIcon />
            </a>
          )
        }
      />
      <ColoredField
        source="reconciliation_status"
        colorFn={(status) =>
          status !== null && status.toLowerCase() === "ok" ? "green" : "red"
        }
        choices={reconciliationStatuses}
      />
      <ShowButton label="" />
      <EditButton label="" />
      <DuplicateOperationButton />
      <CreateOperationReferencingButton />
    </Datagrid>
  </List>
);

const OperationCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="show" submitOnEnter />
    <SaveButton
      label="Save and add"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);

export class OperationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userChoices: [],
      analytics: [],
      edit: props.id != null, // edit form
      companyID: null,
      customerID: null,
    };
    this.fetchCompany(null);
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const defaultInputStyle = {
      width: 256,
    };
    const InputDisabledStyle = {
      position: "relative",
      backgroundColor: "white",
      border: "none",
      padding: "10px",
      margin: "10px",
      width: "256px",
      WebkitAppearance: "none",
      MozAppearance: "textfield",
      fontSize: "16px",
    };
    const hotelFields = (
      <>
        <div>
          <TextInput
            style={defaultInputStyle}
            source="departure_date"
            format={GolangDateFormatter}
            label="Date de début (JJ/MM/AAAA)"
          />
        </div>
        <div>
          <TextInput
            style={defaultInputStyle}
            label="Nom de l'hôtel"
            source="place_detail"
          />
        </div>
        <div>
          <TextInput
            style={defaultInputStyle}
            label="Adresse"
            source="origin"
          />
        </div>
        <div>
          <NumberInput
            style={defaultInputStyle}
            label="Nb nuitées"
            source="nb_item"
          />
        </div>
        <div>
          <TextInput
            style={defaultInputStyle}
            label="PNR (segment passif)"
            source="pnr"
          />
        </div>
        <div>
          <TextInput
            style={defaultInputStyle}
            label="Référence fournisseur"
            source="ref"
          />
        </div>
      </>
    );
    const transportFields = (formData) => (
      <>
        <div>
          <TextInput
            style={defaultInputStyle}
            source="departure_date"
            format={GolangDateFormatter}
            parse={GolangDateParser}
            validate={[ValidateGolangDate]}
            label="Départ le (JJ/MM/AAAA)"
          />
        </div>
        <div>
          <TextInput
            style={defaultInputStyle}
            label="Origine"
            source="origin"
          />
        </div>
        <div>
          <TextInput style={defaultInputStyle} source="destination" />
        </div>
        <div>
          <TextInput style={defaultInputStyle} source="pnr" />
        </div>
        <div>
          <TextInput
            style={defaultInputStyle}
            label="Référence transporteur"
            source="ref"
          />
        </div>
        {formData.category === "rail" || formData.category === "flight" ? (
          <div>
            <TextInput
              style={defaultInputStyle}
              label="Numéro de ticket"
              source="ticket_number"
            />
          </div>
        ) : null}
      </>
    );

    const formContent = (
      <SimpleForm
        toolbar={this.state.edit ? undefined : <OperationCreateToolbar />}
        redirect="show"
        initialValues={
          this.state.edit
            ? null
            : {
              creator_firstname: localStorage.getItem("user_firstname"),
              creator_lastname: localStorage.getItem("user_lastname"),
              creator_email: localStorage.getItem("user_email"),
            }
        }
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.reference_operation == null) {
              return null;
            }
            return (
              <>
                Opération liée à l'opération
                <ReferenceField
                  {...rest}
                  formData
                  style={defaultInputStyle}
                  label="Référence l'opération"
                  source="reference_operation"
                  reference="operations"
                  link="show"
                >
                  <FunctionField
                    {...rest}
                    formData
                    style={defaultInputStyle}
                    render={(record) =>
                      `#${record.id}: ${record.type} ${
                        record.category
                      } : ${CurrencyFormatter(record.price)}€ [${
                        record.status
                      }]`
                    }
                  />
                </ReferenceField>
              </>
            );
          }}
        </FormDataConsumer>
        {this.state.edit ? <TextInput disabled source="id" /> : null}
        {operationType}
        {operationCategory}
        <TextInput source="detail" label="Détail (apparaît sur la facture)" />
        {this.state.edit ? operationStatus : null}
        <ReferenceInput
          source="customer"
          reference="customers"
          filter={{ to_bill: true }}
          filterToQuery={(q) => ({ name: q })}
        >
          <AutocompleteInput optionText={renderCustomerNameWithType} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            this.fetchCustomer(formData.customer);
            return (
              <ArrayInput source="pax">
                <SimpleFormIterator>
                  <TextInput />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
        <BooleanInput source="online" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (
              this.state.analytics == null ||
              this.state.analytics.length === 0
            ) {
              return null;
            }
            return (
              <AnalyticsInput
                style={defaultInputStyle}
                source="analytics"
                analytics={this.state.analytics}
              />
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceInput
              style={defaultInputStyle}
              validate={[required()]}
              label="Fournisseur"
              source="supplier"
              reference="suppliers"
              defaultValue={categoryToSupplier(formData.category)}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (
              formData.type === "modification" ||
              formData.type === "cancellation"
            ) {
              return (
                <>
                  <NumberInput
                    style={defaultInputStyle}
                    label="Solde (€) (- si € dû au client)"
                    validate={[required()]}
                    source="price"
                    parse={CurrencyParser}
                    format={CurrencyFormatter}
                  />
                  &nbsp;
                  <NumberInput
                    style={defaultInputStyle}
                    label="Dont pénalité (>0€)"
                    source="penalty"
                    parse={CurrencyParser}
                    format={CurrencyFormatter}
                  />
                </>
              );
            }
            if (
              formData.type === "regularization" ||
              formData.category === "subscription"
            ) {
              return (
                <>
                  <NumberInput
                    style={defaultInputStyle}
                    label="Solde HT (€) (- si € dû au client)"
                    parse={CurrencyParser}
                    format={CurrencyFormatter}
                    validate={[required()]}
                    source="excl_taxes"
                  />
                  &nbsp;
                  <NumberInput
                    style={defaultInputStyle}
                    label="Taux TVA (%)"
                    source="vat_rate"
                    defaultValue={0}
                  />
                  &nbsp;
                  <NumberInput
                    style={defaultInputStyle}
                    label="Prix TTC (€) (- si € dû au client)"
                    validate={[required()]}
                    source="price"
                    parse={CurrencyParser}
                    format={CurrencyFormatter}
                    defaultValue={Math.round(
                      formData.excl_taxes * (1 + formData.vat_rate / 100)
                    )}
                  />
                  &nbsp;
                  <NumberInput
                    style={defaultInputStyle}
                    label="Dont pénalité (>0€)"
                    source="penalty"
                  />
                </>
              );
            }
            return (
              <NumberInput
                style={defaultInputStyle}
                label="Prix TTC (€)"
                validate={[required()]}
                source="price"
                parse={CurrencyParser}
                format={CurrencyFormatter}
              />
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.category === "hotel"
              ? hotelFields
              : transportFields(formData)
          }
        </FormDataConsumer>

        <TextInput
          source="operation_date"
          format={GolangDateFormatter}
          parse={GolangDateParser}
          label="Date de l'opération (JJ/MM/AAAA)"
          defaultValue={GolangDateParser(new Date())}
          validate={[ValidateGolangDate]}
        />
        <TextInput source="travel" />
        <TextInput source="description" />
      </SimpleForm>
    );

    return this.state.edit ? (
      <Edit {...this.props} title="Modifier une opération">
        {formContent}
      </Edit>
    ) : (
      <Create {...this.props} title="Crééer une opération">
        {formContent}
      </Create>
    );
  }

  fetchCompany(companyID) {
    if (companyID === this.state.companyID) {
      // Do not fetch again the users
      return;
    }
    this.setState({ companyID });
    this.fetchCompanyUsers(companyID);
  }

  fetchCustomer(customerID) {
    if (customerID === this.state.customerID) {
      // Do not fetch again the users
      return;
    }
    this.setState({ customerID });
    this.fetchAnalytics(customerID);
  }

  fetchCompanyUsers(companyID) {
    const headers = apiHeaders;
    headers.Authorization = localStorage.getItem("token");
    const options = {
      headers: new Headers(headers),
    };
    const query = {
      limit: 1000,
      sort_by: "lastname",
      order_by: "asc",
    };
    if (companyID != null) {
      query.company_id = companyID;
    }
    fetch(`${apiUrl}/users/?${stringify(query)}`, options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        if (result == null) {
          return [];
        }
        const userChoices = result.map((user) => {
          let name = user.firstname;
          if (user.lastname) {
            name = `${name} ${user.lastname.toUpperCase()}`;
          }
          return { id: name, name };
        });
        this.setState({ userChoices });
      });
  }

  fetchAnalytics(customerID) {
    if (customerID == null) {
      return;
    }
    const headers = apiHeaders;
    headers.Authorization = localStorage.getItem("token");
    const options = {
      headers: new Headers(headers),
    };
    fetch(`${billingURL}/customers/${customerID}`, options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ customer }) => {
        this.setState({
          analytics:
            customer && customer.config && customer.config.analytics
              ? customer.config.analytics
              : null,
        });
      });
  }
}

const operationType = (
  <SelectInput source="type" validate={[required()]} choices={operationTypes} />
);
const operationCategory = (
  <SelectInput
    source="category"
    validate={[required()]}
    choices={operationCategories}
  />
);
const operationStatus = (
  <SelectInput
    source="status"
    defaultValue="draft"
    choices={operationStatuses}
  />
);

const OperationShowActions = ({ basePath, data, ...rest }) => (
  <TopToolbar
    style={{
      zIndex: 2,
      display: "inline-block",
      float: "right",
    }}
  >
    <EditButton basePath={basePath} record={data} />
    <UpdateOperationStatusButton
      label="À facturer"
      status="pending_billing"
      selectedIds={data != null ? [data.id] : []}
      basePath
      data
      {...rest}
      icon={<PendingBillingIcon />}
    />
    <UpdateOperationStatusButton
      label="Payées"
      status="paid"
      basePath
      data
      selectedIds={data != null ? [data.id] : []}
      {...rest}
      icon={<PaidIcon />}
    />
  </TopToolbar>
);

const ConditionalOriginField = ({ record, ...rest }) =>
  record !== "hotel" ? (
    <TextField label="Origine" source="origin" record={record} {...rest} />
  ) : null;

const ConditionalAddressField = ({ record, ...rest }) =>
  record === "hotel" ? (
    <TextField label="Adresse" source="origin" record={record} {...rest} />
  ) : null;

export const OperationShow = (props) => (
  <Show actions={<OperationShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        formData
        label="Liée à l'opération"
        source="reference_operation"
        reference="operations"
        allowEmpty
        link="show"
      >
        <FunctionField
          render={(record) =>
            `#${record.id}: ${record.type} ${
              record.category
            } : ${CurrencyFormatter(record.price)}€ [${record.status}]`
          }
        />
      </ReferenceField>
      <ReferenceField
        source="customer"
        reference="customers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Créateur"
        render={(record) =>
          `${record.creator_firstname} ${record.creator_lastname}`
        }
      />
      <TextField source="company_name" />
      <TextField source="type" />
      <TextField source="detail" />
      <TextField source="category" />
      <BooleanField source="online" />
      <ColoredField
        label="status"
        source="status"
        colorFn={(status) => {
          switch (status) {
          case "paid":
            return "green";
          case "billed":
            return "blue";
          case "draft":
            return "grey";
          case "pending_billing":
            return "orange";
          default:
            return "black";
          }
        }}
      />
      <ReferenceField
        label="Fournisseur"
        source="supplier"
        reference="suppliers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ColoredField
        label="reconciliation_status"
        source="reconciliation_status"
        colorFn={(status) => (status.toLowerCase() === "ok" ? "green" : "red")}
      />
      <ArrayField label="Réconciliée avec" source="reconciled_with">
        <Datagrid>
          <ReferenceField
            label="Fournitures"
            source="id"
            reference="supplies"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <TextField label="invoice_type" source="invoice_type" />
          <TextField label="category" source="category" />
          <TheTreepDateField label="supply_date" source="supply_date" />
          <TextField label="invoice_number" source="invoice_number" />
          <TextField source="status" />
          <CurrencyField
            label="price_incl_taxes"
            source="price_incl_taxes"
            invertColors
          />
          <TextField source="pnr" />
          <TextField source="ticket_number" />
          <TheTreepDateField source="departure_date" />
          <ConditionalOriginField />
          <ArrayField source="pax">
            <SingleFieldList>
              <ChipField />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <TheTreepDateField source="operation_date" />
      <TheTreepDateField source="creation_date" />
      <TheTreepDateField source="update_date" />
      <CurrencyField label="Prix TTC" source="price" />
      <CurrencyField label="Prix HT" source="excl_taxes" />
      <NumberField label="Taux TVA" source="vat_rate" />
      <TextField source="pnr" />
      <TextField source="ticket_number" />
      <TextField source="travel" />
      <TextField label="Facture n°" source="invoice_number" />
      <TextField label="URL de facture" source="invoice_url" />
      <TextField source="billing_date" />
      <TheTreepDateField source="departure_date" />
      <TextField source="place_detail" />
      <ConditionalAddressField />
      <TextField source="destination" />
      <ArrayField source="pax">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="analytics">
        <Datagrid>
          <TextField source="key" />
          <TextField source="label" />
          <TextField source="value" />
        </Datagrid>
      </ArrayField>
      <TextField source="description" />
      <ArrayField source="ticket_ids">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <TextField source="booking_id" />
      <TextField source="ref" />
    </SimpleShowLayout>
  </Show>
);

function categoryToSupplier(category) {
  switch (category) {
  case "hotel":
    return "CDS";
  case "flight":
  case "rail":
    return "SABRE";
  case "driver":
    return "g7";
  default:
    return null;
  }
}
