import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ShowButton,
  Show,
  SimpleShowLayout,
} from "react-admin";

export const CardTypeList = (props) => (
  <List {...props} title="Liste des cartes des utilisateurs de the treep">
    <Datagrid rowclick="show" perPage={200}>
      <NumberField source="uid" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="description" sortable={false} />
      <TextField source="passenger_type" sortable={false} />
      <TextField source="code" sortable={false} />
      <TextField source="class" sortable={false} />
      <TextField source="class_code" sortable={false} />
      <TextField source="type" sortable={false} />
      <TextField source="card_type" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
);

export const CardTypeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="uid" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="description" sortable={false} />
      <TextField source="passenger_type" sortable={false} />
      <TextField source="code" sortable={false} />
      <TextField source="class" sortable={false} />
      <TextField source="class_code" sortable={false} />
      <TextField source="type" sortable={false} />
      <TextField source="card_type" sortable={false} />
    </SimpleShowLayout>
  </Show>
);
