import React, { Component } from "react";
import { withDataProvider } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class PAODetailsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  async getPAOinfos() {
    const { dataProvider, record } = this.props;
    const { data } = await dataProvider("PAO_DATA", "", { id: record });
    this.setState({ paoData: JSON.stringify(data, null, 4) });
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleClick = () => {
    this.setState({ isOpen: true });
    this.getPAOinfos();
  };

  render() {
    return (
      <>
        <Button variant="contained" color="primary" onClick={this.handleClick}>
          afficher les données PAO du ticket
        </Button>
        <Dialog
          open={this.state.isOpen}
          maxWidth="lg"
          onClose={this.handleDialogClose}
          aria-labelledby="draggable-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Informations sur le dossier pao
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <pre>{this.state.paoData}</pre>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              variant="contained"
              color="secondary"
            >
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withDataProvider(PAODetailsButton);
