import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Confirm, crudDelete } from "react-admin";

class DeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { basePath, crudDelete, resource, id } = this.props;
    crudDelete(resource, id, { views: 0 }, basePath);
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Suppression"
          content={`Etes-vous sûr de vouloir supprimer ${this.props.resource_fr} ? `}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default connect(undefined, { crudDelete })(DeleteButton);
