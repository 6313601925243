import React from "react";
import {
  DisabledInput,
  TopToolbar,
  AutocompleteInput,
  Toolbar,
  ListButton,
  SaveButton,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  ShowButton,
  Show,
  SimpleShowLayout,
  NumberField,
} from "react-admin";
import { Typography } from "@material-ui/core";
import {
  inputsGroupStyle,
  inputsGroupSpaceStyle,
  titleSpaceStyle,
} from "./user-form-styles";
import {
  TheTreepDateField,
  DateUserFormatter,
} from "../components/Fields/TheTreepDateField";
import DateTextInput from "../components/Input/DateTextInput";

export const UserCardList = (props) => (
  <List {...props} title="Liste des cartes des utilisateurs de the treep">
    <Datagrid rowclick="show" perPage={200}>
      <NumberField source="id" sortable={false} />
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
        link="show"
      >
        <TextField source="lastname" />
      </ReferenceField>
      <ReferenceField
        label="CardType"
        source="cardtype_id"
        reference="cardtypes"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="card_name" sortable={false} />
      <TextField source="firstname" sortable={false} />
      <TextField source="lastname" sortable={false} />
      <TextField source="cardnumber" sortable={false} />
      <TheTreepDateField source="effective_date" sortable={false} />
      <TheTreepDateField source="expiration_date" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
);

const UserCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      label="Save"
      redirect={`/users/${props.record.user_id}/show/1`} // "/1" redirect to document tab
      submitOnEnter
    />
  </Toolbar>
);

const UserCardCreateActions = ({ basePath, data, resource }) => (
  <div>
    {data && (
      <TopToolbar>
        <ListButton
          basePath={data}
          label={data === "/users" ? "Retour à la liste" : "Retour au profil"}
        />
      </TopToolbar>
    )}
  </div>
);

export const UserCardCreate = (props) => (
  <Create
    {...props}
    actions={
      <UserCardCreateActions
        data={
          props.location.state
            ? `/users/${props.location.state.record.user_id}/show`
            : "/users"
        }
      />
    }
  >
    <SimpleForm toolbar={<UserCreateToolbar />} redirect="list">
      {props.location.state && (
        <div style={titleSpaceStyle}>
          <Typography fullWidth variant="h5">
            {`Nouvelle carte pour ${props.location.state.record.firstname} ${props.location.state.record.lastname} #${props.location.state.record.user_id}`}
          </Typography>
        </div>
      )}
      <ReferenceInput
        label="CardType"
        source="cardtype_id"
        reference="cardtypes"
        validate={[required()]}
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div style={inputsGroupStyle}>
        <TextInput fullWidth source="card_name" validate={[]} />
        <div style={inputsGroupSpaceStyle} />
        <TextInput
          fullWidth
          source="cardnumber"
          parse={(number) =>
            number == null ? null : number.replace(/\s/g, "").replace(/\./g, "")
          }
          validate={[required()]}
        />
      </div>
      <div style={inputsGroupStyle}>
        <TextInput
          disabled
          fullWidth
          source="firstname"
          validate={[required()]}
        />
        <div style={inputsGroupSpaceStyle} />
        <TextInput
          disabled
          fullWidth
          source="lastname"
          validate={[required()]}
        />
      </div>
      <div style={inputsGroupStyle}>
        <DateTextInput
          fullWidth
          source="effective_date"
          format={DateUserFormatter}
          label="Date d'effet (JJ/MM/AAAA)"
          validate={[]}
        />
        <div style={inputsGroupSpaceStyle} />
        <DateTextInput
          fullWidth
          source="expiration_date"
          format={DateUserFormatter}
          label="Fin de validité (JJ/MM/AAAA)"
          validate={[]}
        />
      </div>
    </SimpleForm>
  </Create>
);

export const UserCardShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" sortable={false} />
      <TextField source="user_id" sortable={false} />
      <TextField source="cardtype_id" sortable={false} />
      <TextField source="cardtype" sortable={false} />
      <TextField source="card_name" sortable={false} />
      <TextField source="firstname" sortable={false} />
      <TextField source="lastname" sortable={false} />
      <TextField source="cardnumber" sortable={false} />
      <TheTreepDateField source="effective_date" sortable={false} />
      <TheTreepDateField source="expiration_date" sortable={false} />
    </SimpleShowLayout>
  </Show>
);
