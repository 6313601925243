const worldRegions = [
  {
    value: "north-africa",
    name: "Afrique du nord",
    countries: ["DZ", "EG", "LY", "MA", "SD", "TN", "EH"],
  },
  {
    value: "subsaharan-africa",
    name: "Afrique subsaharienne",
    countries: [
      "AO",
      "BJ",
      "BW",
      "IO",
      "BF",
      "BI",
      "CV",
      "CM",
      "CF",
      "TD",
      "KM",
      "CG",
      "CD",
      "CI",
      "DJ",
      "GQ",
      "ER",
      "SZ",
      "ET",
      "TF",
      "GA",
      "GM",
      "GH",
      "GN",
      "GW",
      "KE",
      "LS",
      "LR",
      "MG",
      "MW",
      "ML",
      "MR",
      "MU",
      "YT",
      "MZ",
      "NA",
      "NE",
      "NG",
      "RE",
      "RW",
      "SH",
      "ST",
      "SN",
      "SC",
      "SL",
      "SO",
      "ZA",
      "SS",
      "TZ",
      "TG",
      "UG",
      "ZM",
      "ZW",
    ],
  },
  {
    value: "latin-america-caribbean",
    name: "Amérique latine et caraïbes",
    countries: [
      "AI",
      "AG",
      "AR",
      "AW",
      "BS",
      "BB",
      "BZ",
      "BO",
      "BQ",
      "BV",
      "BR",
      "KY",
      "CL",
      "CO",
      "CR",
      "CU",
      "CW",
      "DM",
      "DO",
      "EC",
      "SV",
      "FK",
      "GF",
      "GD",
      "GP",
      "GT",
      "GY",
      "HT",
      "HN",
      "JM",
      "MQ",
      "MX",
      "MS",
      "NI",
      "PA",
      "PY",
      "PE",
      "PR",
      "BL",
      "KN",
      "LC",
      "MF",
      "VC",
      "SX",
      "GS",
      "SR",
      "TT",
      "TC",
      "UY",
      "VE",
      "VG",
      "VI",
    ],
  },
  {
    value: "northern-america",
    name: "Amérique du nord",
    countries: ["BM", "CA", "GL", "PM", "US"],
  },
  {
    value: "eastern-asia",
    name: "Asie de l'est",
    countries: ["CN", "HK", "JP", "KP", "KR", "MO", "MN", "TW"],
  },
  {
    value: "central-asia",
    name: "Asie centrale",
    countries: ["KZ", "KG", "TJ", "TM", "UZ"],
  },
  {
    value: "south-eastern-asia",
    name: "Asie du sud-est",
    countries: [
      "BN",
      "KH",
      "ID",
      "LA",
      "MY",
      "MM",
      "PH",
      "SG",
      "TH",
      "TL",
      "VN",
    ],
  },
  {
    value: "southern-asia",
    name: "Asie du sud",
    countries: ["AF", "BD", "BT", "IN", "IR", "MV", "NP", "PK", "LK"],
  },
  {
    value: "western-asia",
    name: "Asie de l'ouest",
    countries: [
      "AM",
      "AZ",
      "BH",
      "CY",
      "GE",
      "IQ",
      "IL",
      "JO",
      "KW",
      "LB",
      "OM",
      "PS",
      "QA",
      "SA",
      "SY",
      "TR",
      "AE",
      "YE",
    ],
  },
  {
    value: "eastern-europe",
    name: "Europe de l'est",
    countries: ["BY", "BG", "CZ", "HU", "MD", "PL", "RO", "RU", "SK", "UA"],
  },
  {
    value: "northern-europe",
    name: "Europe du nord",
    countries: [
      "AX",
      "DK",
      "EE",
      "FO",
      "FI",
      "GG",
      "IS",
      "IE",
      "IM",
      "JE",
      "LV",
      "LT",
      "NO",
      "SJ",
      "SE",
      "GB",
    ],
  },
  {
    value: "southern-europe",
    name: "Europe du sud",
    countries: [
      "AL",
      "AD",
      "BA",
      "HR",
      "GI",
      "GR",
      "VA",
      "IT",
      "MT",
      "ME",
      "MK",
      "PT",
      "SM",
      "RS",
      "SI",
      "ES",
    ],
  },
  {
    value: "western-europe",
    name: "Europe de l'ouest",
    countries: ["AT", "BE", "FR", "DE", "LI", "LU", "MC", "NL", "CH"],
  },
  {
    value: "australia-nz",
    name: "Australie et nouvelle zélande",
    countries: ["AU", "CX", "CC", "HM", "NZ", "NF"],
  },
  {
    value: "melanesia",
    name: "Mélanésie",
    countries: ["FJ", "NC", "PG", "SB", "VU"],
  },
  {
    value: "micronesia",
    name: "Micronésie",
    countries: ["GU", "KI", "MH", "FM", "NR", "MP", "PW", "UM"],
  },
  {
    value: "polynesia",
    name: "Polynésie",
    countries: ["AS", "CK", "PF", "NU", "PN", "WS", "TK", "TO", "TV", "WF"],
  },
];

export default worldRegions;
