import React from "react";
import {
  ArrayField,
  SingleFieldList,
  Filter,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  ReferenceInput,
  required,
  DisabledInput,
  TextInput,
  SelectInput,
  BooleanInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  EditButton,
  DeleteButton,
  FunctionField,
} from "react-admin";
import ChipField from "../components/Fields/ChipField";
import FormatTextInput from "../components/Input/TextInput";

const AnalyticsFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <ReferenceInput
      label="Entreprise"
      source="company_id"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
    <TextInput source="label" alwaysOn />
    <TextInput source="key" alwaysOn />
    <SelectInput
      source="required"
      alwaysOn
      choices={[
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ]}
    />
  </Filter>
);

export const UserAnalyticsList = ({ permissions, ...props }) => (
  <List {...props} title="Champs structurels" filters={<AnalyticsFilter />}>
    <Datagrid rowclick="show" perPage={100}>
      <NumberField source="id" />
      <ReferenceField
        label="Entreprise"
        source="company_id"
        reference="companies"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="label" />
      <BooleanField source="required" />
      <BooleanField source="is_gdpr_sensitive" />
      <ArrayField source="values" sortable={false}>
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <TextField source="regex" sortable={false} />
      <TextField source="key" />
      <ShowButton label="" />
      {permissions != null && permissions.includes("admin") && (
        <EditButton label="" />
      )}
      <DeleteButton label="" />
    </Datagrid>
  </List>
);

const AnalyticsCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter />
    <SaveButton
      label="Save and add"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);

export const UserAnalyticsCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<AnalyticsCreateToolbar />} redirect="list">
      <ReferenceInput
        label="Entreprise"
        source="company_id"
        reference="companies"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label="Internal name (no space)"
        source="name"
        validate={[required()]}
      />
      <TextInput source="label" validate={[required()]} />
      <BooleanInput source="required" />
      <BooleanInput source="is_gdpr_sensitive" />
      <ArrayInput label="Values" source="values">
        <SimpleFormIterator>
          <TextInput label=" " format={FormatTextInput} />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="regex" />
      <TextInput source="key" />
    </SimpleForm>
  </Create>
);

export const UserAnalyticsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="uid" />
      <ReferenceInput
        label="Entreprise"
        source="company_id"
        reference="companies"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label="Internal name (no space)"
        source="name"
        validate={[required()]}
      />
      <TextInput source="label" validate={[required()]} />
      <BooleanInput source="required" />
      <BooleanInput source="is_gdpr_sensitive" />
      <ArrayInput label="Values" source="values">
        <SimpleFormIterator>
          <TextInput label=" " format={FormatTextInput} />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="regex" />
      <TextInput source="key" />
    </SimpleForm>
  </Edit>
);

export const UserAnalyticsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" sortable={false} />
      <ReferenceField
        label="Entreprise"
        source="company_id"
        reference="companies"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" sortable={false} />
      <TextField source="label" sortable={false} />
      <BooleanField source="required" sortable={false} />
      <BooleanField source="is_gdpr_sensitive" />
      <ArrayField source="values" sortable={false}>
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <TextField source="regex" sortable={false} />
      <TextField source="key" sortable={false} />
    </SimpleShowLayout>
  </Show>
);
