import React, { Component } from "react";
import { isRequired, FieldTitle } from "ra-core";
import { Field } from "react-final-form";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiTextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

export class AnalyticsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  const;

  getValidator = (isRequired) =>
    isRequired ? (value) => (value ? undefined : "Required") : () => {};

  renderTextField = ({
    input,
    label,
    meta: { touched, error, initial },
    ...custom
  }) => (
    <MuiTextField
      label={label}
      error={!!(touched && error)}
      helperText={touched && error}
      required={initial?.analytic?.required}
      {...input}
      {...custom}
    />
  );

  renderSelectField =
    (values) =>
      ({ input, label, meta: { touched, error }, ...custom }) =>
        (
          <MuiTextField
            style={{ marginRight: 20 }}
            label={label}
            error={!!(touched && error)}
            helperText={touched && error}
            select
            margin="normal"
            {...input}
            {...custom}
          >
            {/* eslint-disable-next-line react/no-this-in-sfc */}
            {this.props.allowEmpty ? <MenuItem value="" key="null" /> : null}
            {values.map((value) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </MuiTextField>
        );

  render() {
    const defaultStyle = {
      width: "150px",
      marginRight: "10px",
      marginTop: 0,
    };
    const { className, label, source, resource, validate, analytics, isUser } =
      this.props;
    return (
      <FormControl fullWidth margin="normal" className={className}>
        <InputLabel htmlFor={source} shrink>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired(validate)}
          />{" "}
          &nbsp;
        </InputLabel>
        <div style={{ marginTop: 20 }}>
          {analytics.map((analytic, i) => {
            let component = this.renderTextField;
            if (analytic.values != null && analytic.values.length !== 0) {
              component = this.renderSelectField(analytic.values);
            }
            return (
              <Field
                label={
                  <FieldTitle
                    label={analytic.label}
                    source={`${source}[${i}]`}
                    resource={resource}
                    isRequired={analytic.required}
                  />
                }
                validate={this.getValidator(analytic.required)}
                name={`${source}[${i}]`}
                style={defaultStyle}
                component={component}
                format={(val) =>
                  val != null && val.value != null ? val.value : ""
                }
                parse={(val) =>
                  isUser
                    ? { analytic, value: val }
                    : { key: analytic.name, label: analytic.label, value: val }
                }
                key={`${source}[${i}]`}
              />
            );
          })}
        </div>
      </FormControl>
    );
  }
}

AnalyticsInput.defaultProps = {
  options: {},
  fullWidth: true,
};
export default AnalyticsInput;
