import React, { useState } from "react";
import { Button, Confirm, useDataProvider, useNotify } from "react-admin";
import { useHistory } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const NewCustomerConfig = ({ link }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const history = useHistory();

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    createCustomerConfig();
  };

  const createCustomerConfig = async () => {
    try {
      const data = await dataProvider("CREATE_CUSTOMER_CONFIG", "", {
        url: link.href,
      });
      history.push(`/carrentalconfig_companies/${data.result}`);
      notify("La configuration a été créée.", { type: "info" });
    } catch (error) {
      notify(`Erreur lors de la création : ${error.message}`, {
        type: "warning",
      });
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button onClick={handleClick}>
        <AddCircleOutlineIcon />
      </Button>

      <Confirm
        isOpen={isOpen}
        title="Initialisation de la configuration de l’entité"
        content="Attention, cette entité n'ayant actuellement aucune configuration spécifique, il est nécessaire d'en créer une. Cette dernière héritera de toutes les paramètres de l’entreprise parente. Souhaitez valider cette action ?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default NewCustomerConfig;
