import frenchMessages from "ra-language-french";

export default {
  ...frenchMessages,
  pos: {
    search: "Rechercher",
    dashboard: {
      travel_day: "Voyages du jour",
      pending_tks: "Tickets en attente d'annulation",
      nb_tickets: "Nombre de tickets",
      nb_users: "Nombre d'utilisateurs",
      nb_operations: "Nombre d'opérations non réconciliées",
      nb_supplies: "Nombre de fournitures non réconciliées",
    },
    menu: {
      // TODO
    },
    op: {
      yes: "oui",
      no: "non",
      MS: "MME",
      MR: "M.",
    },
    user: {
      title_info: "Information utilisateur",
      title_handicap: "Handicap",
      title_analytics: "Champs structurels",
      title_cards: "Cartes",
      passport: "Passport",
      documents: "Documents",
    },
  },
  resources: {
    tickets: {
      name: "Billet |||| billets",
      fields: {
        agency: "Agence",
        status: "Statut",
        from: "De",
        to: "À",
        departure: "Départ",
        price: "Prix",
        supplier_ref: "Ref. fournisseur",
        ticket_time_limit: "Limite de validité",
        ticket_travelers: "Voyageurs",
        emission_date: "Date de réservation",
        provider: "Fournisseur",
        offer: {
          fare_category: "Catégorie",
        },
        creator_company: {
          name: "Entreprise",
        },
      },
    },
    ticket_log: {
      name: "Logs des tickets",
      fields: {
        ticketID: "ID ticket",
        travelID: "ID voyage",
        ticket_status: "Statut",
        ticket_type: "Type de ticket",
        booking_id: "ID réservation",
        headsign: "Vol/Train",
        request_id: "Requête",
        time: "Date",
        user_id: "Utilisateur",
        pnr: "PNR",
      },
    },
    companies: {
      name: "Entreprises",
      fields: {
        name: "Nom",
        domain: "Domaine",
        agency: "agence",
        payment_methods: "Modes de paiement autorisés",
        payment_method: "Modes de paiement autorisés",
        travel_policy: "Politique de voyage informative",
        contact: "Email de contact",
        hotel_enabled: "Activer l'hôtel SBT",
        cds_profile: "Nom du profil CDS",
        cds_login: "Login CDS API Rest",
        cds_password: "Mot de passe CDS API Rest",
        sabre_profile_name: "Nom du profil Sabre utilisé au booking",
        sabre_profile_domain_id: "PCC du profil Sabre utilisé au booking",
        sabre_lcc_profile_domain_id: "PCC du profil Sabre LOW COSTS",
        sabre_lcc_profile_name: "Nom du profil Sabre LOW COSTS",
        jenji_user: "Utilisateur Jenji",
        jenji_apikey: "Clé d'API Jenji",
        carrental_enabled: "Activer la location de voiture",
        carbookr_id: "Identifiant du compte client Carbookr",
        disable_sending_thetreep_emails:
          "Désactiver l'envoi d'emails par the Treep",
        enable_user_auto_enrollment:
          "Les nouveaux comptes sont autorisés si leur email appartient au domaine",
        email_header: "Entête des emails",
        email_footer: "Pieds de page des emails",
        email_onboarding: "Onboarding",
        email_confirmation: "Validation de l'email",
        email_welcome: "Bienvenue",
        email_password_reset: "Reset mot de passe",
        email_booking_confirmation: "Confirmation de réservation",
        email_approval: "Demande d'approbation",
        email_approval_pending:
          "Demande d'approbation toujours en attente (demandeur)",
        email_approval_reminder: "Relance de demande d'approbation en attente",
        email_approval_confirmation: "Confirmation d'approbation",
        analytic: {
          key: "Clé SABRE/Amadeus",
          cds_key: "Clé CDS",
        },
        value: "Valeur",
        config: {
          railOfflineCancellation: "Annulation hors ligne pour les trains",
          airOfflineCancellation: "Annulation hors ligne pour l'avion",
          readOnly: "Lecture seule",
          travelAgencyID: "Configuration d'agence",
          test: "Activer le mode démonstration, aucun billet ne sera réellement émis",
          oneClickApprovalEnabled: "Activer l'approbation en un clic",
          analyticValues: "Valeurs analytiques de l'entreprise",
          enableTravelersAnalytics: "Analytiques voyageurs éditables",
          missionOrderConfig: {
            companyName: "Nom de l'entreprise",
            dateFormat: "Format de date",
            prefix: "Prefix",
            digitNumber: "Code Digit",
            enabled: "Ordre de mission activé",
            logo: "Logo",
            signatureLabel: "Intitulé de la signature",
            signatureLocation: "Lieu de la signature",
            footer: "Pied de page",
          },
          providers: {
            rail: "Fournisseur rail",
            air: "Fournisseur vols",
            travelerProfile: "Fournisseur profils voyageurs",
            hotel: "Fournisseur hotels",
            carRental: "Fournisseur location de voitures",
          },
          amadeusCustomerProfile: {
            officeID: "Amadeus Office ID",
            uniqueID: "Amadeus Unique ID",
          },
          amadeusConfigs: {
            defaultQueue: {
              number: "Numéro Ex: 78",
            },
            defaultProfile: {
              pcc: "PCC",
              name: "Nom",
            },
            queues: {
              bookingQueue: {
                number: "Numéro Ex: 80",
              },
              railQueue: {
                number: "Numéro Ex: 80",
              },
              airQueue: {
                number: "Numéro Ex: 80",
              },
              lccQueue: {
                number: "Numéro Ex: 80",
              },
              hotelQueue: {
                number: "Numéro Ex: 80",
              },
              carRentalQueue: {
                number: "Numéro Ex: 80",
              },
              ticketingAutoQueue: {
                number: "Numéro Ex: 80",
              },
              ticketingManualQueue: {
                number: "Numéro Ex: 80",
              },
              rejectionQueue: {
                number: "Numéro Ex: 80",
              },
              cancellationQueue: {
                number: "Numéro Ex: 80",
              },
              disabledPAXQueue: {
                number: "Numéro Ex: 80",
              },
            },
            railTicketing: "Rail",
            flightTicketing: "Vols domestiques",
            flightLCCTicketing: "Vols low-costs",
            flightInternationalTicketing: "Vols internationaux",
          },
          sabreConfigs: {
            bookingPCC: "PCC de réservation",
            ticketingPCC: "PCC d'émission",
            paxProfile: {
              paxPCC: "PCC création des profils voyageur",
              templatePCC: "PCC template",
              templateID: "ID template",
            },
            defaultProfile: {
              pcc: "PCC",
              name: "Nom",
            },
            defaultQueue: {
              pcc: "PCC",
              number: "Numéro Ex: 80",
            },
            queues: {
              bookingQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              railQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              airQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              lccQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              hotelQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              carRentalQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              ticketingAutoQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              ticketingManualQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              rejectionQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              cancellationQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
              disabledPAXQueue: {
                pcc: "PCC",
                number: "Numéro Ex: 80",
              },
            },
            railTicketing: "Rail",
            flightTicketing: "Vols domestiques",
            flightLCCTicketing: "Vols low-costs",
            flightInternationalTicketing: "Vols internationaux",
            printerProfileNumber: "Numéro de profil d'imprimante d'émission",
            retentionSegment: {
              text: "Texte à afficher",
              durationDays: "Nombre de jours de rétention",
            },
            lccPaymentConfig: {
              profile: {
                pcc: "PCC",
                name: "Nom",
              },
              billingInfo: {
                name: "Nom",
                address: "Adresse",
                postalCode: "Code Postal",
                city: "Ville",
                country: "Pays",
              },
              creditCardSecurityCode: "CVV carte de crédit",
            },
          },
          sabreProfile: {
            pcc: "PCC",
            name: "Nom",
          },
          sabrePaxCustomerProfile: {
            pcc: "PCC du profil Sabre auquel sont rattachés les profils voyageurs",
            number:
              "N° d'ID du profil Sabre auquel sont rattachés les profils voyageurs",
            type: "Type de profil",
          },
          cdsProfile: {
            dutyCode: "Duty Code",
          },
          carbookrProfile: {
            clientID: "ClientID",
          },
          hiddenFlightPrice: "Masquer le prix des billets d’avions",
        },
      },
    },
    users: {
      name: "Utilisateurs",
      fields: {
        firstname: "Prénom",
        lastname: "Nom",
        username: "Nom d'utilisateur",
        company_id: "Entreprise",
        inscription_date: "Date d'inscription",
        birthdate: "Date de naissance",
        status: "Statut",
        phone: "Tél",
        permission: "Permission",
        level: "Niveau",
        approval_level: "Niveau d'approbation",
        auth_by_company: "Compte reconnu par l'entreprise",
        user_id: "Utilisateur",
        cards: "Cartes",
        entities: "Entités",
        approvers: "Approbateurs",
        approvees: "Approuve",
        canApproveSafetyApproval: "Approbateur de sureté",
        cardtype: {
          card_type: "Type",
          name: "Intitulée",
        },
        cardnumber: "n° carte",
        analytic: {
          uid: "UID",
          name: "Nom",
          label: "Label",
        },
        value: "valeur",
        handicap: "Handicap",
        disability_degree: "Degré d'invalidité",
        wheelchair: "Chaise roulante",
        wheelchair_dimensions: "Dimensions fauteuil (plié) larg x long x haut",
        wheelchair_weight: "Poids du fauteuil roulant (kg)",
        wheelchair_wheel_thickness: "Epaisseur des roues (cm)",
        need_accompanying: "Carte d'invalidité avec mention tierce personne",
        accompanying_dog: "Chien accompagnant",
        amadeus_profile_id: "Identifiant unique Amadeus",
        amadeus_office_id: "Identifiant office Amadeus",
        service: "Service",
        category: "Catégorie",
      },
    },
    fees: {
      name: "Frais de service",
      fields: {
        operation: "Opération",
        rate: "Taux",
        price: "Prix",
        calculation_unit: "Unité de calcul",
        currency: "Monnaie",
        online: "En ligne",
      },
    },
    analytics: {
      name: "Champs analytiques",
      fields: {
        name: "Nom",
        required: "Requis",
        values: "Valeurs",
        key: "Clé facturation PNR",
      },
    },
    user_analytics: {
      name: "Champs structurels",
      fields: {
        name: "Nom",
        required: "Requis",
        is_gdpr_sensitive: "Sensible RGPD",
        values: "Valeurs",
        key: "Clé facturation PNR",
      },
    },
    operations: {
      name: "Opération",
      fields: {
        creation_date: "Date de création",
        operation_date: "Date de l'opération",
        creator_id: "Créateur",
        company_name: "Entreprise",
        creator_lastname: "Créateur (nom de famille)",
        customer: "Client",
        category: "Catégorie",
        online: "Online",
        departure_date: "Départ le",
        origin: "De",
        destination: "À",
        price: "Prix (€)",
        status: "Statut",
        invoice_number: "Facture n°",
        reconciliation_status: "Réconciliation",
        ticket_number: "Numéro de ticket",
        place_detail: "Nom",
        travel: "Identifiant du voyage",
      },
    },
    invoices: {
      name: "Factures",
      fields: {
        vosfactures_id: "ID vosfactures",
        customer_id: "Entreprise",
        customer: "Client",
        customer_info: {
          name: "Nom",
          recipients: "Destinataires",
        },
        status: "Statut",
        total_incl_taxes: "TTC",
        total_excl_taxes: "TT HT",
        total_vat: "TT TVA",
        subtotal_op: "Ss-total ops",
        subtotal_fee: "Ss-total frais",
        billing_date: "Facturé le",
        number: "N° facture",
        fields: {
          vosfactures_id: "ID vosfactures",
          customer_id: "Entreprise",
          customer_info: {
            name: "Nom",
            recipients: "Destinataires",
          },
          total_incl_taxes: "TTC",
          total_excl_taxes: "TT HT",
          total_vat: "TT TVA",
          subtotal_op: "subtotal_op",
          subtotal_fee: "subtotal_fee",
          billing_date: "Date",
          number: "Numéro de Facture",
        },
      },
    },
    statements: {
      name: "Relevés",
      fields: {
        creation_date: "Date de création",
        customer_name: "Nom du client",
        customer: "Client",
        status: "Statut",
        start_period: "Début",
        end_period: "Fin",
        payment_date: "Paiement",
        sending_date: "Envoyé le",
        total_incl_taxes: "TTC",
        total_excl_taxes: "TT HT",
        total_vat: "TT TVA",
      },
    },
    suppliers: {
      name: "Fournisseurs",
      fields: {
        name: "Nom",
        agency: "Agence",
        categories: "Catégories",
      },
    },
    supplies: {
      name: "Fournitures",
      fields: {
        supply_date: "Date d'approvisionnement",
        invoice_number: "Numéro de facture",
        reconciliation_status: "Réconciliation",
        category: "Catégorie",
        start_supply_date: "À partir de",
        end_supply_date: "Jusqu'à",
        departure_date: "Départ le",
        price_incl_taxes: "TTC",
        ticket_number: "Numéro de ticket",
      },
    },
    usercards: {
      name: "Cartes",
      fields: {
        user_id: "Utilisateur",
        cardtype_id: "Type de carte",
        card_name: "Nom carte",
        firstname: "Prénom",
        lastname: "Nom",
        cardnumber: "Numéro de carte",
        effective_date: "Effective le",
        expiration_date: "Expire le",
      },
    },
    userpassports: {
      name: "Passeports",
    },
    Userdriverlicense: {
      name: "Permis de conduire",
    },
    subsidy: {
      name: "Numéro d'accord",
      fields: {
        id: "ID",
        number: "Numéro d'accord",
        entity_id: "Entité",
        firstname: "Prénom",
        lastname: "Nom",
        rate: "Taux de PEC",
        origin: "Origine",
        destination: "Destination",
        transport_modes: "Mode de transports",
        current_count: "Nb voyages executés",
        max_count: "Nb voyages max",
        additional_information: "Info supplémentaires",
        comment: "Commentaire",
        home_coordinate: {
          lat: "Latitude du domicile",
          lon: "Longitude du domicile",
        },
        acceptable_classes: {
          rail: "Classes acceptées pour le train",
          flight: "Classes acceptées pour l'avion",
        },
        nb_accompanying: "Nombre d'accompagnants autorisés",
      },
    },
    entities: {
      name: "Entités",
      fields: {
        name: "Nom",
        place: "Adresse de facturation",
        address: {
          description: "Adresse de facturation",
        },
        contact: "Email de contact",
        vosfactures_id: "ID vosfactures",
        company: {
          name: "Entreprise",
        },
        sabre_profile_domain_id: "PCC du profil Sabre",
        sabre_profile_name: "Nom du profil Sabre",
        is_open: "N'importe quel employé peut utiliser cette entité",
        analytic: {
          key: "Clé SABRE/Amadeus",
          cds_key: "Clé CDS",
        },
        value: "Valeur",
        config: {
          missionOrderConfig: {
            companyName: "Nom de l'entreprise",
            dateFormat: "Format de date",
            prefix: "Prefix",
            digitNumber: "Le nombre de chiffre du numéro d'increment",
            enabled: "Ordre de mission activé",
            logo: "Logo",
            signatureLabel: "Intitulé de la signature",
            signatureLocation: "Lieu de la signature",
            footer: "Texte de pied de page",
            oneClickApprovalEnabled: 'Activer le "approuver en un Clic"',
          },
          readOnly: "Lecture seule",
          analyticValues: "Valeurs analytiques de l'entreprise",
          travelAgencyID: "Configuration d'agence",
          test: "Activer le mode démonstration, aucun billet ne sera réellement émis",
          providers: {
            rail: "Fournisseur rail",
            air: "Fournisseur vols",
            travelerProfile: "Fournisseur profils voyageurs",
            hotel: "Fournisseur hotels",
            carRental: "Fournisseur location de voitures",
          },
          sabreConfigs: {
            bookingPCC: "PCC de réservation",
            ticketingPCC: "PCC d'émission",
            paxProfile: {
              paxPCC: "PCC création des profils voyageur",
              templatePCC: "PCC template",
              templateID: "ID template",
            },
            defaultProfile: {
              pcc: "PCC",
              name: "Nom",
            },
            defaultQueue: {
              pcc: "PCC",
              number: "Numéro",
            },
            queues: {
              bookingQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              railQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              airQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              lccQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              hotelQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              carRentalQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              ticketingAutoQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              ticketingManualQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              rejectionQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              cancellationQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              disabledPAXQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
            },
            railTicketing: "Rail",
            flightTicketing: "Vols domestiques",
            flightLCCTicketing: "Vols low-costs",
            flightInternationalTicketing: "Vols internationaux",
            printerProfileNumber: "Numéro de profil d'imprimante d'émission",
            retentionSegment: {
              text: "Texte à afficher",
              durationDays: "Nombre de jours de rétention",
            },
            lccPaymentConfig: {
              profile: {
                pcc: "PCC",
                name: "Nom",
              },
              billingInfo: {
                name: "Nom",
                address: "Adresse",
                postalCode: "Code Postal",
                city: "Ville",
                country: "Pays",
              },
              creditCardSecurityCode: "CVV carte de crédit",
            },
          },
          sabreProfile: {
            pcc: "PCC",
            name: "Nom",
          },
          sabrePaxCustomerProfile: {
            pcc: "PCC du profil Sabre auquel sont rattachés les profils voyageurs",
            number:
              "N° d'ID du profil Sabre auquel sont rattachés les profils voyageurs",
            type: "Type de profil",
          },
          cdsProfile: {
            dutyCode: "Duty Code",
          },
          carbookrProfile: {
            clientID: "ClientID",
          },
        },
      },
    },
    services: {
      name: "Services",
      fields: {
        name: "Nom",
        parent_id: "Service parent",
        company_id: "Entreprise",
        approvers: {
          firstname: "Prénom",
          lastname: "Nom",
          approval_level: "Niveau d'approbation",
          service: "Service de rattachement",
        },
        company: {
          name: "Entreprise",
        },
      },
    },
    special_fares: {
      name: "Tarifs négociés aériens",
      fields: {
        name: "Nom",
        userID: "Pour l'utilisateur uniquement",
        companyID: "Pour l'entreprise",
        code: "Code",
        label: "Label",
        isPaxCode: "Le code est un type de voyageur",
        tag: "Tag",
        auto: "Affichés automatiquement",
        company: {
          name: "Entreprise",
        },
      },
    },
    customers: {
      name: "Clients",
      fields: {
        type: "Type",
        parent: "Appartient au client",
        name: "Nom",
        firstname: "Prénom",
        lastname: "Nom",
        vosfactures_id: "ID vosfactures",
        billing_name: "Nom apparaissant sur la facture",
        city: "Ville",
        address: "Adresse de facturation",
        country: "Pays",
        email: "Email",
        billing_time: "Heure de facturation (HH:MM)",
        billing_contacts: "Contacts de facturation",
        billing_emails: "Emails de facturation",
        billing_period: "Période de facturation",
        billing_day: "Jour de facturation",
        config: {
          name: "Entreprise",
          fees: "Frais de service",
          analytics: "Analytiques de facturation",
        },
        to_bill: "À facturer",
        only_draft: "Laisser les opérations en brouillon",
        bill_only_fees: "Ne pas facturer le prix des tickets",
        bill_only_purchase: "Facturer que les achats",
        disable_reconciliation: "Désactiver la réconciliation",
      },
    },
    agencies: {
      name: "Configurations d'agence",
      fields: {
        name: "Nom",
        website: "URL",
        contact: "Email de contact",
        afterSalesContact: "Email d'après-vente",
        recapContact: "Email contact sur TreepRecap",
        recapPhone: "Téléphone sur TreepRecap",
        leisure: "Configuration par défaut",
        sendingRecapDisabled: "Désactiver l'envoi des treep recaps",
        billingName: "Nom du client the treep à facturer",
        oneClickApprovalApp: "Application pour approuver en un Clic",
        railOfflineCancellation: "Annulation hors ligne pour les trains",
        airOfflineCancellation: "Annulation hors ligne pour l'avion",
        railOfflineMessageCancellation:
          "Message annulation hors ligne pour les trains",
        airOfflineMessageCancellation:
          "Message annulation hors ligne pour l'avion",
        agencyAnalytics: "Champs analytiques de l'agence",
        analyticType: "Type",
        key: "Clef",
        cdsKey: "Clef CDS",
        defaultValue: "valeur par défaut",
        readOnly: "Lecture seule",
        providers: {
          rail: "Fournisseur rail",
          air: "Fournisseur vols",
          hotel: "Fournisseur hotels",
          carRental: "Fournisseur location de voitures",
          travelerProfile: "Fournisseur profils voyageurs",
        },
        sabreConfig: {
          pcc: "PCC",
          agentID: "AgentID",
          password: "Mot de passe",
          returnFareOptimizationDisabled:
            "Optimisation tarifaire aller/retour avion",
          defaultConfigs: {
            bookingPCC: "PCC de réservation",
            ticketingPCC: "PCC d'émission",
            railTicketing: "Rail",
            flightTicketing: "Vols domestiques",
            flightLCCTicketing: "Vols low-costs",
            flightInternationalTicketing: "Vols internationaux",
            printerProfileNumber: "Numéro de profil d'imprimante d'émission",
            paxProfile: {
              paxPCC: "PCC création des profils voyageur",
              templatePCC: "PCC template",
              templateID: "ID template",
            },
            defaultProfile: {
              pcc: "PCC",
              name: "Nom",
            },
            retentionSegment: {
              text: "Texte à afficher",
              durationDays: "Nombre de jours de rétention",
            },
            defaultQueue: {
              pcc: "PCC",
              number: "Numéro",
            },
            queues: {
              bookingQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              railQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              airQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              lccQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              hotelQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              carRentalQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              ticketingAutoQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              ticketingManualQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              rejectionQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              cancellationQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
              disabledPAXQueue: {
                pcc: "PCC",
                number: "Numéro",
              },
            },
            lccPaymentConfig: {
              profile: {
                pcc: "PCC",
                name: "Nom",
              },
              billingInfo: {
                name: "Nom",
                address: "Adresse",
                postalCode: "Code Postal",
                city: "Ville",
                country: "Pays",
              },
              creditCardSecurityCode: "CVV carte de crédit",
            },
          },
        },
        amadeusConfig: {
          endpoint: "Endpoint",
          user: "Utilisateur",
          password: "Mot de passe",
          organization: "Organisation",
          officeID: "Office ID",
          defaultConfigs: {
            queues: {
              bookingQueue: {
                number: "Numéro",
              },
              railQueue: {
                number: "Numéro",
              },
              airQueue: {
                number: "Numéro",
              },
              lccQueue: {
                number: "Numéro",
              },
              hotelQueue: {
                number: "Numéro",
              },
              carRentalQueue: {
                number: "Numéro",
              },
              ticketingAutoQueue: {
                number: "Numéro",
              },
              ticketingManualQueue: {
                number: "Numéro",
              },
              rejectionQueue: {
                number: "Numéro",
              },
              cancellationQueue: {
                number: "Numéro",
              },
              disabledPAXQueue: {
                number: "Numéro",
              },
            },
            railTicketing: "Rail",
            flightTicketing: "Vols domestiques",
            flightLCCTicketing: "Vols low-costs",
            flightInternationalTicketing: "Vols internationaux",
          },
        },
        cdsConfig: {
          login: "Login",
          password: "Mot de passe",
          defaultDutyCode: "Duty Code",
        },
        carbookrConfig: {
          id: "Identifiant",
          password: "Mot de passe",
          impersonatedID: "Identifiant Agence",
        },
        carRental: {
          maxDistance: "Distance maximum",
          maxLocation: "Location maximum",
          carGroups: "Type de voiture",
        },
        duffel: {
          token: "Token d'accès",
          cardID: "Identifiant de carte logée",
          currency: "Monnaie",
        },
      },
    },
  },
};
