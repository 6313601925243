import React, { Component } from "react";
import { Button, Confirm, withDataProvider } from "react-admin";

class SyncUsersButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.syncUsers();
  };

  async syncUsers() {
    const { dataProvider, selectedIds, resource } = this.props;
    dataProvider(
      "SYNC",
      "",
      { user_ids: selectedIds },
      {
        onSuccess: {
          notification: {
            body: "Utilisateur(s) synchronisé(s)",
            level: "info",
          },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
        onError: {
          notification: {
            body: "Erreur lors de la synchronisation",
            level: "warning",
          },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Synchronisation utilisateurs"
          content="Voulez-vous forcer la synchronisation des profils utilisateurs sur le GDS ?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default withDataProvider(SyncUsersButton);
