import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Field } from "react-final-form";
import LocationInput from "./LocationInput";

const PlaceInput = (props) => (
  <span>
    <Field name={props.source} label={props.label} component={LocationInput} />
  </span>
);
export default PlaceInput;
