export default function FormatTextInput(value) {
  if (
    value != null &&
    Object.keys(value).length === 0 &&
    value.constructor === Object
  ) {
    return "";
  }
  return value;
}
