import React, { Component } from "react";
import {
  Button,
  Confirm,
  GET_LIST,
  withDataProvider,
  useQueryWithStore,
  GET_MANY,
} from "react-admin";

class OnboardingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.sendOnboardings();
  };

  async sendOnboardings() {
    const { dataProvider, selectedIds, resource } = this.props;
    dataProvider(
      "ONBOARDING",
      "",
      { user_ids: selectedIds },
      {
        onSuccess: {
          notification: { body: "Onboarding envoyé", level: "info" },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
        onError: {
          notification: { body: "Erreur lors de l'envoi", level: "warning" },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Envoyer email d'Onboarding"
          content="Voulez-vous envoyer aux utilisateurs séléctionnés l'email d'onboarding ?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default withDataProvider(OnboardingButton);
