import React from "react";
import Dropzone from "react-dropzone";
import {
  List,
  Filter,
  NumberInput,
  SelectInput,
  TextInput,
  Show,
  SimpleShowLayout,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  SelectField,
  Create,
  Edit,
  SimpleForm,
  TopToolbar,
  CreateButton,
  ExportButton,
  Link,
  Button,
  SelectArrayInput,
  ArrayField,
  SingleFieldList,
  withDataProvider,
  showNotification,
  NumberField,
  ReferenceField,
  ReferenceInput,
  FunctionField,
  AutocompleteArrayInput,
} from "react-admin";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import UploadIcon from "@material-ui/icons/CloudUpload";
import AddIcon from "@material-ui/icons/Add";
import ChipField from "../components/Fields/ChipField";
import { TheTreepDateField } from "../components/Fields/TheTreepDateField";
import IncrementTripButton from "../components/Buttons/IncrementTripButton";

export const subsidyTrasportModes = [
  { id: "rail", name: "Train" },
  { id: "flight", name: "Avion" },
  { id: "boat", name: "Bateau" },
];

export const subsidyRates = [
  { id: 55, name: "55%" },
  { id: 65, name: "65%" },
  { id: 100, name: "100%" },
];

export const subsidyStatuses = [
  { id: "ok", name: "OK" },
  { id: "error", name: "Erreur", backgroundColor: "#ef9a9a" },
];

const railClasses = [
  { id: "1st", name: "1ère classe" },
  { id: "2nd", name: "2ème classe" },
];

const flightClasses = [
  { id: "first", name: "First" },
  { id: "business", name: "Affaire" },
  { id: "economy", name: "Économie" },
];

const ClassesField = ({ record }) => {
  const types = [];
  if (
    record.acceptable_classes === null ||
    record.acceptable_classes === undefined
  ) {
    return null;
  }
  if (record.acceptable_classes.rail !== undefined) {
    types.push("rail");
  }
  if (record.acceptable_classes.flight !== undefined) {
    types.push("flight");
  }
  return (
    <>
      {types.map((type, i) => (
        <ul key={i}>
          {type}
          {record.acceptable_classes[type].map((item, ri) => (
            <li key={ri}>{item}</li>
          ))}
        </ul>
      ))}
    </>
  );
};

ClassesField.defaultProps = { addLabel: true };

const SubsidyActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basepath: basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          basepath: basePath,
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basepath={basePath} />
      <Button
        label="Uploader un fichier"
        component={React.forwardRef((props, ref) => (
          <Link {...props} />
        ))}
        to={{
          pathname: "/subsidy/upload",
        }}
      >
        <UploadIcon />
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={100000}
      />
    </TopToolbar>
  );
};

const SubsidyFilter = (props) => (
  <Filter {...props}>
    <TextInput source="number" alwaysOn />
    <ReferenceInput
      source="entity_id"
      reference="entities"
      filter={{ name: "CNAM" }}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput source="status" choices={subsidyStatuses} alwaysOn />
    <TextInput source="firstname" alwaysOn />
    <TextInput source="lastname" alwaysOn />
    <SelectInput source="rate" choices={subsidyRates} alwaysOn />
    <TextInput source="origine" alwaysOn />
    <TextInput source="destination" alwaysOn />
    <AutocompleteArrayInput
      source="transport_modes"
      choices={subsidyTrasportModes}
      alwaysOn
    />
  </Filter>
);

const IncrementButton = ({ record, ...props }) => (
  <IncrementTripButton
    label="voyage"
    record={record.id}
    icon={<AddIcon />}
    {...props}
  />
);

export const SubsidyList = (props) => (
  <List
    {...props}
    title="Liste des numéros d'accords"
    rowclick="show"
    filters={<SubsidyFilter />}
    actions={<SubsidyActions />}
    sort={{ field: "creation_date", order: "DESC" }}
  >
    <Datagrid
      rowStyle={(ticket) => {
        if (!ticket || !ticket.status) return {};
        for (let i = 0; i < subsidyStatuses.length; i++) {
          const ticketStat = subsidyStatuses[i];
          if (ticket.status === ticketStat.id) {
            return { backgroundColor: ticketStat.backgroundColor };
          }
        }
      }}
    >
      <TheTreepDateField source="creation_date" showTime />
      <TextField source="number" />
      <ReferenceField
        source="entity_id"
        reference="entities"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="status" choices={subsidyStatuses} />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <SelectField source="rate" choices={subsidyRates} />
      <FunctionField
        label="Origine"
        render={(record) => displaySubsidyOrigin(record)}
      />
      <TextField source="destination" />
      <ArrayField source="transport_modes" sortable={false}>
        <SingleFieldList>
          <ChipField clickable={false} />
        </SingleFieldList>
      </ArrayField>
      <FunctionField label="Accompagnant" render={displayAccompanying} />
      <TextField source="additional_information" />
      <TextField source="current_count" />
      <TextField source="max_count" />
      <IncrementButton />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const SubsidyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="number" />
      <ReferenceField
        source="entity_id"
        reference="entities"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TheTreepDateField source="creation_date" showTime />
      <TheTreepDateField source="update_date" showTime />
      <SelectField source="status" choices={subsidyStatuses} />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <SelectField source="rate" choices={subsidyRates} />
      <TextField source="origin" />
      <NumberField source="home_coordinate.lat" />
      <NumberField source="home_coordinate.lon" />
      <TextField source="destination" />
      <ArrayField source="transport_modes" sortable={false}>
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <ClassesField label="Classes acceptées" />
      <FunctionField label="Accompagnant" render={displayAccompanying} />
      <TextField source="additional_information" />
      <TextField source="current_count" />
      <TextField source="max_count" />
      <TextField source="comment" />
    </SimpleShowLayout>
  </Show>
);

export const SubsidyCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="number" />
      <ReferenceInput
        source="entity_id"
        reference="entities"
        filter={{ name: "CNAM" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        label="Status"
        source="status"
        choices={subsidyStatuses}
        defaultValue="ok"
      />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <SelectInput source="rate" choices={subsidyRates} />
      <TextInput source="origin" />
      <NumberInput source="home_coordinate.lat" />
      <NumberInput source="home_coordinate.lon" />
      <TextInput source="destination" />
      <SelectArrayInput
        source="transport_modes"
        choices={subsidyTrasportModes}
      />
      <SelectArrayInput
        source="acceptable_classes.rail"
        choices={railClasses}
      />
      <SelectArrayInput
        source="acceptable_classes.flight"
        choices={flightClasses}
      />
      <NumberInput source="nb_accompanying" />
      <TextInput source="additional_information" />
      <NumberInput source="current_count" />
      <NumberInput source="max_count" />
      <TextInput source="comment" />
    </SimpleForm>
  </Create>
);

export const SubsidyEdit = (props) => (
  <Edit
    {...props}
    transform={(data) => ({
      ...data,
      home_coordinate:
        !data.home_coordinate?.lat && !data.home_coordinate?.lat
          ? null
          : data.home_coordinate,
    })}
  >
    <SimpleForm>
      <TextInput disabled source="number" />
      <ReferenceInput
        source="entity_id"
        reference="entities"
        filter={{ name: "CNAM" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput label="Status" source="status" choices={subsidyStatuses} />
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <SelectInput source="rate" choices={subsidyRates} />
      <TextInput source="origin" />
      <NumberInput source="home_coordinate.lat" />
      <NumberInput source="home_coordinate.lon" />
      <TextInput source="destination" />
      <SelectArrayInput
        source="transport_modes"
        choices={subsidyTrasportModes}
      />
      <SelectArrayInput
        source="acceptable_classes.rail"
        choices={railClasses}
      />
      <SelectArrayInput
        source="acceptable_classes.flight"
        choices={flightClasses}
      />
      <NumberInput source="nb_accompanying" />
      <TextInput source="additional_information" />
      <NumberInput source="current_count" />
      <NumberInput source="max_count" />
      <TextInput source="comment" />
    </SimpleForm>
  </Edit>
);

const Upload = ({ dataProvider, showNotification, push }) => (
  <>
    <h2>Chargement d'un fichier de DAP</h2>
    <SimpleReactFileUpload
      dataProvider={dataProvider}
      showNotification={showNotification}
      push={push}
    />
  </>
);

export const DapUpload = connect(null, {
  showNotification,
  push,
})(withDataProvider(Upload));

// @todo Add style to this function...
class SimpleReactFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      warnings: [],
    };
    this.fileUpload = this.fileUpload.bind(this);
  }

  fileUpload = (file) => {
    const { push, dataProvider, showNotification } = this.props;
    dataProvider("UPLOAD_SUBSIDIES", "subsidies", { file })
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.warnings &&
          res.data.result.warnings.length > 0
        ) {
          this.setState({ warnings: res.data.result.warnings });
          showNotification(
            `Fichier de DAP chargé avec ${res.data.result.warnings.length} avertissements`,
            "info"
          );
        } else {
          showNotification("Fichier de DAP chargé sans erreur", "info");
          push("/subsidy");
        }
      })
      .catch((error) => {
        showNotification(
          `Erreur lors du chargement du fichier de DAP ${error.message}`,
          "warning"
        );
      });
  };

  render() {
    const { warnings, files } = this.state;
    return (
      <>
        <Dropzone
          onDrop={(files) => {
            this.setState({ files });
            return files.forEach((file) => this.fileUpload(file));
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone-container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>
                  Déposer ici le fichier de DAP à uploader ou bien cliquez ici
                  pour le sélectionner.
                </p>
              </div>
              {files && files.length > 0 && (
                <aside>
                  <h4>Fichier chargé</h4>
                  <ul>
                    {files.map((file) => (
                      <li key={file.path}>
                        {file.path} -{file.size} bytes
                      </li>
                    ))}
                  </ul>
                </aside>
              )}
            </section>
          )}
        </Dropzone>
        {warnings && warnings.length > 0 && (
          <div className="warnings">
            <h3>Avertissements lors de l'importation</h3>
            <em>
              Les lignes avec avertissement ont été importées, mais elles
              garderont le statut "Erreur" et ne pourront pas être utilisées
              Online jusqu'à ce qu'elles soient manuellement corrigées.
            </em>
            <br />
            {warnings.map((warn, i) => (
              <div className="warning" key={i}>
                {warn}
              </div>
            ))}
            <br />
            <Button
              className="subsidy-errors-link"
              label="Voir la liste des DAPs en erreur"
              component={React.forwardRef((props, ref) => (
                <Link {...props} />
              ))}
              to={{
                pathname: "/subsidy",
                search:
                  "?filter=%7B%22status%22%3A%22error%22%7D&order=DESC&page=1&perPage=10&sort=creation_date",
              }}
            />
          </div>
        )}
      </>
    );
  }
}

function displayAccompanying(record) {
  if (!record.nb_accompanying) {
    return "Non";
  }
  if (record.nb_accompanying === 1) {
    return "Oui";
  }
  if (record.nb_accompanying > 1) {
    return `Plusieurs (${record.nb_accompanying})`;
  }
  return "invalide";
}

function displaySubsidyOrigin(record) {
  if (record.origin && record.origin !== "") {
    return record.origin;
  }
  if (record.home_coordinate == null) {
    return "";
  }
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.google.com/maps/@?api=1&map_action=map&zoom=13&center=${record.home_coordinate.lat},${record.home_coordinate.lon}`}
    >
      {record.home_coordinate.lat};{record.home_coordinate.lon}
    </a>
  );
}
