import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from "react-admin";
import OAuthClient from "./utils/auth";
import { apiHeaders, apiUrl } from "./constants";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const check = OAuthClient.checkToken({});
    return check != null && check ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_lastname");
    localStorage.removeItem("user_firstname");
    localStorage.removeItem("user_email");
    localStorage.removeItem("permissions");
    OAuthClient.wipeTokens();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_lastname");
      localStorage.removeItem("user_firstname");
      localStorage.removeItem("user_email");
      localStorage.removeItem("permissions");
      Promise.reject(new Error("Got 401 error from API"));
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    localStorage.setItem("previous_url", window.location.href);
    if (!OAuthClient.checkToken({})) {
      return Promise.reject(new Error("Invalid token in AUTH_CHECK"));
    }
    if (localStorage.getItem("user_id")) {
      return Promise.resolve(); // user id and permissions already fetched
    }
    // get user ID and permissions
    const headers = apiHeaders;
    headers.Authorization = localStorage.getItem("token");
    const options = {
      headers: new Headers(headers),
    };
    return fetch(`${apiUrl}/user/`, options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        localStorage.setItem("user_id", result.uid);
        localStorage.setItem("user_lastname", result.lastname);
        localStorage.setItem("user_firstname", result.firstname);
        localStorage.setItem("user_email", result.email);
        // localStorage.set('user', result)
        localStorage.setItem("permissions", result.perms);
      });
  }

  if (type === AUTH_GET_PERMISSIONS) {
    localStorage.setItem("previous_url", window.location.href);
    if (localStorage.getItem("permissions")) {
      return Promise.resolve(localStorage.getItem("permissions")); // permissions already fetched
    }
    if (!OAuthClient.checkToken({})) {
      return Promise.reject();
    }
    // get user ID and permissions
    const headers = apiHeaders;
    headers.Authorization = localStorage.getItem("token");
    const options = {
      headers: new Headers(headers),
    };
    return fetch(`${apiUrl}/user/`, options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        localStorage.setItem("user_id", result.uid);
        localStorage.setItem("user_lastname", result.lastname);
        localStorage.setItem("user_firstname", result.firstname);
        localStorage.setItem("user_email", result.email);
        // localStorage.set('user', result)
        localStorage.setItem("permissions", result.perms);
        const role = localStorage.getItem("permissions");
        return role
          ? Promise.resolve(role)
          : Promise.reject(new Error("AUTH_GET_PERMISSIONS: empty role"));
      });
  }
  return Promise.reject(new Error("Unknown method"));
};
