import { JSO } from "jso";

const OAuthClient = new JSO({
  providerID: "TheTreep",
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_URL,
  authorization: process.env.REACT_APP_OAUTH_AUTHORIZATION,
  debug: process.env.REACT_APP_OAUTH_DEBUG === "true",
  response_type: "token",
});

export default OAuthClient;
