import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  SimpleFormIterator,
  EditButton,
  Edit,
  List,
  Datagrid,
  TextField,
  TextInput,
  required,
  SelectInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  SimpleForm,
  ArrayField,
  FunctionField,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import TabLink from "../components/TabLink/TabLink";
import NewCustomerConfig from "../components/Buttons/NewCustomerConfig";
import { apiUrl, apiHeaders } from "../constants";

export const carRentalAgencies = [
  { id: "ZE", name: "Hertz" },
  { id: "ZI", name: "Avis" },
  { id: "EP", name: "Europcar" },
  { id: "ET", name: "Enterprise" },
  { id: "SX", name: "Sixt" },
];

export const CarRentalConfigCompaniesList = ({ permissions, ...props }) => (
  <>
    <div>
      <TabLink label="agences" to="/carrentalconfig_agencies" />
      <TabLink
        label="entreprises & entités"
        to="/carrentalconfig_companies"
        isActive
      />
    </div>
    <List
      {...props}
      bulkActionButtons={false}
      actions={false}
      title="Tarifs négociés véhicules de location"
    >
      <Datagrid rowclick="show" perPage={200}>
        <TextField label="Entreprise" source="companyName" sortable={false} />
        <TextField label="Entité" source="entityName" sortable={false} />
        <TextField
          label="Nombre de contrats"
          source="carRentalContractsCount"
          sortable={false}
        />

        <FunctionField
          render={(record) =>
            record.status !== "new" ? (
              <ShowButton label="" record={record} />
            ) : null
          }
        />

        <FunctionField
          render={(record) => {
            const creationLink = record.links?.createCustomerConfig;
            return record.status === "new" ? (
              <>{creationLink && <NewCustomerConfig link={creationLink} />}</>
            ) : (
              <EditButton label="" record={record} />
            );
          }}
        />
      </Datagrid>
    </List>
  </>
);

export const CarRentalConfigCompaniesEdit = ({ permissions, ...props }) => {
  const [companyData, setCompanyData] = useState(null);
  const params = props.match?.params;

  useEffect(() => {
    fetchConfigData("company", params.id, setCompanyData);
  }, [params.id]);

  return (
    <Edit
      {...props}
      title="Modifier les tarifs négociés entreprise pour les véhicules de location"
    >
      <SimpleForm>
        {companyData && renderCompanyData(companyData)}
        <ArrayInput label="Contrat Loueur" source="accounts">
          <SimpleFormIterator
            TransitionProps={{
              classNames: {
                enter: "", // no enter animation to fix bug which cause no visibility of first form
              },
            }}
            disableReordering
          >
            <TextInput label="IATA Agence" source="agencyIATA" />
            <SelectInput
              label="Loueur"
              source="renterCode"
              choices={carRentalAgencies}
              validate={[required()]}
            />

            <TextInput
              label="Code de contrat"
              source="corporateDiscountCode"
              validate={[required()]}
            />
            <TextInput
              label="Numéro du compte de facturation"
              source="billingNumber"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const CarRentalConfigCompaniesShow = ({ permissions, ...props }) => {
  const [companyData, setCompanyData] = useState(null);
  const params = props.match?.params;

  useEffect(() => {
    fetchConfigData("company", params.id, setCompanyData);
  }, [params.id]);

  return (
    <Show {...props} title="Tarifs négociés véhicules de location">
      <SimpleShowLayout>
        {companyData && renderCompanyData(companyData)}

        <ArrayField label="Configs" source="accounts">
          <Datagrid>
            <TextField label="IATA Agence" source="agencyIATA" />
            <TextField label="Loueur" source="renterCode" />
            <TextField label="Code de contrat" source="corporateDiscountCode" />
            <TextField
              label="Numéro du compte de facturation"
              source="billingNumber"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const CarRentalConfigAgenciesList = ({ permissions, ...props }) => (
  <>
    <div>
      <TabLink label="agences" to="/carrentalconfig_agencies" isActive />
      <TabLink label="entreprises & entités" to="/carrentalconfig_companies" />
    </div>
    <List
      {...props}
      bulkActionButtons={false}
      actions={false}
      title="Tarifs négociés véhicules de location"
    >
      <Datagrid rowclick="show" perPage={200}>
        <TextField label="Agence" source="agencyName" sortable={false} />
        <TextField
          label="Nombre de contrats"
          source="carRentalContractsCount"
          sortable={false}
        />

        <ShowButton label="" />

        <FunctionField
          render={(record) =>
            record.status === "new" ? (
              <>
                <NewCustomerConfig />
              </>
            ) : (
              <EditButton label="" record={record} />
            )
          }
        />
      </Datagrid>
    </List>
  </>
);

export const CarRentalConfigAgenciesEdit = ({ permissions, ...props }) => {
  const [agencyData, setAgencyData] = useState(null);
  const params = props.match?.params;

  useEffect(() => {
    fetchConfigData("agency", params.id, setAgencyData);
  }, [params.id]);

  return (
    <Edit
      {...props}
      title="Modifier les tarifs négociés agence pour les véhicules de location"
    >
      <SimpleForm>
        {agencyData && renderAgencyData(agencyData)}
        <ArrayInput label="Contrat Loueur" source="accounts">
          <SimpleFormIterator
            TransitionProps={{
              classNames: {
                enter: "", // no enter animation to fix bug which cause no visibility of first form
              },
            }}
            disableReordering
          >
            <TextInput label="IATA Agence" source="agencyIATA" />
            <SelectInput
              label="Loueur"
              source="renterCode"
              choices={carRentalAgencies}
              validate={[required()]}
            />
            <TextInput
              label="Code de contrat"
              source="corporateDiscountCode"
              validate={[required()]}
            />
            <TextInput
              label="Numéro du compte de facturation"
              source="billingNumber"
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const CarRentalConfigAgenciesShow = ({ permissions, ...props }) => {
  const [agencyData, setAgencyData] = useState(null);
  const params = props.match?.params;

  useEffect(() => {
    fetchConfigData("agency", params.id, setAgencyData);
  }, [params.id]);

  return (
    <Show {...props} title="Tarifs négociés véhicules de location">
      <SimpleShowLayout>
        {agencyData && renderAgencyData(agencyData)}
        <ArrayField label="Configs" source="accounts">
          <Datagrid>
            <TextField label="IATA Agence" source="agencyIATA" />
            <TextField label="Loueur" source="renterCode" />
            <TextField label="Code de contrat" source="corporateDiscountCode" />
            <TextField
              label="Numéro du compte de facturation"
              source="billingNumber"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const fetchConfigData = async (type, id, setCompanyData) => {
  const categoryUrl = type === "company" ? "customer-configs" : "agency";
  const token = localStorage.getItem("token");
  const headers = { ...apiHeaders, Authorization: token };

  const options = {
    headers: new Headers(headers),
  };

  try {
    const response = await fetch(`${apiUrl}/${categoryUrl}/${id}`, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const result = data?.result;
    setCompanyData(result);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const renderCompanyData = (companyData) => (
  <div
    style={{
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      margin: "0 0 12px 0 ",
    }}
  >
    <div>
      <Typography
        variant="caption"
        style={{ color: "rgba(0, 0, 0, 0.54)", margin: "0 0 4px 0" }}
      >
        Entreprise
      </Typography>
      <Typography style={{ fontSize: "1rem", margin: 0 }}>
        {companyData.companyName}
      </Typography>
    </div>

    {companyData.entityName && (
      <div>
        <Typography
          variant="caption"
          style={{ color: "rgba(0, 0, 0, 0.54)", margin: "0 0 4px 0" }}
        >
          Entité
        </Typography>
        <Typography style={{ fontSize: "1rem", margin: 0 }}>
          {companyData.entityName}
        </Typography>
      </div>
    )}
  </div>
);
const renderAgencyData = (data) => (
  <div
    style={{
      fontSize: "1rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      margin: "0 0 12px 0 ",
    }}
  >
    <div>
      <Typography
        variant="caption"
        style={{ color: "rgba(0, 0, 0, 0.54)", margin: "0 0 4px 0" }}
      >
        Agence
      </Typography>
      <Typography style={{ fontSize: "1rem", margin: 0 }}>
        {data.name}
      </Typography>
    </div>
  </div>
);
