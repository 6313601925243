import React from "react";
import {
  EditButton,
  Edit,
  List,
  Datagrid,
  TextField,
  Create,
  TextInput,
  required,
  SelectInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  ReferenceInput,
  SimpleForm,
  ArrayField,
  ReferenceField,
  Filter,
  NumberInput,
  AutocompleteInput,
  BooleanInput,
  BooleanField,
} from "react-admin";

const SpecialFareFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <ReferenceInput
      label="Company"
      source="companyID"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="userID"
      reference="users"
      alwaysOn
      filterToQuery={(q) => ({ lastname: q })}
    >
      <AutocompleteInput
        optionText={(choice) => {
          let l = choice?.lastname;
          if (l === null || l === undefined) l = "";
          let f = choice?.firstname;
          if (f === null || f === undefined) f = "";
          return `${f} ${l}`;
        }}
      />
    </ReferenceInput>
    <TextInput source="code" alwaysOn />
    <BooleanInput source="auto" alwaysOn />
  </Filter>
);

export const SpecialFareList = ({ permissions, ...props }) => (
  <List {...props} bulkActionButtons={false} filters={<SpecialFareFilter />}>
    <Datagrid rowclick="show" perPage={200}>
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="label" sortable={false} />
      <BooleanField source="auto" sortable={false} />
      <ReferenceField
        source="companyID"
        reference="companies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userID" reference="users" link="show" allowEmpty>
        <TextField source="lastname" />
      </ReferenceField>
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const SpecialFareCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="code" validate={[required()]} />
      <TextInput source="label" validate={[required()]} />
      <BooleanInput source="auto" />
      {permissions != null && permissions.includes("dev") && (
        <BooleanInput source="isPaxCode" />
      )}
      <ReferenceInput
        label="Entreprise"
        source="companyID"
        reference="companies"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="userID"
        reference="users"
        alwaysOn
        filterToQuery={(q) => ({ lastname: q })}
      >
        <AutocompleteInput
          optionText={(choice) => {
            let l = choice?.lastname;
            if (l === null || l === undefined) l = "";
            let f = choice?.firstname;
            if (f === null || f === undefined) f = "";
            return `${f} ${l}`;
          }}
        />
      </ReferenceInput>
      {permissions != null && permissions.includes("dev") && (
        <TextInput source="tag" />
      )}
    </SimpleForm>
  </Create>
);

export const SpecialFareEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="code" validate={[required()]} />
      <TextInput source="label" validate={[required()]} />
      <BooleanInput source="auto" />
      {permissions != null && permissions.includes("dev") && (
        <BooleanInput source="isPaxCode" />
      )}
      <ReferenceInput
        label="Entreprise"
        source="companyID"
        reference="companies"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="userID"
        reference="users"
        alwaysOn
        filterToQuery={(q) => ({ lastname: q })}
      >
        <AutocompleteInput
          optionText={(choice) => {
            let l = choice?.lastname;
            if (l === null || l === undefined) l = "";
            let f = choice?.firstname;
            if (f === null || f === undefined) f = "";
            return `${f} ${l}`;
          }}
        />
      </ReferenceInput>
      {permissions != null && permissions.includes("dev") && (
        <TextInput source="tag" />
      )}
    </SimpleForm>
  </Edit>
);

export const SpecialFareShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        source="companyID"
        reference="companies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userID" reference="users" link="show" allowEmpty>
        <TextField source="lastname" />
      </ReferenceField>
      <TextField source="code" />
      <TextField source="label" />
      <BooleanField source="auto" />
      {permissions != null && permissions.includes("dev") && (
        <BooleanField source="isPaxCode" />
      )}
      {permissions != null && permissions.includes("dev") && (
        <TextField source="tag" />
      )}
    </SimpleShowLayout>
  </Show>
);
