import CircularProgress from "@material-ui/core/CircularProgress";
import { LockOpen } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import React, { useEffect } from "react";
import { Button, useRefresh, withDataProvider } from "react-admin";

const EditReadOnlyButton = ({ dataProvider, record, entity }) => {
  const [isReadOnly, setIsReadOnly] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lockIcon, setLockIcon] = React.useState(<LockOpen />);
  const [tooltip, setTooltip] = React.useState("Lock");
  const refresh = useRefresh();

  useEffect(() => {
    let contextValue;
    switch (entity) {
    case "agencies":
      contextValue = record.readOnly;
      break;
    case "entities":
    case "companies":
      contextValue = record.config?.readOnly || false;
      break;
    default:
      contextValue = false;
    }
    setIsReadOnly(contextValue);
    if (contextValue) {
      setLockIcon(<LockOpen />);
      setTooltip("Unlock");
    } else {
      setLockIcon(<LockIcon />);
      setTooltip("Lock");
    }
  }, [record]);

  const toggleReadOnly = async () => {
    setIsLoading(true);
    const { id } = record;
    const result = await dataProvider(isReadOnly ? "UNLOCK" : "LOCK", entity, {
      id,
    });
    if (result) {
      setIsLoading(false);
      refresh();
    }
  };

  return (
    <Button size="small" onClick={toggleReadOnly} label={tooltip}>
      {isLoading ? <CircularProgress size={20} /> : lockIcon}
    </Button>
  );
};

export default withDataProvider(EditReadOnlyButton);
