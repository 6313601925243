import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import UserIcon from "@material-ui/icons/Group";
import TicketsIcon from "@material-ui/icons/CreditCard";
import DAPIcon from "@material-ui/icons/CheckCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Flight from "@material-ui/icons/Flight";
import Car from "@material-ui/icons/DirectionsCar";
import SupplierIcon from "@material-ui/icons/Store";
import StatementIcon from "@material-ui/icons/Assignment";
import Storage from "@material-ui/icons/Storage";
import Business from "@material-ui/icons/Business";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import DeviceHub from "@material-ui/icons/DeviceHub";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import Face from "@material-ui/icons/Face";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Queue from "@material-ui/icons/Queue";
import AdminIcon from "@material-ui/icons/Settings";
import AgencyIcon from "@material-ui/icons/SupervisorAccount";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import List from "@material-ui/icons/List";
import UserAnalyticsIcon from "@material-ui/icons/TransferWithinAStation";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import More from "@material-ui/icons/More";
import { withRouter, Redirect } from "react-router-dom";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { translate, MenuItemLink } from "react-admin";
import SubMenu from "./SubMenu";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSetting: false,
      menuOperations: false,
      menuAdmin: false,
      menuSales: false,
    };
  }

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
    const { open } = this.props;
    const permissions = localStorage.getItem("permissions")?.split(",");
    return (
      <div>
        {permissions != null &&
        (permissions.includes("resources_list") ||
          permissions.includes("company_dashboard"))
          ? [
            <MenuItemLink
              key="/dashboard"
              to="/dashboard"
              primaryText="Dashboard"
              leftIcon={<DashboardIcon />}
            />,
            <MenuItemLink
              key="/tickets"
              to="/tickets"
              primaryText="Billets"
              leftIcon={<TicketsIcon />}
            />,
            process.env.REACT_APP_WITH_DAP &&
                process.env.REACT_APP_WITH_DAP === "true" &&
                permissions.includes("resources_list") && (
              <MenuItemLink
                key="/subsidy"
                to="/subsidy"
                primaryText="Numéros d'accord"
                leftIcon={<DAPIcon />}
              />
            ),
            /** <MenuItemLink key="/mission_orders" to="/mission_orders" primaryText="Ordres de missions" leftIcon={<LibraryBooks />} />, */
            <SubMenu
              handleToggle={() => this.handleToggle("menuSetting")}
              isOpen={this.state.menuSetting}
              sidebarIsOpen={open}
              name="Paramétrage"
              icon={<Storage />}
              key="setting"
            >
              {permissions.includes("admin") && (
                <MenuItemLink
                  key="/companies"
                  to="/companies"
                  primaryText="Entreprises"
                  leftIcon={<Business />}
                />
              )}
              {permissions.includes("admin") && (
                <MenuItemLink
                  key="/entities"
                  to="/entities"
                  primaryText="Entités"
                  leftIcon={<SupervisedUserCircle />}
                />
              )}
              {permissions.includes("admin") && (
                <MenuItemLink
                  key="/services"
                  to="/services"
                  primaryText="Services"
                  leftIcon={<DeviceHub />}
                />
              )}
              {permissions.includes("admin") && (
                <MenuItemLink
                  key="/special_fares"
                  to="/special_fares"
                  primaryText="Tarifs négociés"
                  leftIcon={<Flight />}
                />
              )}

              {permissions.includes("admin") && (
                <MenuItemLink
                  key="/carrentalconfig_agencies"
                  to="/carrentalconfig_agencies"
                  primaryText="Tarifs négociés Vloc"
                  leftIcon={<Car />}
                />
              )}
             
              <MenuItemLink
                key="/users"
                to="/users"
                primaryText="Utilisateurs"
                leftIcon={<UserIcon />}
              />
              <MenuItemLink
                key="/analytics"
                to="/analytics"
                primaryText="Champs analytiques"
                leftIcon={<List />}
              />
              <MenuItemLink
                key="/user_analytics"
                to="/user_analytics"
                primaryText="Champs structurels"
                leftIcon={<UserAnalyticsIcon />}
              />
            </SubMenu>,
            permissions != null && permissions.includes("dev") && (
              <SubMenu
                handleToggle={() => this.handleToggle("menuAdmin")}
                isOpen={this.state.menuAdmin}
                sidebarIsOpen={open}
                name="Administration"
                icon={<AdminIcon />}
                key="admin"
              >
                <MenuItemLink
                  key="/agencies"
                  to="/agencies"
                  primaryText="Configurations d'agence"
                  leftIcon={<AgencyIcon />}
                />
              </SubMenu>
            ),
            process.env.REACT_APP_WITH_BILLING &&
                process.env.REACT_APP_WITH_BILLING === "true" &&
                permissions != null &&
                permissions.includes("billing_backoffice") && (
              <SubMenu
                handleToggle={() => this.handleToggle("menuOperations")}
                isOpen={this.state.menuOperations}
                sidebarIsOpen={open}
                name="Opération/Facturation"
                icon={<Queue />}
                key="operations"
              >
                <MenuItemLink
                  key="/operations"
                  to="/operations"
                  primaryText="Opérations"
                  leftIcon={<AddShoppingCart />}
                />
                <MenuItemLink
                  key="/customers"
                  to="/customers"
                  primaryText="Clients"
                  leftIcon={<Face />}
                />
                <MenuItemLink
                  key="/invoices"
                  to="/invoices"
                  primaryText="Factures"
                  leftIcon={<LibraryBooks />}
                />
                <MenuItemLink
                  key="/statements"
                  to="/statements"
                  primaryText="Relevés"
                  leftIcon={<StatementIcon />}
                />
              </SubMenu>
            ),
            process.env.REACT_APP_WITH_BILLING &&
                process.env.REACT_APP_WITH_BILLING === "true" &&
                permissions != null &&
                permissions.includes("billing_backoffice") && (
              <SubMenu
                handleToggle={() => this.handleToggle("menuSales")}
                isOpen={this.state.menuSales}
                sidebarIsOpen={open}
                name="Achats"
                icon={<More />}
                key="sales"
              >
                <MenuItemLink
                  key="/suppliers"
                  to="/suppliers"
                  primaryText="Fournisseurs"
                  leftIcon={<SupplierIcon />}
                />
                <MenuItemLink
                  key="/supplies"
                  to="/supplies"
                  primaryText="Fournitures"
                  leftIcon={<Flight />}
                />
              </SubMenu>
            ),
            permissions.includes("admin") && (
              <MenuItemLink
                key="/ticket_log"
                to="/ticket_log"
                primaryText="Historiques des tickets"
                leftIcon={<AccessTimeIcon />}
              />
            ),
          ]
          : [
            <MenuItemLink
              key="/Accessdenied"
              to="/Accessdenied"
              primaryText="Non autorisé"
              leftIcon={<AnnouncementIcon color="error" />}
            />,
            <Redirect key="/Accessdenied2" to="/Accessdenied" />,
          ]}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);
export default enhance(Menu);
