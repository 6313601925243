import React, { useState } from "react";
import {
  Button,
  Confirm,
  withDataProvider,
  useRefresh,
  useDataProvider,
  useNotify,
} from "react-admin";

const style = {
  dialog: {
    minWidth: 1000,
  },
  bold: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
};

function DeleteUserDataButton(props) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const deleteUser = () => {
    let ids = [];
    if (props.selectedIds != null) {
      ids = props.selectedIds;
    } else if (props.record != null && props.record.id != null) {
      ids = [props.record.id];
    }

    ids.forEach((id) => {
      dataProvider("DELETE_USER_DATA", "", { id })
        .then((response) => {
          notify("Utilisateur(s) supprimé(s)");
          refresh();
        })
        .catch((error) => {
          notify(
            `Erreur lors de le la suppression : ${error.message}`,
            "warning"
          );
        });
    });
    setOpen(false);
  };

  return (
    <>
      <Button label={props.label} onClick={() => setOpen(true)}>
        {props.icon}
      </Button>
      <Confirm
        isOpen={open}
        title="Supprimer le(s) données  de(s) utilisateur(s)"
        content="Voulez-vous vraiment supprimer définitivement les données ?"
        onConfirm={deleteUser}
        onClose={handleDialogClose}
      />
    </>
  );
}

export default withDataProvider(DeleteUserDataButton);
