import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import CardIcon from "./CardIcon";

const styles = {
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
};

function amountOperations(amount, locales) {
  if (amount !== undefined) {
    let total = 0;
    amount.forEach((operation) => {
      total += operation.price;
    });
    return (total / 100).toLocaleString(locales, {
      style: "currency",
      currency: "EUR",
    });
  }
}

const NbOperations = ({ value, amount, header, locales }) => (
  <div style={styles.main}>
    <a href="#/operations" style={{ textDecoration: "none" }}>
      <CardIcon Icon={AddShoppingCart} bgColor="#ff9800" />
      <Card style={styles.card}>
        <Typography style={styles.title} color="textSecondary">
          {header}
        </Typography>
        <Typography variant="h4" component="h1">
          {value}
        </Typography>
        <Typography>
          Montant total : &nbsp;
          {amountOperations(amount, locales)}
        </Typography>
      </Card>
    </a>
  </div>
);

const enhance = compose(translate);

export default enhance(NbOperations);
