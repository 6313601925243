import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { TheTreepDateField } from "../components/Fields/TheTreepDateField";
import { operationCategories } from "./operations";

const postRowStyle = (record, index) => ({
  borderLeftWidth: 5,
  borderLeftStyle: "solid",
  borderLeftColor: record.status === "ok" ? green[500] : red[500],
});

export const ticketlogStatuses = [
  { id: "ok", name: "OK" },
  { id: "error", name: "Erreur" },
];

export const ticketlogTypes = [
  { id: "create", name: "Création" },
  { id: "edit", name: "Édition" },
  { id: "refresh", name: "Rafraichissement" },
  { id: "cancel", name: "Annulation" },
  { id: "approved", name: "Approbation" },
  { id: "booked", name: "Réservation" },
];

const TicketFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="ticket_id" alwaysOn />
    <ReferenceInput
      source="user_id"
      reference="users"
      alwaysOn
      filterToQuery={(q) => ({ lastname: q })}
    >
      <AutocompleteInput
        optionText={(choice) => {
          // add this when users filter properly works...
          let l = choice?.lastname;
          if (l === null || l === undefined) l = "";
          let f = choice?.firstname;
          if (f === null || f === undefined) f = "";
          return `${f} ${l}`;
        }}
      />
    </ReferenceInput>
    <TextInput source="pnr" alwaysOn />
    <TextInput source="ref" alwaysOn />
    <TextInput source="ticketID" alwaysOn />
    <TextInput source="travelID" alwaysOn />
    <NumberInput source="booking_id" alwaysOn />
    <SelectInput source="status" choices={ticketlogStatuses} alwaysOn />
    <SelectInput source="type" choices={ticketlogTypes} alwaysOn />
  </Filter>
);

const TicketPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const TicketLogList = ({ selectedRow, ...props }) => (
  <List
    {...props}
    title="Liste des logs"
    rowclick="show"
    filters={<TicketFilter />}
    pagination={<TicketPagination />}
  >
    <Datagrid rowStyle={postRowStyle} optimized {...props}>
      <TextField source="ticketID" />
      <ReferenceField
        source="user_id"
        reference="users"
        link="show"
        allowEmpty
        sortable={false}
      >
        <FunctionField
          render={(record) => `${record.firstname} ${record.lastname}`}
        />
      </ReferenceField>
      <TheTreepDateField source="time" showTime />
      <SelectField source="type" choices={ticketlogTypes} />
      <TextField source="message" />
      <TextField source="ticket_status" />
      <SelectField source="ticket_type" choices={operationCategories} />
      <TextField source="pnr" />
      <TextField source="ref" />
      <SelectField source="status" choices={ticketlogStatuses} />
      <TextField source="travelID" />
      <FunctionField
        render={(record) => (
          <a
            href={generateGoogleCloudLoggingUrl({
              requestId: record.request_id,
              date: new Date(record.time),
              projectId: "the-treep-api-1525507752734",
              organizationId: "123351477341",
            })}
          >
            {record.request_id}
          </a>
        )}
      />
    </Datagrid>
  </List>
);

const getCurrentSubdomain = () => {
  const hostParts = window.location.hostname.split(".");
  if (hostParts.length < 2) return "";
  if (hostParts[0] === "backoffice" && hostParts.length === 3)
    return "backoffice";
  if (hostParts[0] === "demo-backoffice" && hostParts.length === 3)
    return "demo-backoffice";
  if (hostParts[0] === "backoffice" && hostParts.length > 3)
    return hostParts[1];
  return "";
};

const getNamespaceFromSubdomain = (subdomain) => {
  switch (subdomain) {
  case "backoffice":
    return "prod";
  case "demo-backoffice":
    return "staging";
  default:
    return subdomain;
  }
};

const generateGoogleCloudLoggingUrl = ({
  requestId,
  date = new Date(),
  projectId = "the-treep-api-1525507752734",
  organizationId = "123351477341",
  app = "thetreep-api",
}) => {
  const subdomain = getCurrentSubdomain();
  const namespace = getNamespaceFromSubdomain(subdomain);

  const baseUrl = 'https://console.cloud.google.com/logs/query;query=';

  const query =
    encodeURIComponent(`resource.labels.namespace_name="${namespace}"
labels."k8s-pod/app"="${app}"
jsonPayload.RequestID="${requestId}"`);

  const summaryFields = 'summaryFields=jsonPayload%252Fmsg:false:32:beginning';

  const aroundTime = encodeURIComponent(date.toISOString());

  const duration = 'duration=P1D';

  const projectParams = `project=${projectId}&folder=true&organizationId=${organizationId}`;

  return `${baseUrl}${query};${summaryFields};aroundTime=${aroundTime};${duration}?${projectParams}`.replace(
    /#/g,
    "%23"
  );
};
