import React, { useState, useEffect } from "react";
import {
  Confirm,
  GET_ONE,
  withDataProvider,
  useQueryWithStore,
  GET_MANY,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import TravelersField from "../Fields/TravelersField";

const styles = {
  action: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  size: {
    maxWidth: 500,
  },
  bold: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
};

function CancelTicketsButton(props) {
  const [isOpen, setOpen] = useState(false);
  const [isCancellable, setCancellable] = useState(true);
  const [selectedTickets, setSelectedTickets] = useState([]);

  useEffect(() => {
    (async function getSelectTickets() {
      const tks = [];
      const { dataProvider, selectedIds } = props;
      selectedIds.forEach((selectedId) => {
        dataProvider.getOne("tickets", { id: selectedId }).then((response) => {
          tks.push(response.data[0]);
        });
      });
      setSelectedTickets(tks, checkSelectedTickets());
    })();
  }, [props.selectedIds]);

  const checkSelectedTickets = () => {
    selectedTickets.forEach((tk) => {
      if (
        tk.status === "ticketed" ||
        tk.status === "pending_confirmation" ||
        tk.status === "pending_ticketing"
      ) {
        setCancellable(true);
        return;
      }
      setCancellable(false);
    });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    cancelTickets();
  };

  const cancelTickets = async () => {
    const { dataProvider, selectedIds, resource } = props;
    dataProvider(
      "CANCEL_TICKET",
      "",
      { ticket_ids: selectedIds },
      {
        onSuccess: {
          notification: { body: "Annulations éffectuées", level: "info" },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
        onError: {
          notification: {
            body: "Erreur lors de l'annulation",
            level: "warning",
          },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    setOpen(false);
  };

  const information = selectedTickets.map((tk) => (
    <p style={{ fontWeight: "bold" }} key={tk.id}>
      {tk.ticket_travelers != null && tk.ticket_travelers.length > 0 && (
        <>
          Pax: {tk.ticket_travelers[0].firstname}{" "}
          {tk.ticket_travelers[0].lastname}
          <br />
        </>
      )}
      Prestation:
      <br />-{tk.type} {tk.to} - {tk.from}{" "}
      {moment.utc(tk.departure).format("DD/MM/YYYY à HH:mm")}{" "}
      {tk.offer != null ? tk.offer.fare_category : null}
      <br />
    </p>
  ));

  return (
    isCancellable && (
      <>
        <Button variant="outlined" color="primary" onClick={handleClick}>
          {props.label}
        </Button>
        <Dialog
          open={isOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={styles.action}>
            ANNULATION DE {props.selectedIds.length} PRESTATIONS
          </DialogTitle>
          <DialogContent style={styles.size}>
            <DialogContentText id="alert-dialog-description">
              <div>
                <p>
                  Attention: nous tenterons d'effectuer l'annulation de ces
                  prestations automatiquement. Dans certains cas, cela peut ne
                  pas être possible. Votre agence traitera alors la ou les
                  demande(s) d'annulation manuellement, pendant leurs heures
                  d'ouverture.
                </p>
                {information}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogContent style={styles.action}>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              color="secondary"
            >
              Annuler
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              autoFocus
            >
              Confirmer
            </Button>
          </DialogContent>
        </Dialog>
      </>
    )
  );
}

export default withDataProvider(CancelTicketsButton);
