import React from "react";
import {
  Button,
  Link,
  TextInput,
  Pagination,
  ArrayField,
  SingleFieldList,
  List,
  Filter,
  Datagrid,
  ReferenceField,
  TextField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  BooleanField,
  SelectField,
  TopToolbar,
  ExportButton,
} from "react-admin";
import AddIcon from "@material-ui/icons/AddCircle";
import ClearIcon from "@material-ui/icons/Clear";
import LinkIcon from "@material-ui/icons/Link";
import {
  TheTreepDateField,
  GolangDateParser,
  GolangDateFormatter,
  ValidateGolangDate,
} from "../components/Fields/TheTreepDateField";
import CurrencyField from "../components/Fields/CurrencyField";
import ColoredField from "../components/Fields/ColoredField";
import ChipField from "../components/Fields/ChipField";
import ReconcileOperationsSuppliesButton from "../components/Buttons/ReconcileOperationsSuppliesButton";
import {
  operationCategories,
  operationTypes,
  operationStatuses,
  reconciliationStatuses,
} from "./operations";
import AutoReconciliationOperationSupplies from "../components/Buttons/AutoReconciliationOperationsSupplies";

const SupplyPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const SupplyFilter = (props) => (
  <Filter {...props}>
    <TextInput source="invoice_number" alwaysOn />
    <TextInput label="PNR" source="pnr" alwaysOn />
    <SelectInput source="type" alwaysOn choices={operationTypes} />
    <SelectInput
      source="reconciliation_status"
      alwaysOn
      choices={reconciliationStatuses}
    />
    <SelectInput source="category" alwaysOn choices={operationCategories} />
    <ReferenceInput
      label="Fournisseur"
      source="supplier"
      reference="suppliers"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="PAX" source="pax_contains" alwaysOn />
    <TextInput
      label="À partir de (JJ/MM/AAAA)"
      source="start_supply_date"
      parse={GolangDateParser}
      format={GolangDateFormatter}
      validate={[ValidateGolangDate]}
      alwaysOn
    />
    <TextInput
      label="Jusqu'à (JJ/MM/AAAA)"
      source="end_supply_date"
      parse={GolangDateParser}
      format={GolangDateFormatter}
      validate={[ValidateGolangDate]}
      alwaysOn
    />
    <TextInput source="ticket_number" alwaysOn />
  </Filter>
);

const SupplyActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button
        disabled={props.filters == null || props.filters.length === 0}
        label="Supprimer les filtres"
        onClick={() => (props != null ? props.setFilters({}) : null)}
      >
        <ClearIcon />
      </Button>
      <AutoReconciliationOperationSupplies
        label="Réconciliation automatique"
        icon={<LinkIcon />}
        resource="supplies"
      />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const SuppliesBulkActionButtons = (props) => (
  <>
    <ReconcileOperationsSuppliesButton {...props} />
  </>
);

const supplyRowStyle = (supply) => {
  if (supply.reconciliation_status !== null) {
    switch (supply.reconciliation_status) {
    case "ok":
      return { backgroundColor: "#C8E6C9" };
    case "pending":
      return { backgroundColor: "#ffcc80" };
    default:
      return { backgroundColor: "white" };
    }
  }
};

const CreateOperationReferencingButton = ({ record }) => (
  <Button
    label="Créer une opération"
    component={Link}
    to={{
      pathname: "/operations/create",
      state: {
        record: {
          pnr: record.pnr,
          ticket_number: record.ticket_number,
          supplier: record.supplier,
          type: record.invoice_type === "credit" ? "cancellation" : "purchase",
          category: record.category,
          online: record.online,
          operation_date: record.supply_date,
          departure_date: record.departure_date,
          origin: record.origin,
          destination: record.destination,
          place_detail: record.place_detail,
          pax: record.pax,
          analytics: record.analytics,
          price: record.price_incl_taxes,
        },
      },
    }}
  >
    <AddIcon />
  </Button>
);

export const SupplyList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des fournitures"
    filters={<SupplyFilter />}
    pagination={<SupplyPagination />}
    sort={{ field: "supply_date", order: "DESC" }}
    actions={<SupplyActions />}
    bulkActionButtons={<SuppliesBulkActionButtons />}
  >
    <Datagrid rowclick="show" rowStyle={supplyRowStyle}>
      <TheTreepDateField source="supply_date" />
      <TextField source="customer_name" />
      <ReferenceField
        label="Fournisseur"
        source="supplier"
        reference="suppliers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="invoice_type" />
      <SelectField source="category" choices={operationCategories} />
      <ArrayField source="pax">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <TextField label="pnr" source="pnr" />
      <TheTreepDateField source="departure_date" />
      <TextField source="invoice_number" />
      <CurrencyField source="price_incl_taxes" invertColors />
      <SelectField source="status" choices={operationStatuses} />
      <TextField source="ticket_number" />
      <ColoredField
        source="reconciliation_status"
        colorFn={(status) => (status.toLowerCase() === "ok" ? "green" : "red")}
        choices={reconciliationStatuses}
      />
      <ShowButton label="" />
      <CreateOperationReferencingButton />
    </Datagrid>
  </List>
);

export const SupplyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="id" source="id" />
      <ReferenceField
        label="Fournisseur"
        source="supplier"
        reference="suppliers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="invoice_type" source="invoice_type" />
      <TextField label="category" source="category" />
      <TheTreepDateField label="supply_date" source="supply_date" />
      <TheTreepDateField label="creation_date" source="creation_date" />
      <TextField label="status" source="status" />
      <CurrencyField
        label="price_incl_taxes"
        source="price_incl_taxes"
        invertColors
      />
      <ColoredField
        label="reconciliation_status"
        source="reconciliation_status"
        colorFn={(status) => (status.toLowerCase() === "ok" ? "green" : "red")}
      />
      <ArrayField label="Réconciliée avec" source="reconciled_with">
        <Datagrid>
          <ReferenceField
            label="Opération"
            source="id"
            reference="operations"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <TextField source="type" />
          <TextField source="category" />
          <BooleanField source="online" />
          <TextField source="status" />
          <CurrencyField source="price" />
          <TextField source="pnr" />
          <TextField source="ticket_number" />
          <TheTreepDateField source="departure_date" />
          <TextField source="origin" />
          <ArrayField source="pax">
            <SingleFieldList>
              <ChipField />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <TextField label="pnr" source="pnr" />
      <TextField label="ticket_number" source="ticket_number" />
      <TextField label="invoice_number" source="invoice_number" />
      <TheTreepDateField label="billing_date" source="billing_date" />
      <TheTreepDateField label="departure_date" source="departure_date" />
      <TextField label="origin" source="origin" />
      <TextField label="destination" source="destination" />
      <TextField label="place_detail" source="place_detail" />
      <ArrayField source="pax">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="analytics">
        <Datagrid>
          <TextField source="key" />
          <TextField source="label" />
          <TextField source="value" />
        </Datagrid>
      </ArrayField>
      <TextField label="supplier_id" source="supplier_id" />
      <TextField label="uniqueness_key" source="uniqueness_key" />
      <TextField label="customer_name" source="customer_name" />
      <CurrencyField
        label="price_excl_taxes"
        source="price_excl_taxes"
        invertColors
      />
      <CurrencyField
        label="fees_incl_taxes"
        source="fees_incl_taxes"
        invertColors
      />
      <CurrencyField
        label="fees_excl_taxes"
        source="fees_excl_taxes"
        invertColors
      />
      <CurrencyField label="vat" source="vat" invertColors />
      <TextField label="nb_item" source="nb_item" />
      <TextField label="online" source="online" />
      <TextField label="description" source="description" />
    </SimpleShowLayout>
  </Show>
);
