import React from "react";
import {
  FunctionField,
  TextInput,
  Pagination,
  ArrayField,
  SingleFieldList,
  List,
  Filter,
  Datagrid,
  ReferenceField,
  TextField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  BooleanField,
  SelectField,
  downloadCSV,
  AutocompleteInput,
} from "react-admin";
import LinkIcon from "@material-ui/icons/Link";
import PaidIcon from "@material-ui/icons/EuroSymbol";
import SentIcon from "@material-ui/icons/Mail";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { TheTreepDateField } from "../components/Fields/TheTreepDateField";
import CurrencyField from "../components/Fields/CurrencyField";
import ChipField from "../components/Fields/ChipField";
import { operationStatuses } from "./operations";
import UpdateInvoiceStatusButton from "../components/Buttons/UpdateInvoiceStatusButton";
import LinkField from "../components/Fields/LinkField";
import { renderCustomerNameWithType, renderCustomerName } from "./customers";

const InvoicePagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const InvoiceFilter = (props) => (
  <Filter {...props}>
    <TextInput source="number" alwaysOn />
    <SelectInput source="status" alwaysOn choices={operationStatuses} />
    <ReferenceInput
      source="customer"
      reference="customers"
      filter={{ to_bill: true }}
      filterToQuery={(q) => ({ name: q })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText={renderCustomerNameWithType} />
    </ReferenceInput>
  </Filter>
);

const InvoiceBulkActionButtons = (props) => (
  <>
    <UpdateInvoiceStatusButton
      label="Envoyées"
      status="sent"
      {...props}
      icon={<SentIcon />}
    />
    <UpdateInvoiceStatusButton
      label="Payées"
      status="paid"
      {...props}
      icon={<PaidIcon />}
    />
  </>
);
function displayRecipients(r) {
  if (r === null) {
    return "";
  }
  return r.toString();
}
function displayStatus(status) {
  for (let i = 0; i < operationStatuses.length; i++) {
    if (status === operationStatuses[i].id) {
      return operationStatuses[i].name;
    }
  }
}
const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "tickets_id", "tickets").then((tickets) => {
    const data = records.map((record) => ({
      ...record,
      client: record.customer_info.name,
      destinataire: displayRecipients(record.customer_info.recipients),
      status: displayStatus(record.status),
      lien: record.url,
    }));
    const csv = convertToCSV({
      data,
      fields: [
        "id",
        "client",
        "destinataire",
        "status",
        "total_incl_taxes",
        "total_excl_taxes",
        "total_vat",
        "subtotal_op",
        "subtotal_fee",
        "billing_date",
        "number",
        "lien",
      ],
    });
    downloadCSV(csv, "Factures");
  });
};

export const InvoiceList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des factures"
    exporter={exporter}
    filters={<InvoiceFilter />}
    pagination={<InvoicePagination />}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={<InvoiceBulkActionButtons />}
  >
    <Datagrid
      rowclick="show"
      rowStyle={(invoice) => {
        for (let i = 0; i < operationStatuses.length; i++) {
          const opStat = operationStatuses[i];
          if (invoice.status === opStat.id) {
            return { backgroundColor: opStat.backgroundColorInvoice };
          }
        }
      }}
    >
      <TextField source="id" />
      <ReferenceField
        source="customer"
        reference="customers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <FunctionField label="Nom" render={renderCustomerName} />
      </ReferenceField>
      <TextField source="customer_info.name" />
      <ArrayField source="customer_info.recipients">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <SelectField source="status" choices={operationStatuses} />
      <CurrencyField source="total_incl_taxes" />
      <CurrencyField source="total_excl_taxes" />
      <CurrencyField source="total_vat" />
      <CurrencyField source="subtotal_op" />
      <CurrencyField source="subtotal_fee" />
      <TheTreepDateField source="billing_date" showTime />
      <TextField source="number" />
      <FunctionField
        label="Lien"
        render={(record) =>
          record.url && (
            <a href={record.url} target="_blank" rel="noopener noreferrer" aria-label="Lien">
              <LinkIcon />
            </a>
          )
        }
      />
      <ShowButton label="" />
    </Datagrid>
  </List>
);

export const InvoiceShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="vosfactures_id" />
      <ReferenceField
        source="customer"
        reference="customers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <FunctionField label="Nom" render={renderCustomerNameWithType} />
      </ReferenceField>
      <TextField source="customer_info.name" />
      <TextField source="customer_info.recipients" />
      <TextField source="status" />
      <CurrencyField source="total_incl_taxes" />
      <CurrencyField source="total_excl_taxes" />
      <CurrencyField source="total_vat" />
      <CurrencyField source="subtotal_op" />
      <CurrencyField source="subtotal_fee" />
      <ArrayField source="operations">
        <Datagrid>
          <ReferenceField
            label="Opération"
            source="id"
            reference="operations"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <TextField source="type" />
          <TextField source="category" />
          <BooleanField source="online" />
          <TextField source="status" />
          <CurrencyField source="price" />
          <TextField source="pnr" />
          <TextField source="ticket_number" />
          <TheTreepDateField source="departure_date" />
          <TextField source="origin" />
          <ArrayField source="pax">
            <SingleFieldList>
              <ChipField />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <ArrayField source="fees">
        <Datagrid>
          <TextField source="status" />
          <TextField source="scope.cat" />
          <TextField source="scope.type" />
          <BooleanField source="scope.online" />
          <TextField source="unit" />
          <CurrencyField source="incl_taxes" />
          <CurrencyField source="excl_taxes" />
          <TextField source="session_id" />
        </Datagrid>
      </ArrayField>
      <TextField source="billing_date" />
      <LinkField source="url" />
      <ArrayField source="analytics">
        <Datagrid>
          <TextField source="key" />
          <TextField source="label" />
          <TextField source="value" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
