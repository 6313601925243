import React from "react";
import get from "lodash/get";

const LinkField = ({ record, source }) => {
  if (!record) return null;
  const value = get(record, source);
  if (value == null) return null;
  return (
    <a href={value} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  );
};

LinkField.defaultProps = {
  addLabel: true,
};

LinkField.defaultProps = {
  addLabel: true,
};
export default LinkField;
