import { DialogContent } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ArrowsIcon from "@material-ui/icons/CompareArrows";
import PropTypes from "prop-types";
import React from "react";
import { GET_LIST, GET_MANY, withDataProvider } from "react-admin";

class UserServiceButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedServices: [],
      services: [],
      company: 0,
      message: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleClick = () => {
    this.fetchUsers();
    this.setState({ isOpen: true });
  };

  handleConfirm = () => {
    const { dataProvider, resource, selectedIds } = this.props;
    const { checkedServices } = this.state;
    selectedIds.forEach((selectedId) => {
      dataProvider(
        "APPROVES_SERVICE",
        "",
        {
          id: selectedId,
          services: checkedServices.map((checkedService) => ({
            service_id: parseInt(checkedService.id, 10),
            approval_level: parseInt(checkedService.approval_level, 10),
          })),
        },
        {
          onSuccess: {
            notification: { body: "Services ajoutées", level: "info" },
            refresh: true,
            redirectTo: `/${resource}`,
            unselectAll: true,
          },
          onError: {
            notification: {
              body: "Erreur lors de l'ajout de service",
              level: "warning",
            },
            refresh: true,
            unselectAll: true,
          },
        }
      );
    });
    this.setState({ isOpen: false });
    this.setState({ checkedServices: [] });
  };

  handleChange(e) {
    const item = parseInt(e.target.name, 10);
    const isChecked = e.target.checked;
    let { checkedServices } = this.state;
    if (isChecked === true) {
      checkedServices.push({ id: item, approval_level: "1" });
    } else {
      checkedServices = checkedServices.filter((el) => el.id !== item);
    }
    let parent = null;
    this.state.services.forEach((service) => {
      if (service.uid === item) {
        parent = service;
      }
    });
    if (parent != null) {
      parent.checked = true;
      this.forEachChildren(parent, (child) => {
        if (isChecked === true) {
          checkedServices.push({ id: child.uid, approval_level: "1" });
          this.handleChangeInput(child.uid, e);
        } else {
          checkedServices = checkedServices.filter((el) => el.id !== child.uid);
        }
      });
    }
    this.setState({ checkedServices });
  }

  handleChangeInput(id, e) {
    let { value } = e.target;
    if (e.target.value === "") {
      value = "1";
    }
    const { checkedServices } = this.state;
    checkedServices.forEach((el) => {
      if (el.id === id) {
        el.approval_level = value;
      }
    });
    let parent = null;
    this.state.services.forEach((service) => {
      if (service.uid === id) {
        parent = service;
      }
    });
    if (parent != null) {
      parent.checked = true;
      this.forEachChildren(parent, (child) => {
        checkedServices.forEach((el) => {
          if (el.id === child.uid) {
            el.approval_level = value;
          }
        });
      });
    }
    this.setState({ checkedServices });
  }

  computeChildren = (parent, depth) => {
    parent.children = [];
    this.state.services.forEach((service) => {
      if (service.parent_id === parent.uid) {
        parent.children.push({ depth, child: service });
        this.computeChildren(service, depth + 1);
      }
    });
  };

  forEachChildren = (parent, fn) => {
    this.state.services.forEach((service) => {
      if (service.parent_id === parent.uid) {
        fn(service);
        this.forEachChildren(service, fn);
      }
    });
  };

  displayService = (service) => {
    const children = service.child.children.map((child) =>
      this.displayService(child)
    );
    const marge = `${service.depth * 25}px`;
    let checked = false;
    let value = "";
    this.state.checkedServices.forEach((el) => {
      if (el.id === service.child.uid) {
        checked = true;
        value = el.approval_level;
      }
    });
    return (
      <div key={`${service.uid}`}>
        <div style={{ marginLeft: marge, paddingLeft: marge }}>
          <label key={service.child.uid}>
            {service.child.name}
            <Checkbox
              name={`${service.child.uid}`}
              checked={checked}
              onChange={this.handleChange}
            />
            <Input
              name={`input_${service.child.uid}`}
              id={`${service.child.uid}`}
              type="number"
              disabled={!checked}
              value={value}
              onChange={(e) => this.handleChangeInput(service.child.uid, e)}
            />
          </label>
        </div>
        {children}
      </div>
    );
  };

  loadAlreadyChecked() {
    const { selectedIds } = this.props;
    const { checkedServices } = this.state;
    let warning;
    this.state.services.forEach((s) => {
      const selectedApprovers = [];
      if (s.approvers !== null && s.approvers.length > 0) {
        s.approvers.forEach((approver) => {
          for (let i = 0; i < selectedIds.length; i++) {
            if (selectedIds[i] === approver.uid) {
              let found = false;
              selectedApprovers.forEach((existing) => {
                if (existing.uid === approver.uid) {
                  if (existing.approval_level < approver.approval_level) {
                    existing.approval_level = approver.approval_level;
                  }
                  found = true;
                }
              });
              if (!found) {
                selectedApprovers.push(approver);
              }
            }
          }
        });
      }
      if (
        selectedApprovers.length === selectedIds.length &&
        selectedApprovers.length > 0
      ) {
        let isEqual = true;
        const lvl = selectedApprovers[0].approval_level;
        for (let i = 1; i < selectedApprovers.length; ++i) {
          if (lvl !== selectedApprovers[i].approval_level) {
            isEqual = false;
            warning =
              "Attention ces utilisateurs n'ont pas les mêmes niveaux d'approbation !";
            break;
          }
        }
        if (isEqual && lvl != null) {
          checkedServices.push({ id: s.uid, approval_level: lvl.toString() });
        }
      } else if (
        selectedApprovers.length < selectedIds.length &&
        selectedApprovers.length !== 0
      ) {
        warning =
          "Attention ces utilisateurs peuvent actuellement approuver des services différents !";
      }
    });
    this.setState({ message: warning, checkedServices });
  }

  async fetchUsers() {
    const { dataProvider, selectedIds, s } = this.props;
    const { data: users } = await dataProvider(GET_MANY, "users", {
      ids: selectedIds,
    });
    let warning = "";
    let diff = false;
    for (let i = 1; i < users.length; ++i) {
      if (users[0].company_id !== users[i].company_id) {
        diff = true;
        warning =
          "Attention ! Impossible d'Afficher les services, ces utilisateurs n'ont pas les mêmes entreprises !";
        break;
      }
    }
    if (diff) {
      this.setState({ message: warning });
    } else {
      this.setState(
        {
          company: users[0].company_id,
        },
        this.fetchServices
      );
    }
  }

  async fetchServices() {
    const { dataProvider } = this.props;
    const { data: services } = await dataProvider(GET_LIST, "services", {
      filter: { company_id: this.state.company },
      sort: { field: "uid", order: "DESC" },
      pagination: { page: 1, perPage: 1000 },
    });
    this.setState(
      {
        services,
      },
      this.loadAlreadyChecked
    );
  }

  render() {
    const { message } = this.state;
    const parents = this.state.services.filter(
      (item) => item.parent_id === null
    );
    parents.forEach((parent) => this.computeChildren(parent, 1));
    return (
      <div>
        <Button
          key="click-button"
          color="primary"
          onClick={this.handleClick}
          size="small"
        >
          <ArrowsIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>Délégation d’approbation</span>
        </Button>
        <Dialog
          key="dialog"
          fullScreen
          open={this.state.isOpen || false}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleDialogClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h5" color="inherit">
                Délégation d’approbation
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <h1>Les services</h1>
            {parents.map((parent) =>
              this.displayService({ child: parent, depth: 0 })
            )}
            <Typography variant="body1" color="error">
              {message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button key="cancel" onClick={this.handleDialogClose}>
              Annuler
            </Button>
            <Button key="confirm" onClick={this.handleConfirm} color="primary">
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UserServiceButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(UserServiceButton);
