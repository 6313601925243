import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDataProvider } from "react-admin";
import { debounce } from "@material-ui/core";

export default function UserInput({ label, companyID, onChange }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dataProvider = useDataProvider();

  const onInputChange = (event, newValue) => {
    setLoading(true);

    dataProvider
      .getList("users", {
        filter: { query: newValue, company_id: companyID || undefined },
        pagination: { page: 1, perPage: 10 },
        sort: { field: "uid", order: "ASC" },
      })
      .then(({ data: users }) => {
        setOptions(
          users.map((user) => ({
            name: `${user.firstname} ${user.lastname}`,
            value: user,
          }))
        );
        setLoading(false);
      });
  };
  const onInputChangeDelayed = React.useCallback(debounce(onInputChange, 500), [
    onInputChange,
  ]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      onInputChange={onInputChangeDelayed}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
