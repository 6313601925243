import { DialogContent } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ButtonIcon from "@material-ui/icons/GroupAdd";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  GET_MANY,
  useDataProvider,
  useMutation,
  useNotify,
  useRefresh
} from "react-admin";
import UserInput from "../Input/UserInput";

const SetApproverButton = ({ resource, selectedIds }) => {
  const [showButton, setShowButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [companyID, setCompanyID] = useState(null);
  const [approver, setApprover] = useState(null);
  const dataProvider = useDataProvider();
  const [mutate, { loading }] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    mutate(
      {
        type: "SET_APPROVER",
        resource: "",
        payload: {
          userIDs: selectedIds,
          approverID: approver
        }
      },
      {
        onSuccess: ({ data }) => {
          refresh();
          notify("Approbateur assigné", "info");
        },
        onFailure: (error) => notify(`Erreur lors de l'assignation`, "warning")
      }
    );
    setOpen(false);
  };

  useEffect(() => {
    dataProvider
      .getMany("users", {
        ids: selectedIds
      })
      .then((data) => {
        if (!data?.data) {
          return;
        }
        const users = data.data;
        if (!users || users.length === 0) {
          return;
        }
        // If only one user is selected
        if (users.length === 1) {
          setCompanyID(users[0].company_id);
          return;
        }

        // Check if users come from the same company
        const sameCompany = Array.from(
          new Set(users.map((user) => user.company_id))
        );

        if (sameCompany.length > 1) {
          setCompanyID(null);
          return;
        }
        setCompanyID(users[0].company_id);
      });
  }, [selectedIds]);

  useEffect(() => {
    setShowButton(false);
    if (companyID == null) {
      return;
    }
    dataProvider
      .getMany("companies", {
        ids: [companyID]
      })
      .then(({ data: companies }) => {
        const matches =
          companies && companies.filter((company) => company.uid === companyID);
        if (!matches || matches.length !== 1) {
          return;
        }
        const company = matches[0];
        if (company?.config?.approvalType === "hierarchical") {
          setShowButton(true);
        }
      });
  }, [companyID]);

  const onApproverSelect = (_, value) => {
    if (!value) {
      return;
    }
    setApprover(value.value.uid);
  };

  return (
    <>
      {showButton && (
        <Button
          key="click-button"
          color="primary"
          onClick={handleClick}
          size="small"
        >
          <ButtonIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>Assigner un approbateur</span>
        </Button>
      )}

      <Dialog
        key="dialog"
        open={open}
        maxWidth="md"
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleDialogClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" color="inherit">
              Assigner un approbateur
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <h1>Approbateur</h1>
          <UserInput
            label="Approbateur"
            onChange={onApproverSelect}
            companyID={companyID}
          />
        </DialogContent>
        <DialogActions>
          <Button key="cancel" onClick={handleDialogClose}>
            Annuler
          </Button>
          <Button
            key="confirm"
            onClick={handleConfirm}
            color="primary"
            disabled={approver == null}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetApproverButton;
