import React from "react";
import {
  Edit,
  List,
  Filter,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  Show,
  SimpleShowLayout,
  ShowButton,
  EditButton,
  CloneButton,
  ArrayField,
  SingleFieldList,
  SelectArrayInput,
  SelectField,
  BooleanInput,
  BooleanField,
  FunctionField,
  DisabledInput,
  ReferenceInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";
import FormatTextInput from "../components/Input/TextInput";
import ChipField from "../components/Fields/ChipField";
import { operationCategories, operationTypes } from "./operations";
import CurrencyField, {
  CurrencyParser,
  CurrencyFormatter,
} from "../components/Fields/CurrencyField";

const CustomerFilter = (props) => (
  <Filter {...props}>
    <SelectField source="type" choices={customerTypes} alwaysOn />
  </Filter>
);

export const CustomerList = ({ permissions, ...props }) => (
  <List {...props} title="Liste des fournisseurs" filters={<CustomerFilter />}>
    <Datagrid rowclick="show" perPage={200}>
      <SelectField source="type" choices={customerTypes} />
      <FunctionField label="Nom" render={renderCustomerName} />
      <SelectField source="billing_period" choices={billingPeriods} />
      <BooleanField source="to_bill" />
      <ShowButton label="" />
      <EditButton label="" />
      <CloneButton />
    </Datagrid>
  </List>
);

// todo create/edit/show config (fees and analytics)

export const CustomerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={customerTypes} />
      <ReferenceInput
        source="parent"
        reference="customers"
        filterToQuery={(q) => ({ name: q })}
        allowEmpty
      >
        <AutocompleteInput optionText={renderCustomerNameWithType} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          (formData.type === "agency" ||
            formData.type === "company" ||
            formData.type === "entity") && <TextInput source="name" {...rest} />
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === "consumer" && (
            <TextInput source="firstname" {...rest} />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === "consumer" && (
            <TextInput source="lastname" {...rest} />
          )
        }
      </FormDataConsumer>
      <TextInput source="billing_name" validate={[required()]} />
      <NumberInput source="vosfactures_id" />
      <TextInput source="address" />
      <TextInput source="city" />
      <TextInput source="country" />
      <TextInput source="email" />
      <SelectInput source="billing_period" choices={billingPeriods} />
      <NumberInput source="billing_day" />
      <TextInput source="billing_time" />
      <SelectArrayInput source="billing_contacts" choices={billingContacts} />
      <ArrayInput source="billing_emails">
        <SimpleFormIterator>
          <TextInput label=" " format={FormatTextInput} />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="to_bill" />
      <BooleanInput source="only_draft" />
      <BooleanInput source="bill_only_purchase" />
      <BooleanInput source="bill_only_fees" />
      <BooleanInput source="disable_reconciliation" />
      <ArrayInput source="config.fees">
        <SimpleFormIterator>
          <SelectInput
            label="Opération"
            source="scope.type"
            choices={operationTypes}
          />
          <SelectInput
            label="Catégorie"
            source="scope.cat"
            choices={operationCategories}
          />
          <BooleanInput label="En ligne ?" source="scope.online" defaultValue />
          <SelectInput
            label="Unité de calcul"
            source="unit"
            choices={feeUnits}
          />
          <NumberInput
            label="Prix"
            source="price"
            parse={CurrencyParser}
            format={CurrencyFormatter}
          />
          <NumberInput label="Taux (%)" source="rate" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="config.analytics">
        <SimpleFormIterator>
          <TextInput label="Nom" source="key" />
          <TextInput label="Label" source="label" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const CustomerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput source="type" alwaysOn choices={customerTypes} />
      <ReferenceInput
        source="parent"
        reference="customers"
        filterToQuery={(q) => ({ name: q })}
        allowEmpty
      >
        <AutocompleteInput optionText={renderCustomerNameWithType} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          (formData.type === "agency" ||
            formData.type === "company" ||
            formData.type === "entity") && <TextInput source="name" {...rest} />
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === "consumer" && (
            <TextInput source="firstname" {...rest} />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === "consumer" && (
            <TextInput source="lastname" {...rest} />
          )
        }
      </FormDataConsumer>
      <TextInput source="billing_name" validate={[required()]} />
      <NumberInput source="vosfactures_id" />
      <TextInput source="address" />
      <TextInput source="city" />
      <TextInput source="country" />
      <TextInput source="email" />
      <SelectInput source="billing_period" choices={billingPeriods} />
      <NumberInput source="billing_day" />
      <TextInput source="billing_time" />
      <SelectArrayInput source="billing_contacts" choices={billingContacts} />
      <ArrayInput source="billing_emails">
        <SimpleFormIterator>
          <TextInput label=" " format={FormatTextInput} />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="to_bill" />
      <BooleanInput source="only_draft" />
      <BooleanInput source="bill_only_purchase" />
      <BooleanInput source="bill_only_fees" />
      <BooleanInput source="disable_reconciliation" />
      <ArrayInput source="config.fees">
        <SimpleFormIterator>
          <SelectInput
            label="Opération"
            source="scope.type"
            choices={operationTypes}
          />
          <SelectInput
            label="Catégorie"
            source="scope.cat"
            choices={operationCategories}
          />
          <BooleanInput label="En ligne ?" source="scope.online" defaultValue />
          <SelectInput
            label="Unité de calcul"
            source="unit"
            choices={feeUnits}
          />
          <NumberInput
            label="Prix"
            source="price"
            parse={CurrencyParser}
            format={CurrencyFormatter}
          />
          <NumberInput label="Taux (%)" source="rate" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="config.analytics">
        <SimpleFormIterator>
          <TextInput label="Nom" source="key" />
          <TextInput label="Label" source="label" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const CustomerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <SelectField source="type" choices={customerTypes} />
      <FunctionField label="Nom" render={renderCustomerName} />
      <TextField source="billing_name" />
      <TextField source="vosfactures_id" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="country" />
      <TextField source="email" />
      <SelectField source="billing_period" choices={billingPeriods} />
      <TextField source="billing_day" />
      <TextField source="billing_time" />
      <ArrayField source="billing_contacts" sortable={false}>
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="billing_emails" sortable={false}>
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="to_bill" />
      <BooleanField source="only_draft" />
      <BooleanField source="bill_only_purchase" />
      <BooleanField source="bill_only_fees" />
      <BooleanField source="disable_reconciliation" />
      <ArrayField source="config.fees">
        <Datagrid>
          <SelectField
            label="Opération"
            source="scope.type"
            choices={operationTypes}
          />
          <SelectField
            label="Catégorie"
            source="scope.cat"
            choices={operationCategories}
          />
          <BooleanField label="En ligne ?" source="scope.online" defaultValue />
          <SelectField
            label="Unité de calcul"
            source="unit"
            choices={feeUnits}
          />
          <CurrencyField label="Prix" source="price" />
          <TextField label="Taux" source="rate" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="config.analytics">
        <Datagrid>
          <TextField label="Nom" source="key" />
          <TextField label="Label" source="label" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const renderCustomerNameWithType = (record) => {
  if (!record) {
    return "";
  }
  let cusType = record.type;
  customerTypes.forEach((t) => {
    if (t.id === record.type) {
      cusType = t.name;
    }
  });
  return `${renderCustomerName(record)} (${cusType})`;
};
export const renderCustomerName = (record) =>
  record && record.type === "consumer"
    ? `${record.firstname} ${record.lastname}`
    : record.name;

export const customerTypes = [
  { id: "agency", name: "Agence" },
  { id: "company", name: "Entreprise" },
  { id: "entity", name: "Entité" },
  { id: "consumer", name: "Utilisateur final" },
];

export const billingPeriods = [
  { id: "immediat", name: "Immédiate" },
  { id: "next_day", name: "Journalière" },
  { id: "weekly", name: "Hebdomadaire" },
  { id: "monthly", name: "Mensuelle" },
  { id: "none", name: "Désactivée" },
];

export const billingContacts = [
  { id: "booker", name: "Utilisateur ayant réservé" },
  { id: "customer", name: "Client" },
];

export const feeUnits = [
  { id: "per_operation", name: "Par opération" },
  { id: "per_pnr", name: "Par PNR" },
  { id: "per_trip", name: "Par trajet (aller + retour = 2 trajets)" },
  { id: "per_journey", name: "Par voyage (aller + retour = 1 voyage)" },
  {
    id: "per_session",
    name: "Par session (opérations effectuées en même temps, quelque soit la catégorie)",
  },
  {
    id: "per_session_and_cat",
    name: "Par session et catégorie (opérations effectuées en même temps, par catégorie)",
  },
  {
    id: "per_travel_and_pax",
    name: "Par voyage et passager (2 transports, 1 hotel et 1 location de voiture max)",
  },
];
