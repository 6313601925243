import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextInput, SelectInput, NumberInput } from "react-admin";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

const paramsConfig = [
  { id: "", valeur: "Choisir le(s) paramètre(s) à surcharger" },
  { id: "bookingPCC", valeur: "PCC de réservation", type: "text" }, // means text input
  { id: "ticketingPCC", valeur: "PCC d'émission", type: "text" },
  {
    id: "paxProfile.paxPCC",
    valeur: "PCC création des profils voyageur",
    type: "text",
  },
  { id: "paxProfile.templatePCC", valeur: "PCC template", type: "text" },
  { id: "paxProfile.templateID", valeur: "ID template", type: "text" },
  { id: "defaultProfile.pcc", valeur: "PCC par défaut", type: "text" },
  { id: "defaultProfile.name", valeur: "Nom par défaut", type: "text" },
  { id: "defaultQueue.pcc", valeur: "Queue par défaut: PCC", type: "text" },
  {
    id: "defaultQueue.number",
    valeur: "Queue par défaut: Numéro",
    type: "text",
  },
  {
    id: "bookingQueue.pcc",
    valeur: "Queue pour PNR à la réservation : PCC",
    type: "text",
  },
  {
    id: "bookingQueue.number",
    valeur: "Queue pour les PNR à la réservation : Numéro",
    type: "text",
  },
  {
    id: "queues.railQueue.pcc",
    valeur: "Queue pour les PNR Trains : PCC",
    type: "text",
  },
  {
    id: "queues.railQueue.number",
    valeur: "Queue pour les PNR Trains : Numéro",
    type: "text",
  },
  {
    id: "queues.airQueue.pcc",
    valeur: "Queue pour les PNR Aériens : PCC",
    type: "text",
  },
  {
    id: "queues.airQueue.number",
    valeur: "Queue pour les PNR Aériens : Numéro",
    type: "text",
  },
  {
    id: "queues.lccQueue.pcc",
    valeur: "Queue pour les PNR low-costs : PCC",
    type: "text",
  },
  {
    id: "queues.lccQueue.number",
    valeur: "Queue pour les PNR low-costs : Numéro",
    type: "text",
  },
  {
    id: "queues.hotelBookingQueue.pcc",
    valeur: "Queue pour les PNR Hotels en réservaton : PCC",
    type: "text",
  },
  {
    id: "queues.hotelBookingQueue.number",
    valeur: "Queue pour les PNR Hotels en réservaton : Numéro",
    type: "text",
  },
  {
    id: "queues.hotelIssuingQueue.pcc",
    valeur: "Queue pour les PNR Hotels en émission : PCC",
    type: "text",
  },
  {
    id: "queues.hotelIssuingQueue.number",
    valeur: "Queue pour les PNR Hotels en émission : Numéro",
    type: "text",
  },
  {
    id: "queues.carRentalBookingQueue.pcc",
    valeur: "Queue pour les PNR Location de voitures : PCC",
    type: "text",
  },
  {
    id: "queues.carRentalBookingQueue.number",
    valeur: "Queue pour les PNR Location de voitures : Numéro",
    type: "text",
  },
  {
    id: "queues.carRentalIssuingQueue.pcc",
    valeur: "Queue pour les PNR de location de voiture en émission : PCC",
    type: "text",
  },
  {
    id: "queues.carRentalIssuingQueue.number",
    valeur: "Queue pour les PNR de location de voiture en émission : Numéro",
    type: "text",
  },
  {
    id: "queues.ticketingAutoQueue.pcc",
    valeur: "Queue pour émission par un robot : PCC",
    type: "text",
  },
  {
    id: "queues.ticketingAutoQueue.number",
    valeur: "Queue pour émission par un robot : Numéro",
    type: "text",
  },
  {
    id: "queues.ticketingManualQueue.pcc",
    valeur: "Queue PNR pour émission manuelle : PCC",
    type: "text",
  },
  {
    id: "queues.ticketingManualQueue.number",
    valeur: "Queue PNR pour émission manuelle : Numéro",
    type: "text",
  },
  {
    id: "queues.rejectionQueue.pcc",
    valeur: "Queue pour PNR rejettés : PCC",
    type: "text",
  },
  {
    id: "queues.rejectionQueue.number",
    valeur: "Queue pour PNR rejettés : Numéro",
    type: "text",
  },
  {
    id: "queues.cancellationQueue.pcc",
    valeur: "Queue pour PNR à annuler : PCC",
    type: "text",
  },
  {
    id: "queues.cancellationQueue.number",
    valeur: "Queue pour PNR à annuler : Numéro",
    type: "text",
  },
  {
    id: "lccPaymentConfig.profile.pcc",
    valeur: "Paiement des low-costs : PCC",
    type: "text",
  },
  {
    id: "lccPaymentConfig.profile.name",
    valeur: "Paiement des low-costs : Nom",
    type: "text",
  },
  {
    id: "lccPaymentConfig.creditCardSecurityCode",
    valeur: "Paiement des low-costs : Code CB",
    type: "text",
  },
  {
    id: "lccPaymentConfig.billingInfo.name",
    valeur: "Information facturation pour low-costs : Nom",
    type: "text",
  },
  {
    id: "lccPaymentConfig.billingInfo.address",
    valeur: "Information facturation pour low-costs : Adresse",
    type: "text",
  },
  {
    id: "lccPaymentConfig.billingInfo.postalCode",
    valeur: "Information facturation pour low-costs : Code Postal",
    type: "text",
  },
  {
    id: "lccPaymentConfig.billingInfo.city",
    valeur: "Information facturation pour low-costs : Ville",
    type: "text",
  },
  {
    id: "lccPaymentConfig.billingInfo.country",
    valeur: "Information facturation pour low-costs : Pays",
    type: "text",
  },
  {
    id: "railTicketing",
    valeur: "Mode d'emission automatique : Train",
    type: "select",
  }, // means select input
  {
    id: "flightTicketing",
    valeur: "Mode d'emission automatique : Vols",
    type: "select",
  },
  {
    id: "flightLCCTicketing",
    valeur: "Mode d'emission automatique : Vols low-costs",
    type: "select",
  },
  {
    id: "flightInternationalTicketing",
    valeur: "Mode d'emission automatique : Vols internationaux",
    type: "select",
  },
  {
    id: "printerProfileNumber",
    valeur: "Numéro de profil d'imprimante d'émission",
    type: "text",
  },
  {
    id: "retentionSegment.text",
    valeur: "Segment de rétention : Texte à afficher",
    type: "text",
  },
  {
    id: "printerProfileNumber",
    valeur: "Segment de rétention : Nombre de jours de rétention",
    type: "number",
  }, // mean number input
];

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    margin: 0,
    padding: 5,
    marginTop: "15px",
    marginLeft: "10px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  configurationBody: {
    width: 400,
  },
  configurationSelect: {
    width: 400,
  },
  configurationBodyInput: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  configurationText: {
    display: "flex",
    alignItems: "center",
  },
  configurationRmv: {
    backgroundColor: "#e53c3c",
  },
}));

const ticketingModes = [
  { id: "immediate", name: "Immediat" },
  { id: "postponed", name: "Automatique avant TTL" },
  { id: "manual", name: "Désactivé (placement dans la queue d'émission)" },
];

function getInputType(prefix, val) {
  let input;
  paramsConfig.forEach((o) => {
    if (o.id === val) {
      switch (o.type) {
      case "text":
        input = (
          <TextInput
            label={o.valeur}
            source={prefix.concat(".", val)}
            allowEmpty
          />
        );
        break;
      case "select":
        input = (
          <SelectInput
            label={o.valeur}
            source={prefix.concat(".", val)}
            alwaysOn
            allowEmpty
            choices={ticketingModes}
          />
        );
        break;
      case "number":
        input = (
          <NumberInput
            label={o.valeur}
            source={prefix.concat(".", val)}
            allowEmpty
          />
        );
        break;
      default:
        input = (
          <TextInput
            label={o.valeur}
            source={prefix.concat(".", val)}
            allowEmpty
          />
        );
      }
    }
  });
  return input;
}
export default function SabreConfigInput(props) {
  const classes = useStyles();
  const [params, setParams] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    if (!params.includes(event.target.value)) {
      setParams((oldArray) => [...oldArray, event.target.value]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRemoveItem = (val) => {
    setParams((list) => list.filter((item) => item !== val));
  };
  return (
    <div className={classes.configurationBody}>
      <FormControl className={classes.formControl}>
        <InputLabel id="configuration-select-label">
          Element à surcharger
        </InputLabel>
        <div>
          <Select
            labelId="configuration-label"
            id="configuration-id"
            className={classes.configurationSelect}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={params}
            onChange={handleChange}
          >
            {paramsConfig.map((v) => (
              <MenuItem key={v.id} value={v.id}>
                {v.valeur}
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
      <Button
        className={classes.button}
        onClick={handleOpen}
        variant="contained"
        color="primary"
      >
        Selectionner le(s) élement(s) à surcharger
      </Button>
      <div className={classes.configurationBodyInput}>
        {params.map((v) => (
          <div className={classes.configurationText}>
            {getInputType(props.sourcePrefix, v)}
            <Button
              className={classes.configurationRmv}
              variant="contained"
              color="secondary"
              onClick={(e) => handleRemoveItem(v)}
            >
              X
            </Button>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}
