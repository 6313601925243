import React, { Component } from "react";
import { withDataProvider } from "react-admin";
import SetStatementIcon from "@material-ui/icons/Payment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

class SetStatementStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      status: "paid",
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { dataProvider, record } = this.props;
    dataProvider(
      "SET_STATEMENT_STATUS",
      "",
      { id: record.id, status: this.state.status },
      {
        onSuccess: {
          notification: { body: "Status mis à jour", level: "info" },
          refresh: true,
        },
        onError: {
          notification: {
            body: "Erreur: status non mis à jour",
            level: "warning",
          },
          refresh: true,
        },
      }
    );
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Button color="primary" onClick={this.handleClick} size="small">
          <SetStatementIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>Changer le status</span>
        </Button>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Mise à jour du statut du relevé
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Nouveau status du relevé:</DialogContentText>
            <Select
              value={this.state.status}
              onChange={(event) => {
                this.setState({ status: event.target.value });
              }}
            >
              <MenuItem value="billed">Facturé</MenuItem>
              <MenuItem value="sent">Envoyé</MenuItem>
              <MenuItem value="pending_payment">
                En attente de paiement
              </MenuItem>
              <MenuItem value="paid">Payé</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>Annuler</Button>
            <Button onClick={this.handleConfirm} color="primary">
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

SetStatementStatusButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(SetStatementStatusButton);
