import React from "react";
import {
  EditButton,
  Edit,
  List,
  Datagrid,
  TextField,
  Create,
  TextInput,
  required,
  SelectInput,
  Show,
  ShowButton,
  ReferenceInput,
  Filter,
  NumberInput,
  BooleanInput,
  BooleanField,
  TabbedForm,
  FormTab,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
} from "react-admin";
import { Typography } from "@material-ui/core";
import EditReadOnlyButton from "../components/Buttons/EditReadOnlyButton";
import NoBasePath from "../components/Fields/NoBasePath";
import { CustomerConfigFields, sabreProfileType } from "./companies";
import SabreConfigInput from "../components/Input/SabreConfigInput";
import {
  airProviders,
  carRentalProviders,
  hotelProviders,
  railProviders,
  AmadeusConfigsInputs,
} from "./agencies";

const styles = {
  title: {
    marginTop: "30px",
    marginBottom: "15px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "24px",
    fontWeight: "bold",
  },
};

const EntityFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <ReferenceInput
      label="Company"
      source="company_id"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
  </Filter>
);

const ReadOnlyToggle = ({ record, ...props }) => (
  <EditReadOnlyButton record={record} {...props} entity="entities" />
);

export const EntityList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des entités"
    bulkActionButtons={false}
    filters={<EntityFilter />}
  >
    <Datagrid rowclick="show" perPage={200}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="company.name" sortable={false} />
      <BooleanField source="is_open" />
      <BooleanField source="config.readOnly" sortable={false} />
      <ShowButton label="" />
      <EditButton label="" />
      {permissions != null && permissions.includes("dev") && <ReadOnlyToggle />}
    </Datagrid>
  </List>
);

export const EntityCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <TabbedForm redirect="list">
      <FormTab label="Informations générales">
        <TextInput source="name" validate={[required()]} />
        <ReferenceInput
          label="Entreprise"
          source="company_id"
          reference="companies"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="config.fce" label="FCE SNCF" />
        <BooleanInput source="is_open" />
        {permissions != null && permissions.includes("admin") && (
          <ArrayInput source="config.analyticValues">
            <SimpleFormIterator>
              <TextInput source="analytic.key" />
              <TextInput source="analytic.cds_key" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormTab>

      {permissions != null && permissions.includes("admin") && (
        <FormTab label="Services externes">
          <Typography variant="h5" style={styles.title}>
            Services activés
          </Typography>

          <SelectInput
            source="config.providers.rail"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={railProviders}
          />
          <SelectInput
            source="config.providers.air"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={airProviders}
          />
          <SelectInput
            source="config.providers.travelerProfile"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={airProviders}
          />
          <SelectInput
            source="config.providers.hotel"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={hotelProviders}
          />
          <SelectInput
            source="config.providers.carRental"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={carRentalProviders}
          />

          <Typography variant="h5" style={styles.title}>
            CDS
          </Typography>
          <TextInput source="config.cdsProfile.dutyCode" />

          <Typography variant="h5" style={styles.title}>
            Carbookr
          </Typography>
          <TextInput source="config.carbookrProfile.clientID" />

          <Typography variant="h5" style={styles.title}>
            Prix des billets
          </Typography>
          <BooleanInput
            label="Masquer les tarifs avions"
            source="config.HiddenFlightPrice"
          />
        </FormTab>
      )}
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("amadeus")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("amadeus"))) && (
            <FormTab label="Configuration Amadeus" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Configurer Amadeus
              </Typography>

              <Typography variant="h5" style={styles.title}>
                Profil Amadeus à lier aux profils créés
              </Typography>
              <TextInput source="config.amadeusCustomerProfile.officeID" />
              <TextInput source="config.amadeusCustomerProfile.uniqueID" />

              <Typography variant="h5" style={styles.title}>
                Surcharger la configuration Amadeus
              </Typography>
              {AmadeusConfigsInputs({ sourcePrefix: "config.amadeusConfigs." })}
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("sabre")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("sabre"))) && (
            <FormTab label="Configurer Sabre" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Configurer Sabre
              </Typography>

              <Typography variant="h5" style={styles.title}>
                Profil Sabre à charger sur les PNR
              </Typography>
              <TextInput source="config.sabreProfile.pcc" />
              <TextInput source="config.sabreProfile.name" />
              <Typography variant="h5" style={styles.title}>
                Profil Sabre à lier aux profils créés
              </Typography>
              <TextInput source="config.sabrePaxCustomerProfile.number" />
              <TextInput source="config.sabrePaxCustomerProfile.pcc" />
              <SelectInput
                source="config.sabrePaxCustomerProfile.type"
                alwaysOn
                allowEmpty
                choices={sabreProfileType}
              />

              <Typography variant="h5" style={styles.title}>
                Surcharger la configuration Sabre
              </Typography>
              <SabreConfigInput sourcePrefix="config.sabreConfigs" />
            </FormTab>
          )
        }
      </FormDataConsumer>
    </TabbedForm>
  </Create>
);

export const EntityEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <TabbedForm redirect="list">
      <FormTab label="Informations générales">
        <TextInput disabled label="Id" source="uid" />
        <TextInput source="name" validate={[required()]} />
        <ReferenceInput
          label="Entreprise"
          source="company_id"
          reference="companies"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="is_open" />
        <TextInput source="config.fce" label="FCE SNCF" />
        {permissions != null && permissions.includes("admin") && (
          <ArrayInput source="config.analyticValues">
            <SimpleFormIterator>
              <TextInput source="analytic.key" />
              <TextInput source="analytic.cds_key" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormTab>

      {permissions != null && permissions.includes("admin") && (
        <FormTab label="Services externes">
          <NoBasePath>
            <Typography variant="h5" style={styles.title}>
              Services activés
            </Typography>
          </NoBasePath>

          <SelectInput
            source="config.providers.rail"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={railProviders}
          />
          <SelectInput
            source="config.providers.air"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={airProviders}
          />
          <SelectInput
            source="config.providers.travelerProfile"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={airProviders}
          />
          <SelectInput
            source="config.providers.hotel"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={hotelProviders}
          />
          <SelectInput
            source="config.providers.carRental"
            allowEmpty
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            choices={carRentalProviders}
          />

          <NoBasePath>
            <Typography variant="h5" style={styles.title}>
              CDS
            </Typography>
          </NoBasePath>
          <TextInput source="config.cdsProfile.dutyCode" />

          <NoBasePath>
            <Typography variant="h5" style={styles.title}>
              Carbookr
            </Typography>
          </NoBasePath>
          <TextInput source="config.carbookrProfile.clientID" />

          <NoBasePath>
            <Typography variant="h5" style={styles.title}>
              Prix des billets
            </Typography>
          </NoBasePath>
          <BooleanInput
            label="Masquer les tarifs avions"
            source="config.HiddenFlightPrice"
          />
        </FormTab>
      )}
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("amadeus")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("amadeus"))) && (
            <FormTab label="Configuration Amadeus" {...rest}>
              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Configurer Amadeus
                </Typography>
              </NoBasePath>

              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Profil Amadeus à lier aux profils créés
                </Typography>
              </NoBasePath>
              <TextInput source="config.amadeusCustomerProfile.officeID" />
              <TextInput source="config.amadeusCustomerProfile.uniqueID" />

              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Surcharger la configuration Amadeus
                </Typography>
              </NoBasePath>
              {AmadeusConfigsInputs({ sourcePrefix: "config.amadeusConfigs." })}
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("sabre")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("sabre"))) && (
            <FormTab label="Configurer Sabre" {...rest}>
              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Configurer Sabre
                </Typography>
              </NoBasePath>

              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Profil Sabre à charger sur les PNR
                </Typography>
              </NoBasePath>
              <TextInput source="config.sabreProfile.pcc" />
              <TextInput source="config.sabreProfile.name" />
              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Profil Sabre à lier aux profils créés
                </Typography>
              </NoBasePath>
              <TextInput source="config.sabrePaxCustomerProfile.number" />
              <TextInput source="config.sabrePaxCustomerProfile.pcc" />
              <SelectInput
                source="config.sabrePaxCustomerProfile.type"
                alwaysOn
                allowEmpty
                choices={sabreProfileType}
              />

              <NoBasePath>
                <Typography variant="h5" style={styles.title}>
                  Surcharger la configuration Sabre
                </Typography>
              </NoBasePath>
              <SabreConfigInput sourcePrefix="config.sabreConfigs" />
            </FormTab>
          )
        }
      </FormDataConsumer>
    </TabbedForm>
  </Edit>
);

export const EntityShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Informations générales">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="company.name" sortable={false} />
        <TextField source="config.fce" label="FCE SNCF" />
        <BooleanField source="is_open" />
        {permissions != null && permissions.includes("admin") && (
          <ArrayField source="config.analyticValues">
            <Datagrid>
              <TextField source="analytic.key" />
              <TextField source="analytic.cds_key" />
              <TextField source="value" />
            </Datagrid>
          </ArrayField>
        )}
      </Tab>
      {permissions != null &&
        permissions.includes("admin") &&
        CustomerConfigFields("Configuration avancée")}
    </TabbedShowLayout>
  </Show>
);
