const inputsGroupStyle = {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "100%",
};

const inputsGroupSpaceStyle = {
  width: "32px",
};

const titleSpaceStyle = {
  marginBottom: "32px",
  width: "100%",
};

export { inputsGroupStyle, inputsGroupSpaceStyle, titleSpaceStyle };
