import React, { useState, useEffect } from "react";
import {
  Button,
  Confirm,
  withDataProvider,
  showNotification,
} from "react-admin";
import { connect } from "react-redux";

function UpdateStatusIssuedTicketButton(props) {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [travelIds, setTravelIds] = useState([]);

  useEffect(() => {
    (async () => {
      const tks = [];
      const { dataProvider, selectedIds } = props;
      const fetchPromises = selectedIds.map((selectedId) =>
        dataProvider.getOne("tickets", { id: selectedId })
      );
      setIsLoading(true);
      const responses = await Promise.all(fetchPromises);
      setIsLoading(false);
      responses.forEach((response) => {
        tks.push(response.data[0]);
      });
      setTravelIds(tks.map((tk) => tk.travelID));
    })();
  }, [props.selectedIds]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    updateStatusTkns();
  };

  const updateStatusTkns = async () => {
    const { dataProvider, resource, showNotification } = props;
    dataProvider(
      "STATUS_ISSUED_TRAVELS",
      "",
      { status: "issued", travelIDs: travelIds },
      {
        onSuccess: {
          notification: {
            body: "Le Statut des voyages a été changé en émis.",
            level: "info",
          },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
      }
    ).catch((error) => {
      showNotification(
        `Erreur lors du rafraichissement ${error.message}`,
        "warning"
      );
    });
    setOpen(false);
  };

  return (
    <>
      <Button
        label={isLoading ? "Patientez..." : props.label}
        disabled={isLoading}
        onClick={handleClick}
      >
        {props.icon}
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Changer le statut des voyages en émis"
        content="Voulez-vous mettre le statut en émis ?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
}

export default connect(null, {
  showNotification,
})(withDataProvider(UpdateStatusIssuedTicketButton));
