import React, { Component } from "react";
import {
  withDataProvider,
  GET_LIST,
  GET_MANY,
  TextField,
  ReferenceField,
} from "react-admin";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowsIcon from "@material-ui/icons/CompareArrows";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CurrencyField from "../Fields/CurrencyField";
import { TheTreepDateField } from "../Fields/TheTreepDateField";

function AmountOperations(ops) {
  let total = 0;
  ops.forEach((op) => {
    total += op.price;
  });
  return total;
}

function AmountSupplies(supplies) {
  let total = 0;
  supplies.forEach((sup) => {
    total += sup.price_incl_taxes;
  });
  return total;
}

function AmountSelectedSupplies(list, selected) {
  let total = 0;
  list.forEach((supply) => {
    selected.forEach((select) => {
      if (supply.id === select) {
        total += supply.price_incl_taxes;
      }
    });
  });
  return total;
}

function AmountSelectedOperations(list, selected) {
  let total = 0;
  list.forEach((operation) => {
    selected.forEach((select) => {
      if (operation.id === select) {
        total += operation.price;
      }
    });
  });
  return total;
}

function AmountDisplay(amount, locales) {
  return (amount / 100).toLocaleString(locales, {
    style: "currency",
    currency: "EUR",
  });
}

class ReconcileOperationsSupplies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      status: "ok",
      operations: [],
      supplies: [],
      pendingOperations: [],
      totalPendingOperations: 0,
      selectedOperations: [],
      pendingSupplies: [],
      totalPendingSupplies: 0,
      selectedSupplies: [],
      page: 0,
      rowsPerPage: 15,
      confirmEnabled: false,
    };
  }

  handleClick = () => {
    switch (this.props.resource) {
    case "operations":
      this.fetchOperations();
      this.fetchPendingSupplies();
      break;
    case "supplies":
      this.fetchSupplies();
      this.fetchPendingOperations();
      break;

    default:
      break;
    }
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { dataProvider, resource, selectedIds } = this.props;
    const { selectedOperations, selectedSupplies } = this.state;
    let operations = null;
    let supplies = null;
    if (resource === "operations") {
      operations = selectedIds;
      if (selectedSupplies && selectedSupplies.length > 0) {
        supplies = selectedSupplies;
      }
    }
    if (resource === "supplies") {
      supplies = selectedIds;
      if (selectedOperations && selectedOperations.length > 0) {
        operations = selectedOperations;
      }
    }
    dataProvider(
      "MANUAL_RECONCILIATION",
      "",
      { operations, supplies, status: this.state.status },
      {
        onSuccess: {
          notification: { body: "Status mis à jour", level: "info" },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
        onError: {
          notification: { body: "Error: status not updated", level: "warning" },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    this.setState({ isOpen: false });
  };

  handleSelectOperation = (id) => {
    const selected = this.state.selectedOperations;
    const index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    this.setState({ selectedOperations: selected });
  };

  handleChangeOperationsPage = (event, page) => {
    this.setState({ page }, () => this.fetchPendingOperations());
  };

  handleSelectSupply = (id) => {
    const selected = this.state.selectedSupplies;
    const index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    this.setState({ selectedSupplies: selected });
  };

  handleChangeSuppliesPage = (event, page) => {
    this.setState({ page }, () => this.fetchPendingSupplies());
  };

  toggleButton = () => {
    this.setState({
      confirmEnabled: !this.state.confirmEnabled,
    });
  };

  async fetchOperations() {
    const { dataProvider, selectedIds } = this.props;
    const { data: operations } = await dataProvider(GET_MANY, "operations", {
      ids: selectedIds,
    });
    this.setState({ operations });
  }

  async fetchPendingOperations() {
    const { dataProvider } = this.props;
    const { data: pendingOperations, total: totalPendingOperations } =
      await dataProvider(GET_LIST, "operations", {
        filter: {
          reconciliation_status: "pending",
        },
        sort: { field: "operation_date", order: "DESC" },
        pagination: {
          page: this.state.page + 1,
          perPage: this.state.rowsPerPage,
        },
      });
    this.setState({
      pendingOperations,
      totalPendingOperations,
    });
  }

  async fetchPendingSupplies() {
    const { dataProvider } = this.props;
    const { data: pendingSupplies, total: totalPendingSupplies } =
      await dataProvider(GET_LIST, "supplies", {
        filter: {
          reconciliation_status: "pending",
        },
        sort: { field: "supply_date", order: "DESC" },
        pagination: {
          page: this.state.page + 1,
          perPage: this.state.rowsPerPage,
        },
      });
    this.setState({
      pendingSupplies,
      totalPendingSupplies,
    });
  }

  async fetchSupplies() {
    const { dataProvider, selectedIds } = this.props;
    const { data: supplies } = await dataProvider(GET_MANY, "supplies", {
      ids: selectedIds,
    });
    this.setState({ supplies });
  }

  render() {
    const { resource, selectedIds, locales } = this.props;
    let content = null;
    let supTotal;
    let opTotal;
    if (resource === "operations") {
      supTotal = AmountSelectedSupplies(
        this.state.pendingSupplies,
        this.state.selectedSupplies
      );
      opTotal = AmountOperations(this.state.operations);
    } else {
      supTotal = AmountSupplies(this.state.supplies);
      opTotal = AmountSelectedOperations(
        this.state.pendingOperations,
        this.state.selectedOperations
      );
    }

    switch (resource) {
    case "operations":
      content = (
        <>
          <DialogContentText>
            {this.state.operations && this.state.operations.length} opérations
              séléctionnées:
          </DialogContentText>
          <PendingOperations operations={this.state.operations} />
          <DialogContentText>
              À réconcilier avec les fournitures suivantes en attente de
              réconciliation:
          </DialogContentText>
          <PendingSupplies
            supplies={this.state.pendingSupplies}
            totalSupplies={this.state.totalPendingSupplies}
            page={this.state.page}
            handleChangePage={this.handleChangeSuppliesPage}
            handleSelectSupply={this.handleSelectSupply}
            rowsPerPage={this.state.rowsPerPage}
            withCheckbox
          />
        </>
      );
      break;
    case "supplies":
      content = (
        <>
          <DialogContentText>
            {selectedIds && selectedIds.length} fournitures séléctionnées.
          </DialogContentText>
          <PendingSupplies supplies={this.state.supplies} />
          <DialogContentText>
              À réconcilier avec les opérations suivantes en attente de
              réconciliation:
          </DialogContentText>
          <PendingOperations
            operations={this.state.pendingOperations}
            totalOperations={this.state.totalPendingOperations}
            page={this.state.page}
            handleChangePage={this.handleChangeOperationsPage}
            handleSelectOperation={this.handleSelectOperation}
            rowsPerPage={this.state.rowsPerPage}
            withCheckbox
          />
        </>
      );
      break;

    default:
      break;
    }
    return (
      <>
        <Button color="primary" onClick={this.handleClick} size="small">
          <ArrowsIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>Réconciliation manuelle</span>
        </Button>
        <Dialog
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleDialogClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h5" color="inherit">
                Réconciliation manuelle
              </Typography>
            </Toolbar>
          </AppBar>
          {/* <DialogTitle id="form-dialog-title">Réconciliation manuelle</DialogTitle> */}
          <DialogContent>
            {content}

            <DialogContentText>
              Nouveau status de réconciliation:
            </DialogContentText>
            <Select
              value={this.state.status}
              onChange={(event) => {
                this.setState({ status: event.target.value });
              }}
            >
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="pending">En attente de réconciliation</MenuItem>
            </Select>
            <DialogContentText>
              <h4 style={{ marginBottom: "5px", marginTop: "10px" }}>
                {" "}
                Résumé :{" "}
              </h4>
            </DialogContentText>
            <DialogContentText>
              Nombre d'opérations sélectionnées :
              {resource === "operations"
                ? this.state.operations.length
                : this.state.selectedOperations.length}
            </DialogContentText>
            <DialogContentText>
              {" "}
              Montant des opérations : {AmountDisplay(opTotal)}{" "}
            </DialogContentText>
            <DialogContentText>
              Nombre de fournitures sélectionnées :
              {resource === "operations"
                ? this.state.selectedSupplies.length
                : this.state.supplies.length}
            </DialogContentText>
            <DialogContentText>
              Montant des fournitures : {AmountDisplay(supTotal)}
            </DialogContentText>
            <DialogContentText>
              {supTotal === opTotal ? null : (
                <div>
                  <Typography style={{ color: "red" }}>
                    {" "}
                    Les montants ne correspondent pas. Est-vous sûr de vouloir
                    continuer ? Si oui cochez la case suivante :{" "}
                    <Checkbox onClick={this.toggleButton} />{" "}
                  </Typography>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>Annuler</Button>
            <Button
              onClick={this.handleConfirm}
              disabled={
                supTotal === opTotal ? false : !this.state.confirmEnabled
              }
              color="primary"
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

ReconcileOperationsSupplies.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(ReconcileOperationsSupplies);

class PendingOperations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      data: [this.props.operations],
    };
  }

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.props.handleSelectOperation(id);

    this.setState({ selected: newSelected });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      operations,
      totalOperations,
      page,
      handleChangePage,
      rowsPerPage,
      withCheckbox,
      handleSelectOperation,
    } = this.props;

    return (
      <Paper>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Type</TableCell>
              <TableCell>Categorie</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Entreprise</TableCell>
              <TableCell>Date de l'opération</TableCell>
              <TableCell>PNR</TableCell>
              <TableCell>Origine</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Départ</TableCell>
              <TableCell>PAX</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operations.map((record) => {
              const isSelected = this.isSelected(record.id);
              return (
                <TableRow
                  hover
                  onClick={(event) =>
                    handleSelectOperation && this.handleClick(event, record.id)
                  }
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={record.id}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    {withCheckbox && <Checkbox checked={isSelected} />}
                  </TableCell>
                  <TableCell> {record.type} </TableCell>
                  <TableCell> {record.category} </TableCell>
                  <TableCell> {record.status} </TableCell>
                  <TableCell>
                    <ReferenceField
                      label="Entreprise"
                      basePath="/companies/"
                      record={record}
                      source="customer_id"
                      reference="companies"
                      allowEmpty
                      sortable={false}
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </TableCell>
                  <TableCell>
                    <TheTreepDateField
                      record={record}
                      source="operation_date"
                      showTime
                    />
                  </TableCell>
                  <TableCell>{record.pnr}</TableCell>
                  <TableCell>{record.origin}</TableCell>
                  <TableCell>{record.destination}</TableCell>
                  <TableCell>
                    <TheTreepDateField
                      record={record}
                      source="departure_date"
                      showTime
                    />
                  </TableCell>
                  <TableCell>
                    {record.pax &&
                      record.pax.map((pax, i) => <span key={i}>{pax}</span>)}
                  </TableCell>
                  <TableCell>
                    <CurrencyField record={record} source="price" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {page != null && (
          <TablePagination
            component="div"
            count={totalOperations}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
          />
        )}
      </Paper>
    );
  }
}

class PendingSupplies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      data: [this.props.supplies],
    };
  }

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.props.handleSelectSupply(id);

    this.setState({ selected: newSelected });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      supplies,
      totalSupplies,
      page,
      handleChangePage,
      rowsPerPage,
      withCheckbox,
      handleSelectSupply,
    } = this.props;

    return (
      <Paper>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Type de facture</TableCell>
              <TableCell>Categorie</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Entreprise</TableCell>
              <TableCell>Date de la fourniture</TableCell>
              <TableCell>PNR</TableCell>
              <TableCell>Origine</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Départ</TableCell>
              <TableCell>PAX</TableCell>
              <TableCell>Price TTC</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplies.map((record) => {
              const isSelected = this.isSelected(record.id);
              return (
                <TableRow
                  hover
                  onClick={(event) =>
                    handleSelectSupply && this.handleClick(event, record.id)
                  }
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={record.id}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    {withCheckbox && <Checkbox checked={isSelected} />}
                  </TableCell>
                  <TableCell> {record.invoice_type} </TableCell>
                  <TableCell> {record.category} </TableCell>
                  <TableCell> {record.status} </TableCell>
                  <TableCell> {record.customer_name} </TableCell>
                  <TableCell>
                    <TheTreepDateField
                      record={record}
                      source="supply_date"
                      showTime
                    />
                  </TableCell>
                  <TableCell>{record.pnr}</TableCell>
                  <TableCell>{record.origin}</TableCell>
                  <TableCell>{record.destination}</TableCell>
                  <TableCell>
                    <TheTreepDateField
                      record={record}
                      source="departure_date"
                      showTime
                    />
                  </TableCell>
                  <TableCell>
                    {record.pax &&
                      record.pax.map((pax, i) => <span key={i}>{pax}</span>)}
                  </TableCell>
                  <TableCell>
                    <CurrencyField
                      record={record}
                      source="price_incl_taxes"
                      invertColors
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {page != null && (
          <TablePagination
            component="div"
            count={totalSupplies}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
          />
        )}
      </Paper>
    );
  }
}
