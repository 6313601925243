import React from "react";

const AutoCompleteItems = (props) => {
  if (props.closed) return null;

  let items = [];

  if (!props.loading) {
    items = props.items.map((item) => (
      <li key={item.ID} className="common-text">
        <div
          className="autocompletion-elem"
          onMouseDown={() => props.onClick(item)}
        >
          <span>{item.description}</span>
        </div>
      </li>
    ));
  }

  return (
    <ul className="autocompletion-wrapper">
      {props.loading && (
        <li className="common-text loading">
          <span />
        </li>
      )}
      {!props.loading && !props.items.length && (
        <li className="common-text no-result">
          <span>Pas de résultat correspondant</span>
        </li>
      )}
      {items}
    </ul>
  );
};

export default AutoCompleteItems;
