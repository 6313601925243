import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import ConfirmationNumber from "@material-ui/icons/ConfirmationNumber";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";
import CardIcon from "./CardIcon";

const styles = {
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
};

const NbTickets = ({ value, header }) => (
  <div style={styles.main}>
    <a href="#/tickets" style={{ textDecoration: "none" }}>
      <CardIcon Icon={ConfirmationNumber} bgColor="#ff9800" />
      <Card style={styles.card}>
        <Typography color="textSecondary">{header}</Typography>
        <Typography variant="h4" component="h1">
          {value}
        </Typography>
      </Card>
    </a>
  </div>
);

const enhance = compose(translate);

export default enhance(NbTickets);
