import React, { Component } from "react";
import { withDataProvider, GET_MANY } from "react-admin";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Visibility from "@material-ui/icons/Visibility";
import moment from "moment";

const style = {
  dialog: {
    minWidth: 1000,
  },
  bold: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
};

function translateCardType(type) {
  switch (type) {
  case "loyalty_card":
    return "Carte de fidélité";
  case "discount":
    return "Carte de reduction";
  default:
    return type;
  }
}

function translateCardMode(mode) {
  switch (mode) {
  case "airline":
    return "d'avion";
  case "rail":
    return "de train";
  default:
    return mode;
  }
}

export default function ShowCard(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" onClick={handleClickOpen}>
        <Visibility />
      </Button>
      <Dialog
        style={style.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Détails de la carte
          {"  "}
          {props.record.card_name}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Type :{"  "}
            {translateCardType(props.record.cardtype.card_type)}
            {"  "}
            {translateCardMode(props.record.cardtype.type)}
          </Typography>
          <Typography gutterBottom>
            Nom :{"  "}
            {props.record.cardtype.name}
          </Typography>
          <Typography gutterBottom>
            Numéro :{"  "}
            {props.record.cardnumber}
          </Typography>
          {props.record.expiration_date && (
            <Typography gutterBottom>
              Expire le
              {"  "}
              {moment(props.record.expiration_date).format("DD/MM/YYYY")}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
