import React from "react";
import {
  Edit,
  List,
  Filter,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  Show,
  SimpleShowLayout,
  ShowButton,
  EditButton,
  CloneButton,
  ArrayField,
  SingleFieldList,
  SelectArrayInput,
  SelectField,
  BooleanInput,
  BooleanField,
  AutocompleteInput,
} from "react-admin";
import ChipField from "../components/Fields/ChipField";
import { operationCategories } from "./operations";

const suppliersAgency = [{ id: "vairon", name: "Vairon" }];

const SupplierFilter = (props) => (
  <Filter context="button" {...props}>
    <AutocompleteInput
      source="categories"
      alwaysOn
      choices={operationCategories}
      style={{ width: "50px" }}
    />
    <TextInput source="name" alwaysOn />
    <SelectInput
      allowEmpty
      source="agency"
      alwaysOn
      choices={suppliersAgency}
    />
  </Filter>
);

export const SupplierList = ({ permissions, ...props }) => (
  <List {...props} title="Liste des fournisseurs" filters={<SupplierFilter />}>
    <Datagrid rowclick="show" perPage={200}>
      <TextField source="name" />
      <SelectField source="agency" choices={suppliersAgency} />
      <ArrayField source="categories" sortable={false}>
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="is_default" />
      <ShowButton label="" />
      <EditButton label="" />
      <CloneButton />
    </Datagrid>
  </List>
);

export const SupplierCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <SelectArrayInput
        source="categories"
        validate={[required()]}
        choices={operationCategories}
      />
      <BooleanInput source="is_default" />
      <SelectInput allowEmpty source="agency" choices={suppliersAgency} />
    </SimpleForm>
  </Create>
);

export const SupplierEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <SelectArrayInput
        source="categories"
        validate={[required()]}
        choices={operationCategories}
      />
      <SelectInput allowEmpty source="agency" choices={suppliersAgency} />
      <BooleanInput source="is_default" />
    </SimpleForm>
  </Edit>
);

export const SupplierShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <SelectField source="agency" choices={suppliersAgency} />
      <ArrayField source="categories">
        <SingleFieldList>
          <ChipField />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="is_default" />
    </SimpleShowLayout>
  </Show>
);
