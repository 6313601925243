import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Confirm, crudUpdateMany } from "react-admin";

class UpdateOperationStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
    crudUpdateMany(
      resource,
      selectedIds,
      { status: this.props.status },
      basePath
    );
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Nouveau status"
          content={`Êtes vous sûr de vouloir changer le status de ces opérations à "${this.props.status}"`}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default connect(undefined, { crudUpdateMany })(
  UpdateOperationStatusButton
);
