import React, { Component } from "react";
import { withDataProvider, GET_LIST } from "react-admin";
import CreateStatementIcon from "@material-ui/icons/Assignment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  GolangDateParser,
  ValidateGolangDate,
} from "../Fields/TheTreepDateField";
import { statementsTypes } from "../../entities/statements";
import { renderCustomerNameWithType } from "../../entities/customers";

class CreateStatementButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      start: "",
      end: "",
      customer: 0,
      analytics: null,
      url: "",
      type: "detailed",
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    if (this.state.url && this.state.url !== "") {
      URL.revokeObjectURL(this.state.url);
      this.setState({ isOpen: false, url: "" });
    }
    this.setState({ isOpen: false });
  };

  handleConfirm = async () => {
    const { dataProvider } = this.props;
    const { customer, start, end, analytics, type } = this.state;
    const { data } = await dataProvider(
      "CREATE_STATEMENT",
      "",
      {
        customer: customer || null,
        start_period: start !== "" ? GolangDateParser(start) : null,
        end_period: end !== "" ? GolangDateParser(end) : null,
        analytics,
        type,
      },
      {
        onSuccess: {
          notification: { body: "Relevé créé avec succès", level: "info" },
          refresh: true,
        },
        onError: {
          notification: {
            body: "Erreur lors de la création du relevé",
            level: "warning",
          },
          refresh: true,
        },
      }
    );
    this.setState({ url: data.url });
  };

  render() {
    return (
      <>
        <Button color="primary" onClick={this.handleClick} size="small">
          <CreateStatementIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>Générer un relevé</span>
        </Button>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Générer un relevé</DialogTitle>
          <DialogContent>
            <DialogContentText>Relevé à générer:</DialogContentText>
            <TextField
              required
              value={this.state.start}
              onChange={(event) => {
                this.setState({ start: event.target.value });
              }}
              autoFocus
              margin="dense"
              id="start"
              label="Date de début (JJ/MM/AAAA)"
              type="text"
              placeholder=" "
              fullWidth
              validate={[ValidateGolangDate]}
            />
            <TextField
              required
              value={this.state.end}
              onChange={(event) => {
                this.setState({ end: event.target.value });
              }}
              autoFocus
              margin="dense"
              id="end"
              label="Date de fin (JJ/MM/AAAA)"
              type="text"
              placeholder=" "
              fullWidth
              validate={[ValidateGolangDate]}
            />
            <Select
              required
              fullWidth
              label="Type de relevé"
              value={this.state.type}
              onChange={(event) => {
                this.setState({ type: event.target.value });
              }}
            >
              {statementsTypes.map((el) => (
                <MenuItem value={el.id}>{el.name}</MenuItem>
              ))}
            </Select>

            <CustomerSelect
              required
              value={this.state.customer}
              onChange={(customer) => {
                this.setState({ customer });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>Annuler</Button>
            <Button onClick={this.handleConfirm} color="primary">
              Confirmer
            </Button>
          </DialogActions>
          {this.state.url && this.state.url !== "" && (
            <DialogContent>
              <DialogContentText>
                <a href={this.state.url} download="statement.csv">
                  Téléchargement du relevé
                </a>
              </DialogContentText>
            </DialogContent>
          )}
        </Dialog>
      </>
    );
  }
}

CreateStatementButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(CreateStatementButton);

const CustomerSelect = withDataProvider(({ dataProvider, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("empty");

  React.useEffect(() => {
    let active = true;

    if (loading || searchValue === inputValue) {
      return undefined;
    }
    setLoading(true);
    setSearchValue(inputValue);

    (async () => {
      const { data: customers } = await dataProvider(GET_LIST, "customers", {
        filter: { name: inputValue },
        sort: { field: "type", order: "ASC" },
        pagination: { page: 1, perPage: 100 },
      });
      setLoading(false);
      setOptions(customers);
    })();

    return () => {
      active = false;
    };
  }, [loading, inputValue, setLoading, loading, searchValue, setSearchValue]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="customer-select"
      fullWidth
      label="Client"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => renderCustomerNameWithType(option)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onChange(newInputValue);
      }}
      onChange={(event, value) => {
        onChange(value.id);
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          autoFocus
          margin="dense"
          fullWidth
          label="Client"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});
