import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { DisplayType } from "./TicketsDeparture";

const styles = {
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
    minWidth: "500px",
  },
  root: {
    flex: 1,
  },
};

const PendingCancelTicket = ({ value, header }) => (
  <Card style={styles.main}>
    <CardHeader title={header} />
    <List dense style={styles.list}>
      {value.map((ticket) => (
        <ListItem
          key={ticket.id}
          button
          component={Link}
          to={`/tickets/${ticket.uid}/show`}
          style={styles.item}
        >
          <ListItemAvatar>
            <Avatar>
              <ConfirmationNumberIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${ticket.travelers[0].lastname} ${ticket.travelers[0].firstname}`}
            secondary={`${DisplayType(ticket)} / PNR : ${
              ticket.pnr ?? "Inconnu"
            }`}
          />
        </ListItem>
      ))}
    </List>
  </Card>
);

const enhance = compose(translate);

export default enhance(PendingCancelTicket);
