import React, { Component } from "react";
import { Button, Confirm, withDataProvider } from "react-admin";
import PropTypes from "prop-types";

class UpdateInvoiceStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { dataProvider, selectedIds } = this.props;
    dataProvider(
      "SET_INVOICES_STATUS",
      "",
      { ids: selectedIds, status: this.props.status },
      {
        onSuccess: {
          notification: { body: "Status mis à jour", level: "info" },
          refresh: true,
        },
        onError: {
          notification: {
            body: "Erreur: status non mis à jour",
            level: "warning",
          },
          refresh: true,
        },
      }
    );
    this.setState({ isOpen: false });
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Nouveau status"
          content={`Êtes vous sûr de vouloir changer le status de ces factures à "${this.props.status}"`}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

UpdateInvoiceStatusButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(UpdateInvoiceStatusButton);
