import { DialogContent } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ButtonIcon from "@material-ui/icons/SupervisedUserCircle";
import PropTypes from "prop-types";
import React from "react";
import { GET_LIST, GET_MANY, withDataProvider } from "react-admin";

class UserEntitiesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedEntities: [],
      entities: [],
      company: null,
      warning: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleClick = () => {
    this.fetchUsers();
    this.setState({ isOpen: true });
  };

  handleConfirm = () => {
    const { dataProvider, resource, selectedIds } = this.props;
    const { checkedEntities } = this.state;
    dataProvider(
      "USERS_ENTITIES",
      "",
      {
        user_ids: selectedIds,
        entity_ids: checkedEntities,
      },
      {
        onSuccess: {
          notification: { body: "Entités modifiées", level: "info" },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
        onError: {
          notification: {
            body: "Erreur lors de l'ajout des entités",
            level: "warning",
          },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    this.setState({ ...this.state, isOpen: false, checkedEntities });
  };

  handleChange(e) {
    const item = parseInt(e.target.name, 10);
    const isChecked = e.target.checked;
    const { checkedEntities } = this.state;
    if (isChecked === true) {
      this.setState({
        ...this.state,
        checkedEntities: [...checkedEntities, item],
      });
      // checkedEntities.push(item);
    } else {
      const newCheckedEntities = [...checkedEntities];
      newCheckedEntities.splice(
        newCheckedEntities.findIndex((el) => el === item),
        1
      );
      this.setState({
        ...this.state,
        checkedEntities: [...newCheckedEntities],
      });
    }
  }

  displayEntity = (entity) => {
    let checked = false;
    this.state.checkedEntities.forEach((el) => {
      if (el === entity.uid) {
        checked = true;
      }
    });
    return (
      <div key={`${entity.uid}`}>
        <div>
          <label key={entity.uid}>
            {entity.name}
            <Checkbox
              name={`${entity.uid}`}
              checked={checked}
              onChange={this.handleChange}
            />
          </label>
        </div>
      </div>
    );
  };

  async fetchUsers() {
    const { dataProvider, selectedIds, s } = this.props;
    const { data: users } = await dataProvider(GET_MANY, "users", {
      ids: selectedIds,
    });

    // If only one user is selected
    if (users.length === 1) {
      this.setState(
        {
          ...this.state,
          company: users[0].company_id,
          checkedEntities: [...users[0].entities.map((entity) => entity.uid)],
          warning: null,
        },
        this.fetchentities
      );
      return;
    }

    // Check if users come from the same company
    const sameCompany = Array.from(
      new Set(users.map((user) => user.company_id))
    );
    if (sameCompany.length > 1) {
      this.setState({
        ...this.state,
        warning:
          "Attention ! Impossible d'Afficher les entités, ces utilisateurs n'ont pas les mêmes entreprises !",
      });
      return;
    }

    // Find same entity
    const allEntities = Array.from(
      new Set(users.map((user) => user.entities.map((entity) => entity.uid)))
    );
    const sameEntities = allEntities.map((entity) =>
      allEntities[0].filter((x) => entity.includes(x))
    );

    this.setState(
      {
        company: users[0].company_id,
        checkedEntities: [...sameEntities[sameEntities.length - 1]],
        warning:
          "Attention ! Les entités cochées sont uniquement celles auxquelles appartiennent tous les utilisateurs sélectionnés.",
      },
      this.fetchentities
    );
  }

  async fetchentities() {
    const { dataProvider } = this.props;
    const { data: entities } = await dataProvider(GET_LIST, "entities", {
      filter: { company_id: this.state.company },
      sort: { field: "uid", order: "DESC" },
      pagination: { page: 1, perPage: 1000 },
    });
    this.setState({
      entities,
    });
  }

  render() {
    const { warning, entities } = this.state;
    return (
      <div>
        <Button
          key="click-button"
          color="primary"
          onClick={this.handleClick}
          size="small"
        >
          <ButtonIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>Entités</span>
        </Button>
        <Dialog
          key="dialog"
          fullScreen
          open={this.state.isOpen || false}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleDialogClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h5" color="inherit">
                Entités
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <h1>Entités</h1>
            {entities.map((entity) => this.displayEntity(entity))}
            <Typography variant="body1" color="error">
              {warning}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button key="cancel" onClick={this.handleDialogClose}>
              Annuler
            </Button>
            <Button key="confirm" onClick={this.handleConfirm} color="primary">
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UserEntitiesButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(UserEntitiesButton);
