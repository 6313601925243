import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Send from "@material-ui/icons/Send";
import Sync from "@material-ui/icons/Sync";
import Visibility from "@material-ui/icons/Visibility";
import VpnKey from "@material-ui/icons/VpnKey";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import moment from "moment";
import { stringify } from "query-string";
import React from "react";
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  Edit,
  EditButton,
  email,
  EmailField,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  Link,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  ShowButton,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import countries from "world_countries_lists/data/countries/_combined/countries.json";
import { Renderer } from "xlsx-renderer";
import usersTemplate from "../assets/template-users.xlsx";
import DeleteItemButton from "../components/Buttons/DeleteButton";
import DeleteUserButton from "../components/Buttons/DeleteUserButton";
import DeleteUserDataButton from "../components/Buttons/DeleteUserDataButton";
import OnboardingButton from "../components/Buttons/OnboardingButton";
import ResetPasswordButton from "../components/Buttons/ResetPasswordButton";
import SetApproverButton from "../components/Buttons/SetApproverButton";
import ShowCard from "../components/Buttons/ShowCard";
import SyncUsersButton from "../components/Buttons/SyncUsersButton";
import UserEntitiesButton from "../components/Buttons/UserEntitiesButton";
import UserServiceButton from "../components/Buttons/UserServiceButton";
import {
  DateUserFormatter,
  TheTreepDateField,
} from "../components/Fields/TheTreepDateField";
import { AnalyticsInput } from "../components/Input/AnalyticsInput";
import DateTextInput from "../components/Input/DateTextInput";
import PlaceInput from "../components/Input/PlaceInput";
import { apiHeaders, apiUrl } from "../constants";
import passwordGenerator from "../utils/passwordGenerator";

moment.locale("fr");

const UserPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

/*
  permissions bits

  n°  | perm
  ----+---------
  0   | PermLogin represents a standard account access
  1   | PermPro represents a pro account access
  2   | PermDev represents a dev account access
  3   | PermAdmin represents an the treep admin account access
  4   | PermAccountCreation permission authorizes a user to create an account for another user
  5   | PermBooking permission authorizes a user to book a travel
  6   | PermBookingConfirmation permission authorizes a user to validate a booking
  7   | PermApproval permission authorizes a user to create an account for another user
  8   | PermEmployeeResearch permission authorizes a user to research an employee of his company
  9   | PermTreeperResearch permission authorizes a user to research another user of the database
  10  | PermCompanyPayment permission authorizes a user to pay by company account
  11  | PermCreditCardPayment permission authorizes a user to pay by credit card
  12  | PermPersonalDashboard permission authorizes a user to access to his personal dashboard
  13  | PermCompanyDashboard permission authorizes a user to access to the company dashboard
  14  | PermResourcesList permission authorizes a user to list all tickets, companies, user, user_cards,... of the database
  15  | PermBillingBackoffice permission authorizes a user to access billing backoffice data (operation details, ...)
  16  | PermTravelAgent permission authorizes a user book a travel for another customer (company)
  17  | PermSubsizidedTrip permission authorizes a user to book a subsizided trip
  18  | PermServiceResearch permission authorizes a user to research an employee of his service
  19  | PermCardEdit permission authorizes a user to edit user attached cards
  20  | PermCardList permission authorizes a user to list user attached cards
  21  | PermEscalateApproval permission authorizes a user to escalate or deny an approval
  22  | PermSubsidyEdition permission authorizes a user to edit subsidies
  23  | PermAssistanceAgent give a user the assistance agent status to benefit from different features
*/

const usersPermissions = [
  { id: 0, name: "Compte non actif" },
  { id: 6241, name: "Voyageur grand public" },
  { id: 138339, name: "Voyageur avec paiement subventionné" },
  { id: 6179, name: "Voyageur pro sous approbation" },
  { id: 7267, name: "Voyageur pro autorisé à réserver" },
  {
    id: 268323,
    name: "Voyageur pouvant chercher uniquement dans son service, sous approbation",
  },
  {
    id: 269411,
    name: "Voyageur pouvant chercher uniquement dans son service, autorisé à réserver",
  },
  {
    id: 6435,
    name: "Voyageur pouvant chercher dans tous les services, sous approbation",
  },
  {
    id: 7523,
    name: "Voyageur pouvant chercher dans tous les services, autorisé à réserver",
  },
  { id: 8388977, name: "Chargé d'assistance" },
  { id: 7475, name: "Chargé de voyage sous approbation" },
  { id: 7539, name: "Chargé de voyage autorisé à réserver" },
  { id: 122483, name: "Agent d'agence de voyage" },
  { id: 2104355, name: "Examinateur d'approbations" },
  { id: 2104483, name: "Approbateur" },
  { id: 3685875, name: "Superviseur" },
  { id: 7995387, name: "Administrateur" },
  { id: 16383995, name: "Administrateur d'assistance" },
  { id: 7995391, name: "Developpeur" },
];

const displayPermissions = [
  { id: 0, name: "Compte non actif" },
  { id: 6241, name: "Voyageur grand public" },
  { id: 138339, name: "Voyageur avec paiement subventionné" },
  { id: 6307, name: "Voyageur pro sous approbation" },
  { id: 7395, name: "Voyageur pro autorisé à réserver" },
  {
    id: 268451,
    name: "Voyageur pouvant chercher uniquement dans son service, sous approbation",
  },
  {
    id: 269539,
    name: "Voyageur pouvant chercher uniquement dans son service, autorisé à réserver",
  },
  {
    id: 6563,
    name: "Voyageur pouvant chercher dans tous les services, sous approbation",
  },
  {
    id: 7651,
    name: "Voyageur pouvant chercher dans tous les services, autorisé à réserver",
  },
  { id: 8388977, name: "Chargé d'assistance" },
  { id: 7603, name: "Chargé de voyage sous approbation" },
  { id: 7667, name: "Chargé de voyage autorisé à réserver" },
  { id: 122483, name: "Agent d'agence de voyage" },
  { id: 2104355, name: "Examinateur d'approbations" },
  { id: 2104483, name: "Approbateur" },
  { id: 3685875, name: "Superviseur" },
  { id: 7995387, name: "Administrateur" },
  { id: 16383995, name: "Administrateur d'assistance" },
  { id: 7995391, name: "Developpeur" },
];

const usersGender = [
  { id: "MR", name: "Monsieur" },
  { id: "MS", name: "Madame" },
];

const usersStatus = [
  { id: "email_confirmed", name: "Email confirmé" },
  { id: "deleted", name: "Compte supprimé", backgroundColor: "lightgrey" },
  {
    id: "onboarding",
    name: "En attente d'onboarding",
    backgroundColor: "#ffcc80",
  },
  {
    id: "pending",
    name: "En attente de confirmation",
    backgroundColor: "#ffcc80",
  },
  { id: "locked", name: "Compte verrouillé", backgroundColor: "#ef9a9a" },
  { id: "protected", name: "Compte protégé", backgroundColor: "#ffcc80" },
];

const handicaps = [
  {
    id: "dp_wheelchair_with_stairs",
    name: "Peut se déplacer seul au sol et en cabine, monter et descendre des escaliers",
  },
  {
    id: "dp_wheelchair_no_stairs",
    name: "Peut se déplacer seul au sol et en cabine, mais pas d'escaliers",
  },
  {
    id: "dp_wheelchair_not_alone",
    name: "Ne peut pas se déplacer seul, doit être porté jusqu'à son siège en cabine.",
  },
  { id: "dp_deaf", name: "Non entendant ou malentendant" },
  { id: "dp_deaf_mute", name: "Non entendant ou malentendant et muet" },
  { id: "dp_blind", name: "Non-voyant ou malvoyant" },
  {
    id: "dp_mental_deficiency_understand_security",
    name: "Affecté d'une déficience mentale mais comprend et applique les consignes de sécurité",
  },
  {
    id: "dp_mental_deficiency",
    name: "Affecté d'une déficience mentale mais ne peut pas contribuer à sa propre évacuation",
  },
  {
    id: "dp_extraseet_left",
    name: "Besoin d'un siège suplémentaire pour la jambe gauche",
  },
  {
    id: "dp_extraseet_right",
    name: "Besoin d'un siège suplémentaire pour la jambe droite",
  },
  {
    id: "dp_extraseet",
    name: "Besoin d'un siège suplémentaire pour une autre raison",
  },
  { id: "dp_medical_notice", name: "Nécessite un avis médical pour voyager" },
  {
    id: "dp_medical_notice_stretcher",
    name: "En civière, nécessite un avis médical pour voyager",
  },
];

const wheelchairs = [
  {
    id: "manual_wheelchair_no_elevator",
    name: "Fauteuil roulant manuel sans demande d'élévateur",
  },
  {
    id: "manual_wheelchair_with_elevator",
    name: "Fauteuil roulant manuel avec demande d'élévateur",
  },
  {
    id: "electrical_dry_battery",
    name: "Fauteuil roulant electrique avec batterie sèche",
  },
  {
    id: "electrical_lithium_battery",
    name: "Fauteuil roulant electrique avec batterie au lithium",
  },
  {
    id: "electrical_wet_battery",
    name: "Fauteuil roulant electrique avec batterie humide",
  },
];

const UserFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <ReferenceInput
      label="Entreprise"
      source="company_id"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="firstname" alwaysOn />
    <TextInput source="lastname" alwaysOn />
    <TextInput source="username" alwaysOn />
    <TextInput source="email" alwaysOn />
    <TextInput source="service" alwaysOn />
    <TextInput source="category" alwaysOn />
    <NumberInput source="approval_level" alwaysOn />
    <SelectArrayInput
      label="Permissions"
      source="permissionMask"
      alwaysOn
      allowEmpty
      choices={usersPermissions}
      style={{ minWidth: 200 }}
    />
  </Filter>
);

function displayDate(date) {
  if (date == null || date.length < 12) {
    return "";
  }
  const formattedDate = dayjs(date).format("DD/MM/YYYY");
  return formattedDate || "";
}

function displayRole(permission) {
  for (let i = 0; i < displayPermissions.length; i++) {
    if (permission === displayPermissions[i].id) {
      if (displayPermissions[i].name != null) {
        return displayPermissions[i].name;
      }
    }
  }

  for (let i = 0; i < usersPermissions.length; i++) {
    if (permission === usersPermissions[i].id) {
      if (usersPermissions[i].name != null) {
        return usersPermissions[i].name;
      }
    }
  }
}

function displayBoolean(b) {
  if (b) {
    return "oui";
  }

  return "non";
}

function displayGender(g) {
  if (g === "MR") {
    return "M.";
  }

  return "Mme";
}

const exporter = async (
  records,
  fetchRelatedRecords,
  dataProvider,
  resource
) => {
  // Get the filters from the URL
  const params = new URLSearchParams(window.location.hash.substr(1));
  const filters = JSON.parse(params.get("filter"));

  // Fetch the total number of records
  const { total } = await dataProvider.getList(resource, {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "ASC" },
    filter: filters,
  });

  const totalPages = Math.ceil(total / 100);
  const allRecords = [];

  for (let page = 1; page <= totalPages; page++) {
    // eslint-disable-next-line no-await-in-loop
    const { data, total: pageTotal } = await dataProvider.getList(resource, {
      pagination: { page, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: filters,
    });

    allRecords.push(...data);
  }

  const users = await fetchRelatedRecords(records, "user_id", "users");
  const template = new URL(usersTemplate, process.env.REACT_APP_URL).href;

  const data = {
    users: allRecords.map((record) => {
      // Skip if user has been deleted
      if (!record || record.status === "deleted") {
        return;
      }

      // Adjust some data so that it displays properly
      return {
        ...record,
        perms: displayRole(record.permission),
        entities: record.entities?.map((entity) => entity.name).join("\n"),
        approvers: record.approvers
          ?.map((approver) => approver.username)
          .join("\n"),
        cards: record.cards
          ?.map((card) => `${card.cardnumber} (${card.cardtype?.name})`)
          .join("\n"),
        birthdate: displayDate(record.birthdate),
      };
    }),
  };

  data.users = data.users.filter((user) => user !== undefined);

  fetch(template)
    // 2. Get template as ArrayBuffer.
    .then((response) => response.arrayBuffer())
    // 3. Fill the template with data (generate a report).
    .then((buffer) => new Renderer().renderFromArrayBuffer(buffer, data))
    // 4. Get a report as buffer.
    .then((report) => report.xlsx.writeBuffer())
    // 5. Use `saveAs` to download on browser site.
    .then((buffer) =>
      saveAs(new Blob([buffer]), `${Date.now()}_users_export.xlsx`)
    )
    // Handle errors.
    // eslint-disable-next-line no-console
    .catch((err) => console.error("Error writing excel export", err));
};

const UserBulkActionButtons = (props) => {
  const permissions = localStorage.getItem("permissions")?.split(",");
  return (
    <>
      <ResetPasswordButton
        label="Envoyer lien MDP"
        icon={<VpnKey />}
        {...props}
      />
      <OnboardingButton label="Envoyer Onboarding" icon={<Send />} {...props} />
      <SyncUsersButton label="Synchro GDS" icon={<Sync />} {...props} />
      <UserServiceButton {...props} />
      <UserEntitiesButton {...props} />
      <SetApproverButton {...props} />
      {permissions != null && permissions.includes("admin") ? (
        <DeleteUserButton
          label="Supprimer les utilisateurs"
          icon={<DeleteIcon />}
          {...props}
        />
      ) : (
        <DeleteUserDataButton
          label="Supprimer les données utilisateurs"
          icon={<DeleteIcon />}
          {...props}
        />
      )}
    </>
  );
};

export const UserList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des clients the treep"
    exporter={exporter}
    filters={<UserFilter />}
    pagination={<UserPagination />}
    bulkActionButtons={<UserBulkActionButtons />}
  >
    <Datagrid
      rowclick="show"
      perPage={200}
      rowStyle={(user) => {
        if (user.status && user.status.length > 0) {
          for (let i = 0; i < usersStatus.length; i++) {
            const userStat = usersStatus[i];
            if (user.status === userStat.id) {
              return { backgroundColor: userStat.backgroundColor };
            }
          }
        }
      }}
    >
      <NumberField source="id" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="username" />
      <EmailField source="email" />
      <ReferenceField
        source="company_id"
        reference="companies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="status" choices={usersStatus} />
      <BooleanField source="auth_by_company" />
      <TheTreepDateField source="birthdate" sortable={false} />
      <TextField source="phone" sortable={false} />
      <FunctionField
        label="Permission"
        render={(record) => {
          for (let i = 0; i < displayPermissions.length; i++) {
            if (record.permission === displayPermissions[i].id) {
              if (displayPermissions[i].name != null) {
                return displayPermissions[i].name;
              }
            }
          }
          for (let i = 0; i < usersPermissions.length; i++) {
            if (record.permission === usersPermissions[i].id) {
              if (usersPermissions[i].name != null) {
                return usersPermissions[i].name;
              }
            }
          }
          if (record.perms != null) {
            return record.perms.toString();
          }
          return "Aucune permission";
        }}
      />
      <TextField label="Service" source="service" />
      <TextField label="Catégorie" source="category" />
      <FunctionField
        label="Niveau"
        render={(record) =>
          record.approval_level == null ? 0 : record.approval_level
        }
      />
      <ShowButton label="" />
      {permissions != null &&
        (permissions.includes("admin") ||
          permissions.includes("company_dashboard")) && <EditButton label="" />}
    </Datagrid>
  </List>
);

export class UserCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_analytics: [],
      companyID: null,
    };
    this.fetchCompany(null);
  }

  transform = (data) => ({
    ...data,
    birthdate: data.birthdate
      ? moment(data.birthdate, "DD/MM/YYYY").format("YYYYMMDD[T]HHmmss")
      : null,
  });

  // eslint-disable-next-line react/sort-comp
  render() {
    const { translate, ...restProps } = this.props;
    const pwd = passwordGenerator();
    return (
      <Create {...restProps} transform={this.transform}>
        <TabbedForm validate={validateUserCreation}>
          <FormTab label={translate("pos.user.title_info")}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                this.fetchCompany(formData.company_id);
                return (
                  <ReferenceInput
                    label="Company"
                    source="company_id"
                    reference="companies"
                    allowEmpty
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
            <SelectInput source="gender" choices={usersGender} />
            <TextInput source="firstname" validate={[required()]} />
            <TextInput source="lastname" validate={[required()]} />
            <TextInput source="username" validate={[required()]} />
            <TextInput
              source="email"
              type="email"
              validate={[required(), email()]}
            />
            <DateTextInput
              source="birthdate"
              format={DateUserFormatter}
              label="Date de naissance (JJ/MM/AAAA)"
            />
            <TextInput
              source="phone"
              parse={(phone) =>
                phone == null
                  ? null
                  : phone.replace(/\s/g, "").replace(/\./g, "")
              }
            />
            <PlaceInput source="address" label="Address" />
            <BooleanInput source="auth_by_company" defaultValue />
            <TextInput source="password" type="password" defaultValue={pwd} />
            <SelectInput
              source="birth_country"
              defaultValue="fr"
              choices={countries}
              optionValue="alpha2"
              optionText="fr"
            />
            <TextInput source="city_of_birth" />
            <p>Le mot de passe généré est le suivant :{pwd}</p>
            <SelectInput
              source="permission"
              defaultValue={0}
              choices={usersPermissions}
            />
            <BooleanInput source="canApproveSafetyApproval" />
            <NumberInput source="approval_level" />
            <TextInput source="service" />
            <TextInput source="category" />
          </FormTab>
          <FormTab label={translate("pos.user.title_handicap")}>
            <SelectInput source="handicap" choices={handicaps} />
            <TextInput source="disability_degree" />
            <SelectInput source="wheelchair" choices={wheelchairs} />
            <TextInput source="wheelchair_dimensions" />
            <TextInput source="wheelchair_weight" />
            <TextInput source="wheelchair_wheel_thickness" />
            <BooleanInput source="need_accompanying" />
            <BooleanInput source="accompanying_dog" />
          </FormTab>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (
                this.state.user_analytics == null ||
                this.state.user_analytics.length === 0
              ) {
                return null;
              }
              return (
                <FormTab
                  label={translate("pos.user.title_analytics")}
                  {...rest}
                >
                  <AnalyticsInput
                    label=""
                    source="analytics"
                    analytics={this.state.user_analytics}
                    isUser
                    {...rest}
                  />
                </FormTab>
              );
            }}
          </FormDataConsumer>
        </TabbedForm>
      </Create>
    );
  }

  fetchCompany(companyID) {
    if (companyID === this.state.companyID) {
      // Do not fetch again the users
      return;
    }
    this.setState({ companyID });
    this.fetchUserAnalytics(companyID);
  }

  fetchUserAnalytics(companyID) {
    if (companyID == null) {
      return;
    }
    const headers = apiHeaders;
    headers.Authorization = localStorage.getItem("token");
    const options = {
      headers: new Headers(headers),
    };
    const query = {
      limit: 50,
      company_id: companyID,
    };
    fetch(`${apiUrl}/company/user_analytics?${stringify(query)}`, options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        this.setState({ user_analytics: result });
      });
  }
}

const UserEditActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  data,
  ...props
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {data && (
      <Button
        label="AFFICHER"
        component={React.forwardRef((props, ref) => (
          <Link {...props} />
        ))}
        to={{
          pathname: `/users/${data.id}/show`,
        }}
      >
        <Visibility />
      </Button>
    )}
  </TopToolbar>
);

const CustomToolbar = (props) => {
  const permissions = localStorage.getItem("permissions")?.split(",");
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} />
      {permissions != null && permissions.includes("admin") ? (
        <DeleteUserButton label="Supprimer" icon={<DeleteIcon />} {...props} />
      ) : (
        <DeleteUserDataButton
          label="Supprimer"
          icon={<DeleteIcon />}
          {...props}
        />
      )}
    </Toolbar>
  );
};

const validateUserCreation = (values) => {
  const errors = {};
  if (
    values.birthdate &&
    !moment(values.birthdate, "DD/MM/YYYY", true).isValid() &&
    !moment(values.birthdate, "YYYYMMDD[T]HHmmss", true).isValid()
  ) {
    errors.birthdate = [
      "Le format saisi est non valide, veuillez entrer une date au format suivant : JJ/MM/AAAA",
    ];
  }

  if (
    values.birthdate &&
    moment(values.birthdate, "DD/MM/YYYY", true).isValid() &&
    !moment(values.birthdate, "DD/MM/YYYY", true).isBefore()
  ) {
    errors.birthdate = ["Veuillez saisir une date antérieure à aujourd'hui "];
  }

  if (values.analytics?.length > 0) {
    const { analytics } = values;
    analytics.forEach((analytic) => {
      if (analytic.analytic.required && !analytic.value) {
        errors.analytics = [
          "Veuillez renseigner tous les champs structurels obligatoires",
        ];
      }
    });
  }

  return errors;
};

export class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_analytics: [],
      companyID: null,
    };
    this.fetchCompany(null);
  }

  transform = (data) => ({
    ...data,
    address_id: data.address?.ID,
    birthdate: moment(data.birthdate, "YYYYMMDD[T]HHmmss", true).isValid()
      ? data.birthdate
      : moment(data.birthdate, "DD/MM/YYYY").format("YYYYMMDD[T]HHmmss"),
  });

  render() {
    const { translate, permissions } = this.props;
    const { country } = this.props;
    return (
      <Edit
        {...this.props}
        actions={<UserEditActions />}
        transform={this.transform}
      >
        <TabbedForm toolbar={<CustomToolbar />} validate={validateUserCreation}>
          <FormTab label={translate("pos.user.title_info")}>
            <TextInput disabled label="Id" source="uid" />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                this.fetchCompany(formData.company_id);
                return (
                  <ReferenceInput
                    label="Company"
                    source="company_id"
                    reference="companies"
                    allowEmpty
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
            <SelectInput source="gender" choices={usersGender} />
            <TextInput source="firstname" validate={[required()]} />
            <TextInput source="lastname" validate={[required()]} />
            <TextInput source="username" validate={[required()]} />
            <TextInput
              source="email"
              type="email"
              validate={[required(), email()]}
            />
            <SelectInput source="status" choices={usersStatus} />
            <DateTextInput
              source="birthdate"
              format={DateUserFormatter}
              label="Date de naissance (JJ/MM/AAAA)"
            />
            <TextInput
              source="phone"
              parse={(phone) =>
                phone == null
                  ? null
                  : phone.replace(/\s/g, "").replace(/\./g, "")
              }
            />
            <TextInput disabled source="address.formatted_address" />
            <PlaceInput source="address" label="Edit Address" />
            <BooleanInput source="auth_by_company" />
            <TextInput source="password" type="password" />
            <TextInput source="city_of_birth" />
            <SelectInput
              source="permission"
              defaultValue={0}
              choices={usersPermissions}
            />
            <BooleanInput source="canApproveSafetyApproval" />
            <SelectInput
              source="birth_country"
              defaultValue="fr"
              choices={countries}
              optionValue="alpha2"
              optionText="fr"
            />
            <NumberInput source="approval_level" />
            <TextInput source="service" />
            <TextInput source="category" />
          </FormTab>
          <FormTab label={translate("pos.user.title_handicap")}>
            <SelectInput source="handicap" choices={handicaps} />
            <TextInput source="disability_degree" />
            <SelectInput source="wheelchair" choices={wheelchairs} />
            <TextInput source="wheelchair_dimensions" />
            <TextInput source="wheelchair_weight" />
            <TextInput source="wheelchair_wheel_thickness" />
            <BooleanInput source="need_accompanying" />
            <BooleanInput source="accompanying_dog" />
          </FormTab>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (
                this.state.user_analytics == null ||
                this.state.user_analytics.length === 0
              ) {
                return null;
              }
              return (
                <FormTab
                  label={translate("pos.user.title_analytics")}
                  {...rest}
                >
                  <AnalyticsInput
                    label=""
                    source="analytics"
                    analytics={this.state.user_analytics}
                    isUser
                    {...rest}
                  />
                </FormTab>
              );
            }}
          </FormDataConsumer>
        </TabbedForm>
      </Edit>
    );
  }

  fetchCompany(companyID) {
    if (companyID === this.state.companyID) {
      // Do not fetch again the users
      return;
    }
    this.setState({ companyID });
    this.fetchUserAnalytics(companyID);
  }

  fetchUserAnalytics(companyID) {
    if (companyID == null) {
      return;
    }

    const headers = apiHeaders;
    headers.Authorization = localStorage.getItem("token");
    const options = {
      headers: new Headers(headers),
    };
    const query = {
      limit: 50,
      company_id: companyID,
    };

    fetch(`${apiUrl}/company/user_analytics?${stringify(query)}`, options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        this.setState({ user_analytics: result });
      });
  }
}

const UserShowActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  data,
  ...props
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {data && (
      <Button
        label="Éditer"
        component={React.forwardRef((props, ref) => (
          <Link {...props} />
        ))}
        to={{
          pathname: `/users/${data.id}`,
          state: {
            record: {
              user_id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
            },
          },
        }}
      >
        <EditIcon />
      </Button>
    )}
    {data && (
      <Button
        label="Ajouter une carte"
        component={React.forwardRef((props, ref) => (
          <Link {...props} />
        ))}
        to={{
          pathname: "/usercards/create",
          state: {
            record: {
              user_id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
            },
          },
        }}
      >
        <AddIcon />
      </Button>
    )}
    {data && (
      <Button
        label="Ajouter un passport"
        component={React.forwardRef((props, ref) => (
          <Link {...props} />
        ))}
        to={{
          pathname: "/userpassports/create",
          state: {
            record: {
              user_id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
              type: "passport",
              document_name: "Passport",
            },
          },
        }}
      >
        <AddIcon />
      </Button>
    )}
    {data && (
      <Button
        label="Ajouter un permis de conduire"
        component={React.forwardRef((props, ref) => (
          <Link {...props} />
        ))}
        to={{
          pathname: "/userdriverlicenses/create",
          state: {
            record: {
              user_id: data.id,
              firstname: data.firstname,
              lastname: data.lastname,
              type: "driver_license",
              document_name: "Permis de conduire",
            },
          },
        }}
      >
        <AddIcon />
      </Button>
    )}
  </TopToolbar>
);

export const UserShow = ({ translate, permissions, ...props }) => (
  <Show {...props} actions={<UserShowActions />}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label={translate("pos.user.title_info")}>
        <NumberField source="id" />
        <TextField source="gender" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="username" />
        <EmailField source="email" />
        <ReferenceField
          source="company_id"
          reference="companies"
          link="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="entities">
          <Datagrid>
            <NumberField source="uid" />
            <TextField label="Nom" source="name" />
          </Datagrid>
        </ArrayField>
        <ArrayField source="approvers">
          <Datagrid>
            <TextField label="Prénom" source="firstname" />
            <TextField label="Nom" source="lastname" />
            <TextField label="Email" source="email" />
            <TextField label="Service" source="service" />
            <TextField label="Niveau d'approbation" source="approvalLevel" />
          </Datagrid>
        </ArrayField>
        <ArrayField source="approvees">
          <Datagrid>
            <TextField label="Prénom" source="firstname" />
            <TextField label="Nom" source="lastname" />
            <TextField label="Email" source="email" />
            <TextField label="Service" source="service" />
            <TextField label="Niveau d'approbation" source="approvalLevel" />
          </Datagrid>
        </ArrayField>
        <BooleanField source="auth_by_company" />
        <SelectField source="status" choices={usersStatus} />
        <NumberField source="approval_level" />
        <TextField source="service" />
        <TextField source="category" />
        <TheTreepDateField source="birthdate" />
        <TextField source="phone" />
        <TheTreepDateField source="inscription_date" showTime />
        <TextField label="Address" source="address.formatted_address" />
        <TextField source="birth_country" />
        <TextField source="city_of_birth" />
        <FunctionField
          label="permission"
          render={(record) => {
            for (let i = 0; i < usersPermissions.length; i++) {
              if (record.permission === usersPermissions[i].id) {
                if (usersPermissions[i].name != null) {
                  return usersPermissions[i].name;
                }
              }
            }
            if (record.perms != null) {
              return record.perms.toString();
            }
            return "Aucune permission";
          }}
        />
        <BooleanField source="canApproveSafetyApproval" />
        <TextField source="last_log_date" />
        {permissions != null && permissions.includes("admin") && (
          <TextField source="amadeus_profile_id" />
        )}
        {permissions != null && permissions.includes("admin") && (
          <TextField source="amadeus_office_id" />
        )}
        {permissions != null && permissions.includes("admin") && (
          <TextField source="sabre_profile_context_code" />
        )}
        {permissions != null && permissions.includes("admin") && (
          <TextField source="sabre_profile_domain_id" />
        )}
        {permissions != null && permissions.includes("admin") && (
          <TextField source="sabre_profile_id" />
        )}
      </FormTab>
      <FormTab label={translate("pos.user.title_cards")}>
        <ArrayField label="" source="cards">
          <Datagrid>
            <NumberField source="uid" />
            <TextField source="cardtype.card_type" />
            <TextField source="cardtype.name" />
            <TextField source="cardnumber" />
            <ShowCard />
            <FunctionField
              label="Supprimer la carte "
              render={(record) =>
                record != null ? (
                  <DeleteItemButton
                    id={record.uid}
                    resource="usercards"
                    resource_fr="cette carte"
                    icon={<DeleteIcon />}
                  />
                ) : (
                  <div />
                )
              }
            />
          </Datagrid>
        </ArrayField>
      </FormTab>
      <FormTab label={translate("pos.user.title_handicap")}>
        <SelectField source="handicap" choices={handicaps} />
        <TextField source="disability_degree" />
        <SelectField source="wheelchair" choices={wheelchairs} />
        <TextField source="wheelchair_dimensions" />
        <TextField source="wheelchair_weight" />
        <TextField source="wheelchair_wheel_thickness" />
        <BooleanField source="need_accompanying" />
        <BooleanField source="accompanying_dog" />
      </FormTab>
      <FormTab label={translate("pos.user.documents")}>
        <ArrayField label="Documents" source="travel_documents">
          <Datagrid>
            <NumberField source="uid" />
            <TextField label="Type" source="type" />
            <TextField label="Nom" source="document_name" />
            <TextField label="Prénom" source="firstname" />
            <TextField label="Nom" source="lastname" />
            <TheTreepDateField label="Emis le" source="effective_date" />
            <TextField label="Numéro" source="number" />
            <FunctionField
              label="Supprimer le document "
              render={(record) =>
                record != null ? (
                  <DeleteItemButton
                    id={record.uid}
                    resource="userdocuments"
                    resource_fr="ce document"
                    icon={<DeleteIcon />}
                  />
                ) : (
                  <div />
                )
              }
            />
          </Datagrid>
        </ArrayField>
      </FormTab>
      <FormTab label={translate("pos.user.title_analytics")}>
        <ArrayField source="analytics" label="">
          <Datagrid>
            <NumberField source="analytic.uid" />
            <TextField source="analytic.name" />
            <TextField source="analytic.label" />
            <TextField source="value" />
          </Datagrid>
        </ArrayField>
      </FormTab>
    </TabbedForm>
  </Show>
);
