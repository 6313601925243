import React, { Component } from "react";
import { Button, Confirm, withDataProvider } from "react-admin";

class AutoReconciliationOperationSupplies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.props.dataProvider("AUTO_RECONCILIATION", "", null, {
      onSuccess: {
        notification: { body: "Réconciliation effectuée", level: "info" },
        refresh: true,
        redirectTo: `/${this.props.resource}`,
      },
      onError: {
        notification: {
          body: "Erreur lors de la réconciliation automatique",
          level: "warning",
        },
        refresh: true,
      },
    });
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Réconciliation automatique"
          content="Êtes-vous sûr de vouloir lancer une réconciliation automatique ?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default withDataProvider(AutoReconciliationOperationSupplies);
