import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { useInput, required } from "react-admin";
import moment from "moment";

const ClosureHourTextInput = (props) => {
  const validateDate = (value) => {
    if (value === null)
      return "Le format saisi est non valide, veuillez entrer des heures au format suivant : HH:mm";
    if (
      !moment(value, "HH:mm", true).isValid() &&
      !moment(value, "HH:mm", true).isValid()
    )
      return "Le format saisi est non valide, veuillez entrer des heures au format suivant : HH:mm";
    return undefined;
  };

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput({ ...props, validate: [validateDate] });

  return (
    <TextField
      name={name}
      label={props.label}
      type="text"
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ addingBottom: 24 }}
      variant="filled"
      color="secondary"
      fullWidth={props.fullWidth}
      {...rest}
    />
  );
};

export default ClosureHourTextInput;
