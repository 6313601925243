import React, { Component } from "react";
import {
  Button,
  Confirm,
  withDataProvider,
  showNotification,
} from "react-admin";
import { connect } from "react-redux";

class RefreshTicketsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.refreshTks();
  };

  async refreshTks() {
    const { dataProvider, selectedIds, resource, showNotification } =
      this.props;
    dataProvider(
      "REFRESH_TICKETS",
      "",
      { ticket_ids: selectedIds },
      {
        onSuccess: {
          notification: {
            body: "Les tickets ont été mis à jour.",
            level: "info",
          },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
      }
    ).catch((error) => {
      showNotification(
        `Erreur lors du rafraichissement ${error.message}`,
        "warning"
      );
    });
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Mettre à jour les tickets"
          content="Voulez-vous mettre à jour les tickets ?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default connect(null, {
  showNotification,
})(withDataProvider(RefreshTicketsButton));
