import React, { Component } from "react";
import { GET_LIST, withDataProvider, translate } from "react-admin";
import compose from "recompose/compose";
import { connect } from "react-redux";
import moment from "moment";
import NbTickets from "./components/Dashboard/NbTickets";
import NbUsers from "./components/Dashboard/NbUsers";
import NbOperations from "./components/Dashboard/NbOperations";
import TicketsDeparture from "./components/Dashboard/TicketsDeparture";
import PendingCancelTicket from "./components/Dashboard/PendingCancelTicket";
import NbSupplies from "./components/Dashboard/NbSupplies";

moment.locale("fr");

const styles = {
  flex: { display: "flex", margin: "10px" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em", width: "100%" },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departureToday: [],
      pendingTks: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }

  fetchData() {
    this.fetchTickets();
    if (process.env.REACT_APP_WITH_BILLING &&
      process.env.REACT_APP_WITH_BILLING === "true") {
      this.fetchOperations();
      this.fetchSupplies();
    }
    this.fetchDepartureToday();
    this.fetchPendingCancelTickets();
  }

  async fetchTickets() {
    const { dataProvider } = this.props;
    const { total: nbTickets } = await dataProvider(GET_LIST, "tickets", {
      sort: { field: "uid", order: "DESC" },
      pagination: { page: 1, perPage: 0 },
    });
    const { total: nbUsers } = await dataProvider(GET_LIST, "users", {
      sort: { field: "uid", order: "DESC" },
      pagination: { page: 1, perPage: 1 },
    });
    this.setState({
      nbTickets,
      nbUsers,
    });
  }

  async fetchOperations() {
    const { dataProvider } = this.props;
    const { total: nbOperations, data: price } = await dataProvider(
      GET_LIST,
      "operations",
      {
        filter: { reconciliation_status: "pending" },
        sort: { field: "operation_date", order: "DESC" },
        pagination: false,
      }
    );
    this.setState({
      price,
      nbOperations,
    });
  }

  async fetchSupplies() {
    const { dataProvider } = this.props;
    const { total: nbSupplies, data: priceSupplies } = await dataProvider(
      GET_LIST,
      "supplies",
      {
        filter: { reconciliation_status: "pending" },
        sort: { field: "supply_date", order: "DESC" },
        pagination: false,
      }
    );
    this.setState({
      priceSupplies,
      nbSupplies,
    });
  }

  async fetchDepartureToday() {
    const { dataProvider } = this.props;
    const today = FormatDay(new Date());
    const { data: departureToday } = await dataProvider(GET_LIST, "tickets", {
      filter: { departure: today },
      sort: { field: "departure", order: "ASC" },
      pagination: false,
    });
    this.setState({
      departureToday,
    });
  }

  async fetchPendingCancelTickets() {
    const { dataProvider } = this.props;
    const { data: pendingTks } = await dataProvider(GET_LIST, "tickets", {
      filter: { status: "pending_cancel" },
      sort: { field: "departure", order: "ASC" },
      pagination: false,
    });
    this.setState({
      pendingTks,
    });
  }

  render() {
    const {
      nbTickets,
      nbUsers,
      departureToday,
      nbOperations,
      price,
      nbSupplies,
      priceSupplies,
      pendingTks,
    } = this.state;
    const { translate } = this.props;
    const permissions = localStorage.getItem("permissions")?.split(",");
    return (
      <div style={styles.flex}>
        <div style={styles.singleCol}>
          <div style={styles.flex}>
            <NbTickets
              header={translate("pos.dashboard.nb_tickets")}
              value={nbTickets}
            />
            <NbUsers
              header={translate("pos.dashboard.nb_users")}
              value={nbUsers}
            />
          </div>
          {process.env.REACT_APP_WITH_BILLING === "true" &&
            permissions.includes("admin") && (
            <div style={styles.flex}>
              <NbOperations
                header={translate("pos.dashboard.nb_operations")}
                value={nbOperations}
                amount={price}
              />
              <NbSupplies
                header={translate("pos.dashboard.nb_supplies")}
                value={nbSupplies}
                amount={priceSupplies}
              />
            </div>
          )}
          <TicketsDeparture
            header={translate("pos.dashboard.travel_day")}
            value={departureToday}
          />
        </div>
        {permissions.includes("resources_list") && (
          <div style={styles.rightCol}>
            <div style={styles.flex}>
              <PendingCancelTicket
                header={translate("pos.dashboard.pending_tks")}
                value={pendingTks}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  version: state.admin.ui.viewVersion,
});

export default compose(
  connect(mapStateToProps, null),
  withDataProvider,
  translate
)(Dashboard);

const FormatDay = (date) => {
  if (date == null || !moment.utc(date).isValid()) {
    return null;
  }
  const formatted = moment.utc(date).format("YYYYMMDD[T]");
  return formatted;
};
