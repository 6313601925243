import React, { Component } from "react";
import {
  Button,
  Confirm,
  withDataProvider,
  showNotification,
} from "react-admin";
import { connect } from "react-redux";

class IncrementTripButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.addTrip();
  };

  async addTrip() {
    const { dataProvider, record, resource, showNotification } = this.props;
    dataProvider(
      "INCREMENT_TRIP",
      "",
      { id: record },
      {
        onSuccess: {
          notification: { body: "DAP a été mis à jour.", level: "info" },
          refresh: true,
          redirectTo: `/${resource}`,
          unselectAll: true,
        },
      }
    ).catch((error) => {
      showNotification(
        `Erreur lors de l'incrementation ${error.message}`,
        "warning"
      );
    });
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <>
        <Button
          label={this.props.label}
          onClick={this.handleClick}
          record={this.props.record}
        >
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Ajouter un voyage"
          content="Voulez-vous vraiment ajouter un voyage ?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default connect(null, {
  showNotification,
})(withDataProvider(IncrementTripButton));
