import React from "react";
import {
  DisabledInput,
  EditButton,
  Edit,
  List,
  Datagrid,
  TextField,
  Create,
  TextInput,
  required,
  SelectInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  ReferenceInput,
  SimpleForm,
  ArrayField,
  ReferenceField,
  Filter,
  NumberInput,
  AutocompleteInput,
} from "react-admin";

const ServiceFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" alwaysOn />
    <ReferenceInput
      label="Company"
      source="company_id"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Parent"
      source="parent_id"
      reference="services"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
  </Filter>
);

export const ServiceList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des services"
    bulkActionButtons={false}
    filters={<ServiceFilter />}
  >
    <Datagrid rowclick="show" perPage={200}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <ReferenceField
        source="parent_id"
        reference="services"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="company_id"
        reference="companies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const ServiceCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={[required()]} />
      <ReferenceInput
        label="Parent"
        source="parent_id"
        reference="services"
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Entreprise"
        source="company_id"
        reference="companies"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const ServiceEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput disabled label="Id" source="uid" />
      <TextInput source="name" validate={[required()]} />
      <ReferenceInput
        label="Parent"
        source="parent_id"
        reference="services"
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Entreprise"
        source="company_id"
        reference="companies"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ServiceShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <ReferenceField
        source="parent_id"
        reference="services"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="company_id"
        reference="companies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ArrayField label="Approbateurs" source="approvers">
        <Datagrid>
          <TextField label="id" source="uid" />
          <TextField label="firstname" source="firstname" />
          <TextField label="lastname" source="lastname" />
          <TextField label="approval_level" source="approval_level" />
          <TextField label="Service de Rattachement" source="service" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
