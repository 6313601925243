import React from "react";

export const AccessDenied = () => (
  <div>
    <center style={{ fontSize: "30px" }}>Accès aux ressources refusé !</center>
    <center style={{ fontSize: "30px" }}>
      Vous n'avez pas les permissions nécessaires.
    </center>
  </div>
);

export default AccessDenied;
