import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";

const CurrencyField = ({ locales, record, source, invertColors }) => {
  if (!record) return null;
  const value = get(record, source);
  if (value == null) return null;
  const amount = CurrencyFormatter(value);

  return (
    <Typography
      component="span"
      body1="body1"
      style={
        (amount >= 0 && !invertColors) || (amount <= 0 && invertColors)
          ? coloredStyles.positive
          : coloredStyles.negative
      }
    >
      {amount.toLocaleString(locales, { style: "currency", currency: "EUR" })}
    </Typography>
  );
};

CurrencyField.defaultProps = {
  addLabel: true,
};

export const CurrencyParser = (price) => {
  if (price == null) {
    return null;
  }
  return Math.round(price * 100);
};

export const CurrencyFormatter = (cents) => {
  if (cents == null) {
    return null;
  }
  return cents / 100;
};

const coloredStyles = {
  positive: { color: "green" },
  negative: { color: "red" },
};

const ComposedNumberField = CurrencyField;
ComposedNumberField.defaultProps = {
  addLabel: true,
  textAlign: "right",
};
export default ComposedNumberField;
