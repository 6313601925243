import React from "react";
import {
  EditButton,
  Edit,
  List,
  Datagrid,
  TextField,
  RichTextField,
  Create,
  TextInput,
  required,
  SelectInput,
  NumberInput,
  SelectArrayInput,
  BooleanInput,
  Show,
  ShowButton,
  FormDataConsumer,
  NumberField,
  SaveButton,
  Toolbar,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  BooleanField,
  TopToolbar,
  CreateButton,
  ExportButton,
  ReferenceInput,
  ArrayField,
  SingleFieldList,
  ReferenceField,
  FunctionField,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EditReadOnlyButton from "../components/Buttons/EditReadOnlyButton";
import NoBasePath from "../components/Fields/NoBasePath";
import PlaceInput from "../components/Input/PlaceInput";
import ChipField from "../components/Fields/ChipField";
// eslint-disable-next-line import/no-unresolved
import EditTravelPolicyButton from "../components/Buttons/EditTravelPolicyButton";
import SabreConfigInput from "../components/Input/SabreConfigInput";
import {
  airProviders,
  carRentalProviders,
  hotelProviders,
  railProviders,
  SabreConfigsFields,
  AmadeusConfigsInputs,
  categoryMessages
} from "./agencies";

const styles = {
  title: {
    marginTop: "30px",
    marginBottom: "15px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "24px",
    fontWeight: "bold",
  },
};

export const agencies = [
  { id: "thetreep", name: "TheTreep" },
  { id: "Carlabella", name: "Carlabella" },
  { id: "Vairon", name: "Vairon" },
];

export const sabreProfileType = [
  { id: "CRP", name: "Société" },
  { id: "TVL", name: "Voyageur" },
];

export const approvalType = [
  { id: "basic", name: "Basique" },
  { id: "hierarchical", name: "Hierarchique" },
  { id: "escalation", name: "Escalade" },
];

const markupFeesProviders = ["sncf", "sabre","amadeus","duffel","sabre-car","carbookr","CDS"];

const CompaniesActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const TravelPolicy = ({ record, ...props }) => (
  <EditTravelPolicyButton record={record} {...props} />
);

const ReadOnlyToggle = ({ record, ...props }) => (
  <EditReadOnlyButton record={record} {...props} entity="companies" />
);

export const CompanyList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des entreprises"
    bulkActionButtons={false}
    actions={<CompaniesActions />}
  >
    <Datagrid rowclick="show" perPage={200}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="domain" sortable={false} />
      <ReferenceField
        source="config.travelAgencyID"
        reference="agencies"
        link="show"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TravelPolicy />
      <BooleanField source="config.readOnly" sortable={false} />
      <ShowButton label="" />
      {permissions != null && permissions.includes("admin") && (
        <EditButton label="" />
      )}
      {permissions != null && permissions.includes("dev") && <ReadOnlyToggle />}
    </Datagrid>
  </List>
);

export const CustomerConfigInputs = (title) => (
  <FormTab label={title}>
    <Typography variant="h5" style={styles.title}>
      Services activés
    </Typography>

    <SelectInput
      source="config.providers.rail"
      allowEmpty
      format={(s) => (s != null && s.length > 0 ? s[0] : null)}
      parse={(v) => (v != null ? [v] : null)}
      alwaysOn
      choices={railProviders}
    />
    <SelectInput
      source="config.providers.air"
      allowEmpty
      format={(s) => (s != null && s.length > 0 ? s[0] : null)}
      parse={(v) => (v != null ? [v] : null)}
      alwaysOn
      choices={airProviders}
    />
    <SelectInput
      source="config.providers.travelerProfile"
      allowEmpty
      format={(s) => (s != null && s.length > 0 ? s[0] : null)}
      parse={(v) => (v != null ? [v] : null)}
      alwaysOn
      choices={airProviders}
    />
    <SelectInput
      source="config.providers.hotel"
      allowEmpty
      format={(s) => (s != null && s.length > 0 ? s[0] : null)}
      parse={(v) => (v != null ? [v] : null)}
      alwaysOn
      choices={hotelProviders}
    />
    <SelectInput
      source="config.providers.carRental"
      allowEmpty
      format={(s) => (s != null && s.length > 0 ? s[0] : null)}
      parse={(v) => (v != null ? [v] : null)}
      alwaysOn
      choices={carRentalProviders}
    />

    <Typography variant="h5" style={styles.title}>
      CDS
    </Typography>
    <TextInput source="config.cdsProfile.dutyCode" />

    <Typography variant="h5" style={styles.title}>
      Carbookr
    </Typography>
    <TextInput source="config.carbookrProfile.clientID" />

    <Typography variant="h5" style={styles.title}>
      Prix des billets
    </Typography>
    <BooleanInput
      label="Masquer les tarifs avions"
      source="config.HiddenFlightPrice"
    />
    {process.env.REACT_APP_WITH_MARKUP === "true" && (
      <>
        <Typography variant="h5" style={styles.title}>
          Frais additionnels
        </Typography>

        <div
          style={{
            background: "#ffefd5",
            padding: "16px",
            borderRadius: "8px",
            fontFamily: "sans-serif",
            fontSize: "14px",
            lineHeight: "20px",
            margin: "0 16px 16px 0",
            width: "95%",
            color: "black",
          }}
        >
          Le Distributeur (agence de voyage) dispose techniquement de la
          possibilité de fixer librement les prix qu’il communique à ses Clients
          et aux Utilisateurs des Services. A ce titre, il est rappelé que le
          rôle de the Treep se limite à celui d’intermédiaire proposant des prix
          qui peuvent subir des variations avant d’être présentés aux Clients et
          Utilisateurs, en fonction notamment des frais et commissions que le
          Distributeur applique librement et sur lequel the Treep n’a aucune
          emprise. D’une manière générale, the Treep ne saurait être, de quelque
          manière et sur quelque fondement que ce soit, tenu pour responsable de
          difficultés ou de litiges relatifs aux prix fixés par le Distributeur
          aux Clients et/ou Utilisateurs. Le Distributeur s’engage par
          conséquent à garantir sans limitation de plafond the Treep des
          conséquences financières de tout recours judiciaire intenté par un
          tiers relatif aux prix affichés aux Clients et/ou Utilisateurs.
        </div>

        {markupFeesProviders.map((provider) => (
          <div style={{ width: "100%", marginBottom:"24px", borderBottom:"2px solid #ccc" }} key={provider}>
            <Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
              Frais <span style={{ textTransform: "capitalize" }}>{provider}</span>
            </Typography>
            <div style={{ display: "flex", gap: "48px" }}>
              <NumberInput
                label="montant en centimes (€)"
                placeholder="Ex: 250 pour 2.5€"
                source={`config.markupFees.${provider}.amount`}
              />
              <NumberInput
                source={`config.markupFees.${provider}.percent`}
                step={0.1}
                label="pourcentage"
              />
            </div>
            <ArrayInput
              label="Codes tarifaires sur lesquels seront appliqués les frais supplémentaires. Si aucun code n’est renseigné, les frais s’appliqueront à tous les tarifs de ce fournisseur."
              source={`config.markupFees.${provider}.restrictedForFareCodes`}
            >
              <SimpleFormIterator disableReordering>
                <TextInput label="code tarifaire" />
              </SimpleFormIterator>
            </ArrayInput>
          </div>
        ))}
      </>
    )}

    <Typography variant="h5" style={styles.title}>
      Champs analytiques voyageurs
    </Typography>
    <BooleanInput source="config.enableTravelersAnalytics" />
  </FormTab>
);

export const SabreCustomerConfigInputs = (title, ...rest) => (
  <FormTab label={title} {...rest}>
    <Typography variant="h5" style={styles.title}>
      Configurer Sabre
    </Typography>

    <Typography variant="h5" style={styles.title}>
      Profil Sabre à charger sur les PNR
    </Typography>
    <TextInput source="config.sabreProfile.pcc" />
    <TextInput source="config.sabreProfile.name" />
    <Typography variant="h5" style={styles.title}>
      Profil Sabre à lier aux profils créés
    </Typography>
    <TextInput source="config.sabrePaxCustomerProfile.number" />
    <TextInput source="config.sabrePaxCustomerProfile.pcc" />
    <SelectInput
      source="config.sabrePaxCustomerProfile.type"
      alwaysOn
      allowEmpty
      choices={sabreProfileType}
    />

    <Typography variant="h5" style={styles.title}>
      Surcharger la configuration Sabre
    </Typography>
    <SabreConfigInput sourcePrefix="config.sabreConfigs" />
  </FormTab>
);

export const AmadeusCustomerConfigInputs = () => (
  <>
    <Typography variant="h5" style={styles.title}>
      Configurer Amadeus
    </Typography>

    <Typography variant="h5" style={styles.title}>
      Profil Amadeus à lier aux profils créés
    </Typography>
    <TextInput source="config.amadeusCustomerProfile.officeID" />
    <TextInput source="config.amadeusCustomerProfile.uniqueID" />

    <Typography variant="h5" style={styles.title}>
      Surcharger la configuration Amadeus
    </Typography>
    {AmadeusConfigsInputs({ sourcePrefix: "config.amadeusConfigs." })}
  </>
);
export const CompanyCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Informations générales">
        <TextInput source="name" validate={[required()]} />
        <ReferenceInput
          source="config.travelAgencyID"
          reference="agencies"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectArrayInput
          source="payment_method"
          defaultValue="ON_ACCOUNT"
          format={(s) => (s != null && s.trim() !== "" ? s.split(",") : null)}
          parse={(v) => (v != null && v.length > 0 ? v.join(",") : null)}
          choices={[
            { id: "ON_ACCOUNT", name: "Paiement entreprise" },
            { id: "CREDIT_CARD", name: "Carte de crédit" },
          ]}
        />
        <TextInput source="fce" />
        <RichTextInput
          source="travel_policy"
          toolbar={[
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "align",
            "link",
            "image",
          ]}
        />
        <BooleanInput source="disable_sending_thetreep_emails" />
        <BooleanInput source="enable_user_auto_enrollment" />
        <TextInput source="domain" />
        <TextInput source="siret" />
        <TextInput source="website" type="url" />
        <PlaceInput source="place" label="Address" />
        <TextInput source="contact" />
        <BooleanInput source="config.test" />
        <BooleanInput source="config.oneClickApprovalEnabled" />
        <BooleanInput source="hotel_enabled" />
        <BooleanInput source="carrental_enabled" />
        <SelectInput source="config.approvalType" choices={approvalType} />
        {permissions != null && permissions.includes("dev") && (
          <>
            <BooleanInput source="config.missionOrderConfig.enabled" />
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.config &&
                formData.config.missionOrderConfig &&
                Object.values(formData.config.missionOrderConfig.enabled) && (
                  <MissionOrderConfigInputs />
                )
              }
            </FormDataConsumer>
          </>
        )}
        <BooleanInput source="config.railOfflineCancellation" />
        <BooleanInput source="config.airOfflineCancellation" />

        <Typography variant="h5" style={styles.title}>
          Valeurs analytiques de l'entreprise
        </Typography>
        <ArrayInput source="config.analyticValues">
          <SimpleFormIterator>
            <TextInput source="analytic.key" />
            <TextInput source="analytic.cds_key" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      {permissions != null &&
        permissions.includes("admin") &&
        CustomerConfigInputs("Services externes")}
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("amadeus")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("amadeus"))) && (
            <FormTab label="Configuration Amadeus" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Configurer Amadeus
              </Typography>

              <Typography variant="h5" style={styles.title}>
                Profil Amadeus à lier aux profils créés
              </Typography>
              <TextInput source="config.amadeusCustomerProfile.officeID" />
              <TextInput source="config.amadeusCustomerProfile.uniqueID" />

              <Typography variant="h5" style={styles.title}>
                Surcharger la configuration Amadeus
              </Typography>
              {AmadeusConfigsInputs({ sourcePrefix: "config.amadeusConfigs." })}
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("sabre")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("sabre"))) && (
            <FormTab label="Configurer Sabre" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Configurer Sabre
              </Typography>

              <Typography variant="h5" style={styles.title}>
                Profil Sabre à charger sur les PNR
              </Typography>
              <TextInput source="config.sabreProfile.pcc" />
              <TextInput source="config.sabreProfile.name" />
              <Typography variant="h5" style={styles.title}>
                Profil Sabre à lier aux profils créés
              </Typography>
              <TextInput source="config.sabrePaxCustomerProfile.number" />
              <TextInput source="config.sabrePaxCustomerProfile.pcc" />
              <SelectInput
                source="config.sabrePaxCustomerProfile.type"
                alwaysOn
                allowEmpty
                choices={sabreProfileType}
              />

              <Typography variant="h5" style={styles.title}>
                Surcharger la configuration Sabre
              </Typography>
              <SabreConfigInput sourcePrefix="config.sabreConfigs" />
            </FormTab>
          )
        }
      </FormDataConsumer>
      {permissions != null && permissions.includes("admin") && (
        <FormTab label="Templates Emailing">
          <TextInput multiline source="email_header" />
          <TextInput multiline source="email_footer" />
          <TextInput multiline source="email_onboarding" />
          <TextInput multiline source="email_confirmation" />
          <TextInput multiline source="email_welcome" />
          <TextInput multiline source="email_password_reset" />
          <TextInput multiline source="email_booking_confirmation" />
          <TextInput multiline source="email_approval" />
          <TextInput multiline source="email_approval_pending" />
          <TextInput multiline source="email_approval_reminder" />
          <TextInput multiline source="email_approval_confirmation" />
        </FormTab>
      )}
    </TabbedForm>
  </Create>
);

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const CompanyEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Informations générales">
        <TextInput disabled label="Id" source="uid" />
        <TextInput source="name" validate={[required()]} />
        <ReferenceInput
          source="config.travelAgencyID"
          reference="agencies"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectArrayInput
          source="payment_method"
          defaultValue="ON_ACCOUNT"
          format={(s) => (s != null && s.trim() !== "" ? s.split(",") : null)}
          parse={(v) => (v != null && v.length > 0 ? v.join(",") : null)}
          choices={[
            { id: "ON_ACCOUNT", name: "Paiement entreprise" },
            { id: "CREDIT_CARD", name: "Carte de crédit" },
          ]}
        />
        <TextInput source="fce" />
        <RichTextInput
          source="travel_policy"
          toolbar={[
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "align",
            "link",
            "image",
          ]}
        />
        <BooleanInput source="disable_sending_thetreep_emails" />
        <BooleanInput source="enable_user_auto_enrollment" />
        <TextInput source="domain" />
        <TextInput source="siret" />
        <TextInput source="website" type="url" />
        <PlaceInput source="place" label="Address" />
        <TextInput source="contact" />
        <BooleanInput source="config.test" />
        <BooleanInput source="config.oneClickApprovalEnabled" />
        <BooleanInput source="hotel_enabled" />
        <BooleanInput source="carrental_enabled" />
        <SelectInput source="config.approvalType" choices={approvalType} />
        {permissions != null && permissions.includes("dev") && (
          <>
            <BooleanInput source="config.missionOrderConfig.enabled" />
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.config &&
                formData.config.missionOrderConfig &&
                Object.values(formData.config.missionOrderConfig.enabled) && (
                  <MissionOrderConfigInputs />
                )
              }
            </FormDataConsumer>
          </>
        )}
        <BooleanInput source="railOfflineCancellation" />
        <BooleanInput source="airOfflineCancellation" />

        <Typography variant="h5" style={styles.title}>
          Valeurs analytiques de l'entreprise
        </Typography>
        <ArrayInput source="config.analyticValues">
          <SimpleFormIterator>
            <TextInput source="analytic.key" />
            <TextInput source="analytic.cds_key" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      {permissions != null &&
        permissions.includes("admin") &&
        CustomerConfigInputs("Services externes")}
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("amadeus")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("amadeus"))) && (
            <FormTab label="Configuration Amadeus" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Configurer Amadeus
              </Typography>

              <Typography variant="h5" style={styles.title}>
                Profil Amadeus à lier aux profils créés
              </Typography>
              <TextInput source="config.amadeusCustomerProfile.officeID" />
              <TextInput source="config.amadeusCustomerProfile.uniqueID" />

              <Typography variant="h5" style={styles.title}>
                Surcharger la configuration Amadeus
              </Typography>
              {AmadeusConfigsInputs({ sourcePrefix: "config.amadeusConfigs." })}
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.config &&
          formData.config.providers &&
          ((formData.config.providers.air &&
            formData.config.providers.air.includes("sabre")) ||
            (formData.config.providers.rail &&
              formData.config.providers.rail.includes("sabre"))) && (
            <FormTab label="Configurer Sabre" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Configurer Sabre
              </Typography>

              <Typography variant="h5" style={styles.title}>
                Profil Sabre à charger sur les PNR
              </Typography>
              <TextInput source="config.sabreProfile.pcc" />
              <TextInput source="config.sabreProfile.name" />
              <Typography variant="h5" style={styles.title}>
                Profil Sabre à lier aux profils créés
              </Typography>
              <TextInput source="config.sabrePaxCustomerProfile.number" />
              <TextInput source="config.sabrePaxCustomerProfile.pcc" />
              <SelectInput
                source="config.sabrePaxCustomerProfile.type"
                alwaysOn
                allowEmpty
                choices={sabreProfileType}
              />

              <Typography variant="h5" style={styles.title}>
                Surcharger la configuration Sabre
              </Typography>
              <SabreConfigInput sourcePrefix="config.sabreConfigs" />
            </FormTab>
          )
        }
      </FormDataConsumer>
      {permissions != null && permissions.includes("admin") && (
        <FormTab label="Templates Emailing">
          <TextInput multiline source="email_header" />
          <TextInput multiline source="email_footer" />
          <TextInput multiline source="email_onboarding" />
          <TextInput multiline source="email_confirmation" />
          <TextInput multiline source="email_welcome" />
          <TextInput multiline source="email_password_reset" />
          <TextInput multiline source="email_booking_confirmation" />
          <TextInput multiline source="email_approval" />
          <TextInput multiline source="email_approval_pending" />
          <TextInput multiline source="email_approval_reminder" />
          <TextInput multiline source="email_approval_confirmation" />
        </FormTab>
      )}

      {permissions?.includes("admin") && (
        <FormTab label="Messages d'agence">
          <Typography variant="h5" style={{ ...styles.title, width: "100%" }}>
            Configuration des messages d'agence
          </Typography>

          {categoryMessages.map((category) => (
            <div key={category.id} style={{ marginBottom: "16px" }}>
              <ArrayInput
                source={`config.communicationsBySupport.${category.id}`}
                label={category.name}
                key={category.id}
              >
                <SimpleFormIterator disableReordering>
                  <TextInput source="title" label="Title" />
                  <TextInput source="content" label="Content" multiline />
                  <TextInput source="media" label="Media Link" />
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          ))}
        </FormTab>
      )}
    </TabbedForm>
  </Edit>
);

export const CustomerConfigFields = (title) => (
  <Tab label={title}>
    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Services activés
      </Typography>
    </NoBasePath>
    <ArrayField source="config.providers.rail">
      <SingleFieldList>
        <ChipField />
      </SingleFieldList>
    </ArrayField>
    <ArrayField source="config.providers.air">
      <SingleFieldList>
        <ChipField />
      </SingleFieldList>
    </ArrayField>
    <ArrayField source="config.providers.travelerProfile">
      <SingleFieldList>
        <ChipField />
      </SingleFieldList>
    </ArrayField>
    <ArrayField source="config.providers.hotel">
      <SingleFieldList>
        <ChipField />
      </SingleFieldList>
    </ArrayField>
    <ArrayField source="config.providers.carRental">
      <SingleFieldList>
        <ChipField />
      </SingleFieldList>
    </ArrayField>
    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Profil Sabre à charger sur les PNR
      </Typography>
    </NoBasePath>
    <TextField source="config.sabreProfile.pcc" />
    <TextField source="config.sabreProfile.name" />
    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Profil Sabre à lier aux profils créés
      </Typography>
    </NoBasePath>
    <TextField source="config.sabrePaxCustomerProfile.number" />
    <TextField source="config.sabrePaxCustomerProfile.pcc" />
    <TextField source="config.sabrePaxCustomerProfile.type" />

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Profil Amadeus à lier aux profils créés
      </Typography>
    </NoBasePath>
    <TextField source="config.amadeusCustomerProfile.officeID" />
    <TextField source="config.amadeusCustomerProfile.uniqueID" />

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        CDS
      </Typography>
    </NoBasePath>
    <TextField source="config.cdsProfile.dutyCode" />

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Carbookr
      </Typography>
    </NoBasePath>
    <TextField source="config.carbookrProfile.clientID" />

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Surcharger la configuration Sabre
      </Typography>
    </NoBasePath>
    {SabreConfigsFields({ sourcePrefix: "config.sabreConfigs." })}

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Surcharger la configuration Amadeus
      </Typography>
    </NoBasePath>
    {SabreConfigsFields({ sourcePrefix: "config.amadeusConfigs." })}

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Surcharger la configuration sur les annulations hors lignes
      </Typography>
    </NoBasePath>
    <BooleanField source="config.railOfflineCancellation" />
    <BooleanField source="config.airOfflineCancellation" />

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Prix des billets
      </Typography>
    </NoBasePath>
    <BooleanField source="config.HiddenFlightPrice" />

    <NoBasePath>
      <Typography variant="h5" style={styles.title}>
        Champs analytiques voyageurs
      </Typography>
    </NoBasePath>
    <BooleanField source="config.enableTravelersAnalytics" />
  </Tab>
);

export const CompanyShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Informations générales">
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="siret" sortable={false} />
        <TextField source="domain" sortable={false} />
        <ReferenceField
          source="config.travelAgencyID"
          reference="agencies"
          link="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="place.formatted_address" sortable={false} />
        <TextField source="payment_methods" sortable={false} />
        <TextField source="service_fees" sortable={false} />
        <TextField source="booking_pcc" sortable={false} />
        <TextField source="fce" sortable={false} />
        <RichTextField source="travel_policy" />
        <BooleanField source="config.test" />
        <BooleanField source="config.oneClickApprovalEnabled" />
        <BooleanField source="hotel_enabled" />
        <BooleanField source="carrental_enabled" />
        <TextField source="config.approvalType" label="Type d'approbation" />
        <FunctionField
          label="Configuration de l'ordre de mission"
          render={({ config }) =>
            config &&
            config.missionOrderConfig &&
            Object.values(config.missionOrderConfig.enabled) ? (
                <MissionOrderConfigFields />
              ) : null
          }
        />
        <NoBasePath>
          <Typography variant="h5" style={styles.title}>
            Valeurs analytiques de l'entreprise
          </Typography>
        </NoBasePath>
        <ArrayField source="config.analyticValues">
          <Datagrid>
            <TextField source="analytic.key" />
            <TextField source="analytic.cds_key" />
            <TextField source="value" />
          </Datagrid>
        </ArrayField>
      </Tab>
      {permissions != null &&
        permissions.includes("admin") &&
        CustomerConfigFields("Services externes")}
      {permissions != null && permissions.includes("admin") && (
        <Tab label="Templates Emailing">
          <RichTextField source="email_header" />
          <RichTextField source="email_footer" />
          <RichTextField source="email_onboarding" />
          <RichTextField source="email_confirmation" />
          <RichTextField source="email_welcome" />
          <RichTextField source="email_password_reset" />
          <RichTextField source="email_booking_confirmation" />
          <RichTextField source="email_approval" />
          <RichTextField source="email_approval_pending" />
          <RichTextField source="email_approval_reminder" />
          <RichTextField source="email_approval_confirmation" />
        </Tab>
      )}
    </TabbedShowLayout>
  </Show>
);

export const MissionOrderConfigInputs = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h5" style={styles.title}>
        Configuration de l'ordre de mission
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <TextInput
        label="Nom de l'entreprise"
        source="config.missionOrderConfig.companyName"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        label="Préfixe du numéro"
        source="config.missionOrderConfig.prefix"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        label="Format de date"
        source="config.missionOrderConfig.dateFormat"
      />
    </Grid>
    <Grid item xs={12}>
      <NumberInput
        label="Nombre de chiffre du numéro d'increment"
        source="config.missionOrderConfig.digitNumber"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Logo" source="config.missionOrderConfig.logo" />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        label="Intitulé de la signature"
        source="config.missionOrderConfig.signatureLabel"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        label="Lieu de la signature"
        source="config.missionOrderConfig.signatureLocation"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        label="Texte de pied de page"
        source="config.missionOrderConfig.footer"
      />
    </Grid>
  </Grid>
);

export const MissionOrderConfigFields = (title) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h5" style={styles.title}>
        Configuration de l'ordre de mission
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <BooleanField
        label="Ordre de mission activé"
        source="config.missionOrderConfig.enabled"
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Nom de l\'entreprise"
        source="config.missionOrderConfig.companyName"
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Préfixe du numéro"
        source="config.missionOrderConfig.prefix"
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Format de date"
        source="config.missionOrderConfig.dateFormat"
      />
    </Grid>
    <Grid item xs={12}>
      <NumberField
        label="Nombre de chiffre du numéro d'increment"
        source="config.missionOrderConfig.digitNumber"
      />
    </Grid>
    <Grid item xs={12}>
      <TextField label="Logo" source="config.missionOrderConfig.logo" />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Intitulé de la signature"
        source="config.missionOrderConfig.signatureLabel"
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Lieu de la signature"
        source="config.missionOrderConfig.signatureLocation"
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Texte de pied de page"
        source="config.missionOrderConfig.footer"
      />
    </Grid>
  </Grid>
);
