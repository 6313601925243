import React from "react";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import get from "lodash/get";

const ChipField = ({
  className,
  classes = {},
  source,
  record = {},
  basePath,
  ...rest
}) => (
  <Chip
    className={classnames(classes.chip, className)}
    label={
      source !== null && source !== undefined ? get(record, source) : record
    }
    {...rest}
  />
);

export default ChipField;
