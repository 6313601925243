import React from "react";
import {
  List,
  Edit,
  Filter,
  Toolbar,
  SaveButton,
  DisabledInput,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  Create,
  SimpleForm,
  ReferenceInput,
  required,
  SelectInput,
  NumberInput,
  BooleanInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  EditButton,
  DeleteButton,
  SelectField,
  TextInput,
} from "react-admin";
import { operationTypes, operationCategories } from "./operations";

const FeesFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="ID" source="id" />
    <ReferenceInput
      label="Entreprise"
      source="company_id"
      reference="companies"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput source="operation" choices={operationTypes} alwaysOn />
    <SelectInput
      alwaysOn
      source="tag"
      choices={[
        { id: "flight", name: "flight" },
        { id: "low_cost_flight", name: "low_cost_flight" },
        { id: "rail", name: "rail" },
        { id: "driver", name: "driver" },
        { id: "hotel", name: "hotel" },
        { id: "card_subscription", name: "card_subscription" },
        { id: "insurrance", name: "insurrance" },
        { id: "other_modification", name: "other_modification" },
        { id: "other_request", name: "other_request" },
      ]}
    />
    <NumberInput source="rate" alwaysOn />
    <NumberInput source="price" alwaysOn />
    <SelectInput
      source="online"
      choices={[
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ]}
      alwaysOn
    />
  </Filter>
);

export const FeesList = (props) => (
  <List {...props} title="Frais des entreprises" filters={<FeesFilter />}>
    <Datagrid rowclick="show" perPage={50}>
      <NumberField source="id" />
      <ReferenceField
        label="Entreprise"
        source="company_id"
        reference="companies"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="operation" choices={operationTypes} />
      <TextField source="tag" />
      <NumberField source="rate" />
      <NumberField source="price" />
      <TextField source="calculation_unit" />
      <TextField source="currency" sortable={false} />
      <BooleanField source="online" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" />
    </Datagrid>
  </List>
);

const FeesCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter />
    <SaveButton
      label="Save and add"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);

export const FeesCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<FeesCreateToolbar />} redirect="list">
      {companyInput}
      {operationInput}
      {tagInput}
      {rateInput}
      {priceInput}
      {currencyInput}
      {onlineInput}
      {calculationUnitInput}
    </SimpleForm>
  </Create>
);

export const FeesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="uid" />
      {companyInput}
      {operationInput}
      {tagInput}
      {rateInput}
      {priceInput}
      {currencyInput}
      {onlineInput}
      {calculationUnitInput}
    </SimpleForm>
  </Edit>
);

export const FeesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" sortable={false} />
      <ReferenceField
        label="Entreprise"
        source="company_id"
        reference="companies"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="operation" sortable={false} />
      <TextField source="tag" sortable={false} />
      <NumberField source="rate" sortable={false} />
      <NumberField source="price" sortable={false} />
      <TextField source="calculation_unit" sortable={false} />
      <TextField source="currency" sortable={false} />
      <BooleanField source="online" sortable={false} />
    </SimpleShowLayout>
  </Show>
);

const companyInput = (
  <ReferenceInput
    label="Company"
    source="company_id"
    reference="companies"
    validate={[required()]}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>
);

const operationInput = (
  <SelectInput
    source="operation"
    validate={[required()]}
    choices={operationTypes}
  />
);

const tagInput = (
  <SelectInput
    source="tag"
    validate={[required()]}
    choices={operationCategories.concat([
      { id: "low_cost_flight", name: "low_cost_flight" },
      { id: "card_subscription", name: "card_subscription" },
      { id: "insurrance", name: "insurrance" },
      { id: "other_modification", name: "other_modification" },
      { id: "other_request", name: "other_request" },
    ])}
  />
);

const rateInput = <NumberInput source="rate" />;

const priceInput = <NumberInput source="price" />;

const currencyInput = (
  <SelectInput
    source="currency"
    validate={[required()]}
    defaultValue="EUR"
    choices={[{ id: "EUR", name: "EUR" }]}
  />
);

const onlineInput = <BooleanInput source="online" defaultValue />;

const calculationUnitInput = (
  <SelectInput
    source="calculation_unit"
    defaultValue="per_operation"
    validate={[required()]}
    choices={[
      { id: "per_operation", name: "Par opération" },
      { id: "per_pnr", name: "Par PNR" },
      { id: "per_trip", name: "Par trajet (aller + retour = 2 trajets)" },
      { id: "per_journey", name: "Par voyage (aller + retour = 1 voyage)" },
      {
        id: "per_session",
        name: "Par session (opérations effectuées en même temps, quelque soit la catégorie)",
      },
      {
        id: "per_session_and_cat",
        name: "Par session et catégorie (opérations effectuées en même temps, par catégorie)",
      },
    ]}
  />
);
