import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import Typography from "@material-ui/core/Typography";

const styles = {
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  root: {
    flex: 1,
  },
  list: {
    marginRight: "15px",
    marginLeft: "15px",
  },
  item: {
    paddingBottom: "15px",
    borderBottom: "1px solid lightgrey",
    height: "67px",
  },
  itemSecondaryAction: {
    position: "absolute",
    top: "20px",
  },
  itemText: {
    position: "absolute",
    top: "12px",
  },
  ticketStatus: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "green",
    padding: "5px",
    right: "0",
    position: "absolute",
  },
};

export function DisplayType(props) {
  switch (props.type) {
  case "flight":
    if (!props.headsign) {
      return "Vol";
    }
    return `N° de Vol: ${props.headsign}`;
  case "rail":
    if (!props.headsign) {
      return "Train";
    }
    return `N° de Train: ${props.headsign}`;
  case "driver":
    return "Taxi";
  case "hotel":
    return `Hotel / Ref: ${props.ref ?? "Inconnue"}`;
  case "car_rental":
    return `Loc voiture / Ref: ${props.ref ?? "Inconnue"}`;
  default:
    return `Type inconnu`;
  }
}

export function TicketStat({ classes }) {
  return (
    <Typography type="body1" style={styles.ticketStatus} align="right">
      À l'heure{" "}
    </Typography>
  );
}

export function DisplayTime(time) {
  if (time == null || time.length < 12) {
    return "";
  }
  return `${time[9] + time[10]}h${time[11]}${time[12]}`;
}

const TicketsDeparture = ({ value, header, classes }) => (
  <Card style={styles.main}>
    <CardHeader title={header} />
    <List dense style={styles.list}>
      {value.map((ticket) => {
        if (
          ticket.status === "pending_ticketing" ||
          ticket.status === "ticketed" ||
          ticket.status === "pending_approval"
        ) {
          return (
            <ListItem
              key={ticket.id}
              button
              component={Link}
              to={`/tickets/${ticket.uid}/show`}
              style={styles.item}
            >
              <ListItemText
                style={styles.itemText}
                primary={`${ticket.travelers[0].lastname} ${ticket.travelers[0].firstname}`}
                secondary={`De ${ticket.from} (${DisplayTime(
                  ticket.departure
                )}) à ${ticket.to}`}
              />
              <ListItemSecondaryAction style={styles.itemSecondaryAction}>
                <Typography type="body2" align="right">
                  {" "}
                  {`${DisplayType(ticket)} / PNR : ${ticket.pnr ?? "Inconnu"}`}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
        return "";
      })}
    </List>
  </Card>
);

const enhance = compose(translate);

export default enhance(TicketsDeparture);
