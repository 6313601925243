import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";

const ColoredField = ({
  classes = {},
  className,
  locales,
  options,
  choices,
  record,
  source,
  color,
  colorFn,
}) => {
  if (!record) return null;
  let value = get(record, source);
  if (value == null) return null;
  if (choices != null) {
    choices.forEach((choice) => {
      if (value === choice.id) {
        value = choice.name;
      }
    });
  }
  return (
    <Typography
      component="span"
      body1="body1"
      style={{ color: colorFn != null ? colorFn(value) : color }}
    >
      {value}
    </Typography>
  );
};

ColoredField.defaultProps = {
  addLabel: true,
};

ColoredField.defaultProps = {
  addLabel: true,
  textAlign: "right",
};
export default ColoredField;
