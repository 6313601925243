import React, { Component } from "react";
import { Button, withDataProvider, showNotification } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";

class ApproveTicketsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      decision: "approved",
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.approveTks();
  };

  async approveTks() {
    const { dataProvider, selectedIds, resource, showNotification } =
      this.props;
    const handleTickets = (tickets) => {
      const uniqueApprovalRequests = new Set();
      tickets.forEach((ticket) => {
        uniqueApprovalRequests.add(ticket.approval?.id);
      });

      uniqueApprovalRequests.forEach((approvalRequest) => {
        dataProvider(
          "APPROVE_TICKETS",
          "",
          {
            approvalID: approvalRequest,
            answer: decision === "approved" ? "approved" : "denied",
          },
          {
            onSuccess: {
              notification: {
                body:
                  decision === "approved"
                    ? "Les tickets ont été approuvés."
                    : "Les tickets ont été refusés",
                level: "info",
              },
              refresh: true,
              redirectTo: `/${resource}`,
              unselectAll: true,
            },
          }
        ).catch((error) => {
          showNotification(
            `Erreur lors de l'approbation: ${error.message}`,
            "warning"
          );
        });
      });

      this.setState({ isOpen: false });
    };

    // eslint-disable-next-line prefer-const
    let tks = [];
    let ticketsProcessed = 0;
    selectedIds.forEach((id, index, array) => {
      dataProvider.getOne("tickets", { id }).then((response) => {
        tks.push(response.data[0]);
        ticketsProcessed++;
        if (ticketsProcessed === array.length) {
          handleTickets(tks);
        }
      });
    });
    const { decision } = this.state;
  }

  render() {
    const { selectedIds } = this.props;
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Approbation{" "}
            {selectedIds && selectedIds.length > 1
              ? `des ${selectedIds.length} tickets`
              : "du ticket"}
          </DialogTitle>
          <DialogContent>
            <Select
              value={this.state.decision}
              onChange={(event) => {
                this.setState({ decision: event.target.value });
              }}
            >
              <MenuItem value="approved">Approuver</MenuItem>
              <MenuItem value="denied">Refuser</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button label="Annuler" onClick={this.handleDialogClose} />
            <Button
              label="Confirmer"
              onClick={this.handleConfirm}
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(null, {
  showNotification,
})(withDataProvider(ApproveTicketsButton));
