import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  EditButton,
  Edit,
  List,
  Datagrid,
  TextField,
  Create,
  TextInput,
  required,
  SelectInput,
  NumberInput,
  BooleanInput,
  Show,
  ShowButton,
  SimpleFormIterator,
  SaveButton,
  Toolbar,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  BooleanField,
  TopToolbar,
  CreateButton,
  ExportButton,
  FormDataConsumer,
  ArrayField,
  SingleFieldList,
  SelectField,
  SelectArrayInput,
  NumberField,
  useDataProvider,
} from "react-admin";
import { Typography } from "@material-ui/core";
import EditReadOnlyButton from "../components/Buttons/EditReadOnlyButton";
import ChipField from "../components/Fields/ChipField";
import NoBasePath from "../components/Fields/NoBasePath";
import ClosureDateTextInput from "../components/Input/ClosureDateTextInput";
import ClosureHourTextInput from "../components/Input/ClosureHourTextInput";
import { DateClosureFormatter } from "../components/Fields/TheTreepDateField";
import { carRentalAgencies } from "./carrental_config";

const styles = {
  title: {
    marginTop: "30px",
    marginBottom: "15px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "24px",
    fontWeight: "bold",
  },
};

export const airProviders = [
  { id: "sabre", name: "Sabre" },
  { id: "amadeus", name: "Amadeus" },
  { id: "duffel", name: "Duffel" },
];

export const railProviders = [
  { id: "sabre", name: "Sabre" },
  { id: "sncf", name: "SNCF" },
  { id: "rail-europe", name: "Rail Europe" },
];

export const carRentalProviders = [
  { id: "carbookr", name: "Carbookr" },
  { id: "sabre-car", name: "Sabre (Vloc)" },
];

export const agencyAnalyticType = [
  { id: "agency-value", name: "Valeur constante" },
  { id: "credit-card-token", name: "Jeton de paiement par CB" },
  { id: "credit-card-amount", name: "Montant paiement par CB" },
  { id: "booker", name: "Nom et prénom du Booker" },
  { id: "booker-email", name: "Email du Booker" },
  { id: "company", name: "Entreprise" },
  { id: "service", name: "Service" },
  { id: "one-way", name: "Aller simple" },
  { id: "compliancy", name: "Conformité" },
  { id: "not-compliant-reason", name: "Motif de non conformité" },
  { id: "flight-international", name: "Vol international" },
  { id: "subsidy", name: "N° de subvention" },
  { id: "subsidy-rate", name: "Taux de subvention" },
  { id: "mission-order", name: "Numéro d'ordre de mission" },
];

export const hotelProviders = [{ id: "cds", name: "CDS" }];

export const ticketingModes = [
  { id: "immediate", name: "Immediat" },
  { id: "postponed", name: "Automatique avant TTL" },
  { id: "manual", name: "Désactivé (placement dans la queue d'émission)" },
];

export const weekDays = [
  { name: "Dimanche", id: 0 },
  { name: "Lundi", id: 1 },
  { name: "Mardi", id: 2 },
  { name: "Mercredi", id: 3 },
  { name: "Jeudi", id: 4 },
  { name: "Vendredi", id: 5 },
  { name: "Samedi", id: 6 },
];

export const categoryMessages = [
  { id: "treepRecap", name: "Treep Recap" },
  { id: "homePage", name: "Home Page" },
  { id: "railResultPage", name: "Rail Result Page" },
  { id: "flightResultPage", name: "Flight Result Page" },
  { id: "carRentalResultPage", name: "Car Rental Result Page" },
  { id: "hotelResultPage", name: "Hotel Result Page" },
  { id: "cartPage", name: "Cart Page" },
  { id: "agencyInfo", name: "Agency Info" },
];

const AgenciesActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const ReadOnlyToggle = ({ record, ...props }) => (
  <EditReadOnlyButton record={record} {...props} entity="agencies" />
);

export const AgencyList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des configurations d'agence"
    bulkActionButtons={false}
    actions={<AgenciesActions />}
  >
    <Datagrid rowclick="show" perPage={200}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="leisure" sortable={false} />
      <TextField source="billingName" />
      <BooleanField source="readOnly" sortable={false} />
      <ShowButton label="" />
      {permissions != null && permissions.includes("admin") && (
        <EditButton label="" />
      )}
      {permissions != null && permissions.includes("dev") && <ReadOnlyToggle />}
    </Datagrid>
  </List>
);

export const SabreConfigsInputs = ({ sourcePrefix }) => [
  <Typography variant="h5" style={styles.subtitle}>
    Configuration des PCC
  </Typography>,
  <TextInput source={`${sourcePrefix}ticketingPCC`} />,
  <TextInput source={`${sourcePrefix}bookingPCC`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Création de profils
  </Typography>,
  <TextInput source={`${sourcePrefix}paxProfile.templateID`} />,
  <TextInput source={`${sourcePrefix}paxProfile.templatePCC`} />,
  <TextInput source={`${sourcePrefix}paxProfile.paxPCC`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Profil Sabre chargé sur tous les PNR
  </Typography>,
  <TextInput source={`${sourcePrefix}defaultProfile.pcc`} />,
  <TextInput source={`${sourcePrefix}defaultProfile.name`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Mode d'émission automatique
  </Typography>,
  <SelectInput
    source={`${sourcePrefix}railTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <SelectInput
    source={`${sourcePrefix}flightTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <SelectInput
    source={`${sourcePrefix}flightLCCTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <SelectInput
    source={`${sourcePrefix}flightInternationalTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <TextInput source={`${sourcePrefix}printerProfileNumber`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Segments de rétention
  </Typography>,
  <TextInput source={`${sourcePrefix}retentionSegment.text`} />,
  <NumberInput source={`${sourcePrefix}retentionSegment.durationDays`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue par défaut où sont placés tous les PNR
  </Typography>,
  <TextInput source={`${sourcePrefix}defaultQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}defaultQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR à la réservation
  </Typography>,
  <TextInput source={`${sourcePrefix}bookingQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}bookingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Rail
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.railQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.railQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Aérien
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.airQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.airQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Low Costs
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.lccQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.lccQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Hotel en réservation
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.hotelBookingQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.hotelBookingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Hotel en émission
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.hotelIssuingQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.hotelIssuingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR location de voitures en réservation
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.carRentalBookingQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.carRentalBookingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR location de voitures en émission
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.carRentalIssuingQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.carRentalIssuingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR pour émission par un robot
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.ticketingAutoQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.ticketingAutoQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR pour émission manuelle
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.ticketingManualQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.ticketingManualQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR rejettés lors de l'émission
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.rejectionQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.rejectionQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR à annuler
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.cancellationQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.cancellationQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR pour personnes sous handicap
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.disabledPAXQueue.pcc`} />,
  <TextInput source={`${sourcePrefix}queues.disabledPAXQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Paiement des low-costs
  </Typography>,
  <TextInput source={`${sourcePrefix}lccPaymentConfig.profile.pcc`} />,
  <TextInput source={`${sourcePrefix}lccPaymentConfig.profile.name`} />,
  <TextInput
    source={`${sourcePrefix}lccPaymentConfig.creditCardSecurityCode`}
  />,
  <TextInput source={`${sourcePrefix}lccPaymentConfig.billingInfo.name`} />,
  <TextInput source={`${sourcePrefix}lccPaymentConfig.billingInfo.address`} />,
  <TextInput
    source={`${sourcePrefix}lccPaymentConfig.billingInfo.postalCode`}
  />,
  <TextInput source={`${sourcePrefix}lccPaymentConfig.billingInfo.city`} />,
  <TextInput source={`${sourcePrefix}lccPaymentConfig.billingInfo.country`} />,
];

export const AmadeusConfigsInputs = ({ sourcePrefix }) => [
  <TextInput source={`${sourcePrefix}defaultProfile.name`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Mode d'émission automatique
  </Typography>,
  <SelectInput
    source={`${sourcePrefix}railTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <SelectInput
    source={`${sourcePrefix}flightTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <SelectInput
    source={`${sourcePrefix}flightLCCTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <SelectInput
    source={`${sourcePrefix}flightInternationalTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
  />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue par défaut où sont placés tous les PNR
  </Typography>,
  <TextInput source={`${sourcePrefix}defaultQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR à la réservation
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.bookingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Rail
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.railQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Aérien
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.airQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Low Costs
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.lccQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Hotel en réservation
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.hotelBookingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR Hotel en émission
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.hotelIssuingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR location de voitures en réservation
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.carRentalBookingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR location de voitures en émission
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.carRentalIssuingQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR pour émission par un robot
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.ticketingAutoQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR pour émission manuelle
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.ticketingManualQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR rejettés lors de l'émission
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.rejectionQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR à annuler
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.cancellationQueue.number`} />,
  <Typography variant="h5" style={styles.subtitle}>
    Queue où sont placés les PNR pour personnes sous handicap
  </Typography>,
  <TextInput source={`${sourcePrefix}queues.disabledPAXQueue.number`} />,
];

export const AgencyCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Informations générales">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="logo" />
        <TextInput source="contact" />
        <TextInput source="afterSalesContact" />
        <TextInput source="recapContact" />
        <TextInput source="recapPhone" />
        <TextInput source="billingName" />
        <BooleanInput source="leisure" />
        <BooleanInput source="sendingRecapDisabled" />
        <SelectArrayInput
          source="providers.rail"
          format={(s) => (s != null && s.length > 0 ? s[0] : null)}
          parse={(v) => (v != null ? [v] : null)}
          alwaysOn
          allowEmpty
          choices={railProviders}
        />
        <SelectInput
          source="providers.air"
          format={(s) => (s != null && s.length > 0 ? s[0] : null)}
          parse={(v) => (v != null ? [v] : null)}
          alwaysOn
          allowEmpty
          choices={airProviders}
        />
        <SelectInput
          source="providers.travelerProfile"
          format={(s) => (s != null && s.length > 0 ? s[0] : null)}
          parse={(v) => (v != null ? [v] : null)}
          alwaysOn
          allowEmpty
          choices={airProviders}
        />
        <SelectInput
          source="providers.hotel"
          format={(s) => (s != null && s.length > 0 ? s[0] : null)}
          parse={(v) => (v != null ? [v] : null)}
          alwaysOn
          allowEmpty
          choices={hotelProviders}
        />
        <SelectInput
          source="providers.carRental"
          format={(s) => (s != null && s.length > 0 ? s[0] : null)}
          parse={(v) => (v != null ? [v] : null)}
          alwaysOn
          allowEmpty
          choices={carRentalProviders}
        />
        <TextInput source="oneClickApprovalApp" />
        <ArrayInput source="agencyAnalytics">
          <SimpleFormIterator>
            <SelectInput
              source="analyticType"
              choices={agencyAnalyticType}
              validate={[required()]}
            />
            <TextInput source="key" />
            <TextInput source="cdsKey" />
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource, ...rest }) =>
                scopedFormData &&
                ["agency-value", "one-way", "not-compliant-reason"].includes(
                  scopedFormData.analyticType
                ) && (
                  <TextInput
                    label="Valeur par défaut"
                    source={getSource("defaultValue")}
                  />
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="railOfflineCancellation" />
        <BooleanInput source="airOfflineCancellation" />
      </FormTab>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("amadeus")
          ).length > 0 && (
            <FormTab label="Configuration Amadeus" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Connexion
              </Typography>
              <TextInput source="amadeusConfig.endpoint" />
              <TextInput source="amadeusConfig.user" />
              <TextInput source="amadeusConfig.password" />
              <TextInput source="amadeusConfig.organization" />
              <TextInput source="amadeusConfig.officeID" />
              <TextInput source="amadeusConfig.password" />
              <TextInput source="amadeusConfig.wsap" />
              <Typography variant="h5" style={styles.title}>
                Configuration par défaut
              </Typography>
              {AmadeusConfigsInputs({
                sourcePrefix: "amadeusConfig.defaultConfigs.",
              })}
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("sncf")
          ).length > 0 && (
            <FormTab label="Configuration SNCF" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Connexion
              </Typography>
              <TextInput source="sncfConfig.user" label="User" />
              <TextInput
                source="sncfConfig.password"
                label="Password"
                helperText="The current value is hidden for security reasons, but you can change it."
              />
              <TextInput source="sncfConfig.baseURL" label="URL" />
              <TextInput source="sncfConfig.versionURL" label="Version URL" />
              <TextInput
                source="sncfConfig.proxyURL"
                label="Proxy URL"
                helperText="The current value is hidden for security reasons, but you can change it."
              />
              <Typography variant="h5" style={styles.title}>
                Connexion ITP
              </Typography>
              <TextInput source="sncfConfig.ITP.user" label="User" />
              <TextInput
                source="sncfConfig.ITP.password"
                label="ITP Password"
                helperText="The current value is hidden for security reasons, but you can change it."
              />
              <TextInput source="sncfConfig.ITP.baseUrl" label="URL" />
              <TextInput
                source="sncfConfig.ITP.versionURL"
                label="Version URL"
              />
              <TextInput
                source="sncfConfig.ITP.proxyURL"
                label="Proxy URL"
                helperText="The current value is hidden for security reasons, but you can change it."
              />
              <BooleanInput
                source="sncfConfig.ITP.ouigoEnabled"
                label="Enable OUIGO"
              />
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("rail-europe")
          ).length > 0 && (
            <FormTab label="Configuration Rail Europe" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Connexion
              </Typography>
              <TextInput source="railEuropeConfig.clientID" label="Client ID" />
              <TextInput
                source="railEuropeConfig.clientSecret"
                label="Client Secret"
                helperText="The current value is hidden for security reasons, but you can change it."
              />
              <TextInput source="railEuropeConfig.baseURL" label="URL" />
              <TextInput source="railEuropeConfig.authURL" label="Auth URL" />
              <TextInput
                source="railEuropeConfig.pointOfSale"
                label="Point of Sale"
              />
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("sabre")
          ).length > 0 && (
            <FormTab label="Configuration Sabre" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Connexion
              </Typography>
              <TextInput source="sabreConfig.pcc" />
              <TextInput source="sabreConfig.agentID" />
              <TextInput source="sabreConfig.password" />
              <Typography variant="h5" style={styles.subtitle}>
                Désactiver l'optimisation des prix sur les aller/retour avion
              </Typography>
              <BooleanInput source="sabreConfig.returnFareOptimizationDisabled" />
              <Typography variant="h5" style={styles.title}>
                Configuration par défaut
              </Typography>
              {SabreConfigsInputs({
                sourcePrefix: "sabreConfig.defaultConfigs.",
              })}
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("cds")
          ).length > 0 && (
            <FormTab label="Configuration CDS" {...rest}>
              <div>
                <Typography variant="h5" style={styles.title}>
                  Connexion
                </Typography>
                <TextInput source="cdsConfig.login" />
                <TextInput source="cdsConfig.password" />
                <TextInput source="cdsConfig.defaultDutyCode" />
              </div>
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("carbookr")
          ).length > 0 && (
            <FormTab label="Configuration Carbookr" {...rest}>
              <Typography variant="h5" style={styles.title}>
                Connexion
              </Typography>
              <TextInput source="carbookrConfig.id" />
              <TextInput source="carbookrConfig.password" />
              <TextInput source="carbookrConfig.impersonatedID" />
            </FormTab>
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.providers &&
          Object.values(formData.providers).filter(
            (p) => p && p.includes("duffel")
          ).length > 0 && (
            <FormTab label="Configuration Duffel" {...rest}>
              <TextInput source="duffel.cardID" />
              <TextInput source="duffel.currency" />
              <TextInput source="duffel.token" />
            </FormTab>
          )
        }
      </FormDataConsumer>
    </TabbedForm>
  </Create>
);

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const AgencyEdit = ({ permissions, ...props }) => {
  const dataProvider = useDataProvider();
  const [carGroups, setCarGroups] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dataProvider("CAR_GROUPS", "agencies");
        setCarGroups(result.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Edit {...props}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="Informations générales">
          <TextInput disabled source="id" />
          <TextInput source="name" validate={[required()]} />
          <TextInput source="logo" />
          <TextInput source="contact" />
          <TextInput source="afterSalesContact" />
          <TextInput source="recapContact" />
          <TextInput source="recapPhone" />
          <TextInput source="billingName" />
          <BooleanInput source="leisure" />
          <BooleanInput source="sendingRecapDisabled" />
          <SelectArrayInput source="providers.rail" choices={railProviders} />
          <SelectInput
            source="providers.air"
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            allowEmpty
            choices={airProviders}
          />
          <SelectInput
            source="providers.travelerProfile"
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            allowEmpty
            choices={airProviders}
          />
          <SelectInput
            source="providers.hotel"
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            allowEmpty
            choices={hotelProviders}
          />
          <SelectInput
            source="providers.carRental"
            format={(s) => (s != null && s.length > 0 ? s[0] : null)}
            parse={(v) => (v != null ? [v] : null)}
            alwaysOn
            allowEmpty
            choices={carRentalProviders}
          />
          <TextInput source="oneClickApprovalApp" />
          <ArrayInput source="agencyAnalytics">
            <SimpleFormIterator>
              <SelectInput
                source="analyticType"
                choices={agencyAnalyticType}
                validate={[required()]}
              />
              <TextInput source="key" />
              <TextInput source="cdsKey" />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) =>
                  scopedFormData &&
                  ["agency-value", "one-way"].includes(
                    scopedFormData.analyticType
                  ) && (
                    <TextInput
                      label="Valeur par défaut"
                      source={getSource("defaultValue")}
                    />
                  )
                }
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput source="railOfflineCancellation" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.railOfflineCancellation &&
              Object.values(formData.railOfflineCancellation) && (
                <TextInput source="railOfflineMessageCancellation" />
              )
            }
          </FormDataConsumer>
          <BooleanInput source="airOfflineCancellation" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.airOfflineCancellation &&
              Object.values(formData.airOfflineCancellation) && (
                <TextInput source="airOfflineMessageCancellation" />
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            Object.values(formData.providers).filter(
              (p) => p && p.includes("amadeus")
            ).length > 0 && (
              <FormTab label="Configuration Amadeus" {...rest}>
                <Typography variant="h5" style={styles.title}>
                  Connexion
                </Typography>
                <TextInput source="amadeusConfig.endpoint" />
                <TextInput source="amadeusConfig.user" />
                <TextInput source="amadeusConfig.password" />
                <TextInput source="amadeusConfig.organization" />
                <TextInput source="amadeusConfig.officeID" />
                <TextInput source="amadeusConfig.password" />
                <TextInput source="amadeusConfig.wsap" />
                <Typography variant="h5" style={styles.title}>
                  Configuration par défaut
                </Typography>
                {AmadeusConfigsInputs({
                  sourcePrefix: "amadeusConfig.defaultConfigs.",
                })}
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            Object.values(formData.providers).filter(
              (p) => p && p.includes("sncf")
            ).length > 0 && (
              <FormTab label="Configuration SNCF" {...rest}>
                <Typography variant="h5" style={styles.title}>
                  Connexion
                </Typography>
                <TextInput source="sncfConfig.user" label="User" />
                <TextInput
                  source="sncfConfig.password"
                  label="Password"
                  helperText="The current value is hidden for security reasons, but you can change it."
                />
                <TextInput source="sncfConfig.baseURL" label="URL" />
                <TextInput source="sncfConfig.versionURL" label="Version URL" />
                <TextInput
                  source="sncfConfig.proxyURL"
                  label="Proxy URL"
                  helperText="The current value is hidden for security reasons, but you can change it."
                />
                <Typography variant="h5" style={styles.title}>
                  Connexion ITP
                </Typography>
                <TextInput source="sncfConfig.ITP.user" label="User" />
                <TextInput
                  source="sncfConfig.ITP.password"
                  label="ITP Password"
                  helperText="The current value is hidden for security reasons, but you can change it."
                />
                <TextInput source="sncfConfig.ITP.baseUrl" label="URL" />
                <TextInput
                  source="sncfConfig.ITP.versionURL"
                  label="Version URL"
                />
                <TextInput
                  source="sncfConfig.ITP.proxyURL"
                  label="Proxy URL"
                  helperText="The current value is hidden for security reasons, but you can change it."
                />
                <BooleanInput
                  source="sncfConfig.ITP.ouigoEnabled"
                  label="Enable OUIGO"
                />
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            Object.values(formData.providers).filter(
              (p) => p && p.includes("rail-europe")
            ).length > 0 && (
              <FormTab label="Configuration Rail Europe" {...rest}>
                <Typography variant="h5" style={styles.title}>
                  Connexion
                </Typography>
                <TextInput
                  source="railEuropeConfig.clientID"
                  label="Client ID"
                />
                <TextInput
                  source="railEuropeConfig.clientSecret"
                  label="Client Secret"
                  helperText="The current value is hidden for security reasons, but you can change it."
                />
                <TextInput source="railEuropeConfig.baseURL" label="URL" />
                <TextInput source="railEuropeConfig.authURL" label="Auth URL" />
                <TextInput
                  source="railEuropeConfig.pointOfSale"
                  label="Point of Sale"
                />
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            Object.values(formData.providers).filter(
              (p) => p && p.includes("sabre")
            ).length > 0 && (
              <FormTab label="Configuration Sabre" {...rest}>
                <Typography variant="h5" style={styles.title}>
                  Connexion
                </Typography>
                <TextInput source="sabreConfig.pcc" />
                <TextInput source="sabreConfig.agentID" />
                <TextInput source="sabreConfig.password" />
                <Typography variant="h5" style={styles.subtitle}>
                  Désactiver l'optimisation des prix sur les aller/retour avion
                </Typography>
                <BooleanInput source="sabreConfig.returnFareOptimizationDisabled" />
                <Typography variant="h5" style={styles.title}>
                  Configuration par défaut
                </Typography>
                {SabreConfigsInputs({
                  sourcePrefix: "sabreConfig.defaultConfigs.",
                })}
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            Object.values(formData.providers).filter(
              (p) => p && p.includes("cds")
            ).length > 0 && (
              <FormTab label="Configuration CDS" {...rest}>
                <div>
                  <Typography variant="h5" style={styles.title}>
                    Connexion
                  </Typography>
                  <TextInput source="cdsConfig.login" />
                  <TextInput source="cdsConfig.password" />
                  <TextInput source="cdsConfig.defaultDutyCode" />
                </div>
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            Object.values(formData.providers).filter(
              (p) => p && p.includes("carbookr")
            ).length > 0 && (
              <FormTab label="Configuration Carbookr" {...rest}>
                <Typography variant="h5" style={styles.title}>
                  Connexion
                </Typography>
                <TextInput source="carbookrConfig.id" />
                <TextInput source="carbookrConfig.password" />
                <TextInput source="carbookrConfig.impersonatedID" />
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            formData.providers.carRental &&
            Object.values(formData.providers.carRental).filter(
              (p) => p && p.includes("sabre")
            ).length > 0 && (
              <FormTab label="Configuration Vloc Sabre" {...rest}>
                <NumberInput source="carRental.maxDistance" />
                <NumberInput source="carRental.maxLocation" />

                {carGroups && (
                  <SelectArrayInput
                    source="carRental.carGroups"
                    label="Compte loueur"
                    choices={carGroups}
                  />
                )}

                <Typography variant="h5" style={styles.title}>
                  Informations loueurs
                </Typography>
                <ArrayInput source="carRental.accounts">
                  <SimpleFormIterator>
                    <SelectInput
                      label="Loueur"
                      source="renterCode"
                      choices={carRentalAgencies}
                      validate={[required()]}
                    />
                    <TextInput
                      label="Numéro de compte de facturation"
                      source="billingNumber"
                    />
                    <TextInput
                      label="Numéro de contrat négocié"
                      source="corporateDiscountCode"
                    />
                    <TextInput label="IATA Agence" source="agencyIATA" />
                  </SimpleFormIterator>
                </ArrayInput>
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.providers &&
            Object.values(formData.providers).filter(
              (p) => p && p.includes("duffel")
            ).length > 0 && (
              <FormTab label="Configuration Duffel" {...rest}>
                <TextInput source="duffel.cardID" />
                <TextInput source="duffel.currency" />
                <TextInput source="duffel.token" />
              </FormTab>
            )
          }
        </FormDataConsumer>
        <FormTab label="Heures d'ouverture">
          <BooleanInput
            label="Activer les heures d'ouverture"
            source="restrictToOpeningHours"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.restrictToOpeningHours && (
                <>
                  <NumberInput
                    source="offlineProcessTimeInMinutes"
                    label="Emission d'un billet"
                  />
                  <ArrayInput
                    source="exceptionalClosures"
                    label="Jours fériés et fermeture exceptionnelles"
                  >
                    <SimpleFormIterator>
                      <ClosureDateTextInput
                        format={DateClosureFormatter}
                        label="JJ-MM-AAAA"
                        validate={[required()]}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <ArrayInput
                    source="openingHours"
                    label="Horaires d'ouverture"
                  >
                    <SimpleFormIterator>
                      <SelectInput
                        source="weekDay"
                        alwaysOn
                        choices={weekDays}
                        label="Jour de la semaine"
                      />
                      <ClosureHourTextInput
                        source="openingAt"
                        format={DateClosureFormatter}
                        label="Ouverture (HH:mm)"
                        validate={[required()]}
                      />
                      <ClosureHourTextInput
                        source="closingAt"
                        format={DateClosureFormatter}
                        label="Fermeture (HH:mm)"
                        validate={[required()]}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        {permissions?.includes("admin") && (
          <FormTab label="Messages d'agence">
            <Typography variant="h5" style={{ ...styles.title, width: "100%" }}>
              Configuration des messages d'agence
            </Typography>

            {categoryMessages.map((category) => (
              <div key={category.id} style={{ marginBottom: "16px" }}>
                <ArrayInput
                  source={`communicationsBySupport.${category.id}`}
                  label={category.name}
                  key={category.id}
                >
                  <SimpleFormIterator disableReordering>
                    <TextInput source="title" label="Title" />
                    <TextInput source="content" label="Content" multiline />
                    <TextInput source="media" label="Media Link" />
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            ))}
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export const SabreConfigsFields = ({ sourcePrefix }) => [
  <NoBasePath key="pccConfig">
    <Typography variant="h5" style={styles.subtitle}>
      Configuration des PCC
    </Typography>
  </NoBasePath>,
  <TextField source={`${sourcePrefix}ticketingPCC`} key="ticketingPCC" />,
  <TextField source={`${sourcePrefix}bookingPCC`} key="bookingPCC" />,
  <NoBasePath key="profileCreation">
    <Typography variant="h5" style={styles.subtitle}>
      Création de profils
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}paxProfile.templateID`}
    key="paxProfile.templateID"
  />,
  <TextField
    source={`${sourcePrefix}paxProfile.templatePCC`}
    key="paxProfile.templatePCC"
  />,
  <TextField
    source={`${sourcePrefix}paxProfile.paxPCC`}
    key="paxProfile.paxPCC"
  />,
  <NoBasePath key="sabreProfileForPNRs">
    <Typography variant="h5" style={styles.subtitle}>
      Profil Sabre chargé sur tous les PNR
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}defaultProfile.pcc`}
    key="defaultProfile.pcc"
  />,
  <TextField
    source={`${sourcePrefix}defaultProfile.name`}
    key="defaultProfile.name"
  />,
  <NoBasePath key="automaticTicketingMode">
    <Typography variant="h5" style={styles.subtitle}>
      Mode d'émission automatique
    </Typography>
  </NoBasePath>,
  <SelectField
    source={`${sourcePrefix}railTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key="railTicketing"
  />,
  <SelectField
    source={`${sourcePrefix}flightTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key="flightTicketing"
  />,
  <SelectField
    source={`${sourcePrefix}flightLCCTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key="flightLCCTicketing"
  />,
  <SelectField
    source={`${sourcePrefix}flightInternationalTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key="flightInternationalTicketing"
  />,
  <TextField
    source={`${sourcePrefix}printerProfileNumber`}
    key="printerProfileNumber"
  />,
  <NoBasePath key="archiveSegment">
    <Typography variant="h5" style={styles.subtitle}>
      Segments de rétention
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}retentionSegment.text`}
    key="retentionSegment.text"
  />,
  <NumberField
    source={`${sourcePrefix}retentionSegment.durationDays`}
    key="retentionSegment.durationDays"
  />,
  <NoBasePath key="defaultQueueForAllPNRs">
    <Typography variant="h5" style={styles.subtitle}>
      Queue par défaut où sont placés tous les PNR
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}defaultQueue.pcc`}
    key="defaultQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}defaultQueue.number`}
    key="defaultQueue.number"
  />,
  <NoBasePath key="defaultBookingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR à la réservation
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.bookingQueue.pcc`}
    key="queues.bookingQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.bookingQueue.number`}
    key="queues.bookingQueue.number"
  />,
  <NoBasePath key="defaultRailQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Rail
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.railQueue.pcc`}
    key="queues.railQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.railQueue.number`}
    key="queues.railQueue.number"
  />,
  <NoBasePath key="defaultAirQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Aérien
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.airQueue.pcc`}
    key="queues.airQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.airQueue.number`}
    key="queues.airQueue.number"
  />,
  <NoBasePath key="LCCPNRQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Low Costs
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.lccQueue.pcc`}
    key="queues.lccQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.lccQueue.number`}
    key="queues.lccQueue.number"
  />,
  <NoBasePath key="hotelBookingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Hotel en réservation
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.hotelBookingQueue.pcc`}
    key="queues.hotelBookingQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.hotelBookingQueue.number`}
    key="queues.hotelBookingQueue.number"
  />,
  <NoBasePath key="hotelIssuingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Hotel en émission
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.hotelIssuingQueue.pcc`}
    key="queues.hotelIssuingQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.hotelIssuingQueue.number`}
    key="queues.hotelIssuingQueue.number"
  />,
  <NoBasePath key="carRentalBookingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR location de voitures en réservation
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.carRentalBookingQueue.pcc`}
    key="queues.carRentalBookingQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.carRentalBookingQueue.number`}
    key="queues.carRentalBookingQueue.number"
  />,
  <NoBasePath key="carRentalIssuingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR location de voitures en émission
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.carRentalIssuingQueue.pcc`}
    key="queues.carRentalIssuingQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.carRentalIssuingQueue.number`}
    key="queues.carRentalIssuingQueue.number"
  />,
  <NoBasePath key="ticketingAutoQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR pour émission par un robot
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.ticketingAutoQueue.pcc`}
    key="queues.ticketingAutoQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.ticketingAutoQueue.number`}
    key="queues.ticketingAutoQueue.number"
  />,
  <NoBasePath key="ticketingManualQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR pour émission manuelle
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.ticketingManualQueue.pcc`}
    key="queues.ticketingManualQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.ticketingManualQueue.number`}
    key="queues.ticketingManualQueue.number"
  />,
  <NoBasePath key="rejectionQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR rejettés lors de l'émission
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.rejectionQueue.pcc`}
    key="queues.rejectionQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.rejectionQueue.number`}
    key="queues.rejectionQueue.number"
  />,
  <NoBasePath key="cancellationQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR à annuler
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.cancellationQueue.pcc`}
    key="queues.cancellationQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.cancellationQueue.number`}
    key="queues.cancellationQueue.number"
  />,
  <NoBasePath key="disabledPAXQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR pour personnes sous handicap
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.disabledPAXQueue.pcc`}
    key="queues.disabledPAXQueue.pcc"
  />,
  <TextField
    source={`${sourcePrefix}queues.disabledPAXQueue.number`}
    key="queues.disabledPAXQueue.number"
  />,
  <NoBasePath key="lccPaymentConfig">
    <Typography variant="h5" style={styles.subtitle}>
      Paiement des low-costs
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.profile.pcc`}
    key="lccPaymentConfig.profile.pcc"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.profile.name`}
    key="lccPaymentConfig.profile.name"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.creditCardSecurityCode`}
    key="lccPaymentConfig.creditCardSecurityCode"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.billingInfo.name`}
    key="lccPaymentConfig.billingInfo.name"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.billingInfo.address`}
    key="lccPaymentConfig.billingInfo.address"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.billingInfo.postalCode`}
    key="lccPaymentConfig.billingInfo.postalCode"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.billingInfo.city`}
    key="lccPaymentConfig.billingInfo.city"
  />,
  <TextField
    source={`${sourcePrefix}lccPaymentConfig.billingInfo.country`}
    key="lccPaymentConfig.billingInfo.country"
  />,
];

export const AmadeusConfigsFields = ({ sourcePrefix }) => [
  <NoBasePath key="autoEmissionMode">
    <Typography variant="h5" style={styles.subtitle}>
      Mode d'émission automatique
    </Typography>
  </NoBasePath>,
  <SelectField
    source={`${sourcePrefix}railTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key={`${sourcePrefix}railTicketing`}
  />,
  <SelectField
    source={`${sourcePrefix}flightTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key={`${sourcePrefix}flightTicketing`}
  />,
  <SelectField
    source={`${sourcePrefix}flightLCCTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key={`${sourcePrefix}flightLCCTicketing`}
  />,
  <SelectField
    source={`${sourcePrefix}flightInternationalTicketing`}
    alwaysOn
    allowEmpty
    choices={ticketingModes}
    key={`${sourcePrefix}flightInternationalTicketing`}
  />,
  <NoBasePath key="defaultQueueAllPNRs">
    <Typography variant="h5" style={styles.subtitle}>
      Queue par défaut où sont placés tous les PNR
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}defaultQueue.number`}
    key={`${sourcePrefix}defaultQueue.number`}
  />,
  <NoBasePath key="bookingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR à la réservation
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.bookingQueue.number`}
    key={`${sourcePrefix}queues.bookingQueue.number`}
  />,
  <NoBasePath key="railQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Rail
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.railQueue.number`}
    key={`${sourcePrefix}queues.railQueue.number`}
  />,
  <NoBasePath key="airQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Aérien
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.airQueue.number`}
    key={`${sourcePrefix}queues.airQueue.number`}
  />,
  <NoBasePath key="lccQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Low Costs
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.lccQueue.number`}
    key={`${sourcePrefix}queues.lccQueue.number`}
  />,
  <NoBasePath key="hotelBookingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Hotel lors de la réservation
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.hotelBookingQueue.number`}
    key={`${sourcePrefix}queues.hotelBookingQueue.number`}
  />,
  <NoBasePath key="hotelIssuingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR Hotel lors de l'émission
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.hotelIssuingQueue.number`}
    key={`${sourcePrefix}queues.hotelIssuingQueue.number`}
  />,
  <NoBasePath key="carRentalBookingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR location de voitures lors de la réservation
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.carRentalBookingQueue.number`}
    key={`${sourcePrefix}queues.carRentalBookingQueue.number`}
  />,
  <NoBasePath key="carRentalIssuingQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR location de voitures lors de l'émisssion
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.carRentalIssuingQueue.number`}
    key={`${sourcePrefix}queues.carRentalIssuingQueue.number`}
  />,
  <NoBasePath key="ticketingAutoQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR pour émission par un robot
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.ticketingAutoQueue.number`}
    key={`${sourcePrefix}queues.ticketingAutoQueue.number`}
  />,
  <NoBasePath key="ticketingManualQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR pour émission manuelle
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.ticketingManualQueue.number`}
    key={`${sourcePrefix}queues.ticketingManualQueue.number`}
  />,
  <NoBasePath key="rejectionQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR rejetés lors de l'émission
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.rejectionQueue.number`}
    key={`${sourcePrefix}queues.rejectionQueue.number`}
  />,
  <NoBasePath key="cancellationQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR à annuler
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.cancellationQueue.number`}
    key={`${sourcePrefix}queues.cancellationQueue.number`}
  />,
  <NoBasePath key="disabledPAXQueue">
    <Typography variant="h5" style={styles.subtitle}>
      Queue où sont placés les PNR pour personnes sous handicap
    </Typography>
  </NoBasePath>,
  <TextField
    source={`${sourcePrefix}queues.disabledPAXQueue.number`}
    key={`${sourcePrefix}queues.disabledPAXQueue.number`}
  />,
];

export const AgencyShow = ({ permissions, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Informations générales">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="logo" />
        <TextField source="contact" />
        <TextField source="afterSalesContact" />
        <TextField source="recapContact" />
        <TextField source="recapPhone" />
        <BooleanField source="leisure" />
        <BooleanField source="sendingRecapDisabled" />
        <ArrayField source="providers.rail">
          <SingleFieldList>
            <ChipField />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="providers.air">
          <SingleFieldList>
            <ChipField />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="providers.travelerProfile">
          <SingleFieldList>
            <ChipField />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="providers.hotel">
          <SingleFieldList>
            <ChipField />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="providers.carRental">
          <SingleFieldList>
            <ChipField />
          </SingleFieldList>
        </ArrayField>
        <TextField source="oneClickApprovalApp" />
        <ArrayField source="agencyAnalytics">
          <Datagrid>
            <TextField source="analyticType" />
            <TextField source="key" />
            <TextField source="cdsKey" />
            <TextField source="defaultValue" />
          </Datagrid>
        </ArrayField>
        <BooleanField source="railOfflineCancellation" />
        <BooleanField source="airOfflineCancellation" />
      </Tab>
      <Tab label="Configuration Sabre">
        <Typography variant="h5" style={styles.title}>
          Connexion
        </Typography>
        <TextField source="sabreConfig.pcc" />
        <TextField source="sabreConfig.agentID" />
        <TextField source="sabreConfig.password" />
        <Typography variant="h5" style={styles.subtitle}>
          Désactiver l'optimisation des prix sur les aller/retour avion
        </Typography>
        <BooleanField source="sabreConfig.returnFareOptimizationDisabled" />
        <Typography variant="h5" style={styles.title}>
          Configuration par défaut
        </Typography>
        {SabreConfigsFields({ sourcePrefix: "sabreConfig.defaultConfigs." })}
      </Tab>
      <Tab label="Configuration Amadeus">
        <Typography variant="h5" style={styles.title}>
          Connexion
        </Typography>
        <TextField source="amadeusConfig.endpoint" />
        <TextField source="amadeusConfig.user" />
        <TextField source="amadeusConfig.password" />
        <TextField source="amadeusConfig.organization" />
        <TextField source="amadeusConfig.officeID" />
        <TextField source="amadeusConfig.password" />
        <TextField source="amadeusConfig.wsap" />
        <Typography variant="h5" style={styles.title}>
          Configuration par défaut
        </Typography>
        {AmadeusConfigsFields({
          sourcePrefix: "amadeusConfig.defaultConfigs.",
        })}
      </Tab>
      <Tab label="Configuration CDS">
        <Typography variant="h5" style={styles.title}>
          Connexion
        </Typography>
        <TextField source="cdsConfig.login" />
        <TextField source="cdsConfig.password" />
        <TextField source="cdsConfig.defaultDutyCode" />
      </Tab>
      <Tab label="Configuration SNCF">
        <Typography variant="h5" style={styles.title}>
          Connexion
        </Typography>
        <TextField source="sncfConfig.user" label="User" />
        <TextField
          source="sncfConfig.password"
          label="Password"
          emptyText="***"
        />
        <TextField source="sncfConfig.baseURL" label="URL" />
        <TextField source="sncfConfig.versionURL" label="Version URL" />
        <TextField
          source="sncfConfig.proxyURL"
          label="Proxy URL"
          emptyText="***"
        />
        <Typography variant="h5" style={styles.title}>
          ITP Connexion
        </Typography>
        <TextField source="sncfConfig.ITP.user" label="User" />
        <TextField
          source="sncfConfig.ITP.password"
          label="Password"
          emptyText="***"
        />
        <TextField source="sncfConfig.ITP.baseUrl" label="URL" />
        <TextField source="sncfConfig.ITP.versionURL" label="Version URL" />
        <TextField
          source="sncfConfig.ITP.proxyURL"
          label="Proxy URL"
          emptyText="***"
        />
        <TextField
          source="sncfConfig.ITP.triggerEmitOnPaxs"
          label="Trigger Emit on Paxs"
        />
        <BooleanField
          source="sncfConfig.ITP.ouigoEnabled"
          label="OUIGO Enabled"
        />
      </Tab>
      <Tab label="Configuration Rail Europe">
        <Typography variant="h5" style={styles.title}>
          Connexion
        </Typography>
        <TextField source="railEuropeConfig.clientID" label="Client ID" />
        <TextField
          source="railEuropeConfig.clientSecret"
          label="Client Secret"
          emptyText="***"
        />
        <TextField
          source="railEuropeConfig.authURL"
          label="Authentication URL"
        />
        <TextField source="railEuropeConfig.baseURL" label="URL" />
        <TextField
          source="railEuropeConfig.pointOfSale"
          label="Point of Sale"
        />
      </Tab>
      <Tab label="Configuration Carbookr">
        <Typography variant="h5" style={styles.title}>
          Connexion
        </Typography>
        <TextField source="carbookrConfig.id" />
        <TextField source="carbookrConfig.password" />
        <TextField source="carbookrConfig.impersonatedID" />
      </Tab>
      <Tab label="Configuration Duffel">
        <TextField source="duffel.cardID" />
        <TextField source="duffel.currency" />
        <TextField source="duffel.token" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
