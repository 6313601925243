import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

export const TheTreepDateField = ({
  className,
  record,
  showTime = false,
  source,
}) => {
  if (!record) return null;
  const value = get(record, source);
  if (value == null) return null;
  const date = moment(value);
  const dateString = showTime
    ? date.format("DD/MM/YYYY HH:mm")
    : date.format("DD/MM/YYYY");

  return (
    <Typography component="span" body1="body1" className={className}>
      {dateString}
    </Typography>
  );
};

export const DateFormatter = (date) => {
  if (date == null || !moment(date).isValid()) {
    return null;
  }
  return moment(date).toDate();
};

export const DateParser = (date) => {
  if (date == null || !moment(date, "DD/MM/YYYY").isValid()) {
    return null;
  }
  const formatted = moment(date, "DD/MM/YYYY").format("YYYYMMDD[T]HHmmss");
  return formatted;
};

export const ValidateGolangDate = (date) => {
  if (
    date == null ||
    moment.utc(date, "YYYY-MM-DD[T]HH:mm:ssZ", true).isValid()
  ) {
    return undefined;
  }
  return "Le format saisi est non valide, veuillez entrer une date au format suivant : JJ/MM/AAAA";
};

export const GolangDateFormatter = (date) => {
  if (date == null) {
    return null;
  }
  if (moment.utc(date, "DD/MM/YYYY", true).isValid()) {
    return moment.utc(date, "DD/MM/YYYY").format("DD/MM/YYYY");
  }
  if (moment.utc(date, "YYYY-MM-DD[T]HH:mm:ssZ", true).isValid()) {
    return moment.utc(date).format("DD/MM/YYYY");
  }
  return date;
};

export const GolangDateParser = (date) => {
  if (date == null) {
    return null;
  }
  if (moment.utc(date, "DD/MM/YYYY", true).isValid()) {
    return moment.utc(date, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ssZ");
  }
  if (moment.utc(date, "YYYY-MM-DD[T]HH:mm:ssZ", true).isValid()) {
    return moment.utc(date).format("YYYY-MM-DD[T]HH:mm:ssZ");
  }
  return date;
};

// Used within agency configuration to set closing days
export const DateClosureFormatter = (date) => {
  if (date == null) {
    return null;
  }
  if (moment(date, "YYYY-MM-DD", true).isValid()) {
    const formatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    return formatted;
  }
  return date;
};

export const DateClosureParser = (date) => {
  if (date == null || !moment(date, "DD-MM-YYYY").isValid()) {
    return null;
  }
  const formatted = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  return formatted;
};

export const DateUserFormatter = (date) => {
  if (date == null) {
    return null;
  }
  if (!moment(date).isValid()) {
    return date;
  }
  if (moment(date, "YYYYMMDD[T]HHmmss", true).isValid()) {
    const formatted = moment(date, "YYYYMMDD[T]HHmmss").format("DD/MM/YYYY");
    return formatted;
  }

  return date;
};

TheTreepDateField.defaultProps = {
  addLabel: true,
};
