import InputLabel from "@material-ui/core/InputLabel";
import React, { useState } from "react";
import {
  useFieldArray,
  Controller,
  useForm,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { withDataProvider } from "react-admin";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, Checkbox, Input } from "@material-ui/core";
import { ErrorMessage } from "@hookform/error-message/dist";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import countries from "world_countries_lists/data/countries/_combined/countries.json";
import worldRegions from "../../assets/worldRegions";

export const approvalTab = [
  { value: "always", name: "Toujours" },
  { value: "not_compliant", name: "Non conforme à la politique" },
  { value: "international", name: "L'international" },
  { value: "flight", name: "Avion" },
  { value: "hotel", name: "Hotel" },
  { value: "car_rental", name: "Location de voiture" },
  { value: "rail", name: "Train" },
];

export const operatorTab = [
  { value: "", name: "Type de conformité..." },
  { value: "number", name: "Conforme jusqu'à un certain prix" },
  { value: "list", name: "Conforme selon certains critères" },
];

export const rulesFlight = [
  { value: "", name: "Choisir une catégorie..." },
  { value: "lowcost_allowed", name: "Low-cost", operator: "bool" },
  {
    value: "cheapest_only",
    name: "Tarif le moins cher uniquement",
    operator: "bool",
  },
  { value: "most_flexible_only", name: "Le plus flexible", operator: "bool" },
  { value: "allowed", name: "Règle générale", operator: "bool" },
  { value: "dom_allowed", name: "France uniquement", operator: "bool" },
  { value: "inter_allowed", name: "International", operator: "bool" },
  {
    value: "max_dom",
    name: "France uniquement avec montant max",
    operator: "number",
  },
  {
    value: "max_inter",
    name: "International avec montant max",
    operator: "number",
  },
  { value: "noflex_allowed", name: "No flex", operator: "bool" },
  { value: "semiflex_allowed", name: "Semi flex", operator: "bool" },
  { value: "fullflex_allowed", name: "Full flex", operator: "bool" },
  { value: "first_allowed", name: "First", operator: "bool" },
  { value: "business_allowed", name: "Affaire", operator: "bool" },
  { value: "economy_allowed", name: "Économique", operator: "bool" },
  { value: "eco_premium_allowed", name: "Éco premium", operator: "bool" },
  {
    value: "eco_premium_above_duration",
    name: "Classe Eco Premium autorisée au delà de",
    operator: "duration",
  },
  {
    value: "business_above_duration",
    name: "Classe affaire autorisée au delà de",
    operator: "duration",
  },
  {
    value: "first_above_duration",
    name: "Première classe autorisée au delà de",
    operator: "duration",
  },
  {
    value: "allowed_above_train_duration",
    name: "Autorisé si les trajets en train sont de plus de",
    operator: "duration",
  }
];

export const rulesRail = [
  { value: "", name: "choisir une catégorie..." },
  { value: "lowcost_allowed", name: "Low-cost", operator: "bool" },
  {
    value: "cheapest_only",
    name: "Tarif le moins cher uniquement",
    operator: "bool",
  },
  { value: "most_flexible_only", name: "Le plus flexible", operator: "bool" },
  { value: "allowed", name: "Règle générale", operator: "bool" },
  { value: "dom_allowed", name: "France uniquement", operator: "bool" },
  { value: "inter_allowed", name: "International", operator: "bool" },
  {
    value: "max_dom",
    name: "France uniquement avec montant max",
    operator: "number",
  },
  {
    value: "max_inter",
    name: "International avec montant max",
    operator: "number",
  },
  { value: "noflex_allowed", name: "No flex", operator: "bool" },
  { value: "semiflex_allowed", name: "Semi flex", operator: "bool" },
  { value: "fullflex_allowed", name: "Full flex", operator: "bool" },
  { value: "2nd_allowed", name: "Seconde classe", operator: "bool" },
  { value: "1st_allowed", name: "Première classe", operator: "bool" },
  {
    value: "1st_above_duration",
    name: "Première classe autorisée au delà de",
    operator: "duration",
  },
];

export const rulesHotel = [
  { value: "", name: "Choisir une catégorie..." },
  { value: "allowed", name: "Règle générale", operator: "bool" },
  { value: "max", name: "Conforme avec un montant max", operator: "number" },
  {
    value: "max_dom",
    name: "France uniquement avec montant max",
    operator: "number",
  },
  {
    value: "max_idf",
    name: "Île-de-france uniquement avec montant max",
    operator: "number",
  },
  { value: "noflex_allowed", name: "No flex", operator: "bool" },
  { value: "semiflex_allowed", name: "Semi flex", operator: "bool" },
  { value: "fullflex_allowed", name: "Full flex", operator: "bool" },
  {
    value: "population",
    name: "Tarif selon le nombre d'habitants",
    operator: "population",
  },
  {
    value: "geo",
    name: "Tarif selon une zone géographique",
    operator: "population",
  },
];

export const geoCat = [
  {
    value: "city",
    name: "Ville",
    operator: "number",
    placeholder: "Marseille...",
  },
  {
    value: "postal_code",
    name: "Code postal",
    operator: "number",
    placeholder : "75000...",
  },
  {
    value: "department",
    name: "Département",
    operator: "number",
    placeholder: "78...",
  },
  {
    value: "country",
    name: "Pays",
    operator: "number",
    placeholder: "FR, US...",
  },
  {
    value: "region",
    name: "Région du monde",
    operator: "number",
    placeholder: "Europe de l'est, Amérique du nord...",
  },
];

export const populationCat = [
  {
    value: "catParis",
    name: "Ville de Paris",
    operator: "number",
    defaultValue: 110,
  },
  {
    value: "catGP",
    name: "Ville du Grand Paris",
    operator: "number",
    defaultValue: 90,
  },
  {
    value: "catIDF",
    name: "Ville d'IDF excluant Paris et GP",
    operator: "number",
    defaultValue: 70,
  },
  {
    value: "cat200",
    name: "Ville de plus de 200k habitants",
    operator: "number",
    defaultValue: 90,
  },
  {
    value: "catDefault",
    name: "Autres villes",
    operator: "number",
    defaultValue: 70,
  },
];

export const rulesCarRental = [
  { value: "", name: "Choisir une catégorie..." },
  { value: "allowed", name: "Règle générale", operator: "bool" },
  { value: "max", name: "Conforme avec un montant max", operator: "number" },
  {
    value: "max_dom",
    name: "France uniquement avec montant max",
    operator: "number",
  },
  {
    value: "max_idf",
    name: "Île-de-france uniquement avec montant max",
    operator: "number",
  },
  { value: "noflex_allowed", name: "No flex", operator: "bool" },
  { value: "semiflex_allowed", name: "Semi flex", operator: "bool" },
  { value: "fullflex_allowed", name: "Full flex", operator: "bool" },
  {
    value: "car_whitelist",
    name: "Types de voiture conformes",
    operator: "list",
  },
  {
    value: "car_blacklist",
    name: "Types de voiture non conformes",
    operator: "list",
  },
];

const rulesDriver = ["allowed", "max", "vendor_blacklist", "vendor_whitelist"];

const CarType = [
  { value: "TP", name: "Citadine" },
  { value: "TM", name: "Berline compacte" },
  { value: "TG", name: "Voiture spacieuse, SUV ou monospace" },
  { value: "UP", name: "Petit utilitaire" },
  { value: "UM", name: "Fourgon tôlé 10/12 m3" },
  { value: "UG", name: "Fourgon cellule 20m3 avec Hayon" },
];

export function checkOperator(tab, cat) {
  let op;
  tab.forEach((o) => {
    if (o.value === cat) {
      op = o.operator;
    }
  });
  return op;
}

export function getEntityName(tab, id) {
  let name;
  tab.forEach((entity) => {
    if (parseInt(entity.uid, 10) === parseInt(id, 10)) {
      name = entity.name;
    }
  });
  return name;
}

const useStyles = makeStyles({
  travel_form: {
    maxWidth: "900px",
    minWidth: "400px",
    margin: "0 auto",
  },
  travel_form_input_company: { gridArea: "company" },
  travel_form_input_params: { gridArea: "params" },
  travel_form_input_rules: { gridArea: "rules" },
  travel_form_input_container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas:
      '"company params"\n    "company params"\n    "rules rules"',
    padding: "10px",
    gridGap: "20px",
  },
  travel_form_input: {
    WebkitAppearance: "none",
    backgroundColor: "white",
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  travel_form_select_controller: {
    width: "100%",
    backgroundColor: "white",
    padding: "5px",
    marginTop: "10px",
  },
  travel_form_select_controller_car: {
    width: "325px",
    backgroundColor: "white",
  },
  travel_form_select: {
    marginRight: "10px",
    backgroundColor: "white",
    padding: "5px",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  travel_form_checkbox: {
    width: "50px",
    margin: "0 auto",
  },
  travel_form_submit: {
    width: "100%",
    backgroundColor: "#90EE90",
    color: "white",
    padding: "14px 20px",
    margin: "8px 0",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  travel_form_submit_hover: { background: "#4CAF50" },
  travel_form_submit_active: {
    transition: "0.3s all",
    transform: "translateY(3px)",
    border: "1px solid transparent",
    opacity: "0.8",
  },
  travel_form_submit_button_active: {
    transition: "0.3s all",
    transform: "translateY(3px)",
    border: "1px solid transparent",
    opacity: "0.8",
  },
  travel_form_cancel: {
    width: "100%",
    backgroundColor: "#e82b2b",
    color: "white",
    padding: "14px 20px",
    margin: "8px 0",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  travel_form_input_disabled: { opacity: "0.4" },
  travel_form_input_hover: { transition: "0.3s all" },
  input_form_pannel: {
    backgroundColor: "#f5f5F5",
    boxShadow: "0 4px 8px 0 rgba(172,200,221,0.2)",
    transition: "0.3s",
    padding: "10px",
    margin: "10px",
  },
  input_form_pannel_hover: { boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)" },
  travel_form_infos: { fontSize: "12px", fontStyle: "italic" },
});

function ParseValue(operator, value) {
  if (operator === "bool") {
    if (value === false || value === "false" || value === undefined) {
      return false;
    }
    return true;
  }
  if (operator === "number") {
    return parseInt(value, 10);
  }

  return value;
}

const TravelPolicyFormEdit = (props) => {
  let methods;

  if (props.isEdit) {
    methods = useForm({ defaultValues: props.isEdit });
  } else {
    methods = useForm({
      defaultValues: {
        "travel_rules.rail": [
          {
            criteria: { category: "allowed", mode: "rail" },
            operator: "bool",
            value: true,
          },
        ],
        "travel_rules.flight": [
          {
            criteria: { category: "allowed", mode: "flight" },
            operator: "bool",
            value: true,
          },
        ],
        "travel_rules.car_rental": [
          {
            criteria: { category: "allowed", mode: "car_rental" },
            operator: "bool",
            value: true,
          },
        ],
        "travel_rules.hotel": [
          {
            criteria: { category: "allowed", mode: "hotel" },
            operator: "bool",
            value: true,
          },
        ],
      },
      shouldUnregister: false,
    });
  }

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
    watch,
    unregister,
    setValue,
  } = methods;

  const onSubmit = (data) => {
    data.company_id = parseInt(props.company, 10);
    if (props.isEdit) {
      data.id = props.isEdit.id;
    }
    if (data.entity_id === "none") {
      delete data.entity_id;
    } else {
      data.entity_id = parseInt(data.entity_id, 10);
    }
    if (!data.entity_id) {
      delete data.entity_id;
    }
    if (data.user_level === "all") {
      delete data.user_level;
    } else {
      data.user_level = parseInt(data.user_level, 10);
    }
    const mode = [
      data.travel_rules.rail,
      data.travel_rules.flight,
      data.travel_rules.car_rental,
      data.travel_rules.hotel,
    ];
    for (let i = 0; i < mode.length; i++) {
      const selectMode = mode[i];
      if (selectMode) {
        for (let i = 0; i < selectMode.length; i++) {
          selectMode[i].value = ParseValue(
            selectMode[i].operator,
            selectMode[i].value
          );
          if (selectMode[i].criteria.category === "population") {
            selectMode[i].criteria.population_rule.type = "cityPopulation";
          }
        }
      }
    }
    delete data.choose_mode;
    props.upsertPolicy(data);
  };

  function handleFormClose() {
    props.handleFormClose();
  }

  const classes = useStyles();

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={classes.travel_form}
      >
        <h1>Configuration de la PVE</h1>

        <section className={classes.travel_form_input_container}>
          <div
            className={classnames({
              [classes.input_form_pannel]: true,
              [classes.travel_form_input_company]: true,
            })}
          >
            <h3>À qui s'applique t'elle ? </h3>

            <InputLabel>Entité :</InputLabel>
            <select
              name="entity_id"
              ref={register()}
              className={classes.travel_form_input}
            >
              <option value="none" key="0">
                Toutes
              </option>
              {props.entities &&
                props.entities.map((e, index) => (
                  <option value={e.uid} key={index + 1}>
                    {e.name}
                  </option>
                ))}
            </select>

            <InputLabel>Service :</InputLabel>
            <select
              name="user_service"
              ref={register()}
              className={classes.travel_form_input}
            >
              <option value="*" key="0">
                Tous
              </option>
              {props.services &&
                props.services.map((e, index) => (
                  <option value={e.name} key={index + 1}>
                    {e.name}
                  </option>
                ))}
            </select>

            <InputLabel>Niveau hiérarchique :</InputLabel>
            <select
              name="user_level"
              ref={register()}
              className={classes.travel_form_input}
            >
              <option value="all" key="0">
                Tous
              </option>
              <option value={1} key="1">
                1
              </option>
              <option value={2} key="2">
                2
              </option>
              <option value={3} key="3">
                3
              </option>
              <option value={4} key="4">
                4
              </option>
              <option value={5} key="5">
                5
              </option>
              <option value={6} key="6">
                6
              </option>
              <option value={7} key="7">
                7
              </option>
              <option value={8} key="8">
                8
              </option>
              <option value={9} key="9">
                9
              </option>
              <option value={10} key="10">
                10
              </option>
            </select>

            <Controller
              control={control}
              name="user_category"
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  label="Catégorie de voyageur"
                  variant="filled"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  fullWidth
                />
              )}
            />
            <p className={classes.travel_form_infos}>
              {watch("entity_id") && watch("entity_id") !== "none"
                ? `La pve s'applique uniquement à l'entité ${getEntityName(
                  props.entities,
                  watch("entity_id")
                )}. \n`
                : ""}
              {watch("user_service") && watch("user_service") !== "*"
                ? `Cette pve s'applique uniquement au service : ${watch(
                  "user_service"
                )} .`
                : "La pve s'applique à tous les services."}
              {watch("user_level") && watch("user_level") !== "all"
                ? `Cette pve est applicable uniquement aux voyageurs de niveau ${watch(
                  "user_level"
                )}. `
                : "Cette pve s'applique à tous les utilisateurs de votre entreprise. "}
            </p>
          </div>

          <div
            className={classnames({
              [classes.input_form_pannel]: true,
              [classes.travel_form_input_params]: true,
            })}
          >
            <h3>Comment s'applique t'elle ?</h3>

            <label>Masquer les tarifs non conforme : </label>
            <div className={classes.travel_form_checkbox}>
              <Controller
                control={control}
                name="hide_fares_off"
                render={({ onChange, onBlur, value, name, ref }) => (
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    inputRef={ref}
                  />
                )}
              />
            </div>

            <label>Approbation requise : </label>

            <Controller
              className={classes.travel_form_select_controller}
              as={
                <Select multiple>
                  <MenuItem value="always">Toujours</MenuItem>
                  <MenuItem value="not_compliant">
                    Non conforme à la politique
                  </MenuItem>
                  <MenuItem value="international">L'international</MenuItem>
                  <MenuItem value="flight">Avion</MenuItem>
                  <MenuItem value="hotel">Hotel</MenuItem>
                  <MenuItem value="car_rental">Location de voiture</MenuItem>
                  <MenuItem value="rail">Train</MenuItem>
                </Select>
              }
              control={control}
              name="approval_requirement"
              defaultValue={
                !props.isEdit || !props.isEdit.approval_requirement
                  ? []
                  : props.isEdit.approval_requirement
              }
            />

            <p className={classes.travel_form_infos}>
              {watch("hide_fares_off") &&
                "L'entreprise masque l'affichage des tarifs non-conformes. "}
              {watch("approval_requirement") &&
              watch("approval_requirement").length > 0
                ? `Une approbation est requise pour les cas suivants : ${watch(
                  "approval_requirement"
                ).toString()} .`
                : "Aucune approbation est requise."}
            </p>
          </div>

          <div
            className={classnames({
              [classes.input_form_pannel]: true,
              [classes.travel_form_input_rules]: true,
            })}
          >
            <h3>Modifier les règles de la PVE</h3>

            <h4>Politique de voyage pour les trains :</h4>
            <RulesForm typeName="trains" type="rail" categories={rulesRail} />

            <h4>Politique de voyage pour les vols :</h4>
            <RulesForm typeName="vols" type="flight" categories={rulesFlight} />

            <h4>Politique de voyage pour les hotels :</h4>
            <RulesForm typeName="hotels" type="hotel" categories={rulesHotel} />

            <h4>Politique de voyage pour les locations de voitures :</h4>
            <RulesForm
              typeName="locations de voiture"
              type="car_rental"
              categories={rulesCarRental}
            />
          </div>
        </section>

        <input
          className={classes.travel_form_submit}
          type="submit"
          value="Valider"
        />
        <input
          className={classes.travel_form_cancel}
          type="button"
          value="Annuler"
          onClick={() => handleFormClose()}
        />
      </form>
    </FormProvider>
  );
};

export default withDataProvider(TravelPolicyFormEdit);

function PopDefaultValue(item, cat) {
  if (item !== undefined && !Number.isNaN(item)) {
    return parseInt(item, 10);
  }
  for (let i = 0; i < populationCat.length; i++) {
    if (populationCat[i].value === cat) {
      return populationCat[i].defaultValue;
    }
  }
  return null;
}

function RulesForm(props) {
  const { register, watch, control, errors, setValue, reset, getValues } =
    useFormContext();
  const [count, setCount] = useState(0);
  const [isAllowed, setAllowed] = useState(false);
  const [hotelDefaultValues, setHotelDefaultValues] = useState(false);
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `travel_rules.${props.type}`,
  });

  React.useEffect(() => {
    fields.forEach((_, i) => {
      if (
        watch(`travel_rules.${props.type}[${i}].criteria.category`) ===
          "allowed" &&
        watch(`travel_rules.${props.type}[${i}].value`) === false
      ) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
    });
    for (let index = 0; index < fields.length; index++) {
      if (
        watch(`${criteria(index)}.category`) === "allowed" &&
        watch(value(index)) === false
      ) {
        for (let i = 0; i < fields.length; i++) {
          setCount(index);
          if (i !== index) {
            remove(i);
          }
        }
      }
    }
  });

  React.useEffect(() => {
    if (props.type === "hotel" && hotelDefaultValues) {
      for (let index = 0; index < fields.length; index++) {
        if (watch(`${criteria(index)}.category`) === "population") {
          remove(index);
        }
      }

      for (let i = 0; i < populationCat.length; i++) {
        append({
          criteria: {
            category: "population",
            population_rule: {
              type: "cityPopulation",
              code: populationCat[i].value,
            },
            value: populationCat[i].defaultValue,
          },
        });
      }
    }
  }, [hotelDefaultValues]);

  let value = (index) => `travel_rules.${props.type}[${index}].value`;
  let criteria = (index) => `travel_rules.${props.type}[${index}].criteria`;
  const weight = (index) => `travel_rules.${props.type}[${index}].weight`;

  return (
    <>
      <ul>
        {fields.map((item, index) => (
          <li key={item.id}>
            Règle {index + 1} :
            <select
              className={classes.travel_form_select}
              name={`${criteria(index)}.category`}
              ref={register({ required: true })}
              defaultValue={`${item.criteria.category}`}
            >
              {props.categories.map((r, selectIndex) => (
                <option value={r.value} key={selectIndex}>
                  {r.name}
                </option>
              ))}
            </select>
            <input
              type="hidden"
              name={`${criteria(index)}.mode`}
              defaultValue={`${props.type}`}
              ref={register({ required: true })}
            />
            {checkOperator(
              props.categories,
              watch(`${criteria(index)}.category`)
            ) === "bool" && (
              <>
                <input
                  id="bool_true"
                  name={`travel_rules.${props.type}[${index}].operator`}
                  type="hidden"
                  value="bool"
                  ref={register()}
                />
                <FormGroup>
                  <Controller
                    control={control}
                    name={value(index)}
                    defaultChecked={item.value}
                    render={({ onChange, onBlur, ref }) => (
                      <FormControlLabel
                        control={
                          <Radio
                            onBlur={onBlur}
                            onChange={(e) => setValue(value(index), true)}
                            checked={watch(value(index)) === true}
                            inputRef={ref}
                            inputProps={{ "aria-label": "Conforme" }}
                          />
                        }
                        label="Conforme"
                      />
                    )}
                  />
                  <Controller
                    name={value(index)}
                    defaultChecked={item.value}
                    render={({ onChange, onBlur, value, ref }) => (
                      <FormControlLabel
                        control={
                          <Radio
                            onBlur={onBlur}
                            onChange={(e) =>
                              setValue(
                                `travel_rules.${props.type}[${index}].value`,
                                false
                              )
                            }
                            value={false}
                            checked={
                              watch(
                                `travel_rules.${props.type}[${index}].value`
                              ) === false
                            }
                            inputRef={ref}
                          />
                        }
                        label="Non conforme"
                      />
                    )}
                  />
                </FormGroup>
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Ce champ est obligatoire"
                />
              </>
            )}
            {checkOperator(
              props.categories,
              watch(`${criteria(index)}.category`)
            ) === "number" && (
              <>
                <input
                  name={`travel_rules.${props.type}[${index}].operator`}
                  type="hidden"
                  value="number"
                  ref={register()}
                />
                <input
                  name={value(index)}
                  type="number"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="montant..."
                  defaultValue={parseInt(item.value, 10)}
                />
                {props.type === "hotel" && (
                  <input
                    name={weight(index)}
                    type="number"
                    ref={register({
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder="poids..."
                    defaultValue={parseInt(item.weight, 10)}
                  />
                )}
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Veuillez saisir un montant"
                />
              </>
            )}
            {checkOperator(
              props.categories,
              watch(`${criteria(index)}.category`)
            ) === "duration" && (
              <>
                <input
                  name={`travel_rules.${props.type}[${index}].operator`}
                  type="hidden"
                  value="number"
                  ref={register()}
                />
                <input
                  name={value(index)}
                  type="number"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="durée (en minutes)"
                  defaultValue={parseInt(item.value, 10)}
                />
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Veuillez saisir une durée en minutes"
                />
              </>
            )}
            {watch(`${criteria(index)}.category`) === "population" && (
              <>
                <select
                  className={classes.travel_form_select}
                  name={`${criteria(index)}.population_rule.code`}
                  ref={register({ required: true })}
                  defaultValue={
                    item.criteria.population_rule != null
                      ? `${item.criteria.population_rule.code}`
                      : "cat200"
                  }
                >
                  {populationCat.map((r, selectIndex) => (
                    <option value={r.value} key={selectIndex}>
                      {r.name}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Veuillez choisir une catégorie"
                />
                <input
                  name={value(index)}
                  type="number"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="montant..."
                  defaultValue={PopDefaultValue(
                    item.value,
                    watch(`${criteria(index)}.population_rule.code`)
                  )}
                />
                <input
                  name={weight(index)}
                  type="number"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="poids..."
                  defaultValue={parseInt(item.weight, 10)}
                />
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Veuillez saisir un montant"
                />
              </>
            )}
            {watch(`${criteria(index)}.category`) === "geo" && (
              <>
                <select
                  className={classes.travel_form_select}
                  name={`${criteria(index)}.geo_rule.type`}
                  ref={register({ required: true })}
                  defaultValue={
                    item.criteria.geo_rule != null
                      ? `${item.criteria.geo_rule.type}`
                      : "city"
                  }
                >
                  {geoCat.map((r, selectIndex) => (
                    <option
                      value={r.value}
                      key={selectIndex}
                      selected={r.value === item.criteria.geo_rule?.type}
                    >
                      {r.name}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Veuillez choisir une catégorie"
                />
                {watch(`${criteria(index)}.geo_rule.type`) === "region" && (
                  <select
                    className={classes.travel_form_select}
                    name={`${criteria(index)}.geo_rule.area`}
                    ref={register({ required: true })}
                  >
                    {worldRegions.map((wr, selectIndex) => (
                      <option
                        value={wr.value}
                        key={selectIndex}
                        selected={
                          wr.value === item.criteria.geo_rule?.code ||
                          wr.value === item.criteria.geo_rule?.area
                        }
                      >
                        {wr.name}
                      </option>
                    ))}
                  </select>
                )}
                {watch(`${criteria(index)}.geo_rule.type`) === "country" && (
                  <select
                    className={classes.travel_form_select}
                    name={`${criteria(index)}.geo_rule.area`}
                    ref={register({ required: true })}
                  >
                    {countries
                      .sort((a, b) => a.fr.localeCompare(b.fr))
                      .map((c, selectIndex) => (
                        <option
                          value={c.alpha2}
                          key={selectIndex}
                          selected={
                            c.alpha2 === item.criteria.geo_rule?.code ||
                            c.alpha2 === item.criteria.geo_rule?.area
                          }
                        >
                          {c.fr}
                        </option>
                      ))}
                  </select>
                )}
                {!["region", "country"].includes(
                  watch(`${criteria(index)}.geo_rule.type`)
                ) && (
                  <input
                    name={`${criteria(index)}.geo_rule.area`}
                    type="text"
                    ref={register({
                      required: true,
                    })}
                    placeholder="Marseille, 78..."
                    defaultValue={
                      item.criteria.geo_rule != null
                        ? `${item.criteria.geo_rule.area}`
                        : ""
                    }
                  />
                )}
                <input
                  name={value(index)}
                  type="number"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="montant..."
                  defaultValue={parseInt(item.value, 10)}
                />
                <input
                  name={weight(index)}
                  type="number"
                  ref={register({
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="poids..."
                  defaultValue={parseInt(item.weight, 10)}
                />
                <ErrorMessage
                  errors={errors}
                  name={value(index)}
                  message="Veuillez saisir un montant"
                />
              </>
            )}
            {checkOperator(
              props.categories,
              watch(`${criteria(index)}.category`)
            ) === "list" && (
              <>
                {watch(`${criteria(index)}.category`) === "car_whitelist" ||
                watch(`${criteria(index)}.category`) === "car_blacklist" ? (
                    <Controller
                      className={classes.travel_form_select_controller_car}
                      as={
                        <Select multiple>
                          {CarType.map((r, selectIndex) => (
                            <MenuItem value={r.value} key={selectIndex}>
                              {r.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      control={control}
                      name={value(index)}
                      defaultValue={Array.isArray(item.value) ? item.value : []}
                    />
                  ) : (
                    <Controller
                      name={value(index)}
                      as={<input type="text" />}
                      control={control}
                      defaultValue={item.value}
                      onChange={([e]) => e.target.value.split(",")}
                      placeholder="Séparer les éléments par une virgule"
                    />
                  )}
              </>
            )}
            <button
              className={classes.travel_form_del}
              type="button"
              onClick={() => {
                remove(index);
              }}
            >
              Supprimer
            </button>
          </li>
        ))}
      </ul>
      <section>
        {isAllowed ? (
          <p>
            Vouz avez complétement interdit ce mode de déplacement. Si vous
            souhaitez peaufiner les règles, veuillez sélectionner un autre
            élément de la liste.
          </p>
        ) : (
          <>
            <button
              type="button"
              className={classes.travel_form_button}
              onClick={() => {
                append({ criteria: { category: "" } });
              }}
            >
              ajouter
            </button>
            {props.type === "hotel" && (
              <button
                type="button"
                className={classes.travel_form_button}
                onClick={() => {
                  setHotelDefaultValues(true);
                }}
                disabled={hotelDefaultValues}
              >
                ajouter les valeurs par défauts
              </button>
            )}
          </>
        )}
      </section>
    </>
  );
}
