import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogin } from "react-admin";
import OAuthClient from "../utils/auth";

class Login extends Component {
  constructor(props) {
    super(props);
    OAuthClient.getToken({}).then((token) => {
      localStorage.setItem("token", token.access_token);
      const url = localStorage.getItem("previous_url", token.access_token);
      if (url && url.length > 0 && !url.endsWith("/login")) {
        window.location.href = url;
      } else {
        window.location.href = "/";
      }
    });
  }

  render() {
    return <p>Redirection en cours...</p>;
  }
}

export default connect(undefined, { userLogin })(Login);
