import { Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import BuilIcon from "@material-ui/icons/Build";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";
import React from "react";
import { GET_LIST, withDataProvider } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TravelPolicyForm, {
  getEntityName,
  rulesHotel,
  rulesCarRental,
  rulesRail,
  rulesFlight,
} from "../Form/TravelPolicyForm";

const style = {
  table: {
    minWidth: 650,
  },
  bold: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
};

export function getPolicy(type) {
  if (!type) return "Tous";
  const policies = type.map((t, i) => {
    let result;
    let allow = "";
    switch (t.criteria.mode) {
    case "hotel":
      result = rulesHotel.find((r) => r.value === t.criteria.category);
      break;
    case "car_rental":
      result = rulesCarRental.find((c) => c.value === t.criteria.category);
      break;
    case "rail":
      result = rulesRail.find((r) => r.value === t.criteria.category);
      break;
    case "flight":
      result = rulesFlight.find((f) => f.value === t.criteria.category);
      break;
    default:
      break;
    }
    allow = result ? result.name : t.criteria.category;
    if (allow.includes("max")) {
      allow += ` de ${t.value}€`;
    } else if (t.criteria.category.includes("allowed")) {
      if (t.value === false) {
        allow = (
          <Tooltip title="Interdit">
            <CancelIcon color="error" />
          </Tooltip>
        );
      } else {
        allow = (
          <Tooltip title="Autorisé">
            <CheckCircleIcon color="primary" />
          </Tooltip>
        );
      }
    } else if (t.criteria.category.includes("population")) {
      switch (t.criteria.population_rule.code) {
      case "cat200":
        allow = `Max ville avec plus de 200k habitants : ${t.value}€ `;
        break;
      case "catGP":
        allow = `Max Grand Paris : ${t.value}€ `;
        break;
      case "catParis":
        allow = `Max Paris : ${t.value}€ `;
        break;
      case "catIDF":
        allow = `Max IDF excluant Paris et GP : ${t.value}€ `;
        break;
      case "catDefault":
        allow = `Max autres villes : ${t.value}€ `;
        break;
      default:
        break;
      }
    } else if (t.criteria.category === "geo") {
      allow = `Max ${t.criteria.geo_rule.area} : ${t.value}€`;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {" "}
        {result?.name ?? t.criteria.category}: {allow}{" "}
      </div>
    );
  });

  return policies;
}

class EditTravelPolicyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      company: {},
      isOpen: false,
      isNew: false,
      isEdit: false,
      rules: null,
    };
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false, isNew: false, isEdit: false });
  };

  handleDialogCloseNew = () => {
    this.setState({ isNew: false, isOpen: true });
  };

  handleDialogCloseEdit = () => {
    this.setState({ isEdit: false, isOpen: true });
  };

  handleClickOpen = () => {
    this.setState({ isOpen: false, isNew: true });
  };

  handleClick = () => {
    this.fetchCompay();
    this.fetchServices();
    this.setState({ isOpen: true });
  };

  handleEdit = (rules) => {
    this.setState({
      rules,
    });
    this.setState({ isOpen: false, isEdit: true });
  };

  updatePolicy = (data) => {
    this.props.dataProvider(
      "UPDATE_PVE",
      "",
      { data },
      {
        onSuccess: {
          notification: {
            body: "Politique de voyage mise à jour",
            level: "info",
          },
          refresh: true,
          redirectTo: "/companies",
          unselectAll: true,
        },
        onError: {
          notification: {
            body: "Erreur lors de la mise à jour de la politique de voyage",
            level: "warning",
          },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    this.setState({ isOpen: true, isEdit: false }, this.fetchCompay);
  };

  createPolicy = (data) => {
    this.props.dataProvider(
      "CREATE_PVE",
      "",
      { data },
      {
        onSuccess: {
          notification: { body: "Politique de voyage créée", level: "info" },
          refresh: true,
          redirectTo: "/companies",
          unselectAll: true,
        },
        onError: {
          notification: {
            body: "Erreur lors de la création de la politique de voyage",
            level: "warning",
          },
          refresh: true,
          unselectAll: true,
        },
      }
    );
    this.setState({ isOpen: true, isNew: false }, this.fetchCompay);
  };

  async fetchCompay() {
    const { dataProvider, record } = this.props;
    const { data: companies } = await dataProvider(GET_LIST, "companies");
    const company = companies.filter((company) => company.uid === record.id);
    this.setState({
      company: company[0],
    });
  }

  async fetchServices() {
    const { dataProvider, record } = this.props;
    const { data: services } = await dataProvider(GET_LIST, "services", {
      filter: { company_id: record.id },
      sort: { field: "uid", order: "DESC" },
      pagination: { page: 1, perPage: 1000 },
    });
    this.setState({
      services,
    });
  }

  render() {
    const { record } = this.props;
    const { company } = this.state;
    let rule;
    return (
      <>
        <Button
          key={`button-${record.id}`}
          color="primary"
          onClick={this.handleClick}
          size="small"
        >
          <BuilIcon className="smallIcon" />{" "}
          <span style={{ paddingLeft: "0.5em" }}>PVE</span>
        </Button>
        <Dialog
          key={`dialog-${record.id}`}
          fullScreen
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                Politiques de voyage applicables dans l'entreprise{" "}
                {company.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ margin: "10px" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Table style={style.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={style.bold}>Entité</TableCell>
                    <TableCell style={style.bold}>Service</TableCell>
                    <TableCell style={style.bold}>
                      Niveau hiérarchique
                    </TableCell>
                    <TableCell style={style.bold}>
                      Catégorie de voyageur
                    </TableCell>
                    <TableCell style={style.bold}>
                      Règles d'approbation
                    </TableCell>
                    <TableCell style={style.bold}>Train</TableCell>
                    <TableCell style={style.bold}>Avion</TableCell>
                    <TableCell style={style.bold}>Hotel</TableCell>
                    <TableCell style={style.bold}>Voiture</TableCell>
                    <TableCell style={style.bold}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {company.travel_policies &&
                    company.travel_policies.map((rules, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={style.bold}
                          component="th"
                          scope="row"
                        >
                          {rules.entity_id
                            ? getEntityName(company.entities, rules.entity_id)
                            : "toutes"}
                        </TableCell>
                        <TableCell
                          style={style.bold}
                          component="th"
                          scope="row"
                        >
                          {rules.user_service && rules.user_service === "*"
                            ? "tous"
                            : rules.user_service}
                        </TableCell>
                        <TableCell style={style.bold}>
                          {!rules.user_level ? "tous" : rules.user_level}
                        </TableCell>
                        <TableCell style={style.bold}>
                          {!rules.user_category ? "tous" : rules.user_category}
                        </TableCell>
                        <TableCell style={style.bold} align="right">
                          {rules.approval_requirement &&
                            rules.approval_requirement.join(", ")}
                        </TableCell>
                        <TableCell align="right">
                          {rules.travel_rules &&
                            getPolicy(rules.travel_rules.rail)}
                        </TableCell>
                        <TableCell align="right">
                          {rules.travel_rules &&
                            getPolicy(rules.travel_rules.flight)}
                        </TableCell>
                        <TableCell align="right">
                          {rules.travel_rules &&
                            getPolicy(rules.travel_rules.hotel)}
                        </TableCell>
                        <TableCell align="right">
                          {rules.travel_rules &&
                            getPolicy(rules.travel_rules.car_rental)}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => this.handleEdit(rules)}
                          >
                            Modifier
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </DialogContentText>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleClickOpen}
              style={{ margin: "10px" }}
            >
              Ajouter une nouvelle PVE
            </Button>
            <p>{this.state.currentUser}</p>
          </DialogContent>
        </Dialog>
        <Dialog
          key={`dialog-3-${record.id}`}
          fullScreen
          open={this.state.isNew}
          onClose={this.handleDialogCloseNew}
          aria-labelledby="form-dialog-title"
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                Création d'une politique de voyage applicable à votre entreprise{" "}
                {company.name} :
              </Typography>
            </Toolbar>
          </AppBar>
          <TravelPolicyForm
            company={company.uid}
            entities={company.entities}
            services={this.state.services}
            handleFormClose={this.handleDialogCloseNew}
            upsertPolicy={this.createPolicy}
          />
        </Dialog>
        <Dialog
          key={`dialog-2-${record.id}`}
          fullScreen
          open={this.state.isEdit}
          onClose={this.handleDialogCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                Edition d'une politique de voyage applicable à votre entreprise{" "}
                {company.name} :
              </Typography>
            </Toolbar>
          </AppBar>
          <TravelPolicyForm
            company={company.uid}
            entities={company.entities}
            services={this.state.services}
            isEdit={this.state.rules}
            handleFormClose={this.handleDialogCloseEdit}
            upsertPolicy={this.updatePolicy}
          />
        </Dialog>
      </>
    );
  }
}

EditTravelPolicyButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

export default withDataProvider(EditTravelPolicyButton);
