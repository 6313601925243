import React from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

const styles = {
  listItem: {
    height: "48px",
  },
  ListItemIcon: {
    position: "absolute",
    left: "16px",
  },
  listItemText: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.54)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    position: "absolute",
    paddingLeft: "2.2rem",
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
};

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
}) => (
  <>
    <ListItem dense button onClick={handleToggle} style={styles.listItem}>
      <ListItemIcon style={styles.ListItemIcon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <ListItemText
        disableTypography
        inset
        primary={isOpen ? name : ""}
        secondary={isOpen ? "" : name}
        style={styles.listItemText}
      />
    </ListItem>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List
        dense
        component="div"
        disablePadding
        style={sidebarIsOpen ? styles.sidebarIsOpen : styles.sidebarIsClosed}
      >
        {children}
      </List>
      <Divider />
    </Collapse>
  </>
);

export default SubMenu;
