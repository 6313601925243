import React from "react";
import {
  DisabledInput,
  TopToolbar,
  Toolbar,
  ListButton,
  SaveButton,
  AutocompleteInput,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  ShowButton,
  Show,
  SimpleShowLayout,
  NumberField,
} from "react-admin";
import { Typography } from "@material-ui/core";
import {
  inputsGroupStyle,
  inputsGroupSpaceStyle,
  titleSpaceStyle,
} from "./user-form-styles";
import {
  TheTreepDateField,
  DateFormatter,
  DateParser,
  DateUserFormatter,
} from "../components/Fields/TheTreepDateField";
import DateTextInput from "../components/Input/DateTextInput";

const usersGender = [
  { id: "MR", name: "Monsieur" },
  { id: "MS", name: "Madame" },
];

const countries = [
  { id: "ad", name: "Andorre" },
  { id: "ae", name: "Émirats Arabes Unis" },
  { id: "af", name: "Afghanistan" },
  { id: "ag", name: "Antigua-Et-Barbuda" },
  { id: "ai", name: "Anguilla" },
  { id: "al", name: "Albanie" },
  { id: "am", name: "Arménie" },
  { id: "ao", name: "Angola" },
  { id: "ap", name: "Région Asie/Pacifique" },
  { id: "aq", name: "Antarctique" },
  { id: "ar", name: "Argentine" },
  { id: "as", name: "Samoa Américaines" },
  { id: "at", name: "Autriche" },
  { id: "au", name: "Australie" },
  { id: "aw", name: "Aruba" },
  { id: "ax", name: "Îles Åland" },
  { id: "az", name: "Azerbaïdjan" },
  { id: "ba", name: "Bosnie-Herzégovine" },
  { id: "bb", name: "Barbad" },
  { id: "bd", name: "Bangladesh" },
  { id: "be", name: "Belgique" },
  { id: "bf", name: "Burkina Faso" },
  { id: "bg", name: "Bulgarie" },
  { id: "bh", name: "Bahreïn" },
  { id: "bi", name: "Burundi" },
  { id: "bj", name: "Bénin" },
  { id: "bl", name: "Saint-Barthélemy" },
  { id: "bm", name: "Bermudes" },
  { id: "bn", name: "Brunei Darussalam" },
  { id: "bo", name: "État Plurinational De Bolivie" },
  { id: "bq", name: "Bonaire, Saint-Eustache Et Saba" },
  { id: "br", name: "Brésil" },
  { id: "bs", name: "Bahamas" },
  { id: "bt", name: "Bhoutan" },
  { id: "bv", name: "Île Bouvet" },
  { id: "bw", name: "Botswana" },
  { id: "by", name: "Biélorussie" },
  { id: "bz", name: "Belize" },
  { id: "ca", name: "Canada" },
  { id: "cc", name: "Îles Cocos" },
  { id: "cd", name: "République Démocratique Du Congo" },
  { id: "cf", name: "République Centrafricaine" },
  { id: "cg", name: "Congo" },
  { id: "ch", name: "Suisse" },
  { id: "ci", name: "Côte D'Ivoire" },
  { id: "ck", name: "Îles Cook" },
  { id: "cl", name: "Chili" },
  { id: "cm", name: "Cameroun" },
  { id: "cn", name: "Chine" },
  { id: "co", name: "Colombie" },
  { id: "cr", name: "Costa Rica" },
  { id: "cu", name: "Cuba" },
  { id: "cv", name: "Cap-Vert" },
  { id: "cw", name: "Curaçao" },
  { id: "cx", name: "Île Christmas" },
  { id: "cy", name: "Chypre" },
  { id: "cz", name: "République Tchèque" },
  { id: "de", name: "Allemagne" },
  { id: "dj", name: "Djibouti" },
  { id: "dk", name: "Denmark" },
  { id: "dm", name: "Dominique" },
  { id: "do", name: "République Dominicaine" },
  { id: "dz", name: "Algérie" },
  { id: "ec", name: "Équateur" },
  { id: "ee", name: "Estonie" },
  { id: "eg", name: "Égypte" },
  { id: "eh", name: "Sahara Occidental" },
  { id: "er", name: "Érythrée" },
  { id: "es", name: "Espagne" },
  { id: "et", name: "Éthiopie" },
  { id: "eu", name: "Europe" },
  { id: "fi", name: "Finlande" },
  { id: "fj", name: "Fidji" },
  { id: "fk", name: "Îles Malouines" },
  { id: "fm", name: "États Fédérés De Micronésie" },
  { id: "fo", name: "Îles Féroé" },
  { id: "fr", name: "France" },
  { id: "ga", name: "Gabon" },
  { id: "gb", name: "Royaume-Uni" },
  { id: "gd", name: "Grenade" },
  { id: "ge", name: "Géorgie" },
  { id: "gf", name: "Guyane" },
  { id: "gg", name: "Guernesey" },
  { id: "gh", name: "Ghana" },
  { id: "gi", name: "Gibraltar" },
  { id: "gl", name: "Groenland" },
  { id: "gm", name: "Gambie" },
  { id: "gn", name: "Guinée" },
  { id: "gp", name: "Guadeloupe" },
  { id: "gq", name: "Guinée Équatoriale" },
  { id: "gr", name: "Grèce" },
  { id: "gs", name: "Géorgie Du Sud-Et-Les Îles Sandwich Du Sud" },
  { id: "gt", name: "Guatemala" },
  { id: "gu", name: "Guam" },
  { id: "gw", name: "Guinée-Bissau" },
  { id: "gy", name: "Guyana" },
  { id: "hk", name: "Hong Kong" },
  { id: "hm", name: "Îles Heard-Et-MacDonald" },
  { id: "hn", name: "Honduras" },
  { id: "hr", name: "Croatie" },
  { id: "ht", name: "Haïti" },
  { id: "hu", name: "Hongrie" },
  { id: "id", name: "Indonésie" },
  { id: "ie", name: "Irlande" },
  { id: "il", name: "Israël" },
  { id: "im", name: "Île De Man" },
  { id: "in", name: "Inde" },
  { id: "io", name: "Territoire Britannique De L'océan Indien" },
  { id: "iq", name: "Irak" },
  { id: "ir", name: "République Islamique D'Iran" },
  { id: "is", name: "Islande" },
  { id: "it", name: "Italie" },
  { id: "je", name: "Jersey" },
  { id: "jm", name: "Jamaïque" },
  { id: "jo", name: "Jordanie" },
  { id: "jp", name: "Japon" },
  { id: "ke", name: "Kenya" },
  { id: "kg", name: "Kirghizistan" },
  { id: "kh", name: "Cambodge" },
  { id: "ki", name: "Kiribati" },
  { id: "km", name: "Comores" },
  { id: "kn", name: "Saint-Christophe-et-Niévès" },
  { id: "kp", name: "République Populaire Démocratique De Corée" },
  { id: "kr", name: "République De Corée" },
  { id: "kw", name: "Koweït" },
  { id: "ky", name: "Îles Caïmans" },
  { id: "kz", name: "Kazakhstan" },
  { id: "la", name: "République Démocratique Populaire Lao" },
  { id: "lb", name: "Liban" },
  { id: "lc", name: "Sainte-Lucie" },
  { id: "li", name: "Liechtenstein" },
  { id: "lk", name: "Sri Lanka" },
  { id: "lr", name: "Liberia" },
  { id: "ls", name: "Lesotho" },
  { id: "lt", name: "Lituanie" },
  { id: "lu", name: "Luxembourg" },
  { id: "lv", name: "Lettonie" },
  { id: "ly", name: "Libye" },
  { id: "ma", name: "Maroc" },
  { id: "mc", name: "Monaco" },
  { id: "md", name: "République De Moldavie" },
  { id: "me", name: "Monténégro" },
  { id: "mf", name: "Saint-Martin (Partie Française)" },
  { id: "mg", name: "Madagascar" },
  { id: "mh", name: "Îles Marshall" },
  { id: "mk", name: "Macédoine" },
  { id: "ml", name: "Mali" },
  { id: "mm", name: "Birmanie" },
  { id: "mn", name: "Mongolie" },
  { id: "mo", name: "Macao" },
  { id: "mp", name: "Îles Mariannes Du Nord" },
  { id: "mq", name: "Martinique" },
  { id: "mr", name: "Mauritanie" },
  { id: "ms", name: "Montserrat" },
  { id: "mt", name: "Malte" },
  { id: "mu", name: "Maurice" },
  { id: "mv", name: "Maldives" },
  { id: "mw", name: "Malawi" },
  { id: "mx", name: "Mexique" },
  { id: "my", name: "Malaisie" },
  { id: "mz", name: "Mozambique" },
  { id: "na", name: "Namibie" },
  { id: "nc", name: "Nouvelle-Calédonie" },
  { id: "ne", name: "Niger" },
  { id: "nf", name: "Île Norfolk" },
  { id: "ng", name: "Nigéria" },
  { id: "ni", name: "Nicaragua" },
  { id: "nl", name: "Pays-Bas" },
  { id: "no", name: "Norvège" },
  { id: "np", name: "Népal" },
  { id: "nr", name: "Nauru" },
  { id: "nu", name: "Niue" },
  { id: "nz", name: "Nouvelle-Zélande" },
  { id: "om", name: "Oman" },
  { id: "pa", name: "Panama" },
  { id: "pe", name: "Pérou" },
  { id: "pf", name: "Polynésie Française" },
  { id: "pg", name: "Papouasie-Nouvelle-Guinée" },
  { id: "ph", name: "Philippines" },
  { id: "pk", name: "Pakistan" },
  { id: "pl", name: "Pologne" },
  { id: "pm", name: "Saint-Pierre-Et-Miquelon" },
  { id: "pn", name: "Pitcairn" },
  { id: "pr", name: "Porto Rico" },
  { id: "ps", name: "Territoires Palestiniens Occupés" },
  { id: "pt", name: "Portugal" },
  { id: "pw", name: "Palaos" },
  { id: "py", name: "Paraguay" },
  { id: "qa", name: "Qatar" },
  { id: "re", name: "Réunion" },
  { id: "ro", name: "Roumanie" },
  { id: "rs", name: "Serbie" },
  { id: "ru", name: "Fédération De Russie" },
  { id: "rw", name: "Rwanda" },
  { id: "sa", name: "Arabie Saoudite" },
  { id: "sb", name: "Îles Salomon" },
  { id: "sc", name: "Seychelles" },
  { id: "sd", name: "Soudan" },
  { id: "se", name: "Suède" },
  { id: "sg", name: "Singapour" },
  { id: "sh", name: "Sainte-Hélène" },
  { id: "si", name: "Slovénie" },
  { id: "sj", name: "Svalbard Et Jan Mayen" },
  { id: "sk", name: "Slovaquie" },
  { id: "sl", name: "Sierra Leone" },
  { id: "sm", name: "Saint-Marin" },
  { id: "sn", name: "Sénégal" },
  { id: "so", name: "Somalie" },
  { id: "sr", name: "Suriname" },
  { id: "ss", name: "Soudan Du Sud" },
  { id: "st", name: "Sao Tomé-Et-Principe" },
  { id: "sv", name: "République Du Salvador" },
  { id: "sx", name: "Saint-Martin (Partie Néerlandaise)" },
  { id: "sy", name: "République Arabe Syrienne" },
  { id: "sz", name: "Swaziland" },
  { id: "tc", name: "Îles Turks-Et-Caïcos" },
  { id: "td", name: "Tchad" },
  { id: "tf", name: "Terres Australes Françaises" },
  { id: "tg", name: "Togo" },
  { id: "th", name: "Thaïlande" },
  { id: "tj", name: "Tadjikistan" },
  { id: "tk", name: "Tokelau" },
  { id: "tl", name: "Timor-Leste" },
  { id: "tm", name: "Turkménistan" },
  { id: "tn", name: "Tunisie" },
  { id: "to", name: "Tonga" },
  { id: "tr", name: "Turquie" },
  { id: "tt", name: "Trinité-Et-Tobago" },
  { id: "tv", name: "Tuvalu" },
  { id: "tw", name: "Taïwan" },
  { id: "tz", name: "République-Unie De Tanzanie" },
  { id: "ua", name: "Ukraine" },
  { id: "ug", name: "Ouganda" },
  { id: "um", name: "Îles Mineures Éloignées Des États-Unis" },
  { id: "us", name: "États-Unis" },
  { id: "uy", name: "Uruguay" },
  { id: "uz", name: "Ouzbékistan" },
  { id: "va", name: "Saint-Siège (État De La Cité Du Vatican)" },
  { id: "vc", name: "Saint-Vincent-Et-Les Grenadines" },
  { id: "ve", name: "Venezuela" },
  { id: "vg", name: "Îles Vierges Britanniques" },
  { id: "vi", name: "Îles Vierges Des États-Unis" },
  { id: "vn", name: "Viet Nam" },
  { id: "vu", name: "Vanuatu" },
  { id: "wf", name: "Wallis Et Futuna" },
  { id: "ws", name: "Samoa" },
  { id: "ye", name: "Yémen" },
  { id: "yt", name: "Mayotte" },
  { id: "za", name: "Afrique Du Sud" },
  { id: "zm", name: "Zambie" },
  { id: "zw", name: "Zimbabwe" },
];

const UserDriverLicenseCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      label="Save"
      redirect={`/users/${props.record.user_id}/show/3`} // "/3" redirect to document tab
      submitOnEnter
    />
  </Toolbar>
);

const UserDriverLicenseActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton
      basePath={data}
      label={data === "/users" ? "Retour à la liste" : "Retour au profil"}
    />
  </TopToolbar>
);

export const UserDriverLicenseCreate = (props) => (
  <Create
    {...props}
    actions={
      <UserDriverLicenseActions
        data={
          props.location.state
            ? `/users/${props.location.state.record.user_id}/show`
            : "/users"
        }
      />
    }
  >
    <SimpleForm toolbar={<UserDriverLicenseCreateToolbar />} redirect="list">
      {props.location.state && (
        <div style={titleSpaceStyle}>
          <Typography fullWidth variant="h5">
            {`Nouveau permis de conduire pour ${props.location.state.record.firstname} ${props.location.state.record.lastname} #${props.location.state.record.user_id}`}
          </Typography>
        </div>
      )}
      <div style={inputsGroupStyle}>
        <SelectInput
          source="civility"
          choices={usersGender}
          validate={[required()]}
        />
        <div style={inputsGroupSpaceStyle} />
        <TextInput
          disabled
          fullWidth
          source="firstname"
          validate={[required()]}
        />
        <div style={inputsGroupSpaceStyle} />
        <TextInput
          disabled
          fullWidth
          source="lastname"
          validate={[required()]}
        />
      </div>
      <TextInput
        fullWidth
        source="number"
        parse={(number) =>
          number == null ? null : number.replace(/\s/g, "").replace(/\./g, "")
        }
        validate={[required()]}
      />
      <div style={inputsGroupStyle}>
        <DateTextInput
          fullWidth
          source="effective_date"
          format={DateUserFormatter}
          label="Date de délivrance (JJ/MM/AAAA)"
        />
        <div style={inputsGroupSpaceStyle} />
        <SelectInput
          fullWidth
          source="nationality"
          choices={countries.sort((a, b) => a.name.localeCompare(b.name))}
          validate={[required()]}
        />
      </div>
    </SimpleForm>
  </Create>
);

export const UserDriverLicenseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="id" sortable={false} />
      <TextField source="user_id" sortable={false} />
      <TextField source="type" sortable={false} />
      <TextField source="document_name" sortable={false} />
      <TextField source="civility" sortable={false} />
      <TextField source="firstname" sortable={false} />
      <TextField source="lastname" sortable={false} />
      <TextField source="number" sortable={false} />
      <TheTreepDateField source="effective_date" sortable={false} />
      <TextField source="nationality" sortable={false} />
    </SimpleShowLayout>
  </Show>
);
