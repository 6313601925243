import React from "react";

import TextField from "@material-ui/core/TextField";
import AutocompleteItems from "./AutoCompleteItems";
import { apiUrl, apiHeaders } from "../../../constants";
import "./autocompletion.css";

export default class LocationInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.location || "",
      places: [],
      searching: false,
      isFocused: false,
    };

    this.request = null;
  }

  componentDidUpdate(prevProps) {
    // If a location is passed down by props from the parent, we update the search field
    if (prevProps.location !== this.props.location) {
      this.setState({
        search: this.props.location || "",
      });
    }
  }

  handleChange = (e) => {
    const {
      input: { onChange },
    } = this.props;
    const val = e.target.value;

    if (this.props.setTouched) {
      this.props.setTouched();
    }

    this.setState({
      search: val,
    });

    // Clears the location if the user clears the input
    if (!val.length) {
      // Calls the redux method with an empty object to reset the departing place
      onChange({});

      if (this.props.checkForm) {
        this.props.checkForm();
      }

      return;
    }

    if (this.request) this.cancelRequest();

    this.getPlaces(val);
  };

  getPlaces = (q = this.state.search) => {
    this.setState({
      searching: true,
    });
    this.request = null;

    const request = new Request(`${apiUrl}/places?q=${q}`, {
      method: "GET",
      headers: new Headers(apiHeaders),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        const obj = response.json();
        return obj;
      })
      .then((data) => {
        if (data.result != null) {
          this.setState({
            places: data.result,
            searching: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          places: [],
          searching: false,
        });
      });
  };

  cancelRequest = () => {
    this.request = null;
  };

  handleClick = (place) => {
    const {
      input: { onChange },
    } = this.props;
    onChange(place);

    if (this.props.checkForm) {
      this.props.checkForm();
    }

    this.setState({
      search: place.description,
    });
  };

  handleFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  handleBlur = () => {
    this.setState({
      isFocused: false,
    });
  };

  hasErrored = () =>
    this.props.touched && !this.props.isFocused && this.props.error;

  render() {
    let classes = "common-input";
    if (this.state.isFocused) classes += " on-focus";
    if (this.hasErrored()) classes += " on-error";

    return (
      <>
        <div className="form-group">
          <label className={classes}>
            <TextField
              type="text"
              label={this.props.label}
              placeholder={this.props.placeholder}
              onChange={this.handleChange}
              value={this.state.search}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          </label>
          <AutocompleteItems
            closed={
              !this.state.isFocused ||
              !this.state.search.length ||
              this.state.search === this.props.location
            }
            onClick={this.handleClick}
            loading={this.state.searching}
            items={this.state.places}
          />
          {this.hasErrored() && (
            <div className="search-form-error-wrapper">{this.props.error}</div>
          )}
        </div>
      </>
    );
  }
}
