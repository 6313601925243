import React from "react";
import {
  TopToolbar,
  ExportButton,
  FunctionField,
  Pagination,
  ArrayField,
  List,
  Filter,
  Datagrid,
  ReferenceField,
  TextField,
  SelectInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  SelectField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import LinkIcon from "@material-ui/icons/Link";
import { TheTreepDateField } from "../components/Fields/TheTreepDateField";
import CurrencyField from "../components/Fields/CurrencyField";
import CreateStatementButton from "../components/Buttons/CreateStatementButton";
import SetStatementStatusButton from "../components/Buttons/SetStatementStatusButton";
import { renderCustomerName, renderCustomerNameWithType } from "./customers";

export const statementsTypes = [
  { id: "billing", name: "Relevé à payer" },
  { id: "detailed", name: "Détaillé" },
  { id: "sumup", name: "Relevé résumé" },
  { id: "accounting", name: "Relevé comptable" },
];

const statementsStatuses = [
  { id: "paid", name: "Payé", backgroundColor: "#C8E6C9" },
  {
    id: "pending_payment",
    name: "En attente de paiement",
    backgroundColor: "#ffcc80",
  },
  { id: "draft", name: "Brouillon", backgroundColor: "white" },
];

const StatementActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;
  return (
    <TopToolbar>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateStatementButton {...props} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </TopToolbar>
  );
};

const StatementPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const StatementFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="customer"
      reference="customers"
      filter={{ to_bill: true }}
      filterToQuery={(q) => ({ name: q })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText={renderCustomerNameWithType} />
    </ReferenceInput>
    <SelectInput source="status" alwaysOn choices={statementsStatuses} />
  </Filter>
);

export const StatementList = ({ permissions, ...props }) => (
  <List
    {...props}
    title="Liste des relevés"
    filters={<StatementFilter />}
    pagination={<StatementPagination />}
    sort={{ field: "id", order: "DESC" }}
    actions={<StatementActions />}
  >
    <Datagrid
      rowclick="show"
      rowStyle={(statement) => {
        for (let i = 0; i < statementsStatuses.length; i++) {
          const opStat = statementsStatuses[i];
          if (statement.status === opStat.id) {
            return { backgroundColor: opStat.backgroundColor };
          }
        }
      }}
    >
      <TheTreepDateField source="creation_date" showTime />
      <ReferenceField
        source="customer"
        reference="customers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <FunctionField label="Nom" render={renderCustomerName} />
      </ReferenceField>
      <TextField source="customer_info.name" />
      <SelectField source="type" choices={statementsTypes} />
      <SelectField source="status" choices={statementsStatuses} />
      <TheTreepDateField source="start_period" showTime />
      <TheTreepDateField source="end_period" showTime />
      <TheTreepDateField source="payment_date" showTime />
      <TheTreepDateField source="sending_date" showTime />
      <CurrencyField source="total_incl_taxes" />
      <CurrencyField source="total_excl_taxes" />
      <CurrencyField source="total_vat" />
      <ShowButton label="" />
      <SetStatementStatusButton />
    </Datagrid>
  </List>
);

export const StatementShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TheTreepDateField source="creation_date" showTime />
      <ReferenceField
        source="customer"
        reference="customers"
        link="show"
        allowEmpty
        sortable={false}
      >
        <FunctionField label="Nom" render={renderCustomerNameWithType} />
      </ReferenceField>
      <TextField source="customer_info.name" />
      <TextField source="type" />
      <TextField source="status" />
      <TheTreepDateField source="start_period" showTime />
      <TheTreepDateField source="end_period" showTime />
      <TheTreepDateField source="payment_date" showTime />
      <TheTreepDateField source="sending_date" showTime />
      <CurrencyField source="total_incl_taxes" />
      <CurrencyField source="total_excl_taxes" />
      <CurrencyField source="total_vat" />
      <ArrayField source="analytics">
        <Datagrid>
          <TextField source="key" />
          <TextField source="label" />
          <TextField source="value" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="invoices">
        <Datagrid>
          <ReferenceField
            label="Facture"
            source="id"
            reference="invoices"
            link="show"
          >
            <TextField source="number" />
          </ReferenceField>
          <TextField source="vosfactures_id" />
          <ReferenceField
            label="Client"
            source="customer"
            reference="customers"
            link="show"
            allowEmpty
            sortable={false}
          >
            <FunctionField label="Nom" render={renderCustomerName} />
          </ReferenceField>
          <TextField source="customer_info.name" />
          <TextField source="customer_info.recipients" />
          <TextField source="status" />
          <CurrencyField source="total_incl_taxes" />
          <CurrencyField source="total_excl_taxes" />
          <CurrencyField source="total_vat" />
          <CurrencyField source="subtotal_op" />
          <CurrencyField source="subtotal_fee" />
          <TheTreepDateField source="billing_date" showTime />
          <FunctionField
            label="Facture"
            render={(record) =>
              record.url && (
                <a href={record.url} target="_blank" rel="noopener noreferrer" aria-label="Facture">
                  <LinkIcon />
                </a>
              )
            }
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
