/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
// in myRestProvider.js
import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE_MANY,
} from "react-admin";
import { apiUrl, apiHeaders, billingURL } from "./constants";
import {
  DateParser,
  DateClosureParser,
} from "./components/Fields/TheTreepDateField";

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (type, resource, params) => {
  let url = "";
  const token = localStorage.getItem("token");
  const headers = apiHeaders;
  headers.Authorization = token;
  const options = {
    headers: new Headers(headers),
  };

  // Catch here custom rest functions (with special types)

  switch (type) {
  case "MANUAL_RECONCILIATION":
    url = `${billingURL}/reconciliation`;
    options.method = "PUT";
    options.body = JSON.stringify({ forced_reconciliation: params });
    return fetch(url, options).then((res) => ({ data: null }));
  case "AUTO_RECONCILIATION":
    url = `${billingURL}/reconciliation`;
    options.method = "PUT";
    return fetch(url, options).then((res) => ({ data: null }));
  case "SET_STATEMENT_STATUS":
    url = `${billingURL}/statements/${params.id}/status?status=${params.status}`;
    options.method = "PUT";
    return fetch(url, options).then((res) => ({ data: null }));
  case "SET_INVOICES_STATUS":
    // not supported directly, do this one by one for each invoice
    options.method = "PUT";
    return Promise.all(
      params.ids.map((id) =>
        fetch(
            `${billingURL}/invoices/${id}/status?status=${params.status}`,
            options
        )
      )
    )
      .then((data) => Promise.all(data.map((res) => res.json())))
      .then((responses) => ({
        data: null,
      }));
  case "CREATE_STATEMENT":
    url = `${billingURL}/statements`;
    options.method = "PUT";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => ({ data: { url } }));
  case "PASSWORD_RESET":
    url = `${apiUrl}/user/forgot`;
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "ONBOARDING":
    url = `${apiUrl}/user/onboarding`;
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "SYNC":
    url = `${apiUrl}/user/sync/`;
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "REFRESH_TICKETS":
    url = `${apiUrl}/ticket/refresh`;
    options.method = "PUT";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "APPROVE_TICKETS":
    url = `${apiUrl}/approval/answer`;
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "STATUS_ISSUED_TRAVELS":
    url = `${apiUrl}/travel/status`;
    options.method = "PUT";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "APPROVES_SERVICE":
    url = `${apiUrl}/user/approves_service?id=${params.id}`;
    options.method = "POST";
    options.body = JSON.stringify(params.services);
    return fetch(url, options).then((res) => ({ data: null }));
  case "USERS_ENTITIES":
    url = `${apiUrl}/user/entities`;
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options).then((res) => ({ data: null }));
  case "SET_APPROVER":
    url = `${apiUrl}/user/${params.approverID}/approvees`;
    options.method = "PATCH";
    options.body = JSON.stringify({ userIDs: params.userIDs });
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "INCREMENT_TRIP":
    url = `${apiUrl}/subsidy/${params.id}/increment`;
    options.method = "PUT";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "PNR_DATA":
    url = `${apiUrl}/pnr/${params.pnrRef}`;
    options.method = "GET";
    return fetch(url, options)
      .then((response) => response.json())
      .then((response) => ({ data: response.result }));
  case "PAO_DATA":
    url = `${apiUrl}/ticket/${params.id}/info/sncf`;
    options.method = "GET";
    return fetch(url, options)
      .then((response) => response.json())
      .then((response) => ({ data: response.result }));
  case "DUFFEL_DATA":
    url = `${apiUrl}/ticket/${params.id}/info/duffel`;
    options.method = "GET";
    return fetch(url, options)
      .then((response) => response.json())
      .then((response) => ({ data: response.result }));
  case "RAILEUROPE_DATA":
    url = `${apiUrl}/ticket/${params.id}/info/rail-europe`;
    options.method = "GET";
    return fetch(url, options)
      .then((response) => response.json())
      .then((response) => ({ data: response.result }));
  case "DELETE_USER":
    url = `${apiUrl}/user/?id=${params.id}`;
    options.method = "DELETE";
    options.body = JSON.stringify(params.data);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "DELETE_USER_DATA":
    url = `${apiUrl}/user/data?id=${params.id}`;
    options.method = "DELETE";
    options.body = JSON.stringify(params.data);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "UPDATE_PVE":
    url = `${apiUrl}/company/travelpolicy`;
    options.method = "PUT";
    options.body = JSON.stringify(params.data);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "CREATE_PVE":
    url = `${apiUrl}/company/travelpolicy`;
    options.method = "POST";
    options.body = JSON.stringify(params.data);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "UPLOAD_SUBSIDIES":
    url = `${apiUrl}/subsidy/upload`;
    if (params.file != null) {
      const formData = new FormData();
      formData.append("file", params.file);
      options.body = formData;
      delete headers["Content-Type"]; // Will be set by the browser...
      options.headers = new Headers(headers);
    }
    options.method = "POST";
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data }));
  case "CANCEL_TICKET":
    url = `${apiUrl}/ticket/cancel`;
    options.method = "POST";
    options.body = JSON.stringify(params);
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ({ data: null }));
  case "CREATE_CUSTOMER_CONFIG":
    url = `${apiUrl}${params.url}`;
    options.method = "POST";
    return fetch(url, options)
      .then((res) => parseJSONCatchErrors(res))
      .then((data) => ( data ));
  default:
    // continue
  }

  switch (resource) {
  // billing resources
  case "operations":
    switch (type) {
    case CREATE:
      url = `${billingURL}/operations`;
      options.method = "POST";
      options.body = JSON.stringify({ operations: [params.data] });
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            if (
              response.operations != null &&
                  response.operations.length > 0
            ) {
              return { data: response.operations[0] };
            }
            return { data: response.operation };
          }
          return { data: null };
        });

    case UPDATE:
      url = `${billingURL}/operations/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify({ operation: params.data });
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.operation };
          }
          return { data: null };
        });
    case DELETE:
      url = `${billingURL}/operations/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${billingURL}/operations?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.operations != null) {
            data = response.operations;
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${billingURL}/operations/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.operation };
          }
          return { data: null };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${billingURL}/operations/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((response) => response.operation),
        }));
    case UPDATE_MANY:
      // UPDATE_MANY not supported directly, use several requests to SetStatus
      if (params.data.status == null) {
        console.log("params", params);
        return Promise.reject(
          new Error("unsupported params for UPDATE_MANY operations")
        );
      }
      options.method = "PUT";
      return Promise.all(
        params.ids.map((id) =>
          fetch(
                        `${billingURL}/operations/${id}/status?status=${params.data.status}`,
                        options
          )
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({ data: responses }));
    default:
      console.log("unknown type", type);
    }
    break;
  case "customers":
    switch (type) {
    case CREATE: 
      url = `${billingURL}/customers`;
      options.method = "POST";
      options.body = JSON.stringify({ customer: params.data });
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            if (
              response.customers != null &&
                          response.customers.length > 0
            ) {
              return { data: response.customers[0] };
            }
            return { data: response.customer };
          }
          return { data: null };
        });
    
    case UPDATE:
      url = `${billingURL}/customers/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify({ customer: params.data });
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.customer };
          }
          return { data: null };
        });
    case DELETE:
      url = `${billingURL}/customers/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${billingURL}/customers?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.customers != null) {
            data = response.customers;
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${billingURL}/customers/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.customer };
          }
          return { data: null };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${billingURL}/customers/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((response) => response.customer),
        }));
    case UPDATE_MANY:
      // UPDATE_MANY not supported directly, use several requests to SetStatus
      if (params.data.status == null) {
        console.log("params", params);
        return Promise.reject(
          new Error("unsupported params for UPDATE_MANY customers")
        );
      }
      options.method = "PUT";
      return Promise.all(
        params.ids.map((id) =>
          fetch(
                        `${billingURL}/customers/${id}/status?status=${params.data.status}`,
                        options
          )
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({ data: responses }));
    default:
      console.log("unknown type", type);
    }
    break;
  case "agencies":
    switch (type) {
    case "LOCK":
      url = `${apiUrl}/agency/${params.id}/readonly`;
      options.method = "POST";
      return fetch(url, options).then((res) => res.json()).then((response) => {
        if (response != null) {
          return {
            data: {
              ...response.result,
            },
          };
        }
        return { data: [] };
      });
    case "UNLOCK":
      url = `${apiUrl}/agency/${params.id}/readonly`;
      options.method = "DELETE";
      return fetch(url, options).then((res) => res.json()).then((response) => {
        if (response != null) {
          return {
            data: {
              ...response.result,
            },
          };
        }
        return { data: [] };
      });

    case "CAR_GROUPS":
      url = `${apiUrl}/agency/carGroups`;
      options.method = "GET";
      return fetch(url, options)
        .then((response) => response.json())
        .then((response) => ({ data: response }));

    case CREATE:
      url = `${apiUrl}/agency/`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.result };
          }
          return { data: null };
        });
    case UPDATE:
      url = `${apiUrl}/agency/${params.id}`;
      options.method = "PUT";
      params.data = {
        ...params.data,
        exceptionalClosures: params.data.exceptionalClosures
          ? params.data.exceptionalClosures.map((value) =>
            DateClosureParser(value)
          )
          : null,
        openingHours: {
          ...params.data.openingHours.reduce(
            (obj, item) => ({
              ...obj,
              [item.weekDay]: {
                openingAt: item.openingAt,
                closingAt: item.closingAt,
              },
            }),
            {}
          ),
        },
      };
      options.body = JSON.stringify(params.data);
      return fetch(url, options)
        .then((res) => {
          if (!res.ok) {
            return res.json().then((response) => {
              throw new Error(response.message);
            });
          }
          return res.json();
        })
        .then((response) => {
          if (response != null) {
            return { data: response.result };
          }
          return { data: null };
        });
    case DELETE:
      url = `${apiUrl}/agency/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/agency/?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.result != null) {
            data = response.result;
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${apiUrl}/agency/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return {
              data: {
                ...response.result,
                openingHours: response.result.openingHours
                  ? Object.keys(response.result.openingHours).map(
                    (key) => ({
                      ...response.result.openingHours[key],
                      weekDay: key,
                    })
                  )
                  : [],
              },
            };
          }
          return { data: [] };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) => fetch(`${apiUrl}/agency/${id}`, options))
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((response) => response.result),
        }));
    default:
      console.log("unknown type", type);
    }
    break;
  case "supplies":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${billingURL}/supplies?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.supplies != null) {
            data = response.supplies;
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${billingURL}/supplies/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.supply };
          }
          return { data: null };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${billingURL}/supplies/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((response) => response.supply),
        }));
    default:
      console.log("unknown type", type);
    }
    break;
  case "invoices":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${billingURL}/invoices?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.invoices != null) {
            data = response.invoices;
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${billingURL}/invoices/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.invoice };
          }
          return { data: null };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${billingURL}/invoices/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((response) => response.invoice),
        }));
    default:
      console.log("unknown type", type);
    }
    break;
  case "statements":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${billingURL}/statements?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.statements != null) {
            data = response.statements;
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${billingURL}/statements/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: response.statement };
          }
          return { data: null };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${billingURL}/statements/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((response) => response.statement),
        }));
    default:
      console.log("unknown type", type);
    }
    break;
  case "suppliers":
    switch (type) {
    case CREATE:
      url = `${billingURL}/suppliers`;
      options.method = "POST";
      options.body = JSON.stringify({ supplier: params.data });
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            const { supplier } = response;
            supplier.id = supplier.name;
            return { data: supplier };
          }
          return { data: null };
        });

    case UPDATE:
      url = `${billingURL}/suppliers/${params.id}`;
      options.method = "PUT";
      delete params.data.id;
      options.body = JSON.stringify({ supplier: params.data });
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            const { supplier } = response;
            supplier.id = supplier.name;
            return { data: supplier };
          }
          return { data: null };
        });
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${billingURL}/suppliers?${stringify(query)}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          let total = 0;
          let data = [];
          if (response.suppliers != null) {
            data = response.suppliers.map((supplier) => {
              supplier.id = supplier.name;
              return supplier;
            });
          }
          if (response.total != null) {
            total = response.total;
          } else {
            total = data.length;
          }
          return { data, total };
        });
    case GET_ONE:
      url = `${billingURL}/suppliers/${params.id}`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            const { supplier } = response;
            supplier.id = supplier.name;
            return { data: supplier };
          }
          return { data: null };
        });
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${billingURL}/suppliers/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => ({
          data: responses.map((supplier) => {
            supplier.id = supplier.name;
            return supplier;
          }),
        }));
    default:
      console.log("unknown type", type);
    }
    break;
    // API resources
  case "tickets":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        full: true,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/tickets/?${stringify(query)}`;
      break;
    case GET_MANY:
      url = `${apiUrl}/tickets/?full=true`;
      break;
    case GET_ONE:
      url = `${apiUrl}/tickets/?uid=${params.id}&full=true`;
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "users":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
        full: "true",
      };
      query["disable-hiding"] = "true";
      url = `${apiUrl}/users/?${stringify(query)}`;
      break;
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(
                `${apiUrl}/users/?full=true&disable-hiding=true&uid=${id}`,
                options
          )
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => {
          const datas = {
            data: responses
              .map((response) => {
                if (
                  response &&
                      response.result &&
                      response.result.length > 0
                ) {
                  return {
                    ...response.result[0],
                    id: response.result[0].uid,
                  };
                }
                return null;
              })
              .filter((elem) => elem != null),
          };
          return datas;
        });
    case GET_ONE:
      url = `${apiUrl}/users/?full=true&disable-hiding=true&uid=${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/admin/user`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/admin/user/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/user/data?id=${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "services":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      if (params.filter != null && params.filter.name == null) {
        params.filter.name = params.filter.q;
        delete params.filter.q;
      }
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
        full: "true",
      };
      url = `${apiUrl}/service/?${stringify(query)}`;
      break;
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${apiUrl}/service/detail?id=${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => {
          const datas = {
            data: responses
              .map((response) => {
                if (
                  response &&
                      response.result &&
                      response.result.length > 0
                ) {
                  return {
                    ...response.result[0],
                    id: response.result[0].uid,
                  };
                }
                return null;
              })
              .filter((elem) => elem != null),
          };
          return datas;
        });
    case GET_ONE:
      url = `${apiUrl}/service/detail?id=${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/service/`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/service/`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/service/?id=${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "special_fares":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      if (params.filter != null && params.filter.name == null) {
        params.filter.name = params.filter.q;
        delete params.filter.q;
      }
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
        full: "true",
      };
      url = `${apiUrl}/special_fares/?${stringify(query)}`;
      break;
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${apiUrl}/special_fares/${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => {
          const datas = {
            data: responses
              .map((response) => {
                if (
                  response &&
                      response.result &&
                      response.result.length > 0
                ) {
                  return {
                    ...response.result[0],
                    id: response.result[0].uid,
                  };
                }
                return null;
              })
              .filter((elem) => elem != null),
          };
          return datas;
        });
    case GET_ONE:
      url = `${apiUrl}/special_fares/${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/special_fares/`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/special_fares/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/special_fares/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "carrentalconfig_agencies":
    switch (type) {
    case GET_LIST:
      url = `${apiUrl}/agencies`;
      break;
    case GET_ONE:
      url = `${apiUrl}/agencies/${params.id}/configs/car-rental`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: {id:params.id,...response.result }       
          
            };
          }
          return { data: [] };
        });
    case UPDATE:
      url = `${apiUrl}/agencies/${params.id}/configs/car-rental`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/agencies/${params.id}/configs/car-rental`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "carrentalconfig_companies":
    switch (type) {
    case GET_LIST:
      url = `${apiUrl}/customer-configs/`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          const dataWithIds = response.result.map((item, index) => ({
            ...item,
            id: item.id || String(index), 
          }));
          return {
            data: dataWithIds,
            total: response.total,
          };
        });          
    case GET_ONE:
      url = `${apiUrl}/customer-configs/${params.id}/configs/car-rental`;
      return fetch(url, options)
        .then((res) => res.json())
        .then((response) => {
          if (response != null) {
            return { data: {id:params.id,...response.result }       
            };
          }
          return { data: [] };
        });
    case UPDATE:
      url = `${apiUrl}/customer-configs/${params.id}/configs/car-rental`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/customer-configs/${params.id}/configs/car-rental`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;

  case "entities":
    switch (type) {
    case "LOCK":
      url = `${apiUrl}/entity/${params.id}/readonly`;
      options.method = "POST";
      return fetch(url, options).then((res) => res.json()).then((response) => {
        if (response != null) {
          return {
            data: {
              ...response.result,
            },
          };
        }
        return { data: [] };
      });
    case "UNLOCK":
      url = `${apiUrl}/entity/${params.id}/readonly`;
      options.method = "DELETE";
      return fetch(url, options).then((res) => res.json()).then((response) => {
        if (response != null) {
          return {
            data: {
              ...response.result,
            },
          };
        }
        return { data: [] };
      });
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
        full: "true",
      };
      url = `${apiUrl}/entity/?${stringify(query)}`;
      break;
    case GET_MANY:
      // GET_MANY not supported directly, use several requests GET_ONE
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${apiUrl}/entity/detail?id=${id}`, options)
        )
      )
        .then((data) => Promise.all(data.map((res) => res.json())))
        .then((responses) => {
          const datas = {
            data: responses
              .map((response) => {
                if (
                  response &&
                      response.result &&
                      response.result.length > 0
                ) {
                  return {
                    ...response.result[0],
                    id: response.result[0].uid,
                  };
                }
                return null;
              })
              .filter((elem) => elem != null),
          };
          return datas;
        });
    case GET_ONE:
      url = `${apiUrl}/entity/detail?id=${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/entity/`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/entity/`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/entity/?id=${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "usercards":
    switch (type) {
    case GET_LIST:
    case GET_MANY:
    case GET_ONE:
      url = `${apiUrl}/admin/user/card`;
      break;
    case CREATE:
      url = `${apiUrl}/admin/user/card`;
      options.method = "POST";
      params.data = {
        ...params.data,
        effective_date: DateParser(params.data.effective_date),
        expiration_date: DateParser(params.data.expiration_date),
      };
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/admin/user/card/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "cardtypes":
    switch (type) {
    case GET_LIST:
    case GET_MANY:
    case GET_ONE:
      url = `${apiUrl}/user/cards/types`;
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "userpassports":
    switch (type) {
    case CREATE:
      url = `${apiUrl}/user/document`;
      options.method = "POST";
      const payload = {
        travel_document: {
          ...params.data,
          effective_date: DateParser(params.data.effective_date),
          expiration_date: DateParser(params.data.expiration_date),
        },
      };
      options.body = JSON.stringify(payload);
      break;
    case DELETE:
      url = `${apiUrl}/user/document/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "userdriverlicenses":
    switch (type) {
    case CREATE:
      url = `${apiUrl}/user/document`;
      options.method = "POST";
      const payload = {
        travel_document: {
          ...params.data,
          effective_date: DateParser(params.data.effective_date),
          expiration_date: null,
        },
      };
      options.body = JSON.stringify(payload);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "userdocuments":
    switch (type) {
    case DELETE:
      url = `${apiUrl}/user/document/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "companies":
    switch (type) {
    case "LOCK":
      url = `${apiUrl}/companies/${params.id}/readonly`;
      options.method = "POST";
      return fetch(url, options).then((res) => res.json()).then((response) => {
        if (response != null) {
          return {
            data: {
              ...response.result,
            },
          };
        }
        return { data: [] };
      });
    case "UNLOCK":
      url = `${apiUrl}/companies/${params.id}/readonly`;
      options.method = "DELETE";
      return fetch(url, options).then((res) => res.json()).then((response) => {
        if (response != null) {
          return {
            data: {
              ...response.result,
            },
          };
        }
        return { data: [] };
      });
    case GET_LIST:
      url = `${apiUrl}/companies/?limit=1000`;
      break;
    case GET_MANY:
      url = `${apiUrl}/companies/?limit=1000`;
      break;
    case GET_ONE:
      url = `${apiUrl}/companies/?id=${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/company/`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/companies/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "fees":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/company/fees?${stringify(query)}`;
      break;
    case GET_MANY:
    case GET_ONE:
      url = `${apiUrl}/company/fees`;
      break;
    case CREATE:
      url = `${apiUrl}/company/fees`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/company/fees/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/company/fees/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "analytics":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/company/analytics?${stringify(query)}`;
      break;
    case GET_MANY:
    case GET_ONE:
      url = `${apiUrl}/company/analytics`;
      break;
    case CREATE:
      url = `${apiUrl}/company/analytics`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/company/analytics/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/company/analytics/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "user_analytics":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        offset: (page - 1) * perPage,
        limit: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/company/user_analytics?${stringify(query)}`;
      break;
    case GET_MANY:
    case GET_ONE:
      url = `${apiUrl}/company/user_analytics`;
      break;
    case CREATE:
      url = `${apiUrl}/company/user_analytics`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/company/user_analytics/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/company/user_analytics/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;

  case "subsidy":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/subsidy/?${stringify(query)}`;
      break;
    case GET_MANY:
    case GET_ONE:
      url = `${apiUrl}/subsidy/?id=${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/subsidy/`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/subsidy/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/subsidy/${params.id}`;
      options.method = "DELETE";
      options.body = JSON.stringify(params.data);
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "ticket_log":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/ticket_log/?${stringify(query)}`;
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  case "mission_orders":
    switch (type) {
    case GET_LIST:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...params.filter,
        page,
        per_page: perPage,
        sort_by: field,
        order_by: order,
      };
      url = `${apiUrl}/mission_orders/?${stringify(query)}`;
      break;
    default:
      console.log("unknown type", type);
    }
    break;
  default:
    console.log("unknown resource", resource);
    switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;
    case UPDATE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "PUT";
      options.body = JSON.stringify(params.data);
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "DELETE";
      break;
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    break;
  }

  return fetch(url, options)
    .then((res) => parseJSONCatchErrors(res))
    .then((response) => {
      let total = 0;
      let data = null;
      // console.log(response)
      if (response.result != null) {
        switch (type) {
        case CREATE:
        case UPDATE:
          data = {
            ...response.result,
            id:
                response.result.uid != null
                  ? response.result.uid
                  : response.result.id,
          };
          return { data };
        case DELETE:
          data = { previousData: params.data, id: params.id };
          return { data };
        case GET_ONE:
          if (response instanceof Array) {
            response.result.forEach((elem) => {
              if (
                elem != null &&
                  elem.id != null &&
                  elem.uid == null &&
                  elem.id === params.id
              ) {
                data = elem;
              }
              if (elem != null && elem.uid === Number(params.id)) {
                data = { ...elem, id: elem.uid };
              }
            });
          } else {
            data = { ...response.result, id: response.result.uid };
          }

          if (data == null) {
            console.log("elem", params.id, "not found");
          }
          return { data };
        case GET_MANY:
          const elems = [];
          response.result.forEach((elem) => {
            if (elem.id != null && elem.uid == null) {
              elem.uid = elem.id;
            }
            if (
              elem != null &&
                params.ids != null &&
                params.ids.includes(elem.uid)
            ) {
              elems.push({ ...elem, id: elem.uid });
            }
          });
          return { data: elems, total: elems.length };
        default:
          if (response.total != null) {
            total = response.total;
          } else {
            total = response.result.length;
          }
          data = response.result.map((elem) => ({
            ...elem,
            id: elem.uid != null ? elem.uid : elem.id,
          }));
          return { data, total };
        }
      }
      switch (type) {
      case DELETE:
        data = { previousData: params.data, id: params.id };
        return { data };
      case GET_MANY:
      case GET_LIST:
        // response.result == null -> data not found, return empty array
        return { data: [], total: 0 };
      default:
        return { data, total };
      }
    });
};

function parseJSONCatchErrors(resp) {
  if (resp.status >= 200 && resp.status <= 299) {
    // no errors
    return resp.json();
  }
  if (resp.status === 401 || resp.status === 403) {
    // capture auth error
    throw resp;
  }
  let errMsg = `Erreur ${resp.status}`;
  if (resp.statusText) {
    errMsg = `${errMsg}: ${resp.statusText}`;
  }
  // try to build json to get error message
  return resp.json().then(
    (resp) => {
      if (resp.errorMessage) {
        errMsg = `${errMsg}: ${resp.errorMessage}`;
      } else if (resp.msg) {
        errMsg = `${errMsg}: ${resp.msg}`;
      }
      throw new Error(errMsg);
    },
    (e) => {
      if (e instanceof SyntaxError) {
        // cannot be parsed as json
        throw new Error(errMsg);
      } else {
        throw e;
      }
    }
  );
}
