import React, { Component } from "react";
import {
  Button,
  Confirm,
  GET_LIST,
  withDataProvider,
  useQueryWithStore,
  GET_MANY,
} from "react-admin";

class ResetPasswordButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.sendPasswords();
  };

  async sendPasswords() {
    const { dataProvider, selectedIds, resource } = this.props;
    const { data: users } = await dataProvider(GET_MANY, "users", {
      ids: selectedIds,
    });
    users.forEach((user) => {
      dataProvider(
        "PASSWORD_RESET",
        "",
        { username: user.username },
        {
          onSuccess: {
            notification: { body: "Mots de passe envoyés", level: "info" },
            refresh: true,
            redirectTo: `/${resource}`,
            unselectAll: true,
          },
          onError: {
            notification: { body: "Erreur lors de l'envoi", level: "warning" },
            refresh: true,
            unselectAll: true,
          },
        }
      );
    });
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <>
        <Button label={this.props.label} onClick={this.handleClick}>
          {this.props.icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Envoyer lien (ré)initialisation du mot de passe"
          content="Voulez-vous envoyer aux utilisateurs un lien pour réinitialiser leur mot de passe ?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </>
    );
  }
}

export default withDataProvider(ResetPasswordButton);
