import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = {
  link: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    textTransform: "uppercase",
    padding: "12px 16px",
    lineHeight: "22px",
    fontSize: "14px",
    textDecoration: "none",
    color: "#202020",
    borderBottom: "2px solid transparent",
    transition: "border-color 0.3s, color 0.3s",
    display: "inline-block",
    marginRight: "16px",
    "&.is-active": {
      borderBottom: "2px solid #3f51b5",
    },
    "&:active": {
      background: "#bfbfbf",
    },
  },
};

const TabLink = withStyles(styles)(({ classes, label, to, isActive }) => (
  <Link to={to} className={`${classes.link} ${isActive ? "is-active" : ""}`}>
    {label}
  </Link>
));

export default TabLink;
