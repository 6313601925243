import React from "react";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Receipt";
import moment from "moment";

const TravelersField = ({ className, record, source, detailed }) => {
  if (!record) return null;
  const value = get(record, source);
  if (value == null) return null;
  const names = value.map((traveler) => {
    let recap = "";
    if (traveler.recap_url) {
      recap = (
        <a
          aria-label="Recap"
          href={traveler.recap_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon style={{ verticalAlign: "middle" }} />
        </a>
      );
    }
    let details = "";
    if (detailed) {
      details = ` né(e) le ${moment(traveler.birthdate).format(
        "DD/MM/YYYY"
      )} ; Tel: ${traveler.phone}`;
    }
    return (
      <span key={traveler.uid}>
        {recap}
        {traveler.firstname} {traveler.lastname} ({traveler.email}){details}
      </span>
    );
  });

  return (
    <Typography component="span" body1="body1" className={className}>
      {names}
    </Typography>
  );
};

TravelersField.defaultProps = {
  addLabel: true,
};

export default TravelersField;
